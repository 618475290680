<template>
  <div>
    <sistema>
      <div class="home">AET</div>
    </sistema>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class Home extends Vue {}
</script>
