import { useApiWithAuth } from "@/modules/api";

interface PainelRoboPayload {
  idOrgao?: number | null;
  situacoesLicencas: Record<string, number>;
}

interface PainelRoboFilterPayload {
  idCliente?: number;
}

const usePainelService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getPainelRobo = (filter: PainelRoboFilterPayload): Promise<void> => {
    return get("/painel/fila", {
      ...filter
    });
  };

  return {
    data,
    loading,
    getPainelRobo
  };
};

export { usePainelService, PainelRoboPayload, PainelRoboFilterPayload };
