
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref,
  PropType
} from "vue";
import { useMunicipioService } from "../../modules/business/common/commonBusiness";
import { useStorage } from "../../modules/storage";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useToast } from "../../modules/message";
import {
  EngenheiroPayload,
  useEngenheiroService
} from "../../modules/business/engenheiro/engenheiroBusiness";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import EntitySelectorMunicipio from "../common/EntitySelectorMunicipio.vue";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";

interface EngenheiroCadastroState {
  entity: EngenheiroPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EngenheiroCadastroState>({
      entity: useEngenheiroService().safeEngenheiro(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const setEntity = (entity: EngenheiroPayload) => {
      state.entity = useEngenheiroService().safeEngenheiro(entity);
      const assinElement = document.querySelector(
        "#" + state.stateId + "_assinatura"
      ) as HTMLInputElement;
      if (assinElement) {
        assinElement.value = "";
      }
    };

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const downloadAssinatura = (newTab: boolean, onEnd: Function) => {
      if (state.entity.assinatura.idDocumentoEngenheiro) {
        const { downloadAssinatura, loading } = useEngenheiroService();
        state.loadings.push(loading);
        downloadAssinatura(
          state.entity.assinatura.idDocumentoEngenheiro ?? 0,
          newTab
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const save = () => {
      const idEngenheiroProps = props.idEngenheiro ?? 0;
      const isInsert = !idEngenheiroProps;
      const {
        saveNewEngenheiro,
        updateEngenheiro,
        loading: loadingCadastro,
        data: dataCadastro
      } = useEngenheiroService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewEngenheiro(state.entity)
        : updateEngenheiro(idEngenheiroProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Engenheiro salvo com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idEngenheiro);
          }
        })
        .catch(() => {
          if (state.entity.assinatura.keyBlDocumentoTemp) {
            useStorage().deleteTempFile(
              state.entity.assinatura.keyBlDocumentoTemp
            );
            state.entity.assinatura.keyBlDocumentoTemp = null;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const submitSave = () => {
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_assinatura"
      ) as HTMLInputElement;

      if (!file || !file.files || file.files.length === 0) {
        save();
      } else {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            state.entity.assinatura.keyBlDocumentoTemp = key;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            save();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      }
    };

    onMounted(async () => {
      const {
        getEngenheiro,
        newEngenheiro,
        data,
        loading
      } = useEngenheiroService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      (props.idEngenheiro
        ? getEngenheiro(props.idEngenheiro)
        : newEngenheiro(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      downloadAssinatura,
      municipiosFiltrados,
      amIAllowed,
      confirmSelectionCliente,
      stateLoading,
      stateBlocked,
      afterSetMunicipio,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorMunicipio
  },
  props: {
    idEngenheiro: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
