<template>
  <template v-if="stateReady">
    <input-with-label
      label="Órgão"
      :required="required"
      :addRequiredHiddenField="true"
      :hasValue="!!orgaoInterno?.idOrgao"
      v-slot="l"
    >
      <Dropdown
        v-model="orgaoInterno"
        :options="orgaosDisponiveis"
        optionLabel="sigla"
        :showClear="true"
        :id="l.id"
        :required="l.required"
        :filter="true"
        filterPlaceholder="Sigla do órgão"
        :disabled="!!readOnly"
        :placeholder="'&nbsp;'"
        v-focus="focus"
        :optionGroupLabel="applyGroupByAtendimento ? 'key' : null"
        :optionGroupChildren="applyGroupByAtendimento ? 'items' : null"
      >
        <template #option="slotProps">
          {{ getOrgaoAetStr(slotProps.option.idOrgao) }}
        </template>
        <template #value="slotProps">
          <div v-if="slotProps.value">
            <span v-text="getOrgaoAetStr(slotProps.value.idOrgao)" />
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
      </Dropdown>
    </input-with-label>
  </template>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useOrgaoAetService,
  OrgaoPayload
} from "../../modules/business/common/commonBusiness";

//TODO KAIO TROCAR OS ENTITYSELECTORS PARA USAR V-MODEL
interface OrgaoSelectorAetState {
  stateReady: boolean;
  orgaoInterno?: OrgaoPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<OrgaoSelectorAetState>({
      stateReady: false,
      orgaoInterno: useOrgaoAetService().getOrgaoAet(props.idOrgao)
    });

    watch([props], () => {
      if (props.idOrgao != state.orgaoInterno?.idOrgao) {
        state.orgaoInterno = useOrgaoAetService().getOrgaoAet(props.idOrgao);
      }
    });

    watch([state], () => {
      emit("after-set-orgao", state.orgaoInterno?.idOrgao ?? null);
    });

    const getOrgaoAetStr = (idOrgao?: number) => {
      return useOrgaoAetService().getOrgaoAetStr(idOrgao);
    };

    const orgaosDisponiveis = computed(() => {
      const orgaos = useOrgaoAetService().getOrgaosAet();
      if (props.applyGroupByAtendimento) {
        const map = new Map();
        const robo = {
          label: "Solicitação via Robo",
          order: 1
        };
        const capa = {
          label: "Solicitação via Capa",
          order: 2
        };
        const arm = {
          label: "Apenas Armazenamento",
          order: 3
        };
        orgaos.forEach((item) => {
          const key = item.integracaoOrgaoViaRobo
            ? robo
            : item.integracaoOrgaoViaCapa
            ? capa
            : arm;
          const collection = map.get(key);
          if (!collection) {
            map.set(key, [item]);
          } else {
            collection.push(item);
          }
        });

        return Array.from(map, ([key, value]) => ({
          key: key.label,
          order: key.order,
          items: value
        })).sort((a, b) => a.order - b.order);
      } else {
        return orgaos;
      }
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      orgaosDisponiveis,
      getOrgaoAetStr,
      ...toRefs(state)
    };
  },
  props: {
    required: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    applyGroupByAtendimento: {
      type: Boolean,
      default: false
    },
    idOrgao: {
      type: Number
    },
    id: {
      type: String
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
