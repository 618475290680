<template>
  <sistema>
    <div>
      <crud-cliente :isSearch="false" :idCliente="idCliente" />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudCliente from "../../components/cliente/CrudCliente.vue";

@Options({
  props: {
    idCliente: Number
  },
  components: {
    CrudCliente
  }
})
export default class Cliente extends Vue {
  idCliente?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
