import { useApiWithAuth } from "@/modules/api";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";

interface ImportacaoDocumentoPayload {
  cliente?: ClientePayload | null;
  keyBlDocumentoTemp?: string | null;
  anoExercicioCrlv?: number;
}

interface DocumentoReturn {
  nome: string;
  erro: string;
}

interface ImportacaoDocumentoReturn {
  sucesso?: Array<string> | null;
  erros?: Array<DocumentoReturn> | null;
}

const useImportacaoVeiculoService = () => {
  const { data, loading, post } = useApiWithAuth();

  const importFile = (
    importData: ImportacaoDocumentoPayload
  ): Promise<void> => {
    return post("/veiculo/importacao-documento-lote", importData);
  };

  return {
    data,
    loading,
    importFile
  };
};

export {
  ImportacaoDocumentoReturn,
  ImportacaoDocumentoPayload,
  useImportacaoVeiculoService
};
