
import {
  defineComponent,
  toRefs,
  reactive,
  PropType,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useGabaritoService,
  GabaritoPayload
} from "../../../modules/business/licenca/gabaritoBusiness";

//TODO KAIO TROCAR OS ENTITYSELECTORS PARA USAR V-MODEL
interface GabaritoSelectorState {
  stateReady: boolean;
  gabaritoInterno?: GabaritoPayload | null;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<GabaritoSelectorState>({
      stateReady: false,
      gabaritoInterno: useGabaritoService().getGabarito(props.idGabarito),
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    watch([props], () => {
      if (props.idGabarito != state.gabaritoInterno?.idGabarito) {
        state.gabaritoInterno = useGabaritoService().getGabarito(
          props.idGabarito
        );
      }
    });

    watch([state], () => {
      emit("after-set-gabarito", state.gabaritoInterno?.idGabarito ?? null);
    });

    const gabaritosDisponiveisInternal = computed(() => {
      if (props.gabaritosDisponiveis) {
        return props.gabaritosDisponiveis;
      }
      return useGabaritoService().getGabaritosAtivos();
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      gabaritosDisponiveisInternal,
      ...toRefs(state)
    };
  },
  props: {
    required: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    idGabarito: {
      type: Number
    },
    gabaritosDisponiveis: {
      type: Array as PropType<Array<GabaritoPayload>>,
      required: false
    },
    id: {
      type: String
    }
  }
});
