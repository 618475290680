
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  computed
} from "vue";
import CrudArtEngenheiroTransportador from "./CrudArtEngenheiroTransportador.vue";
import {
  ArtEngenheiroTransportadorPayload,
  useArtEngenheiroTransportadorService
} from "../../../modules/business/transportador/artEngenheiroTransportadorBusiness";
import { TransportadorPayload } from "../../../modules/business/transportador/transportadorBusiness";
import { format, formatDate } from "../../../modules/utils";
import { isPast } from "date-fns";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";

interface ArtEngenheiroTransportadorSelectorFilterState {
  transportador: TransportadorPayload;
  idEngenheiro?: number;
}

interface ArtEngenheiroTransportadorSelectorState {
  stateReady: boolean;
  crudOpen: boolean;
  idArtEngenheiroTransportadorToView?: number | null;
  filter: ArtEngenheiroTransportadorSelectorFilterState;
  entitiesToSelect: Array<ArtEngenheiroTransportadorPayload>;
  art?: ArtEngenheiroTransportadorPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ArtEngenheiroTransportadorSelectorState>({
      stateReady: false,
      crudOpen: false,
      idArtEngenheiroTransportadorToView: null,
      entitiesToSelect: [],
      filter: {
        transportador: props.transportador,
        idEngenheiro: props.idEngenheiro
      }
    });

    const es = ref();

    const entitiesSelected = computed(() => {
      return props.art && props.art.idArtEngenheiroTransportador
        ? [props.art]
        : state.art && state.art.idArtEngenheiroTransportador
        ? [state.art]
        : [];
    });

    const clearEntity = () => {
      state.art = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idArtEngenheiroTransportadorToView = null;
      es.value.focus();
    };

    const confirmSelection = (
      selected: Array<ArtEngenheiroTransportadorPayload>
    ) => {
      state.art = selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.art);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idArtEngenheiroTransportadorToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idArtEngenheiroTransportadorToView = null;
      emit("cancel-selection");
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idArtEngenheiroTransportadorToView = null;
      state.crudOpen = true;
    };

    const openEntity = (e: ArtEngenheiroTransportadorPayload) => {
      if (e.idArtEngenheiroTransportador) {
        state.idArtEngenheiroTransportadorToView =
          e.idArtEngenheiroTransportador;
        state.crudOpen = true;
      }
    };

    const getNome = (art: ArtEngenheiroTransportadorPayload) => {
      return useArtEngenheiroTransportadorService().getNomeArtEngenheiroTransportador(
        art
      );
    };

    onMounted(async () => {
      state.art = props.art;
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      confirmSelection,
      cancelSelection,
      clearEntity,
      entitiesSelected,
      es,
      getNome,
      format,
      formatDate,
      isPast,
      ...toRefs(state)
    };
  },
  components: {
    CrudArtEngenheiroTransportador
  },
  props: {
    art: {
      type: Object as PropType<ArtEngenheiroTransportadorPayload>
    },
    transportador: {
      type: Object as PropType<TransportadorPayload>,
      required: true
    },
    idEngenheiro: Number,
    cliente: Object as PropType<ClientePayload>,
    canEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "ART"
    }
  }
});
