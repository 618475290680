//Criado para conseguir usar o router em contextos externos ao vue (exemplo: dentro da api)
import app from "@/main";
import { Router } from "vue-router";
import { useConfirm as useConfirmInternal } from "primevue/useconfirm";
import { useToast as useToastInternal } from "primevue/usetoast";

export function initGlobalToast(): void {
  if (
    !app.config.globalProperties.$useOldToaster &&
    !app.config.globalProperties.$internalToast
  ) {
    app.config.globalProperties.$internalToast = useToastInternal();
  }
}

export function useGlobalToaster() {
  if (app.config.globalProperties.$useOldToaster) {
    return app.config.globalProperties.$toast;
  }
  initGlobalToast();
  return app.config.globalProperties.$internalToast;
}

export function initGlobalConfirm(): void {
  if (!app.config.globalProperties.$internalConfirm) {
    app.config.globalProperties.$internalConfirm = useConfirmInternal();
  }
}

export function useGlobalConfirm() {
  if (!app.config.globalProperties.$internalConfirm) {
    initGlobalConfirm();
  }
  return app.config.globalProperties.$internalConfirm;
}

export function useRouter(): Router {
  return app.config.globalProperties.$internalRouter;
}

export function focusOnLastElement(): void {
  if (app.config.globalProperties.$lastElementFocus) {
    try {
      app.config.globalProperties.$lastElementFocus.focus();
    } catch (err) {
      app.config.globalProperties.$lastElementFocus = null;
    }
  }
}
