<template>
  <sistema>
    <container :isModal="false" :loading="!stateReady">
      <template v-slot:header>
        <h3>Faturamento</h3>
      </template>
      <template v-slot:body>
        <div>
          <form @submit.prevent="submitSearch">
            <entity-selector-cliente
              :cliente="cliente"
              :idClienteFiltered="filter.idCliente"
              :canEdit="true"
              @confirm-selection="confirmSelectionCliente"
              :focus="true"
              :parentLoading="stateLoading"
            />
            <fieldset :disabled="stateBlocked">
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-6">
                  <entity-selector-transportador
                    :cliente="cliente"
                    :idTransportadorFiltered="filter.idTransportador"
                    @confirm-selection="confirmSelectionTransportador"
                    :focus="!amIAllowed('view', 'root-cliente')"
                    :required="false"
                  />
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label label="Situação" v-slot="l">
                    <Dropdown
                      :id="l.id"
                      :required="l.required"
                      v-model="filter.flSituacao"
                      :options="tiposDisponiveis"
                      optionValue="flag"
                      optionLabel="descricao"
                      :placeholder="'&nbsp;'"
                      :disabled="stateBlocked"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <period-picker
                    v-model:start="filter.dtInicial"
                    v-model:end="filter.dtFinal"
                    v-model:type="periodoFixo"
                    :showTypeSelector="false"
                  />
                </div>
                <div class="p-col-12">
                  <Button
                    type="submit"
                    class="p-button-rounded"
                    label="Enviar"
                  />
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </template>
    </container>
  </sistema>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import EntitySelectorTransportador from "../../components/transportador/EntitySelectorTransportador.vue";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { formatDate, DATE_SELECTORS } from "../../modules/utils";
import {
  useLicencaFaturamentoService,
  LicencaFaturamentoFilterPayload,
  TipoLicencaFaturamentoPayload
} from "../../modules/business/licenca/licencaFaturamentoBusiness";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import EntitySelectorCliente from "../../components/cliente/EntitySelectorCliente.vue";
import { TransportadorPayload } from "../../modules/business/transportador/transportadorBusiness";

interface CombinacaoVeiculoState {
  filter: LicencaFaturamentoFilterPayload;
  cliente?: ClientePayload | null;
  stateLoading: boolean;
  stateReady: boolean;
  tiposDisponiveis: Array<TipoLicencaFaturamentoPayload>;
  periodoFixo: string;
}

export default defineComponent({
  setup() {
    const state = reactive<CombinacaoVeiculoState>({
      filter: { flSituacao: "F" },
      cliente: null,
      stateLoading: false,
      stateReady: false,
      tiposDisponiveis: useLicencaFaturamentoService().getTiposLicencaFaturamento(),
      periodoFixo: DATE_SELECTORS.MONTH
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateBlocked = computed(() => {
      if (state.stateLoading) {
        return true;
      } else if (amIAllowed("view", "root-cliente")) {
        return !state.filter.idCliente;
      }
      return false;
    });

    const executeSearch = () => {
      state.stateLoading = true;
      const { downloadFaturamento } = useLicencaFaturamentoService();
      downloadFaturamento(state.filter)
        .then(() => {
          state.stateLoading = false;
        })
        .catch(() => {
          state.stateLoading = false;
        });
    };

    const submitSearch = () => {
      return executeSearch();
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
      state.cliente = c;
      state.filter = { idCliente: state.filter.idCliente, flSituacao: "F" };
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      state.filter.idTransportador =
        t && t.idTransportador ? t.idTransportador : undefined;
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submitSearch,
      amIAllowed,
      confirmSelectionCliente,
      confirmSelectionTransportador,
      formatDate,
      stateBlocked,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorTransportador,
    EntitySelectorCliente
  }
});
</script>
