<template>
  <div>
    <container
      :isModal="true"
      @close-modal="closeScreen()"
      :loading="stateLoading || !stateReady"
    >
      <template v-slot:header>
        <h3>Cadastro do Modelo</h3>
      </template>
      <template v-slot:body>
        <template v-if="stateReady">
          <form @submit.prevent="submitSave">
            <entity-selector-cliente
              :cliente="entity.cliente"
              :canEdit="
                (!entity.cliente?.idCliente || !entity.idModeloVeiculoTrator) &&
                  !stateBlocked
              "
              @confirm-selection="confirmSelectionCliente"
              :required="true"
              :parentLoading="stateBlocked"
            />
            <fieldset
              :disabled="
                stateBlocked ||
                  (amIAllowed('view', 'root-cliente') &&
                    !entity.cliente?.idCliente)
              "
            >
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-4 p-xl-2">
                  <input-with-label label="Marca" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.marca"
                      :id="l.id"
                      :required="l.required"
                      maxlength="50"
                      minlength="3"
                      v-focus
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-8 p-xl-5">
                  <input-with-label
                    label="Nome Oficial"
                    subLabel="Conforme Descrito no CRLV"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.nome"
                      :id="l.id"
                      :required="l.required"
                      maxlength="50"
                      minlength="3"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-xl-5">
                  <input-with-label
                    label="Apelido"
                    subLabel="Usado internamente no GovPass"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.apelido"
                      :id="l.id"
                      :required="l.required"
                      maxlength="400"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="CMT"
                    subLabel="t"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.cmt"
                      :step="0.001"
                      :minFractionDigits="3"
                      :maxFractionDigits="3"
                      :min="1"
                      :max="250"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="Potência"
                    subLabel="cv"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.potencia"
                      :step="1"
                      :min="1"
                      :max="1500"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="Potência Máxima"
                    subLabel="kW"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.potenciaMaxima"
                      :step="1"
                      :min="1"
                      :max="800"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="Torque Máximo"
                    subLabel="Nm"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.torqueMaximo"
                      :step="1"
                      :min="1"
                      :max="10000"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-sm-12 p-md-4">
                  <input-with-label
                    label="Peso Incidente No Eixo De Tração"
                    subLabel="Kgf(P)"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.massaEixoTracao"
                      :step="1"
                      :min="1"
                      :max="100000"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-sm-12 p-md-4">
                  <input-with-label
                    label="Redução Máxima Da Caixa de Câmbio"
                    subLabel="ic"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.reducaoMaximaCaixaMudanca"
                      :step="0.01"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      :min="10"
                      :max="20"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-sm-12 p-md-4">
                  <input-with-label
                    label="Redução No Eixo Traseiro"
                    subLabel="id"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.reducaoEixo"
                      :step="0.01"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      :min="1"
                      :max="50"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>

                <Panel
                  header="Relação De Redução Da Caixa De Câmbio"
                  class="p-field p-col-12"
                  :toggleable="true"
                >
                  <div class="p-grid">
                    <div
                      v-for="marcha in entity.marchas"
                      :key="marcha.numeroMarcha"
                      class="p-field p-col-12 p-sm-6 p-md-4 p-lg-2"
                    >
                      <input-with-label
                        :label="marcha.numeroMarcha + 'ª IC'"
                        v-slot="l"
                      >
                        <InputNumber
                          v-model="marcha.reducao"
                          :step="0.01"
                          :minFractionDigits="2"
                          :maxFractionDigits="2"
                          :min="0"
                          :max="50"
                          :id="l.id"
                          :required="l.required"
                        />
                      </input-with-label>
                    </div>
                  </div>
                </Panel>

                <div class="p-col-12">
                  <button-group>
                    <Button
                      type="submit"
                      class="p-button-rounded"
                      label="Salvar"
                    />
                    <Button
                      label="Gerar Cópia"
                      class="p-button-rounded p-button-text"
                      v-if="
                        entity.idModeloVeiculoTrator &&
                          amIAllowed('create', 'veiculo-trator-modelo')
                      "
                      @click="gerarCopia()"
                    />
                  </button-group>
                </div>
              </div>
            </fieldset>
          </form>
        </template>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref
} from "vue";
import { useAuth, PermissionPayload } from "../../../../modules/auth";
import { useToast } from "../../../../modules/message";
import {
  ModeloVeiculoTratorPayload,
  useModeloVeiculoTratorService
} from "../../../../modules/business/veiculo/veiculoTratorBusiness";
import EntitySelectorCliente from "../../../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../../../modules/business/cliente/clienteBusiness";
import { PropType } from "vue";

interface ModeloVeiculoTratorCadastroState {
  entity: ModeloVeiculoTratorPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ModeloVeiculoTratorCadastroState>({
      entity: useModeloVeiculoTratorService().safeModeloVeiculoTrator(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const setEntity = (entity?: ModeloVeiculoTratorPayload) => {
      state.entity = useModeloVeiculoTratorService().safeModeloVeiculoTrator(
        entity
      );
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const save = () => {
      const idModeloVeiculoTratorProps =
        (props.modeloVeiculoTrator &&
          props.modeloVeiculoTrator.idModeloVeiculoTrator) ||
        0;
      const isInsert = !idModeloVeiculoTratorProps;
      const {
        saveNewModeloVeiculoTrator,
        updateModeloVeiculoTrator,
        loading: loadingCadastro,
        data: dataCadastro
      } = useModeloVeiculoTratorService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewModeloVeiculoTrator(state.entity)
        : updateModeloVeiculoTrator(idModeloVeiculoTratorProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Modelo salvo com sucesso");
          if (isInsert) {
            emit(
              "after-save-new-entity",
              dataCadastro.value.idModeloVeiculoTrator
            );
          }
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const gerarCopia = () => {
      emit("generate-copy", state.entity.idModeloVeiculoTrator);
    };

    const submitSave = () => {
      save();
    };

    onMounted(async () => {
      setEntity(props.modeloVeiculoTrator);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      state.stateReady = true;
    });

    return {
      submitSave,
      closeScreen,
      gerarCopia,
      amIAllowed,
      confirmSelectionCliente,
      stateLoading,
      stateBlocked,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente
  },
  props: {
    modeloVeiculoTrator: Object as PropType<ModeloVeiculoTratorPayload>,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean,
    canGenerateCopy: Boolean
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
