
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import EntitySelectorVeiculoTrator from "../../components/veiculo/trator/EntitySelectorVeiculoTrator.vue";
import EntitySelectorMultiCarreta from "../../components/veiculo/carreta/EntitySelectorMultiCarreta.vue";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { formatDate } from "../../modules/utils";
import {
  useLicencaService,
  LicencaPorVeiculoFilterPayload,
  LicencaPayload
} from "../../modules/business/licenca/licencaBusiness";
import { useLicencaOrgaoService } from "../../modules/business/licenca/licencaOrgaoBusiness";
import { useToast } from "../../modules/message";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../modules/business/cliente/clienteBusiness";
import {
  VeiculoTratorPayload,
  useVeiculoTratorService
} from "../../modules/business/veiculo/veiculoTratorBusiness";
import { CarretaPayload } from "../../modules/business/veiculo/carretaBusiness";
import { PaginationResult, defaultResults } from "../../pagination";
import EntitySelectorCliente from "../../components/cliente/EntitySelectorCliente.vue";
import CrudLicenca from "../../components/licenca/CrudLicenca.vue";
import {
  useTransportadorService,
  TransportadorPayload
} from "../../modules/business/transportador/transportadorBusiness";
import { useGabaritoService } from "../../modules/business/licenca/gabaritoBusiness";
import { useOrgaoAetService } from "../../modules/business/common/commonBusiness";
import { useRouter } from "vue-router";

interface CombinacaoVeiculoState {
  filter: LicencaPorVeiculoFilterPayload;
  cliente?: ClientePayload | null;
  stateLoading: boolean;
  stateReady: boolean;
  results: PaginationResult<LicencaPayload>;
  page: number;
  showEdit: boolean;
  idLicencaToView: number | null;
}

export default defineComponent({
  setup() {
    const router = useRouter();

    const state = reactive<CombinacaoVeiculoState>({
      filter: { idCarretas: [] },
      results: defaultResults(),
      cliente: null,
      stateLoading: false,
      stateReady: false,
      showEdit: false,
      idLicencaToView: null,
      page: 0
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const executeSearch = () => {
      state.stateLoading = true;
      const { data, getRelatorioLicencasPorVeiculo } = useLicencaService();
      getRelatorioLicencasPorVeiculo(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          if (!state.results || state.results.totalPages <= 0) {
            useToast().warning(
              "Nenhuma licença encontrada para esta combinação de veículos"
            );
          }
          state.page = state.results.number;
          state.stateLoading = false;
        })
        .catch(() => {
          state.stateLoading = false;
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
      state.cliente = c;
      state.filter = { idCarretas: [], idCliente: state.filter.idCliente };
    };

    const confirmSelectionVeiculoTrator = (v?: VeiculoTratorPayload) => {
      state.filter.idVeiculoTrator =
        v && v.idVeiculoTrator ? v.idVeiculoTrator : undefined;
    };

    const confirmSelectionCarretas = (c?: Array<CarretaPayload>) => {
      state.filter.idCarretas = [];

      if (c && c.length) {
        c.forEach((car) => {
          if (car && car.idCarreta) {
            state.filter.idCarretas.push(car.idCarreta);
          }
        });
      }
    };

    const openEntity = (idLicenca: number, newTab?: boolean) => {
      if (newTab) {
        window.open(
          router.resolve({
            name: "licenca-edit",
            params: { idLicenca: idLicenca + "" }
          }).fullPath
        );
      } else {
        state.idLicencaToView = idLicenca;
        state.showEdit = true;
      }
    };

    const afterRegisterScreenClosed = () => {
      state.showEdit = false;
      state.idLicencaToView = null;
    };

    const downloadAet = (
      idLicenca: number,
      idLicencaOrgao: number,
      newTab?: boolean
    ) => {
      const { downloadAet } = useLicencaOrgaoService();
      state.stateLoading = true;
      downloadAet(idLicenca, idLicencaOrgao, newTab)
        .then(() => {
          state.stateLoading = false;
        })
        .catch(() => {
          state.stateLoading = false;
        });
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    const getNomeTransportador = (e?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const getNomeVeiculoTrator = (e?: VeiculoTratorPayload) => {
      return useVeiculoTratorService().getNomeVeiculoTrator(e);
    };

    const getNomeGabarito = (idGabarito?: number | null) => {
      return useGabaritoService().getNomeGabarito(idGabarito);
    };

    const getOrgaoAetSigla = (idOrgao?: number | null) => {
      return useOrgaoAetService().getOrgaoAetSigla(idOrgao);
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submitSearch,
      afterPageChange,
      openEntity,
      amIAllowed,
      confirmSelectionCliente,
      confirmSelectionVeiculoTrator,
      confirmSelectionCarretas,
      getNomeTransportador,
      getNomeVeiculoTrator,
      getNomeGabarito,
      getOrgaoAetSigla,
      getNomeCliente,
      downloadAet,
      formatDate,
      afterRegisterScreenClosed,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorMultiCarreta,
    EntitySelectorVeiculoTrator,
    EntitySelectorCliente,
    CrudLicenca
  }
});
