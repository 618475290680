<template>
  <template class="p-d-flex p-jc-center" style="width: 100%" v-if="stateReady">
    <!--<a
      class="p-menuitem-link"
      @click.stop="clickView"
      v-if="imgErroThumbnailUrl"
    >
      <img
        class="img-fluid"
        style="width: 100%"
        :src="imgErroThumbnailUrl"
        alt="Imagem do Erro"
      />
    </a>
    <Skeleton width="100%" height="10rem" v-else />
    -->

    <Button
      label="Ver detalhes da situação"
      class="p-button-rounded p-button-text"
      @click="clickView()"
    />

    <container
      :isModal="true"
      :loading="!stateReady"
      v-if="containerOpen"
      @close-modal="closeView()"
    >
      <template v-slot:header>
        <h3>Detalhes da Situação</h3>
      </template>
      <template v-slot:body>
        <div class="p-d-grid p-m-2">
          <div class="p-col-12">
            <h4 v-text="'Situação GovPass:'" />
            <div
              class="p-col p-p-0"
              v-html="
                /\r|\n/.exec(licencaOrgao.situacaoGovpass)
                  ? '<ul><li>' +
                    licencaOrgao.situacaoGovpass
                      .split(/\r|\n/)
                      .join('</li><li>') +
                    '</li></ul>'
                  : licencaOrgao.situacaoGovpass
              "
            />
          </div>
          <div class="p-col-12">
            <h4 v-text="'Situação Órgão:'" />
            <div class="p-col p-p-0" v-text="licencaOrgao.situacao" />
          </div>
          <div
            class="p-col-12"
            v-if="
              !!licencaOrgao.timestampUltimaConsulta ||
                !!licencaOrgao.timestampUltimaConsulta
            "
          >
            <h4 v-text="'Horário Última Consulta:'" />
            <div
              class="p-col p-p-0"
              v-text="formatDateTime(licencaOrgao.timestampUltimaConsulta)"
            />
          </div>
          <div
            class="p-col-12"
            v-else-if="
              !!licencaOrgao.timestampAlteracao ||
                !!licencaOrgao.timestampAlteracao
            "
          >
            <h4 v-text="'Horário Última Alteração:'" />
            <div
              class="p-col p-p-0"
              v-text="formatDateTime(licencaOrgao.timestampAlteracao)"
            />
          </div>
          <div
            class="p-col-12"
            v-if="amIAllowed('view_error_image', licencaOrgao.permissionInfo)"
          >
            <h4
              class="p-col-fixed p-p-0"
              style="width: 12rem"
              v-text="'Imagem'"
            />
            <img
              class="img-fluid"
              style="width: 100%"
              v-if="imgErrolUrl"
              :src="imgErrolUrl"
              alt="Imagem do Erro"
            />
            <Skeleton width="100%" height="80vh" v-else />
          </div>
          <div
            class="p-col-12"
            v-if="amIAllowed('view_execution_log', licencaOrgao.permissionInfo)"
          >
            <h4 v-text="'Log:'" />
            <div class="p-col p-p-0" v-text="logExecucao" />
          </div>
        </div>
      </template>
    </container>
  </template>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted, PropType } from "vue";

import { useAuth, PermissionPayload } from "../../../modules/auth";
import {
  useLicencaOrgaoService,
  LicencaOrgaoPayload
} from "../../../modules/business/licenca/licencaOrgaoBusiness";
import { formatDateTime } from "../../../modules/utils";

interface LicencaOrgaoErroImgState {
  stateId: string;
  stateReady: boolean;
  containerOpen: boolean;
  imgErrolUrl: string | null;
  logExecucao: string | null;
  imgErroThumbnailUrl: string | null;
  licencaOrgao: LicencaOrgaoPayload;
}

export default defineComponent({
  setup(props) {
    const state = reactive<LicencaOrgaoErroImgState>({
      imgErroThumbnailUrl: null,
      imgErrolUrl: null,
      logExecucao: null,
      stateReady: false,
      containerOpen: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      licencaOrgao: props.licencaOrgao
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const clickView = () => {
      const idLicenca = state.licencaOrgao.idLicenca;
      const idLicencaOrgao = state.licencaOrgao.idLicencaOrgao;
      if (idLicenca && idLicencaOrgao) {
        state.imgErrolUrl = null;
        state.logExecucao = null;

        if (
          amIAllowed("view_error_image", state.licencaOrgao?.permissionInfo)
        ) {
          const { getUrlErro, data } = useLicencaOrgaoService();
          getUrlErro(idLicenca, idLicencaOrgao).then(() => {
            state.imgErrolUrl = data?.value ?? null;
          });
        }

        if (
          amIAllowed("view_execution_log", state.licencaOrgao?.permissionInfo)
        ) {
          const { getLogExecucao, data: dataLog } = useLicencaOrgaoService();
          getLogExecucao(idLicenca, idLicencaOrgao).then(() => {
            state.logExecucao = dataLog.value;
          });
        }
        state.containerOpen = true;
      }
    };

    const closeView = () => {
      state.imgErrolUrl = null;
      state.logExecucao = null;
      state.containerOpen = false;
    };

    const loadImgErro = () => {
      //state.imgErroThumbnailUrl = null;
      //if (props.idLicenca && props.idLicencaOrgao) {
      //  const { getUrlErroThumbnail, data } = useLicencaOrgaoService();
      //  getUrlErroThumbnail(props.idLicenca, props.idLicencaOrgao).then(() => {
      //    state.imgErroThumbnailUrl = data?.value ?? null;
      //  });
      //}
    };

    onMounted(async () => {
      state.stateReady = true;
      loadImgErro();
    });

    return {
      amIAllowed,
      clickView,
      closeView,
      formatDateTime,
      ...toRefs(state)
    };
  },
  props: {
    licencaOrgao: {
      type: Object as PropType<LicencaOrgaoPayload>,
      required: true
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
