<template>
  <div>
    <container
      :isModal="true"
      @close-modal="closeScreen()"
      :loading="stateLoading || !stateReady"
    >
      <template v-slot:header>
        <h3>Cadastro do Planilha de Trechos</h3>
      </template>
      <template v-slot:body>
        <template v-if="stateReady">
          <form @submit.prevent="submitSave">
            <entity-selector-cliente
              :cliente="entity.cliente"
              :canEdit="
                (!entity.cliente?.idCliente || !entity.idPlanilhaTrechos) &&
                  !stateBlocked
              "
              @confirm-selection="confirmSelectionCliente"
              :required="true"
              :parentLoading="stateBlocked"
            />
            <fieldset :disabled="stateFullBlocked">
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-6">
                  <input-with-label label="Nome" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.nome"
                      maxlength="500"
                      :id="l.id"
                      :required="l.required"
                      v-focus
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6">
                  <entity-selector-orgao-aet
                    :idOrgao="entity.idOrgao"
                    @after-set-orgao="afterSetOrgao"
                    :required="true"
                    :readOnly="stateFullBlocked"
                  />
                </div>
                <template v-if="!entity.idOrgao">
                  <b
                    >Selecione um órgão para habilitar o cadastro de trechos.</b
                  >
                </template>
                <template v-else>
                  <div class="sub-title">
                    <h4>Importar Trechos</h4>
                  </div>
                  <div class="p-field p-fluid p-col-12">
                    <template v-if="mostraImportacaoLicenca">
                      <div class="p-inputgroup">
                        <input-with-label label="Número da Licença" v-slot="l">
                          <InputText
                            v-model="numeroLicencaImportacao"
                            maxlength="50"
                            :id="l.id"
                            :required="l.required"
                          />
                        </input-with-label>
                        <Button
                          class="p-button-sm p-button-icon-only"
                          @click="loadFromLicenca()"
                          :disabled="!numeroLicencaImportacao"
                          style="min-width: initial"
                          v-title="'Carregar trechos da licença'"
                        >
                          <ProgressSpinner
                            v-if="loadingTrechos"
                            style="width: 1.2rem; height: 1.2rem"
                          />
                          <i
                            class="fas fa-sync-alt"
                            style="width: 1.2rem"
                            v-else
                          />
                        </Button>
                      </div>
                    </template>
                    <template v-else>
                      <div class="p-inputgroup">
                        <input-file
                          label="Arquivo no formato xls/xlsx a ser Importado"
                          :id="stateId + '_fileImport'"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          :hasDownload="false"
                        />
                        <button-group
                          :menuItems="getModeloMenu()"
                          menuButtonClass="p-button-rounded p-button-info"
                        >
                          <Button
                            class="
                              p-button-rounded p-button-sm p-button-icon-only
                            "
                            @click="loadFromFile()"
                            style="min-width: initial"
                            v-title="'Carregar do Arquivo'"
                          >
                            <ProgressSpinner
                              v-if="loadingTrechos"
                              style="width: 1.2rem; height: 1.2rem"
                            />
                            <i
                              class="fas fa-upload"
                              style="width: 1.2rem"
                              v-else
                            />
                          </Button>
                        </button-group>
                      </div>
                    </template>
                  </div>
                  <div class="p-field p-col-12">
                    <search-result
                      :showSelector="false"
                      :results="entity.trechos"
                      :loading="stateLoading"
                      :breakpoint="'90rem'"
                      :hidePagination="true"
                    >
                      <template v-slot:title> Trechos </template>
                      <template v-slot:columns>
                        <Column
                          field="rodovia"
                          header="Rodovia"
                          :style="mostraDescricaoTrechos ? 'width: 7rem;' : ''"
                        >
                          <template #body="slotProps">
                            <input-with-label
                              label="Rodovia"
                              :required="true"
                              v-slot="l"
                            >
                              <InputText
                                v-model="slotProps.data.rodovia"
                                maxlength="14"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>
                        <Column
                          field="idUnidadeFederal"
                          header="UF"
                          :style="mostraDescricaoTrechos ? 'width: 7rem;' : ''"
                        >
                          <template #body="slotProps">
                            <entity-selector-uf
                              :idUnidadeFederal="
                                slotProps.data.idUnidadeFederal
                              "
                              :required="true"
                              :readOnly="stateFullBlocked"
                              @after-set-uf="
                                (idUnidadeFederal) =>
                                  afterSetUFTrecho(
                                    slotProps.data,
                                    idUnidadeFederal
                                  )
                              "
                            />
                          </template>
                        </Column>
                        <Column
                          field="kmInicial"
                          header="km Inicial"
                          :style="mostraDescricaoTrechos ? 'width: 7rem;' : ''"
                        >
                          <template #body="slotProps">
                            <input-with-label
                              label="km Inicial"
                              :required="true"
                              v-slot="l"
                            >
                              <InputNumber
                                v-model="slotProps.data.kmInicial"
                                :min="0"
                                :max="2000"
                                :step="0.001"
                                :minFractionDigits="3"
                                :maxFractionDigits="3"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>

                        <Column
                          field="sgTrechoInicial"
                          header="Sigla Trecho Inicial"
                          v-if="mostraDescricaoTrechos"
                          style="width: 9.5rem"
                        >
                          <template #body="slotProps">
                            <input-with-label
                              label="Sigla Trecho Inicial"
                              v-slot="l"
                              :required="true"
                            >
                              <InputText
                                v-model="slotProps.data.sgTrechoInicial"
                                maxlength="20"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>

                        <Column
                          field="nmTrechoInicial"
                          header="Nome Trecho Inicial"
                          v-if="mostraDescricaoTrechos"
                        >
                          <template #body="slotProps">
                            <input-with-label
                              label="Nome Trecho Inicial"
                              v-slot="l"
                            >
                              <InputText
                                v-model="slotProps.data.nmTrechoInicial"
                                maxlength="250"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>

                        <Column
                          field="kmFinal"
                          header="km Final"
                          :style="mostraDescricaoTrechos ? 'width: 7rem;' : ''"
                        >
                          <template #body="slotProps">
                            <input-with-label
                              label="km Final"
                              :required="true"
                              v-slot="l"
                            >
                              <InputNumber
                                v-model="slotProps.data.kmFinal"
                                :min="0"
                                :max="2000"
                                :step="0.001"
                                :minFractionDigits="3"
                                :maxFractionDigits="3"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>

                        <Column
                          field="sgTrechoFinal"
                          header="Sigla Trecho Final"
                          v-if="mostraDescricaoTrechos"
                          style="width: 9.5rem"
                        >
                          <template #body="slotProps">
                            <input-with-label
                              label="Sigla Trecho Final"
                              v-slot="l"
                              :required="true"
                            >
                              <InputText
                                v-model="slotProps.data.sgTrechoFinal"
                                maxlength="20"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>

                        <Column
                          field="nmTrechoFinal"
                          header="Nome Trecho Final"
                          v-if="mostraDescricaoTrechos"
                        >
                          <template #body="slotProps">
                            <input-with-label
                              label="Nome Trecho Final"
                              v-slot="l"
                            >
                              <InputText
                                v-model="slotProps.data.nmTrechoFinal"
                                maxlength="250"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>

                        <Column
                          field="observacao"
                          header="Observação"
                          v-if="mostraDescricaoTrechos"
                        >
                          <template #body="slotProps">
                            <input-with-label label="Observação" v-slot="l">
                              <InputText
                                v-model="slotProps.data.observacao"
                                maxlength="250"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </template>
                        </Column>

                        <Column
                          :exportable="false"
                          style="width: 5rem; text-align: right"
                        >
                          <template #body="slotProps">
                            <button-group>
                              <Button
                                icon="fas fa-trash-alt "
                                class="p-button-rounded p-button-danger"
                                @click="removeTrecho(slotProps.data)"
                              />
                            </button-group>
                          </template>
                        </Column>
                      </template>
                      <template v-slot:actions>
                        <Button
                          class="p-button-rounded"
                          label="Adicionar Trecho"
                          @click="addTrecho()"
                        />
                      </template>
                    </search-result>
                  </div>

                  <div class="p-col-12">
                    <Button
                      type="submit"
                      class="p-button-rounded"
                      label="Salvar"
                    />
                    <Button
                      label="Gerar Cópia"
                      class="p-button-rounded p-button-text"
                      v-if="
                        entity.idPlanilhaTrechos &&
                          amIAllowed('create', 'trechos-planilha')
                      "
                      @click="gerarCopia()"
                    />
                  </div>
                </template>
              </div>
            </fieldset>
          </form>
        </template>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  PropType,
  Ref
} from "vue";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useToast } from "../../modules/message";
import { useStorage } from "../../modules/storage";
import { useUfService } from "../../modules/business/common/commonBusiness";
import {
  PlanilhaTrechosPayload,
  TrechoPayload,
  usePlanilhaTrechosService
} from "../../modules/business/trecho/planilhaTrechosBusiness";
import EntitySelectorOrgaoAet from "../common/EntitySelectorOrgaoAet.vue";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import { useOrgaoAetService } from "../../modules/business/common/commonBusiness";
import EntitySelectorUf from "../common/EntitySelectorUf.vue";
import { MenuEvent } from "../../router";

interface PlanilhaTrechosCadastroState {
  entity: PlanilhaTrechosPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  numeroLicencaImportacao: string;
  loadings: Array<Ref<boolean>>;
  loadingTrechos: boolean;
  mostraImportacaoLicenca: boolean;
  mostraDescricaoTrechos: boolean;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<PlanilhaTrechosCadastroState>({
      entity: usePlanilhaTrechosService().safePlanilhaTrechos(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      numeroLicencaImportacao: "",
      loadings: [],
      mostraImportacaoLicenca: false,
      mostraDescricaoTrechos: false,
      loadingTrechos: false
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };
    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const stateFullBlocked = computed(() => {
      return (
        stateBlocked.value ||
        (amIAllowed("view", "root-cliente") && !state.entity.cliente?.idCliente)
      );
    });

    const afterSetOrgao = (idOrgao?: number | null) => {
      state.entity.idOrgao = idOrgao;
      //limpar
      state.mostraImportacaoLicenca = false;
      state.mostraDescricaoTrechos = false;
      if (state.entity.idOrgao) {
        const orgao = useOrgaoAetService().getOrgaoAet(state.entity.idOrgao);
        if (orgao) {
          if (orgao.permiteDownloadTrechosLicenca) {
            state.mostraImportacaoLicenca = true;
          }
          if (orgao.permiteDescricaoTrechos) {
            state.mostraDescricaoTrechos = true;
          }
        }
      }
    };

    const setEntity = (entity?: PlanilhaTrechosPayload) => {
      state.entity = usePlanilhaTrechosService().safePlanilhaTrechos(entity);
      const docElement = document.querySelector(
        "#" + state.stateId + "_fileImport"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }

      afterSetOrgao(state.entity.idOrgao);
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const afterSetUFTrecho = (
      trecho: TrechoPayload,
      idUnidadeFederal?: number | null
    ) => {
      if (idUnidadeFederal !== trecho.idUnidadeFederal) {
        trecho.idUnidadeFederal = idUnidadeFederal;
      }
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const getUfSigla = (idUnidadeFederal?: number) => {
      return useUfService().getUfSigla(idUnidadeFederal);
    };

    const submitSave = () => {
      const idPlanilhaTrechosProps =
        (props.planilhaTrechos && props.planilhaTrechos.idPlanilhaTrechos) ?? 0;
      const isInsert = !idPlanilhaTrechosProps;
      const {
        saveNewPlanilhaTrechos,
        updatePlanilhaTrechos,
        loading: loadingCadastro,
        data: dataCadastro
      } = usePlanilhaTrechosService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewPlanilhaTrechos(state.entity)
        : updatePlanilhaTrechos(idPlanilhaTrechosProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Planilha salva com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idPlanilhaTrechos);
          }
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const atribuiTrechosCarga = (trechos?: Array<TrechoPayload> | null) => {
      if (trechos && trechos.length) {
        state.entity.trechos = trechos;
        useToast().success("Carregado com sucesso");
      } else {
        useToast().success(
          "Carregado com sucesso, porém nenhum trecho foi encontrado. Mantendo os trechos antigos..."
        );
      }
    };

    const getModeloMenu = () => {
      return [
        {
          label: "Baixar Arquivo de Exemplo",
          icon: "fas fa-download",
          command: (event?: MenuEvent) => {
            if (event?.originalEvent) {
              event.originalEvent.preventDefault();
            }
            const { getDefaultFile, loading } = usePlanilhaTrechosService();
            state.loadings.push(loading);
            getDefaultFile(event?.originalEvent?.button == 1)
              .then(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      ];
    };

    const loadFromFile = () => {
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_fileImport"
      ) as HTMLInputElement;
      if (file && file.files && file.files.length > 0) {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        state.loadingTrechos = true;
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            const trechos = usePlanilhaTrechosService();
            state.loadings.push(trechos.loading);
            trechos
              .loadFromFile(key)
              .then(() => {
                atribuiTrechosCarga(trechos.data.value);
                state.loadings.splice(
                  state.loadings.indexOf(trechos.loading),
                  1
                );
                state.loadingTrechos = false;
                file.value = "";
                useStorage().deleteTempFile(key);
              })
              .catch(() => {
                state.loadings.splice(
                  state.loadings.indexOf(trechos.loading),
                  1
                );
                state.loadingTrechos = false;
                useStorage().deleteTempFile(key);
              });
            state.loadingTrechos = false;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          })
          .catch(() => {
            state.loadingTrechos = false;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      } else {
        useToast().warning("Selecione um arquivo.");
      }
    };

    const loadFromLicenca = () => {
      if (state.numeroLicencaImportacao && state.entity.idOrgao) {
        const trechos = usePlanilhaTrechosService();
        state.loadings.push(trechos.loading);
        state.loadingTrechos = true;
        trechos
          .loadFromLicenca(state.entity.idOrgao, state.numeroLicencaImportacao)
          .then(() => {
            atribuiTrechosCarga(trechos.data.value);
            state.loadings.splice(state.loadings.indexOf(trechos.loading), 1);
            state.loadingTrechos = false;
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(trechos.loading), 1);
            state.loadingTrechos = false;
          });
      }
    };

    const addTrecho = () => {
      state.entity.trechos.push({});
    };

    const gerarCopia = () => {
      emit("generate-copy", state.entity.idPlanilhaTrechos);
    };

    const removeTrecho = (trecho: TrechoPayload) => {
      state.entity.trechos.splice(state.entity.trechos.indexOf(trecho), 1);
    };

    onMounted(async () => {
      setEntity(props.planilhaTrechos);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      state.stateReady = true;
    });

    return {
      submitSave,
      closeScreen,
      amIAllowed,
      confirmSelectionCliente,
      stateLoading,
      stateBlocked,
      stateFullBlocked,
      loadFromFile,
      loadFromLicenca,
      afterSetOrgao,
      addTrecho,
      removeTrecho,
      gerarCopia,
      getUfSigla,
      getModeloMenu,
      afterSetUFTrecho,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorOrgaoAet,
    EntitySelectorUf
  },
  props: {
    planilhaTrechos: Object as PropType<PlanilhaTrechosPayload>,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
