import { useApiWithAuth } from "@/modules/api";
import { PermissionPayload } from "@/modules/auth";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";

interface PlanilhaTrechosFilterPayload {
  idCliente?: number;
  idOrgao?: number | null;
  nome?: string | null;
  arquivado: boolean;
}

interface TrechoPayload {
  rodovia?: string | null;
  kmInicial?: number | null;
  kmFinal?: number | null;
  nmTrechoInicial?: string | null;
  nmTrechoFinal?: string | null;
  sgTrechoInicial?: string | null;
  sgTrechoFinal?: string | null;
  observacao?: string | null;
  idUnidadeFederal?: number | null;
}

interface PlanilhaTrechosPayload {
  idPlanilhaTrechos?: number | null;
  nome?: string | null;
  padrao?: boolean | null;
  idOrgao?: number | null;
  trechos: Array<TrechoPayload>;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
}

interface TrechoDiferencaPayload {
  trechosAdicionados: Array<TrechoPayload>;
  trechosRemovidos: Array<TrechoPayload>;
  trechosSelecionados: Array<TrechoPayload>;
}

const usePlanilhaTrechosService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    patch,
    downloadFile,
    downloadFileNewTab
  } = useApiWithAuth();

  const searchPlanilhaTrechos = (
    filter: PlanilhaTrechosFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/trecho/planilha", {
      ...filter,
      ...{
        page: page
      }
    });
  };

  const getPlanilhaTrechos = (idPlanilhaTrechos: number): Promise<void> => {
    return get(`/trecho/planilha/${idPlanilhaTrechos}`);
  };

  const newPlanilhaTrechos = (idCliente?: number | null): Promise<void> => {
    return get(
      "/trecho/planilha/new",
      idCliente ? { idCliente: idCliente } : {}
    );
  };

  const safeFilterPlanilhaTrecho = (): PlanilhaTrechosFilterPayload => {
    return {
      idOrgao: null,
      arquivado: false
    };
  };

  const shelvePlanilhaTrechos = (idPlanilhaTrechos: number): Promise<void> => {
    return patch("/trecho/planilha/" + idPlanilhaTrechos + "/arquivar");
  };

  const unshelvePlanilhaTrechos = (
    idPlanilhaTrechos: number
  ): Promise<void> => {
    return patch("/trecho/planilha/" + idPlanilhaTrechos + "/desarquivar");
  };

  const setDefaultPlanilhaTrechos = (
    idPlanilhaTrechos: number
  ): Promise<void> => {
    return patch("/trecho/planilha/" + idPlanilhaTrechos + "/padrao");
  };

  const copyPlanilhaTrechos = (idPlanilhaTrechos: number): Promise<void> => {
    return get("/trecho/planilha/new", {
      idPlanilhaOrigem: idPlanilhaTrechos
    });
  };

  const saveNewPlanilhaTrechos = (
    planilhaTrechos: PlanilhaTrechosPayload
  ): Promise<void> => {
    return post("/trecho/planilha", planilhaTrechos);
  };

  const updatePlanilhaTrechos = (
    idPlanilhaTrechos: number,
    planilhaTrechos: PlanilhaTrechosPayload
  ): Promise<void> => {
    return put(`/trecho/planilha/${idPlanilhaTrechos}`, planilhaTrechos);
  };

  const loadFromFile = (keyTemp: string): Promise<void> => {
    return get(`/trecho/planilha/carregar/arquivo/${keyTemp}`);
  };

  const getDefaultFile = (newTab?: boolean): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/trecho/planilha/carregar/arquivo/modelo`
    );
  };

  const loadFromLicenca = (
    idOrgao: number,
    numeroPedido: string
  ): Promise<void> => {
    return get(
      `/trecho/planilha/carregar/licenca/orgao/${idOrgao}/numero-licenca/${numeroPedido}`
    );
  };

  const safePlanilhaTrechos = (
    entity?: PlanilhaTrechosPayload | null
  ): PlanilhaTrechosPayload => {
    const ret = entity ?? {
      trechos: []
    };
    ret.trechos = ret.trechos ?? [];
    return ret;
  };

  const getNomePlanilhaTrechos = (entity?: PlanilhaTrechosPayload) => {
    if (!entity) {
      return "";
    }
    return entity.nome;
  };

  return {
    data,
    loading,
    searchPlanilhaTrechos,
    safeFilterPlanilhaTrecho,
    saveNewPlanilhaTrechos,
    updatePlanilhaTrechos,
    newPlanilhaTrechos,
    copyPlanilhaTrechos,
    getPlanilhaTrechos,
    safePlanilhaTrechos,
    setDefaultPlanilhaTrechos,
    shelvePlanilhaTrechos,
    unshelvePlanilhaTrechos,
    loadFromFile,
    loadFromLicenca,
    getDefaultFile,
    getNomePlanilhaTrechos
  };
};

export {
  usePlanilhaTrechosService,
  PlanilhaTrechosFilterPayload,
  PlanilhaTrechosPayload,
  TrechoDiferencaPayload,
  TrechoPayload
};
