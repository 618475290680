
import { Options, Vue } from "vue-class-component";
import CrudModeloVeiculoTrator from "../../components/veiculo/trator/modelo/CrudModeloVeiculoTrator.vue";

@Options({
  props: {
    isNew: Boolean,
    idModeloVeiculoTrator: Number,
    idModeloVeiculoTratorCopy: Number
  },
  components: {
    CrudModeloVeiculoTrator
  }
})
export default class ModeloVeiculoTrator extends Vue {
  isNew?: boolean;
  idModeloVeiculoTrator?: number;
  idModeloVeiculoTratorCopy?: number;
}
