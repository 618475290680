<template>
  <div v-if="stateReady">
    <template
      v-for="(entity, index) in entities"
      :key="entity[keyParam ?? 'id']"
    >
      <slot :entity="entity" :index="index" />
    </template>
  </div>
</template>

<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineComponent, PropType, reactive, toRefs, onMounted } from "vue";

interface EntitySelectorState {
  stateReady: boolean;
}

export default defineComponent({
  setup() {
    const state = reactive<EntitySelectorState>({
      stateReady: false
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      ...toRefs(state)
    };
  },
  props: {
    entities: {
      type: Array as PropType<Array<any>>
    },
    keyParam: String,
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
