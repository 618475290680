import { useApiWithAuth } from "@/modules/api";
import { useCache } from "@/modules/cache";

interface TipoCarroceriaPayload {
  idTipoCarroceria?: number | null;
  descricao?: string | null;
  tipoCargaPadrao?: string | null;
  auxiliar?: boolean | null;
  carroceriasCompativeis: Array<number> | null;
}

export const useTipoCarroceriaService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getTiposCarroceriaToCache = (): Promise<void> => {
    return get(`/veiculo/carroceria`);
  };

  const getTiposCarroceria = (): Array<TipoCarroceriaPayload> => {
    return useCache().getTiposCarroceria();
  };

  const getTipoCarroceria = (
    idTipoCarroceria?: number | null
  ): TipoCarroceriaPayload | null => {
    if (!idTipoCarroceria) {
      return null;
    }

    return (
      getTiposCarroceria().find(
        (a) => a.idTipoCarroceria == idTipoCarroceria
      ) ?? null
    );
  };

  return {
    data,
    loading,
    getTiposCarroceriaToCache,
    getTiposCarroceria,
    getTipoCarroceria
  };
};

export { TipoCarroceriaPayload };
