<template>
  <sistema>
    <div>
      <crud-engenheiro
        :isSearch="false"
        :isNew="isNew"
        :idEngenheiro="idEngenheiro"
      />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudEngenheiro from "../../components/engenheiro/CrudEngenheiro.vue";

@Options({
  props: {
    isNew: Boolean,
    idEngenheiro: Number
  },
  components: {
    CrudEngenheiro
  }
})
export default class Engenheiro extends Vue {
  isNew?: boolean;
  idEngenheiro?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
