<template>
  <div v-if="stateReady">
    <Dialog
      v-if="isModal"
      v-model:visible="dialogOpen"
      class="dialog-internal"
      :position="'center'"
      :modal="true"
      :dismissableMask="false"
      :draggable="false"
      @hide="clickClose"
    >
      <div class="body-container p-inputtext-sm" :id="stateId">
        <loading v-if="loading" :fullscreen="false" />
        <slot name="body" :id="stateId" />
      </div>
      <template #header>
        <slot name="header" />
      </template>
    </Dialog>
    <div v-else class="p-col-12">
      <div class="body-container p-inputtext-sm" :id="stateId">
        <div v-if="$slots.header">
          <slot name="header" />
        </div>
        <div>
          <loading v-if="loading" />
          <slot name="body" :id="stateId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";

import { initGlobalMessageService } from "../../../modules/message";

interface ModalState {
  stateReady: boolean;
  dialogOpen: boolean;
  stateId: string;
}
export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ModalState>({
      stateReady: false,
      dialogOpen: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    initGlobalMessageService();

    onMounted(async () => {
      if (props.isModal) {
        state.dialogOpen = true;
      }

      state.stateReady = true;
    });

    const clickClose = () => {
      if (props.isModal) {
        emit("close-modal");
      }
    };

    return {
      clickClose,
      ...toRefs(state)
    };
  },
  props: {
    isModal: Boolean,
    loading: Boolean
  },
  components: {}
});
</script>
