<template>
  <div @click="stateReady && total > 0 && openTab()">
    <i class="fas fa-bell fa-lg" v-if="!stateReady || total <= 0" />
    <i class="fas fa-bell fa-lg" v-else v-badge.danger />

    <Sidebar v-model:visible="isOpen" position="right" @hide="closeTab">
      <div class="p-grid">
        <div
          class="p-fluid p-mt-3 p-mb-1"
          v-for="notificacao in notificacoes"
          :key="notificacao.idNotificacao"
        >
          <div class="p-inputgroup">
            <input-with-label
              :label="nameNotification(notificacao)"
              :subLabel="formatDateTime(notificacao.timestampCadastro)"
              v-slot="l"
            >
              <Button
                :icon="notificacao.loading ? '' : iconNotification(notificacao)"
                class="
                  p-button-text p-inputtext-sm p-inputtext p-inputwrapper-filled
                "
                :class="classNotification(notificacao)"
                iconPos="right"
                :label="notificacao.mensagem"
                :id="l.id"
                @click="onClickNotification(notificacao)"
                :disabled="!!notificacao.loading"
                @click.middle.prevent.stop="
                  onClickNotification(notificacao, true)
                "
              />
            </input-with-label>
            <Button
              class="p-button-text p-button-icon-only"
              v-if="!!notificacao.loading"
              style="width: auto; padding: 0px"
              :disabled="true"
            >
              <ProgressSpinner
                v-if="stateLoading"
                style="width: 0.8rem; height: 0.8rem"
              />
            </Button>
          </div>
        </div>
      </div>
      <div class="p-fluid p-col-12" v-observe-visibility="loadMoreUnread">
        <Button
          class="p-button-rounded"
          :disabled="stateLoading || total <= 0"
          @click="loadMoreUnread(true)"
        >
          <span class="p-button-label">
            <span v-if="stateLoading">
              Carregando Notificações...
              <ProgressSpinner
                v-if="stateLoading"
                style="width: 1.2rem; height: 1.2rem"
              />
            </span>
            <span v-else> Carregar Mais </span>
          </span>
        </Button>
      </div>
    </Sidebar>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  onUnmounted,
  computed,
  Ref
} from "vue";
import {
  NotificacaoPayload,
  NotificacaoTotalPayload,
  useNotificacaoService
} from "../../modules/business/notificacao/notificacaoBusiness";
import { useLicencaOrgaoService } from "../../modules/business/licenca/licencaOrgaoBusiness";
import { useAuth } from "../../modules/auth";
import { formatDateTime } from "../../modules/utils";
import { useRouter } from "vue-router";
import Sidebar from "primevue/sidebar";

interface NotificacaoState {
  stateReady: boolean;
  isOpen: boolean;
  notificacoes: Array<NotificacaoPayload>;
  totalUnreadNotification: NotificacaoTotalPayload;
  loadings: Array<Ref<boolean>>;
  stateId: string;
  interval?: number | null;
}

export default defineComponent({
  setup() {
    const router = useRouter();
    const state = reactive<NotificacaoState>({
      stateReady: false,
      isOpen: false,
      notificacoes: [],
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      totalUnreadNotification: {}
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });
    const total = computed(() => {
      const tt =
        state.totalUnreadNotification &&
        state.totalUnreadNotification.total &&
        state.totalUnreadNotification.total > 0
          ? state.totalUnreadNotification.total
          : 0;
      return tt;
    });

    const reloadNotification = (): Promise<void> => {
      if (!useAuth().user?.value) {
        return Promise.resolve();
      }
      return new Promise<void>((resolve, reject) => {
        const total = useNotificacaoService();
        total
          .findUnreadTotal()
          .then(() => {
            const totalDb = total.data.value;
            state.totalUnreadNotification.total = totalDb.total;
            state.totalUnreadNotification.idUltimaNotificacaoDisponivel =
              totalDb.idUltimaNotificacaoDisponivel;
            resolve();
          })
          .catch(reject);
      });
    };

    const markAsRead = (): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        const idUltimaNotificacao =
          state.notificacoes.length > 0
            ? state.notificacoes
                .map((e) => e.idNotificacao)
                .reduce((a, b) => Math.max(a, b))
            : 0;

        if (idUltimaNotificacao <= 0) {
          resolve();
        } else {
          const { read, loading } = useNotificacaoService();
          read(idUltimaNotificacao)
            .then(() => {
              resolve();
              state.loadings.splice(state.loadings.indexOf(loading), 1);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(loading), 1);
              reject();
            });
        }
      });
    };

    const loadMoreUnread = (isVisible: boolean) => {
      if (total.value > 0 && isVisible && !stateLoading.value) {
        const { findUnread, loading, data } = useNotificacaoService();
        state.loadings.push(loading);
        findUnread()
          .then(() => {
            if (data && data.value) {
              data.value.forEach((n: NotificacaoPayload) => {
                state.notificacoes.push(n);
              });
            }
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .then(() => {
            return markAsRead();
          })
          .then(() => {
            return reloadNotification();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      }
    };

    const clear = () => {
      state.notificacoes.splice(0, state.notificacoes.length);
    };

    const openTab = () => {
      if (!state.isOpen) {
        state.isOpen = true;
      }
    };

    const closeTab = () => {
      state.isOpen = false;
      clear();
    };

    const iconNotification = (notificacao: NotificacaoPayload) => {
      if (notificacao.topico == "licenca:disponivel") {
        return "far fa-file-pdf";
      } else if (notificacao.topico == "boleto:disponivel") {
        return "fas fa-dollar-sign";
      }
      return "pi pi-pencil";
    };

    const classNotification = (notificacao: NotificacaoPayload) => {
      let ret = "";
      switch (notificacao.topico) {
        case "licenca:disponivel":
        case "boleto:disponivel":
          ret = "p-button-success";
          break;
        case "licenca:fila":
          ret = "p-button-info";
          break;
        case "licenca:erro":
        case "veiculotrator:erro":
        case "carreta:erro":
        case "veiculo:erro":
          ret = "p-button-danger";
          break;
      }
      return ret;
    };

    const nameNotification = (notificacao: NotificacaoPayload) => {
      let ret = "";
      switch (notificacao.topico) {
        case "licenca:disponivel":
          ret = "Licença disponível para baixar";
          break;
        case "boleto:disponivel":
          ret = "Boleto disponível para baixar";
          break;
        case "licenca:fila":
          ret = "Licença iniciou execução";
          break;
        case "licenca:erro":
          ret = "Erro cadastro licença";
          break;
        case "veiculotrator:erro":
        case "carreta:erro":
        case "filaveiculo:erro":
          ret = "Erro Cadastro Veículo";
          break;
      }
      return ret;
    };

    const onClickNotification = (
      notificacao: NotificacaoPayload,
      newTab?: boolean
    ) => {
      let routeParams = null;
      const meta = notificacao.metadata;
      switch (notificacao.topico) {
        case "licenca:erro":
        case "licenca:fila":
          routeParams = {
            name: "licenca-edit",
            params: {
              idLicenca: meta.idLicenca + ""
            }
          };
          break;
        case "veiculotrator:semcrlv:erro":
          routeParams = {
            name: "veiculo-trator-edit",
            params: {
              idVeiculoTrator: meta.idVeiculoTrator + ""
            }
          };
          break;
        case "carreta:semcrlv:erro":
          routeParams = {
            name: "carreta-edit",
            params: {
              idCarreta: meta.idCarreta + ""
            }
          };
          break;
        case "filaveiculo:erro:usuario":
        case "filaveiculo:erro:cpfcnpj":
          routeParams = {
            name: "transportador-edit",
            params: {
              idTransportador: meta.idTransportador + ""
            }
          };
          break;
      }
      if (routeParams) {
        if (newTab) {
          window.open(router.resolve(routeParams).fullPath);
        } else {
          router.push(routeParams);
          closeTab();
        }
      } else if (
        notificacao.topico == "licenca:disponivel" &&
        meta.idLicencaOrgao &&
        meta.idLicenca
      ) {
        notificacao.loading = true;
        useLicencaOrgaoService()
          .downloadAet(meta.idLicenca, meta.idLicencaOrgao, newTab)
          .then(() => {
            notificacao.loading = false;
          })
          .catch(() => {
            notificacao.loading = false;
          });
      } else if (
        notificacao.topico == "boleto:disponivel" &&
        meta.idLicencaOrgao &&
        meta.idLicenca
      ) {
        notificacao.loading = true;
        useLicencaOrgaoService()
          .downloadBoleto(meta.idLicenca, meta.idLicencaOrgao, newTab)
          .then(() => {
            notificacao.loading = false;
          })
          .catch(() => {
            notificacao.loading = false;
          });
      }
    };

    onMounted(async () => {
      reloadNotification()
        .then(() => {
          state.interval = setInterval(reloadNotification, 5 * 60 * 1000);
          state.stateReady = true;
        })
        .catch(() => {
          state.stateReady = true;
        });
    });

    onUnmounted(() => {
      if (state.interval) {
        clearInterval(state.interval);
        state.interval = null;
      }
    });

    return {
      onClickNotification,
      classNotification,
      nameNotification,
      iconNotification,
      loadMoreUnread,
      formatDateTime,
      openTab,
      closeTab,
      stateLoading,
      total,
      ...toRefs(state)
    };
  },
  components: { Sidebar }
});
</script>
