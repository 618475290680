<template>
  <sistema>
    <div>
      <crud-art-engenheiro-transportador label="Fila ARTS" />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudArtEngenheiroTransportador from "../../components/transportador/art/CrudArtEngenheiroTransportador.vue";

@Options({
  components: {
    CrudArtEngenheiroTransportador
  }
})
export default class Dashboard extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
