<template>
  <div>
    <div v-if="stateReady">
      <fieldset :disabled="loading">
        <DataTable
          :value="internalResults.content"
          responsiveLayout="stack"
          :breakpoint="breakpoint"
          :loading="loading"
          loadingIcon=""
          :lazy="true"
          :rowClass="internalRowClassFunction"
          :stripedRows="stripedRows"
          :resizableColumns="true"
          columnResizeMode="expand"
          class="p-datatable-sm"
          v-model:selection="componentSelected"
          :dataKey="keyParam"
          ref="tableRef"
          @rowSelect="toggleSelected($event.data)"
          @rowUnselect="toggleSelected($event.data)"
          @rowSelectAll="toggleSelectedAll($event.data)"
          @rowUnselectAll="toggleSelectedAll($event.data)"
          @rowDblclick="toggleSelected($event.data, true)"
          :selectionMode="
            showSelector ? (multiple ? 'multiple' : 'single') : null
          "
          :metaKeySelection="false"
          :rowGroupMode="keyGroupParam ? rowGroupMode : null"
          :groupRowsBy="keyGroupParam ? keyGroupParam : null"
          :scrollable="
            !!(keyGroupParam && rowGroupMode == 'subheader' && scrollable)
          "
          :scrollHeight="
            keyGroupParam && rowGroupMode == 'subheader' && scrollable
              ? '600px'
              : null
          "
          :editMode="allowEdit ? 'cell' : null"
        >
          <template #loading>
            <div class="main-loading" />
          </template>
          <template #header v-if="!hideHeader">
            <slot name="title"> Resultados </slot>
          </template>
          <Column
            v-if="$slots.rowGroupHeader"
            :exportable="false"
            header=" "
            bodyClass="rowgroup-group-data"
            :style="
              'width: 1rem; padding: 0; ' +
                (scrollable ? 'max-width: 1rem;' : '')
            "
          />
          <Column
            v-if="showSelector"
            :selectionMode="multiple ? 'multiple' : 'single'"
            :exportable="false"
            headerClass=" p-jc-center"
            bodyClass=" p-jc-center"
            :style="
              'width: 2.1rem; ' + (scrollable ? 'max-width: 2.1rem;' : '')
            "
          />

          <Column
            field="blocked"
            header="Motivo Bloqueio"
            :bodyClass="'blocked-column'"
            :style="'width: 12rem; ' + (scrollable ? 'max-width: 12rem;' : '')"
            v-if="anyBlockedString"
          >
            <template #body="slotProps">
              <span
                v-text="
                  internalBlockedSelection(slotProps.data) &&
                  typeof internalBlockedSelection(slotProps.data) === 'string'
                    ? internalBlockedSelection(slotProps.data)
                    : ''
                "
              />
            </template>
          </Column>

          <Column
            field="cliente.idCliente"
            header="Cliente"
            :style="'width: 15rem; ' + (scrollable ? 'max-width: 15rem;' : '')"
            v-if="showClient && amIAllowed('view', 'root-cliente')"
          >
            <template #body="slotProps">
              <span
                v-text="
                  getNomeCliente(internalGetClientFunction(slotProps.data))
                "
              />
            </template>
          </Column>

          <slot name="columns" />

          <Column
            v-if="$slots.rowGroupHeader && !scrollable"
            :exportable="false"
            header=" "
            class="hidden-input"
            style="width: 0; padding: 0"
          />

          <template #groupheader="slotProps" v-if="$slots.rowGroupHeader">
            <div
              class="rowgroup-group-data"
              :style="slotProps.data.groupIndex > 0 ? 'margin-top: -1rem;' : ''"
            >
              <slot name="rowGroupHeader" :data="slotProps.data" />
            </div>
          </template>

          <template
            #groupfooter="slotProps"
            v-if="$slots.rowGroupHeader || $slots.rowGroupFooter"
          >
            <div class="rowgroup-group-data" v-if="scrollable">
              <slot
                name="rowGroupFooter"
                :data="slotProps.data"
                v-if="$slots.rowGroupFooter"
              />
              <div v-else style="height: 1rem" />
            </div>
            <td style="padding: 0" :colspan="columnsSize" v-else>
              <div class="rowgroup-group-data">
                <slot
                  name="rowGroupFooter"
                  :data="slotProps.data"
                  v-if="$slots.rowGroupFooter"
                />
                <div v-else style="height: 1rem" />
              </div>
            </td>
          </template>

          <template #footer v-if="!hidePagination || $slots.footer">
            <slot name="footer" v-if="$slots.footer" />
            <!-- sobrescrito pois era necessario para controle externo do numero de itens -->
            <div class="p-d-flex p-jc-center" v-if="!hidePagination">
              <Paginator
                :rows="internalResults.size"
                :totalRecords="internalResults.totalElements"
                template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="Registro {first} ao {last}, de {totalRecords}"
                class="p-paginator-bottom"
                @page="onPage($event)"
              />
            </div>
          </template>
        </DataTable>
        <div v-observe-visibility="setVisibilityBottom" />
        <Toolbar
          class="p-pl-0 buttom-bottom"
          :class="floatingButton ? 'float-buttom-bottom p-pr-3 ' : 'p-pr-0 '"
          v-if="!hideActions"
        >
          <template #start>
            <button-group>
              <Button
                label="Confirmar"
                class="p-button-rounded"
                v-if="showSelector && hasConfirm"
                @click="onConfirm()"
              />
              <slot name="actions" :selected="internalSelected" />
            </button-group>
          </template>
        </Toolbar>
      </fieldset>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRefs,
  ref,
  watch,
  onMounted
} from "vue";
import { PaginationResult } from "../../../pagination";
import {
  toggleElement,
  indexesOfElement,
  getArrayExclude,
  isArrayEquals
} from "../../../modules/utils";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../../modules/business/cliente/clienteBusiness";

interface SearchResultState {
  initialSelected: Array<any>; //sistema usa arrays para todos, para padronizar
  internalSelected: Array<any>; //sistema usa arrays para todos, para padronizar
  componentSelected: Array<any> | any; //componente as vezes usa array, as vezes usa o proprio objeto
  stateReady: boolean;
  isBottomVisible: boolean;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<SearchResultState>({
      initialSelected: props.selected ? [...props.selected] : [],
      internalSelected: [],
      componentSelected: props.multiple ? [] : null,
      stateReady: false,
      isBottomVisible: true,
      stateId: Math.random()
        .toString(36)
        .substring(2)
    });

    const tableRef = ref();

    const processInitialState = () => {
      state.internalSelected = props.selected ? [...props.selected] : [];
      state.componentSelected = props.multiple
        ? props.selected
          ? [...props.selected]
          : []
        : props.selected
        ? props.selected[0]
        : null;
    };

    processInitialState();

    const onPage = (event: any) => {
      emit("after-page-change", event.page);
    };

    const floatingButton = computed(() => {
      return !state.isBottomVisible;
    });

    const internalResults = computed(() => {
      if (props.results) {
        if (Object.prototype.hasOwnProperty.call(props.results, "content")) {
          return props.results as PaginationResult<any>;
        } else if (Array.isArray(props.results)) {
          return {
            content: props.results,
            totalPages: 1,
            number: 0
          };
        }
      }
      return {
        content: [],
        totalPages: 1,
        number: 0
      };
    });

    const internalBlockedSelection = (element: any) => {
      if (props.blockedSelection) {
        return props.blockedSelection(element);
      }
      return null;
    };

    const anyBlockedString = computed(() => {
      return !!internalResults.value.content?.find(
        (el) =>
          internalBlockedSelection(el) &&
          typeof internalBlockedSelection(el) === "string"
      );
    });

    const internalGetClientFunction = (element: any) => {
      if (props.getClientFunction) {
        return props.getClientFunction(element);
      }
      return element.cliente;
    };

    const internalRowClassFunction = (element: any) => {
      const ret = [];
      if (props.rowClassFunction) {
        const rcf = props.rowClassFunction(element);
        if (rcf) {
          if (Array.isArray(rcf)) {
            rcf.forEach((r) => ret.push(r));
          } else if (typeof rcf == "object") {
            Object.keys(rcf).forEach((key) => {
              if (rcf[key]) {
                ret.push(key);
              }
            });
          } else {
            ret.push(rcf);
          }
        }
      }
      if (props.showSelector) {
        if (internalBlockedSelection(element)) {
          ret.push("table-line-disabled");
        }
      }
      return ret.join(" ");
    };

    const onConfirm = () => {
      emit(
        "confirm-selection",
        //Todos elementos
        state.internalSelected,
        //elmentos adicionados
        getArrayExclude(
          (ob) => ob[props.keyParam],
          props.selected,
          state.internalSelected
        ),
        //elmentos removidos
        getArrayExclude(
          (ob) => ob[props.keyParam],
          state.internalSelected,
          props.selected
        )
      );
    };

    const toggleSelected = (element: any, confirm?: boolean) => {
      if (props.showSelector) {
        if (internalBlockedSelection(element)) {
          if (props.multiple) {
            toggleElement(
              state.componentSelected,
              element,
              (ob) => ob[props.keyParam],
              true
            );
          } else {
            state.componentSelected = null;
          }
        } else {
          toggleElement(
            state.internalSelected,
            element,
            (ob) => ob[props.keyParam],
            props.multiple
          );
          if (!props.hasConfirm || (!props.multiple && confirm)) {
            onConfirm();
          }
        }
      }
    };

    const toggleSelectedAll = (elements?: Array<any> | null) => {
      if (props.showSelector && props.multiple) {
        if (elements?.length) {
          elements
            .filter(
              (el) =>
                indexesOfElement(
                  state.internalSelected,
                  el,
                  (ob) => ob[props.keyParam]
                ).length <= 0
            )
            .forEach((el) => toggleSelected(el));
        } else {
          state.internalSelected
            .filter(
              (el) =>
                indexesOfElement(
                  state.internalSelected,
                  el,
                  (ob) => ob[props.keyParam]
                ).length > 0
            )
            .forEach((el) => toggleSelected(el));
        }
      }
    };

    const columnsSize = computed(() => {
      return tableRef.value?.columns?.length ?? 0;
    });

    const setVisibilityBottom = (isVisible: boolean) => {
      state.isBottomVisible = !!isVisible;
    };

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    onMounted(async () => {
      state.stateReady = true;
      if (props.selected) {
        watch([props], () => {
          if (!isArrayEquals(props.selected, state.initialSelected)) {
            processInitialState();
          }
        });
      }
    });

    return {
      onPage,
      getNomeCliente,
      amIAllowed,
      toggleSelected,
      toggleSelectedAll,
      internalBlockedSelection,
      internalRowClassFunction,
      internalGetClientFunction,
      anyBlockedString,
      floatingButton,
      setVisibilityBottom,
      columnsSize,
      onConfirm,
      tableRef,
      internalResults,
      ...toRefs(state)
    };
  },
  props: {
    selected: {
      type: Array as PropType<Array<any>>
    },
    results: {
      type: Object as PropType<PaginationResult<any>> | PropType<Array<any>>
    },
    showSelector: {
      type: Boolean,
      default: false
    },
    hasConfirm: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    keyParam: {
      type: String,
      default: "id"
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showClient: {
      type: Boolean,
      default: false
    },
    getClientFunction: {
      type: Function,
      required: false
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    keyGroupParam: String,
    rowGroupMode: {
      type: String,
      default: "subheader"
    },
    breakpoint: {
      type: String,
      default: "60rem"
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
    stripedRows: {
      type: Boolean,
      default: true
    },
    rowClassFunction: {
      type: Function,
      required: false
    },
    blockedSelection: {
      type: Function,
      required: false
    }
  },
  components: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.selector {
  max-width: 20px;
  min-width: 20px;
}

.p-paginator {
  border: hidden !important;
}

.buttom-bottom {
  -webkit-animation: all 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: all 0.5s; /* Firefox < 16 */
  -ms-animation: all 0.5s; /* Internet Explorer */
  -o-animation: all 0.5s; /* Opera < 12.1 */
  animation: all 0.5s;
  &.float-buttom-bottom {
    position: fixed;
    bottom: 0px;
  }
}
</style>
