
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref,
  PropType
} from "vue";
import { useStorage } from "../../../modules/storage";
import {
  useArtEngenheiroTransportadorService,
  ArtEngenheiroTransportadorPayload
} from "../../../modules/business/transportador/artEngenheiroTransportadorBusiness";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { EngenheiroPayload } from "../../../modules/business/engenheiro/engenheiroBusiness";
import { VeiculoTratorPayload } from "../../../modules/business/veiculo/veiculoTratorBusiness";
import { useToast } from "../../../modules/message";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import EntitySelectorEngenheiro from "../../engenheiro/EntitySelectorEngenheiro.vue";
import EntitySelectorUf from "../../common/EntitySelectorUf.vue";

// eslint-disable-next-line
declare var require: any; //PQP MEMO

interface ArtEngenheiroTransportadorCadastroState {
  entity: ArtEngenheiroTransportadorPayload;
  stateReady: boolean;
  hasUser: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ArtEngenheiroTransportadorCadastroState>({
      entity: useArtEngenheiroTransportadorService().safeArtEngenheiroTransportador(),
      hasUser: false,
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const isRequiredBaseFields = computed(() => {
      return (
        !!state.entity.idArtEngenheiroTransportador ||
        !amIAllowed("play", state.entity.permissionInfo)
      );
    });

    const afterSetUF = (idUnidadeFederal?: number | null) => {
      state.entity.idUnidadeFederal = idUnidadeFederal;
    };

    const setEntity = (entity: ArtEngenheiroTransportadorPayload) => {
      state.entity = useArtEngenheiroTransportadorService().safeArtEngenheiroTransportador(
        entity
      );
      const docElement = document.querySelector(
        "#" + state.stateId + "_documento"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
    };

    const downloadArtFile = (newTab: boolean, onEnd: Function) => {
      const {
        downloadArtEngenheiroTransportador,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      downloadArtEngenheiroTransportador(state.entity, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionEngenheiro = (eng: EngenheiroPayload) => {
      if (eng) {
        state.entity.engenheiro = eng;
      } else {
        state.entity.engenheiro = useArtEngenheiroTransportadorService().safeArtEngenheiroTransportador().engenheiro;
      }
    };
    const confirmSelectionVeiculoTratorRIV = (
      veiculoTratorRIV: VeiculoTratorPayload
    ) => {
      if (veiculoTratorRIV) {
        state.entity.veiculoTratorRIV = veiculoTratorRIV;
      } else {
        state.entity.veiculoTratorRIV = useArtEngenheiroTransportadorService().safeArtEngenheiroTransportador().veiculoTratorRIV;
      }
    };

    const save = () => {
      if (!props.idTransportador) {
        return;
      }
      const {
        saveNewArtEngenheiroTransportador,
        updateArtEngenheiroTransportador,
        loading: loadingCadastro,
        data: dataCadastro
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loadingCadastro);
      (props.idArtEngenheiroTransportador
        ? updateArtEngenheiroTransportador(
            props.idTransportador,
            props.idArtEngenheiroTransportador,
            state.entity
          )
        : saveNewArtEngenheiroTransportador(props.idTransportador, state.entity)
      )
        .then(() => {
          if (!props.idTransportador) {
            return;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          setEntity(dataCadastro.value);
          state.savedEntity = true;
          useToast().success("ART salva com sucesso");
        })
        .catch(() => {
          if (state.entity.keyBlDocumentoTemp) {
            useStorage().deleteTempFile(state.entity.keyBlDocumentoTemp);
            state.entity.keyBlDocumentoTemp = null;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const submitSave = () => {
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_documento"
      ) as HTMLInputElement;
      if (!file || !file.files || file.files.length === 0) {
        save();
      } else {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            state.entity.keyBlDocumentoTemp = key;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            save();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      }
    };

    onMounted(async () => {
      if (!props.idTransportador) {
        return;
      }
      const {
        getArtEngenheiroTransportador,
        newArtEngenheiroTransportador,
        data,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);

      (props.idArtEngenheiroTransportador
        ? getArtEngenheiroTransportador(
            props.idTransportador,
            props.idArtEngenheiroTransportador
          )
        : newArtEngenheiroTransportador(
            props.idTransportador,
            props.idEngenheiro
          )
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      downloadArtFile,
      confirmSelectionEngenheiro,
      confirmSelectionVeiculoTratorRIV,
      afterSetUF,
      stateLoading,
      stateBlocked,
      isRequiredBaseFields,
      amIAllowed,
      ...toRefs(state)
    };
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.EntitySelectorVeiculoTrator = require("../../veiculo/trator/EntitySelectorVeiculoTrator.vue").default;
    }
  },
  components: {
    EntitySelectorEngenheiro,
    EntitySelectorUf
  },
  props: {
    idTransportador: Number,
    idEngenheiro: Number,
    idArtEngenheiroTransportador: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
