
import { defineComponent, toRefs, reactive, onMounted, watch } from "vue";
import {
  useUfService,
  UFPayload
} from "../../modules/business/common/commonBusiness";

//TODO KAIO TROCAR OS ENTITYSELECTORS PARA USAR V-MODEL

interface UFSelectorState {
  stateReady: boolean;
  unidadeFederalInterno?: UFPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<UFSelectorState>({
      stateReady: false,
      unidadeFederalInterno: useUfService().getUf(props.idUnidadeFederal)
    });

    watch([props], () => {
      if (
        props.idUnidadeFederal != state.unidadeFederalInterno?.idUnidadeFederal
      ) {
        state.unidadeFederalInterno = useUfService().getUf(
          props.idUnidadeFederal
        );
      }
    });

    watch([state], () => {
      emit(
        "after-set-uf",
        state.unidadeFederalInterno?.idUnidadeFederal ?? null
      );
    });

    const ufs = useUfService().getUfs();

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      ufs,
      ...toRefs(state)
    };
  },
  props: {
    required: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    idUnidadeFederal: {
      type: Number
    },
    id: {
      type: String
    }
  }
});
