<template>
  <sistema>
    <div>
      <crud-transportador
        :isSearch="false"
        :isNew="isNew"
        :idTransportador="idTransportador"
      />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudTransportador from "../../components/transportador/CrudTransportador.vue";

@Options({
  props: {
    isNew: Boolean,
    idTransportador: Number
  },
  components: {
    CrudTransportador
  }
})
export default class Transportador extends Vue {
  isNew?: boolean;
  idTransportador?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
