<template>
  <container :isModal="false" :loading="loading">
    <template v-slot:body>
      <form @submit.prevent="submit">
        <fieldset
          :disabled="loading"
          class="p-d-flex p-jc-center"
          style="height: 100vh"
        >
          <div
            class="p-as-center p-p-5 shadow-box p-shadow-24"
            style="background: white; border-radius: 0.25rem"
          >
            <div style="width: 300px">
              <div class="p-d-flex m-3 p-jc-center">
                <img class="img-fluid main-logo" alt="GovPass" />
              </div>

              <div class="p-d-flex p-jc-center p-mt-3">
                <input-with-label
                  iconClass="fas fa-envelope p-mr-1"
                  label="Usuário"
                  v-slot="l"
                  :required="true"
                >
                  <InputText
                    v-model="credenciais.login"
                    :id="l.id"
                    :required="l.required"
                    v-focus
                  />
                </input-with-label>
              </div>
              <div class="p-d-flex p-jc-center p-mt-2">
                <input-with-label
                  iconClass="fas fa-lock p-mr-1"
                  label="Senha"
                  v-slot="l"
                  :required="true"
                >
                  <Password
                    v-model="credenciais.senha"
                    :id="l.id"
                    :required="l.required"
                    :feedback="false"
                  />
                </input-with-label>
              </div>
              <div class="p-d-flex p-m-3 p-jc-center">
                <Button type="submit" class="p-button-rounded" label="Entrar" />
              </div>
              <div class="p-d-flex p-m-3 p-jc-center">
                <p>
                  <router-link
                    router-link
                    :to="{ name: 'recover-request' }"
                    class="link-secondary"
                    >Esqueci minha senha</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
  </container>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "../../modules/auth";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";

interface LoginState {
  credenciais: CredencialPayload;
  loading: boolean;
}

export default defineComponent({
  setup() {
    const router = useRouter();
    const state = reactive<LoginState>({
      credenciais: {},
      loading: false
    });
    const submit = () => {
      state.loading = true;
      useAuth()
        .login(state.credenciais)
        .then(() => router.push({ name: "home" }))
        .then(() => {
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };
    return {
      submit,
      ...toRefs(state)
    };
  },
  components: {}
});
</script>
