
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    group: { type: String, default: "top-right" }
  },
  components: {}
});
