<template>
  <container
    :isModal="params.isSearch"
    @close-modal="cancelSelection()"
    :loading="!stateReady"
  >
    <template v-slot:header>
      <h3>Consulta de Transportadores</h3>
    </template>
    <template v-slot:body>
      <div>
        <form @submit.prevent="submitSearch">
          <entity-selector-cliente
            :cliente="cliente"
            :canEdit="!cliente"
            :idClienteFiltered="filter.idCliente"
            @confirm-selection="confirmSelectionCliente"
            :parentLoading="stateLoading || showEdit"
          />
          <fieldset :disabled="stateLoading || showEdit">
            <div class="p-formgrid p-grid">
              <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                <input-with-label label="Nome" v-slot="l">
                  <InputText
                    v-model="filter.nome"
                    :id="l.id"
                    :required="l.required"
                    v-focus
                  />
                </input-with-label>
              </div>
              <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                <input-with-label label="CPF/CNPJ" v-slot="l">
                  <InputText
                    v-model="filter.cpfCnpj"
                    v-maska="['###.###.###-##', '##.###.###/####-##']"
                    :id="l.id"
                    :required="l.required"
                  />
                </input-with-label>
              </div>
              <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4 p-pt-3">
                <input-with-label
                  label="Arquivado"
                  v-slot="l"
                  :floatLabel="false"
                >
                  <InputSwitch
                    v-model="filter.arquivado"
                    :id="l.id"
                    :required="l.required"
                  />
                </input-with-label>
              </div>
              <div class="p-col-12">
                <button-group>
                  <Button
                    type="submit"
                    class="p-button-rounded"
                    label="Buscar"
                  />
                  <Button
                    label="Limpar"
                    class="p-button-rounded p-button-text"
                    @click="clearFilter()"
                  />
                </button-group>
              </div>
            </div>
          </fieldset>
        </form>
      </div>

      <search-result
        :showSelector="params.isSearch"
        :results="results"
        :selected="params.selected"
        :multiple="params.isMultiple"
        :keyParam="'idTransportador'"
        :loading="stateLoading"
        :showClient="true"
        @after-page-change="afterPageChange"
        @confirm-selection="confirmSelection"
      >
        <template v-slot:columns>
          <Column field="nome" header="Nome" />
          <Column field="cpfCnpj" header="CPF/CNPJ" style="width: 15rem">
            <template #body="slotProps">
              <span
                v-text="
                  format(
                    ['###.###.###-##', '##.###.###/####-##'],
                    slotProps.data.cpfCnpj
                  )
                "
              />
            </template>
          </Column>
          <Column field="telefone" header="Telefone" style="width: 15rem">
            <template #body="slotProps">
              <span
                v-text="
                  format(
                    [
                      '####-####',
                      '#####-####',
                      '(##) ####-####',
                      '(##) #####-####'
                    ],
                    slotProps.data.telefone
                  )
                "
              />
            </template>
          </Column>
          <Column :exportable="false" style="width: 10rem; text-align: right">
            <template #body="slotProps">
              <button-group>
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success"
                  v-if="amIAllowed('view', slotProps.data.permissionInfo)"
                  @click="openEntity(slotProps.data.idTransportador)"
                  @click.middle.prevent.stop="
                    openEntity(slotProps.data.idTransportador, false, true)
                  "
                />
                <Button
                  icon="fas fa-folder-minus"
                  class="p-button-rounded p-button-danger"
                  v-if="
                    !params.isSearch &&
                      amIAllowed('shelve', slotProps.data.permissionInfo)
                  "
                  @click="shelveEntity(slotProps.data)"
                  :disabled="
                    amIBlocked('shelve', slotProps.data.permissionInfo)
                  "
                  v-title="
                    [
                      'Arquivar',
                      amIBlocked('shelve', slotProps.data.permissionInfo)?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                />
                <Button
                  icon="fas fa-folder-plus"
                  class="p-button-rounded p-button-secondary"
                  v-if="
                    !params.isSearch &&
                      amIAllowed('unshelve', slotProps.data.permissionInfo)
                  "
                  @click="unshelveEntity(slotProps.data)"
                  :disabled="
                    amIBlocked('unshelve', slotProps.data.permissionInfo)
                  "
                  v-title="
                    [
                      'Desarquivar',
                      amIBlocked('unshelve', slotProps.data.permissionInfo)
                        ?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                />
              </button-group>
            </template>
          </Column>
        </template>
        <template v-slot:actions>
          <Button
            label="Novo"
            :class="
              'p-button-rounded' + (params.isSearch ? ' p-button-text' : '')
            "
            v-if="amIAllowed('create', 'transportador')"
            @click="newEntity()"
          />
        </template>
      </search-result>
      <div class="register" v-if="showEdit && keyRegister">
        <cadastro-transportador
          :idTransportador="idTransportador"
          @after-register-screen-closed="afterRegisterScreenClosed"
          @after-save-new-entity="afterSaveNewEntity"
          @request-entity-edit="openEntity"
          :parentLoading="stateLoading"
          :key="keyRegister"
          :cliente="cliente"
        />
      </div>
    </template>
  </container>
</template>

<script lang="ts">
import CadastroTransportador from "./CadastroTransportador.vue";
import { PaginationResult, defaultResults } from "../../pagination";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { useRouter, RouteLocationNormalizedLoaded } from "vue-router";
import { useAuth, PermissionPayload } from "../../modules/auth";
import {
  indexesOfElement,
  addSetElement,
  toggleElement,
  format,
  copyObject,
  clearObject
} from "../../modules/utils";
import {
  useTransportadorService,
  TransportadorFilterPayload,
  TransportadorPayload
} from "../../modules/business/transportador/transportadorBusiness";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../modules/business/cliente/clienteBusiness";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import { focusOnLastElement } from "../../modules/globalAppProperties";
import { useConfirm } from "../../modules/message";

interface TransportadorComponentParams {
  isSearch: boolean;
  selected: Array<TransportadorPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idTransportador?: number;
  preFilter?: TransportadorFilterPayload;
}

interface TransportadorState {
  idTransportador: number | null;
  showEdit: boolean;
  results: PaginationResult<TransportadorPayload>;
  filter: TransportadorFilterPayload;
  page: number;
  stateReady: boolean;
  params: TransportadorComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<TransportadorState>({
      idTransportador: null,
      showEdit: false,
      stateReady: false,
      filter: useTransportadorService().safeFilterTransportador(),
      page: 0,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idTransportador: props.idTransportador,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple,
        preFilter: props.preFilter
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    copyObject(clearObject(props.preFilter), state.filter);

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const keyRegister = computed(() => {
      return state.showEdit
        ? state.idTransportador
          ? state.idTransportador
          : "new-" +
            Math.random()
              .toString(36)
              .substring(2)
        : null;
    });

    const executeSearch = () => {
      const { data, loading, searchTransportador } = useTransportadorService();
      state.loadings.push(loading);
      return searchTransportador(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const getParamsRouteEdit = (idTransportador: number) => {
      return {
        name: "transportador-edit",
        params: { idTransportador: idTransportador + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (route.name == "transportador-new") {
        state.showEdit = true;
        state.idTransportador = null;
      } else if (route.name == "transportador-edit") {
        if (route.params && route.params.idTransportador) {
          state.idTransportador = Array.isArray(route.params.idTransportador)
            ? +route.params.idTransportador[0]
            : +route.params.idTransportador;
        } else {
          state.idTransportador = null;
        }

        state.showEdit = true;
      } else {
        state.showEdit = false;
        state.idTransportador = null;
        executeSearch();
      }
    };

    const confirmSelection = (selected: Array<TransportadorPayload>) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const openEntity = (idTransportador: number, newTab?: boolean) => {
      const routeParams = getParamsRouteEdit(idTransportador);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = () => {
      const routeParams = {
        name: "transportador-new"
      };
      if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const shelveEntity = (transportador: TransportadorPayload) => {
      useConfirm().require({
        message: `Deseja arquivar o transportador '${transportador.cpfCnpj} - ${transportador.nome}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (transportador && transportador.idTransportador) {
            const { shelveTransportador, loading } = useTransportadorService();
            state.loadings.push(loading);
            shelveTransportador(transportador.idTransportador)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const unshelveEntity = (transportador: TransportadorPayload) => {
      useConfirm().require({
        message: `Deseja desarquivar o transportador '${transportador.cpfCnpj} - ${transportador.nome}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (transportador && transportador.idTransportador) {
            const {
              unshelveTransportador,
              loading
            } = useTransportadorService();
            state.loadings.push(loading);
            unshelveTransportador(transportador.idTransportador)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const afterRegisterScreenClosed = (
      transportador?: TransportadorPayload
    ) => {
      const routeParams = {
        name: "transportador"
      };
      if (state.params.isSearch) {
        if (state.params.idTransportador) {
          if (transportador && transportador.idTransportador) {
            confirmSelection([transportador]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            transportador &&
            indexesOfElement(
              state.params.selected,
              transportador,
              (ob) => ob && ob.idTransportador
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  transportador,
                  (ob) => ob && ob.idTransportador,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterSaveNewEntity = (idTransportador: number) => {
      openEntity(idTransportador);
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const clearFilter = () => {
      state.filter = useTransportadorService().safeFilterTransportador();
      state.results = defaultResults();
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
    };

    const deveAbrirTelaCadastro =
      state.params.idTransportador || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idTransportador) {
        state.idTransportador = state.params.idTransportador;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      if (props.cliente && props.cliente.idCliente) {
        state.filter.idCliente = props.cliente.idCliente;
      }
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
      if (!deveAbrirTelaCadastro) {
        submitSearch().then(() => {
          if (
            state.params.preFilter &&
            state.params.preFilter.cpfCnpj &&
            state.results.content.length == 1
          ) {
            const arr = [] as Array<TransportadorPayload>;
            state.params.selected.forEach((el) => arr.push(el));
            addSetElement(
              arr,
              state.results.content[0],
              (ob) => ob && ob.idTransportador,
              state.params.isMultiple
            );
            confirmSelection(arr);
          } else {
            focusOnLastElement();
          }
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      shelveEntity,
      unshelveEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterSaveNewEntity,
      afterPageChange,
      clearFilter,
      format,
      amIAllowed,
      amIBlocked,
      getParamsRouteEdit,
      stateLoading,
      getNomeCliente,
      keyRegister,
      confirmSelectionCliente,
      ...toRefs(state)
    };
  },
  components: {
    CadastroTransportador,
    EntitySelectorCliente
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idTransportador: Number,
    preFilter: Object as PropType<TransportadorFilterPayload>,
    cliente: Object as PropType<ClientePayload>,
    selected: {
      type: Array as PropType<Array<TransportadorPayload>>
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
