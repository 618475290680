import { clearObject } from "@/modules/utils";
import { useApiWithAuth, DownloadViewReturn } from "@/modules/api";
import { useAuth } from "@/modules/auth";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "@/modules/business/cliente/clienteBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "@/modules/business/transportador/transportadorBusiness";
import {
  EngenheiroPayload,
  useEngenheiroService
} from "@/modules/business/engenheiro/engenheiroBusiness";
import {
  useVeiculoTratorService,
  VeiculoTratorPayload
} from "@/modules/business/veiculo/veiculoTratorBusiness";
import { CarretaPayload } from "@/modules/business/veiculo/carretaBusiness";
import {
  VeiculoConjuntoTransportadorGabaritoPayload,
  useGabaritoService
} from "@/modules/business/licenca/gabaritoBusiness";
import { DocumentoPayload } from "@/modules/business/common/commonBusiness";
import { LicencaOrgaoPayload } from "@/modules/business/licenca/licencaOrgaoBusiness";
import { PermissionPayload } from "@/modules/auth";

interface LicencaFilterPayload {
  idCliente?: number;
  idTransportador?: number;
  idCarretas: Array<number>;
  idOrgao?: number;
  idGabarito?: number;
  idVeiculoTrator?: number;
  dtInicial?: Date;
  dtFinal?: Date;
  flSituacoes: Array<string>;
  numeroPedido?: string;
}

interface LicencaFilterReturnPayload {
  idLicenca: number | null;
  flExclusao?: string | null;
  idGabarito: number | null;
  comprimentoTotal?: number | null;
  transportador: TransportadorPayload;
  veiculoTratorPrincipal: VeiculoTratorPayload;
  carretasPrincipais?: Array<CarretaPayload>;
  rodizios?: Array<CarretaPayload>;
  engenheiro?: EngenheiroPayload;
  licencasOrgaos: Array<LicencaOrgaoPayload>;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
}

interface LicencaPayload {
  idLicenca?: number | null;
  flExclusao?: string | null;
  idGabarito?: number | null;
  excessoLateralEsquerdo?: number | null;
  excessoLateralDireito?: number | null;
  excessoDianteiro?: number | null;
  excessoTraseiro?: number | null;
  alturaTotal?: number | null;
  larguraTotal?: number | null;
  comprimentoTotal?: number | null;
  tipoCarga?: string | null;
  notaFiscalCarga?: DocumentoPayload | null;
  transportador: TransportadorPayload;
  engenheiro: EngenheiroPayload;
  licencasOrgaos?: Array<LicencaOrgaoPayload>;
  conjuntoTransportador: Array<VeiculoConjuntoTransportadorGabaritoPayload>;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
}

interface LicencaPorVeiculoFilterPayload {
  idVeiculoTrator?: number | null;
  idCarretas: Array<number>;
  idCliente?: number | null;
}

const useLicencaService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    patch,
    downloadToView,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const searchLicenca = (
    filter: LicencaFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/licenca", {
      ...clearObject(filter),
      ...{
        page: page
      }
    });
  };

  const printSearch = (
    filter: LicencaFilterPayload,
    xls: boolean,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(`/licenca/download`, {
      ...clearObject(filter),
      ...{
        xls: xls
      }
    });
  };

  const getLicenca = (idLicenca: number): Promise<void> => {
    return get(`/licenca/${idLicenca}`);
  };

  const newLicenca = (idCliente?: number | null): Promise<void> => {
    return get("/licenca/new", idCliente ? { idCliente: idCliente } : {});
  };

  const copyLicenca = (idLicenca: number): Promise<void> => {
    return get("/licenca/new", {
      idLicencaOrigem: idLicenca
    });
  };

  const shelveLicenca = (idLicenca: number): Promise<void> => {
    return patch("/licenca/" + idLicenca + "/arquivar");
  };

  const unshelveLicenca = (idLicenca: number): Promise<void> => {
    return patch("/licenca/" + idLicenca + "/desarquivar");
  };

  const saveNewLicenca = (licenca: LicencaPayload): Promise<void> => {
    return post("/licenca", licenca);
  };

  const updateLicenca = (
    idLicenca: number,
    licenca: LicencaPayload
  ): Promise<void> => {
    return put(`/licenca/${idLicenca}`, licenca);
  };

  const getNomeLicencaVisualizacao = (entity?: LicencaFilterReturnPayload) => {
    if (!entity) {
      return "";
    }
    return (
      useVeiculoTratorService().getNomeVeiculoTrator(
        entity.veiculoTratorPrincipal
      ) +
      " - " +
      useTransportadorService().getNomeTransportador(entity.transportador) +
      " - " +
      useGabaritoService().getNomeGabarito(entity.idGabarito) +
      (entity.cliente
        ? " - " +
          (useAuth().amIAllowed("view", "root-cliente")
            ? useClienteService()
            : useClienteLogadoService()
          ).getNomeCliente(entity.cliente)
        : "")
    );
  };

  const downloadVisualizacao = (
    idLicenca: number,
    newTab?: boolean
  ): Promise<void | string> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/download/visualizacao`
    );
  };

  const downloadVisualizacaoExibicao = (
    idLicenca: number
  ): Promise<DownloadViewReturn> => {
    return downloadToView(`/licenca/${idLicenca}/download/visualizacao`);
  };

  const downloadNotaFiscalCarga = (
    idLicenca: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/nota-fiscal-carga`
    );
  };

  const getRelatorioLicencasPorVeiculo = (
    filter: LicencaPorVeiculoFilterPayload,
    page: number
  ): Promise<void> => {
    return get(`/licenca/combinacao-veiculos`, {
      ...clearObject(filter),
      ...{
        page: page
      }
    });
  };

  const safeLicenca = (entity?: LicencaPayload | null): LicencaPayload => {
    const ret = entity ?? {
      conjuntoTransportador: [],
      engenheiro: useEngenheiroService().safeEngenheiro(),
      transportador: useTransportadorService().safeTransportador()
    };
    ret.conjuntoTransportador = ret.conjuntoTransportador ?? [];
    ret.engenheiro = ret.engenheiro ?? useEngenheiroService().safeEngenheiro();
    ret.transportador =
      ret.transportador ?? useTransportadorService().safeTransportador();
    return ret;
  };

  const safeFilterLicenca = () => {
    return {
      flSituacoes: [],
      idCarretas: []
    };
  };

  return {
    data,
    loading,
    searchLicenca,
    printSearch,
    shelveLicenca,
    unshelveLicenca,
    saveNewLicenca,
    copyLicenca,
    updateLicenca,
    newLicenca,
    getLicenca,
    safeLicenca,
    safeFilterLicenca,
    getNomeLicencaVisualizacao,
    downloadVisualizacao,
    downloadVisualizacaoExibicao,
    downloadNotaFiscalCarga,
    getRelatorioLicencasPorVeiculo
  };
};

export {
  useLicencaService,
  LicencaFilterPayload,
  LicencaFilterReturnPayload,
  LicencaPayload,
  LicencaPorVeiculoFilterPayload
};
