
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref
} from "vue";
import CadastroLicencaOrgao from "./CadastroLicencaOrgao.vue";
import CrudLicencaOrgaoErroDetalhes from "./CrudLicencaOrgaoErroDetalhes.vue";
import LicencaOrgaoDiferencasTrechos from "./LicencaOrgaoDiferencasTrechos.vue";
import { PaginationResult, defaultResults } from "../../../pagination";
import {
  useLicencaOrgaoService,
  LicencaOrgaoPayload
} from "../../../modules/business/licenca/licencaOrgaoBusiness";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import {
  indexesOfElement,
  toggleElement,
  differenceInDays,
  formatDatePeriod,
  formatDateTime,
  copyToClipboard
} from "../../../modules/utils";
import { isPast } from "date-fns";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import {
  LicencaPayload,
  LicencaFilterReturnPayload
} from "../../../modules/business/licenca/licencaBusiness";
import { MenuModel, MenuEvent } from "../../../router";
import { useConfirm } from "../../../modules/message";

interface LicencaOrgaoComponentParams {
  isSearch: boolean;
  showSelector: boolean;
  selected: Array<LicencaOrgaoPayload>;
  isMultiple?: boolean;
  deveAbrirTelaCadastro: boolean;
  idLicencaOrgao: number | null;
  licenca: LicencaPayload | LicencaFilterReturnPayload | null;
}

interface LicencaOrgaoState {
  idLicencaOrgaoEdit: number | null;
  licencaEdit?: LicencaPayload | LicencaFilterReturnPayload | null;
  showEdit: boolean;
  resultsInternal: PaginationResult<LicencaOrgaoPayload>;
  stateReady: boolean;
  params: LicencaOrgaoComponentParams;
  loadings: Array<Ref<boolean>>;
  showStretchDifferences: boolean;
  idLicencaOrgaoStretchDifferences: number | null;
  idLicencaStretchDifferences: number | null;
  stateId: string;
  estiloAplicado?: boolean | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<LicencaOrgaoState>({
      idLicencaOrgaoEdit: null,
      licencaEdit: null,
      showEdit: false,
      stateReady: false,
      resultsInternal: defaultResults(),
      loadings: [],
      showStretchDifferences: false,
      idLicencaOrgaoStretchDifferences: null,
      idLicencaStretchDifferences: null,
      estiloAplicado: false, //desativado ate o momento
      params: {
        isSearch: props.isSearch,
        showSelector: props.showSelector,
        isMultiple: props.isMultiple,
        selected: props.selected ?? [],
        deveAbrirTelaCadastro: !!(props.idLicencaOrgao || props.isNew),
        idLicencaOrgao: props.idLicencaOrgao ?? null,
        licenca: props.licenca ?? null
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const results = computed(() => {
      return props.externalResult || state.resultsInternal;
    });

    const executeSearch = (page?: number) => {
      if (props.externalResult) {
        emit("execute-search", page);
      } else if (props.licenca && props.licenca.idLicenca) {
        const { data, loading, searchLicencaOrgao } = useLicencaOrgaoService();
        state.loadings.push(loading);
        searchLicencaOrgao(props.licenca.idLicenca)
          .then(() => {
            state.resultsInternal = data.value.content;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      }
    };

    const openEntity = (licencaOrgao: LicencaOrgaoPayload) => {
      if (licencaOrgao.idLicencaOrgao) {
        state.idLicencaOrgaoEdit = licencaOrgao.idLicencaOrgao;
        state.licencaEdit = licencaOrgao.licenca || props.licenca;
        state.showEdit = true;
      }
    };

    const newEntity = (
      licenca: LicencaPayload | LicencaFilterReturnPayload
    ) => {
      state.idLicencaOrgaoEdit = null;
      state.licencaEdit = licenca;
      state.showEdit = true;
    };

    const confirmSelection = (
      selected: Array<LicencaOrgaoPayload>,
      added?: Array<LicencaOrgaoPayload>,
      removed?: Array<LicencaOrgaoPayload>
    ) => {
      emit("confirm-selection", selected, added, removed);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const returnToDefault = (licencaOrgao?: LicencaOrgaoPayload) => {
      state.idLicencaOrgaoEdit = null;
      state.licencaEdit = null;
      state.showEdit = false;
      if (licencaOrgao) {
        executeSearch();
      }
    };

    const afterRegisterScreenClosed = (licencaOrgao?: LicencaOrgaoPayload) => {
      if (props.idLicencaOrgao && state.params.isSearch) {
        if (licencaOrgao && licencaOrgao.idLicencaOrgao) {
          confirmSelection([licencaOrgao]);
        } else {
          cancelSelection();
        }
      } else {
        if (
          licencaOrgao &&
          state.params.isSearch &&
          indexesOfElement(
            state.params.selected,
            licencaOrgao,
            (ob) => ob && ob.idLicencaOrgao
          ).length <= 0
        ) {
          useConfirm().require({
            message: "Deseja selecionar o registro salvo?",
            header: "Confirmação",
            icon: "pi pi-info-circle",
            accept: () => {
              toggleElement(
                state.params.selected,
                licencaOrgao,
                (ob) => ob && ob.idLicencaOrgao,
                state.params.isMultiple
              );
              returnToDefault(licencaOrgao);
            },
            reject: () => {
              returnToDefault(licencaOrgao);
            }
          });
        } else {
          returnToDefault(licencaOrgao);
        }
      }
    };

    const shelveEntity = (licencaOrgao: LicencaOrgaoPayload) => {
      useConfirm().require({
        message: `Deseja arquivar a licenca para o Órgão '${useLicencaOrgaoService().getNomeLicencaOrgao(
          licencaOrgao
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            licencaOrgao &&
            licencaOrgao.idLicenca &&
            licencaOrgao.idLicencaOrgao
          ) {
            const { loading, shelveLicencaOrgao } = useLicencaOrgaoService();
            state.loadings.push(loading);
            shelveLicencaOrgao(
              licencaOrgao.idLicenca,
              licencaOrgao.idLicencaOrgao
            )
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const unshelveEntity = (licencaOrgao: LicencaOrgaoPayload) => {
      useConfirm().require({
        message: `Deseja dearquivar a licenca para o Órgão '${useLicencaOrgaoService().getNomeLicencaOrgao(
          licencaOrgao
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            licencaOrgao &&
            licencaOrgao.idLicenca &&
            licencaOrgao.idLicencaOrgao
          ) {
            const { loading, unshelveLicencaOrgao } = useLicencaOrgaoService();
            state.loadings.push(loading);
            unshelveLicencaOrgao(
              licencaOrgao.idLicenca,
              licencaOrgao.idLicencaOrgao
            )
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const downloadBoleto = (
      idLicenca: number,
      idLicencaOrgao: number,
      newTab?: boolean
    ) => {
      const { downloadBoleto, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadBoleto(idLicenca, idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadAet = (
      idLicenca: number,
      idLicencaOrgao: number,
      newTab?: boolean
    ) => {
      const { downloadAet, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadAet(idLicenca, idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadCapa = (
      idLicenca: number,
      idLicencaOrgao: number,
      newTab?: boolean
    ) => {
      const { downloadCapa, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadCapa(idLicenca, idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadDocumentosVeiculos = (
      idLicenca: number,
      idLicencaOrgao: number,
      newTab?: boolean
    ) => {
      const { downloadDocumentosVeiculos, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadDocumentosVeiculos(idLicenca, idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadErro = (
      idLicenca: number,
      idLicencaOrgao: number,
      newTab?: boolean
    ) => {
      const { downloadErro, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadErro(idLicenca, idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const processProjeto = (
      idLicenca: number,
      idLicencaOrgao: number,
      newTab?: boolean
    ) => {
      const { processProjeto, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      processProjeto(idLicenca, idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const play = (licencaOrgao: LicencaOrgaoPayload) => {
      useConfirm().require({
        message: `Deseja solicitar a licenca para o Órgão '${useLicencaOrgaoService().getNomeLicencaOrgao(
          licencaOrgao
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            licencaOrgao &&
            licencaOrgao.idLicenca &&
            licencaOrgao.idLicencaOrgao
          ) {
            const { play, loading } = useLicencaOrgaoService();
            state.loadings.push(loading);
            play(licencaOrgao.idLicenca, licencaOrgao.idLicencaOrgao)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const replay = (
      licencaOrgao: LicencaOrgaoPayload,
      isDocs?: boolean,
      isVehicles?: boolean
    ) => {
      useConfirm().require({
        message:
          `Deseja recolocar a licenca para o Órgão '${useLicencaOrgaoService().getNomeLicencaOrgao(
            licencaOrgao
          )}' na fila de execução` +
          (isDocs
            ? " para recadastrar os documentos"
            : isVehicles
            ? ", forçando recadastro de todos os veículos"
            : "") +
          "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            licencaOrgao &&
            licencaOrgao.idLicenca &&
            licencaOrgao.idLicencaOrgao
          ) {
            const {
              replay,
              replayForceData,
              loading
            } = useLicencaOrgaoService();
            state.loadings.push(loading);
            (isVehicles ? replayForceData : replay)(
              licencaOrgao.idLicenca,
              licencaOrgao.idLicencaOrgao
            )
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const setPriority = (licencaOrgao: LicencaOrgaoPayload) => {
      useConfirm().require({
        message: `Deseja priorizar consulta da licença '${useLicencaOrgaoService().getNomeLicencaOrgao(
          licencaOrgao
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            licencaOrgao &&
            licencaOrgao.idLicenca &&
            licencaOrgao.idLicencaOrgao
          ) {
            const { setPriority, loading } = useLicencaOrgaoService();
            state.loadings.push(loading);
            setPriority(licencaOrgao.idLicenca, licencaOrgao.idLicencaOrgao)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const copyIdToClipboard = (licencaOrgao?: LicencaOrgaoPayload) => {
      copyToClipboard(licencaOrgao?.idLicencaOrgao?.toString());
    };

    const addToQueue = (licencaOrgao: LicencaOrgaoPayload) => {
      useConfirm().require({
        message: `Deseja adicionar na fila de consulta a licença '${useLicencaOrgaoService().getNomeLicencaOrgao(
          licencaOrgao
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            licencaOrgao &&
            licencaOrgao.idLicenca &&
            licencaOrgao.idLicencaOrgao
          ) {
            const { addToQueue, loading } = useLicencaOrgaoService();
            state.loadings.push(loading);
            addToQueue(licencaOrgao.idLicenca, licencaOrgao.idLicencaOrgao)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const openStretchDifferences = (
      idLicenca: number,
      idLicencaOrgao: number
    ) => {
      if (idLicencaOrgao) {
        state.idLicencaOrgaoStretchDifferences = idLicencaOrgao;
        state.idLicencaStretchDifferences = idLicenca;
        state.showStretchDifferences = true;
      }
    };

    const closeScreenDiferencas = () => {
      state.idLicencaOrgaoStretchDifferences = null;
      state.idLicencaStretchDifferences = null;
      state.showStretchDifferences = false;
    };

    const isBotaoAetPresente = (licencaOrgao: LicencaOrgaoPayload) => {
      return !!amIAllowed("download", licencaOrgao.permissionInfo);
    };
    const isBotaoBoletoPresente = (licencaOrgao: LicencaOrgaoPayload) => {
      return !!amIAllowed("download_boleto", licencaOrgao.permissionInfo);
    };
    const isBotaoPlayPresente = (licencaOrgao: LicencaOrgaoPayload) => {
      return !!amIAllowed("play", licencaOrgao.permissionInfo);
    };
    const isBotaoReplayPresente = (licencaOrgao: LicencaOrgaoPayload) => {
      return !!amIAllowed("replay", licencaOrgao.permissionInfo);
    };
    const isBotaoCapaPresente = (licencaOrgao: LicencaOrgaoPayload) => {
      return !!amIAllowed("download_capa", licencaOrgao.permissionInfo);
    };
    const isBotaoCopyIdPresente = (licencaOrgao: LicencaOrgaoPayload) => {
      return !!amIAllowed("view_id", licencaOrgao.permissionInfo);
    };

    const processAuthMenu = (licencaOrgao: LicencaOrgaoPayload) => {
      const items = [] as Array<MenuModel>;
      if (
        licencaOrgao &&
        licencaOrgao.idLicenca &&
        licencaOrgao.idLicencaOrgao
      ) {
        const idLicenca = licencaOrgao.idLicenca;
        const idLicencaOrgao = licencaOrgao.idLicencaOrgao;

        if (isBotaoPlayPresente(licencaOrgao)) {
          items.push({
            label: [
              "Play",
              amIAllowed("play", licencaOrgao.permissionInfo)?.info
            ]
              .filter((e) => e)
              .join(" - "),
            icon: "fas fa-play",
            command: () => {
              play(licencaOrgao);
            },
            disabled: !!amIBlocked("play", licencaOrgao.permissionInfo)
          });
        }
        if (isBotaoReplayPresente(licencaOrgao)) {
          items.push({
            label: [
              "Replay",
              amIAllowed("replay", licencaOrgao.permissionInfo)?.info
            ]
              .filter((e) => e)
              .join(" - "),
            icon: "fas fa-redo",
            command: () => {
              replay(licencaOrgao);
            },
            disabled: !!amIBlocked("replay", licencaOrgao.permissionInfo)
          });
        }

        if (amIAllowed("replay_docs", licencaOrgao.permissionInfo)) {
          items.push({
            label: "Recadastrar Documentos",
            icon: "fas fa-redo",
            command: () => {
              replay(licencaOrgao, true);
            }
          });
        }

        if (amIAllowed("replay_vehicles", licencaOrgao.permissionInfo)) {
          items.push({
            label: [
              "Replay Recadastrando Todos os Veículos",
              amIAllowed("replay_vehicles", licencaOrgao.permissionInfo)?.info
            ]
              .filter((e) => e)
              .join(" - "),
            icon: "fas fa-redo",
            command: () => {
              replay(licencaOrgao, false, true);
            },
            disabled: !!amIBlocked(
              "replay_vehicles",
              licencaOrgao.permissionInfo
            )
          });
        }

        if (amIAllowed("view_error_image", licencaOrgao.permissionInfo)) {
          items.push({
            label: "Visualizar Imagem de Erro",
            icon: "fas fa-exclamation-triangle",
            command: (event?: MenuEvent) => {
              if (event?.originalEvent) {
                event.originalEvent.preventDefault();
              }
              downloadErro(
                idLicenca,
                idLicencaOrgao,
                event?.originalEvent?.button == 1
              );
            }
          });
        }

        if (isBotaoCapaPresente(licencaOrgao)) {
          items.push({
            label: [
              "Capa",
              amIBlocked("download_capa", licencaOrgao.permissionInfo)?.info
            ]
              .filter((e) => e)
              .join(" - "),
            icon: "fas fa-download",
            command: (event?: MenuEvent) => {
              if (event?.originalEvent) {
                event.originalEvent.preventDefault();
              }
              downloadCapa(
                idLicenca,
                idLicencaOrgao,
                event?.originalEvent?.button == 1
              );
            },
            disabled: !!amIBlocked("download_capa", licencaOrgao.permissionInfo)
          });
        }

        if (isBotaoBoletoPresente(licencaOrgao)) {
          items.push({
            label: "Boleto",
            icon: "fas fa-dollar-sign",
            command: (event?: MenuEvent) => {
              if (event?.originalEvent) {
                event.originalEvent.preventDefault();
              }
              downloadBoleto(
                idLicenca,
                idLicencaOrgao,
                event?.originalEvent?.button == 1
              );
            }
          });
        }

        if (isBotaoAetPresente(licencaOrgao)) {
          items.push({
            label: "Licença",
            icon: "far fa-file-pdf",
            command: (event?: MenuEvent) => {
              if (event?.originalEvent) {
                event.originalEvent.preventDefault();
              }
              downloadAet(
                idLicenca,
                idLicencaOrgao,
                event?.originalEvent?.button == 1
              );
            }
          });
        }

        if (amIAllowed("download_projeto", licencaOrgao.permissionInfo)) {
          items.push({
            label: "Projeto",
            icon: "fas fa-cogs",
            command: (event?: MenuEvent) => {
              if (event?.originalEvent) {
                event.originalEvent.preventDefault();
              }
              processProjeto(
                idLicenca,
                idLicencaOrgao,
                event?.originalEvent?.button == 1
              );
            }
          });
        }
        if (amIAllowed("consultar_novamente", licencaOrgao.permissionInfo)) {
          items.push({
            label: [
              "Consultar com prioridade",
              amIBlocked("consultar_novamente", licencaOrgao.permissionInfo)
                ?.info
            ]
              .filter((e) => e)
              .join(" - "),
            icon: "fas fa-redo",
            command: () => {
              setPriority(licencaOrgao);
            },
            disabled: !!amIBlocked(
              "consultar_novamente",
              licencaOrgao.permissionInfo
            )
          });
        }

        if (amIAllowed("add_monitoring", licencaOrgao.permissionInfo)) {
          items.push({
            label: [
              "Adicionar na fila de consulta",
              amIBlocked("add_monitoring", licencaOrgao.permissionInfo)?.info
            ]
              .filter((e) => e)
              .join(" - "),
            icon: "fas fa-search-plus",
            command: () => {
              addToQueue(licencaOrgao);
            },
            disabled: !!amIBlocked(
              "add_monitoring",
              licencaOrgao.permissionInfo
            )
          });
        }

        if (
          amIAllowed(
            "download_documentos_veiculos",
            licencaOrgao.permissionInfo
          )
        ) {
          items.push({
            label: "Baixar Documentos dos Veículos",
            icon: "fa-solid fa-truck",
            command: (event?: MenuEvent) => {
              if (event?.originalEvent) {
                event.originalEvent.preventDefault();
              }
              downloadDocumentosVeiculos(
                idLicenca,
                idLicencaOrgao,
                event?.originalEvent?.button == 1
              );
            }
          });
        }

        if (amIAllowed("diferencas_trechos", licencaOrgao.permissionInfo)) {
          items.push({
            label: "Trechos",
            icon: "fas fa-road",
            command: () => openStretchDifferences(idLicenca, idLicencaOrgao)
          });
        }
        if (amIAllowed("shelve", licencaOrgao.permissionInfo)) {
          items.push({
            label: "Arquivar",
            icon: "fas fa-folder-minus",
            command: () => shelveEntity(licencaOrgao)
          });
        }
        if (amIAllowed("unshelve", licencaOrgao.permissionInfo)) {
          items.push({
            label: "Desarquivar",
            icon: "fas fa-folder-plus",
            command: () => unshelveEntity(licencaOrgao)
          });
        }

        if (isBotaoCopyIdPresente(licencaOrgao)) {
          items.push({
            label: "Copiar Id GovPass",
            icon: "fa-solid fa-copy",
            command: (event?: MenuEvent) => {
              if (event?.originalEvent) {
                event.originalEvent.preventDefault();
              }
              copyIdToClipboard(licencaOrgao);
            }
          });
        }
      }
      return items;
    };

    const rowClassFunction = (licencaOrgao: LicencaOrgaoPayload) => {
      let rowClass = "";

      if (state.estiloAplicado) {
        if (
          licencaOrgao.dtValidadeInicial &&
          licencaOrgao.dtValidadeFinal &&
          (isBotaoAetPresente(licencaOrgao) ||
            isBotaoCapaPresente(licencaOrgao))
        ) {
          if (!isPast(licencaOrgao.dtValidadeInicial)) {
            //rowClass = "table-line-success";
          } else if (!isPast(licencaOrgao.dtValidadeFinal)) {
            if (
              differenceInDays(new Date(), licencaOrgao.dtValidadeFinal) < 30
            ) {
              rowClass = "table-line-warning";
            } else {
              rowClass = "table-line-success";
            }
          } else {
            rowClass = "table-line-danger";
          }
        }
      }
      return rowClass;
    };

    const getSituacaoNome = (licencaOrgao: LicencaOrgaoPayload) => {
      if (licencaOrgao.situacaoGovpass || licencaOrgao.situacao) {
        return [
          licencaOrgao.situacao != licencaOrgao.situacaoGovpass
            ? "<b>Situação GovPass</b>: " +
              licencaOrgao.situacaoGovpass?.toUpperCase()
            : null,
          licencaOrgao.situacao
            ? [
                //(licencaOrgao.situacao != licencaOrgao.situacaoGovpass ?
                "<b>Situação Órgão</b>: " +
                  //: "")
                  (
                    licencaOrgao.situacao ?? licencaOrgao.situacaoGovpass
                  )?.toUpperCase() +
                  "",
                licencaOrgao.timestampUltimaConsulta
                  ? "<small>Consultado em " +
                    formatDateTime(licencaOrgao.timestampUltimaConsulta) +
                    "</small>"
                  : null
              ]
                .filter((e) => e)
                .join(" - ")
            : null,
          !licencaOrgao.timestampUltimaConsulta &&
          licencaOrgao.timestampAlteracao
            ? "<small class='p-m-1'>Última alteração no GovPass: " +
              formatDateTime(licencaOrgao.timestampAlteracao) +
              "</small>"
            : null
        ]
          .filter((e) => e)
          .join(" <br/> ");
      }
      return "---";
    };

    if (state.params.deveAbrirTelaCadastro) {
      if (state.params.idLicencaOrgao) {
        state.licencaEdit = state.params.licenca;
        state.idLicencaOrgaoEdit = state.params.idLicencaOrgao;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      state.stateReady = true;
      if (!state.params.deveAbrirTelaCadastro && !props.externalResult) {
        executeSearch();
      }
    });

    return {
      executeSearch,
      openEntity,
      newEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      amIAllowed,
      amIBlocked,
      formatDatePeriod,
      formatDateTime,
      isPast,
      stateLoading,
      results,
      shelveEntity,
      unshelveEntity,
      play,
      replay,
      downloadAet,
      downloadBoleto,
      processProjeto,
      downloadCapa,
      downloadErro,
      isBotaoCapaPresente,
      isBotaoAetPresente,
      isBotaoBoletoPresente,
      isBotaoPlayPresente,
      isBotaoReplayPresente,
      closeScreenDiferencas,
      processAuthMenu,
      getSituacaoNome,
      rowClassFunction,
      ...toRefs(state)
    };
  },
  components: {
    CadastroLicencaOrgao,
    CrudLicencaOrgaoErroDetalhes,
    LicencaOrgaoDiferencasTrechos
  },
  props: {
    isSearch: Boolean,
    showSelector: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    cliente: Object as PropType<ClientePayload>,
    idLicencaOrgao: Number,
    licenca: {
      type:
        (Object as PropType<LicencaPayload>) ||
        (Object as PropType<LicencaFilterReturnPayload>),
      required: false
    },
    selected: {
      type: Array as PropType<Array<LicencaOrgaoPayload>>
    },
    externalResult: {
      type: Object as PropType<PaginationResult<LicencaOrgaoPayload>>
    },
    blockedSelection: {
      type: Function,
      required: false
    },
    parentLoading: Boolean
  }
});
