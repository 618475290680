<template>
  <container
    :isModal="params.isSearch"
    @close-modal="cancelSelection()"
    :loading="!stateReady"
  >
    <template v-slot:header>
      <h3>Consulta de Carretas</h3>
    </template>
    <template v-slot:body>
      <div>
        <form @submit.prevent="submitSearch">
          <entity-selector-cliente
            :cliente="cliente"
            :canEdit="!cliente"
            :idClienteFiltered="filter.idCliente"
            @confirm-selection="confirmSelectionCliente"
            :parentLoading="stateLoading || showEdit"
          />
          <fieldset :disabled="stateLoading || showEdit">
            <div class="p-formgrid p-grid">
              <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                <input-with-label label="Placa/Nota Fiscal" v-slot="l">
                  <InputText
                    v-model="filter.placaNf"
                    :id="l.id"
                    :required="l.required"
                    v-focus
                  />
                </input-with-label>
              </div>
              <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                <entity-selector-transportador
                  :cliente="cliente"
                  :idTransportadorFiltered="filter.idTransportador"
                  @confirm-selection="confirmSelectionTransportador"
                  label="Proprietário"
                  :required="false"
                />
              </div>
              <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4 p-pt-3">
                <input-with-label
                  label="Arquivado"
                  v-slot="l"
                  :floatLabel="false"
                >
                  <InputSwitch
                    v-model="filter.arquivado"
                    :id="l.id"
                    :required="l.required"
                  />
                </input-with-label>
              </div>
              <div class="p-col-12">
                <button-group>
                  <Button
                    type="submit"
                    class="p-button-rounded"
                    label="Buscar"
                  />
                  <Button
                    label="Limpar"
                    class="p-button-rounded p-button-text"
                    @click="clearFilter()"
                  />
                </button-group>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <search-result
        :showSelector="params.isSearch"
        :results="results"
        :selected="params.selected"
        :multiple="params.isMultiple"
        :keyParam="'idCarreta'"
        :loading="stateLoading"
        :blockedSelection="blockedSelection"
        :showClient="true"
        @after-page-change="afterPageChange"
        @confirm-selection="confirmSelection"
      >
        <template v-slot:columns>
          <Column field="placa" header="Placa" style="width: 20rem"
            ><template #body="slotProps">
              <span v-text="getNome(slotProps.data)" />
            </template>
          </Column>
          <Column field="nome" header="Transportador">
            <template #body="slotProps">
              <span
                v-text="getNomeTransportador(slotProps.data.transportador)"
              />
            </template>
          </Column>
          <Column field="flTipoCarreta" header="Tipo/Carroceria">
            <template #body="slotProps">
              <span v-text="getNomeTipo(slotProps.data)" />
            </template>
          </Column>
          <Column field="numeroEixos" header="Eixos" style="width: 10rem" />
          <Column :exportable="false" style="width: 10rem; text-align: right">
            <template #body="slotProps">
              <button-group>
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success"
                  v-if="amIAllowed('view', slotProps.data.permissionInfo)"
                  @click="openEntity(slotProps.data.idCarreta)"
                  @click.middle.prevent.stop="
                    openEntity(slotProps.data.idCarreta, false, true)
                  "
                />
                <Button
                  icon="fas fa-download"
                  class="p-button-rounded"
                  @click="downloadCRLV(slotProps.data.idCarreta)"
                  @click.middle.prevent.stop="
                    downloadCRLV(slotProps.data.idCarreta, true)
                  "
                  v-if="slotProps.data.keyBlDocumentoCrlv"
                />
                <Button
                  icon="fas fa-folder-minus"
                  class="p-button-rounded p-button-danger"
                  v-if="
                    !params.isSearch &&
                      amIAllowed('shelve', slotProps.data.permissionInfo)
                  "
                  @click="shelveEntity(slotProps.data)"
                  :disabled="
                    amIBlocked('shelve', slotProps.data.permissionInfo)
                  "
                  v-title="
                    [
                      'Arquivar',
                      amIBlocked('shelve', slotProps.data.permissionInfo)?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                />
                <Button
                  icon="fas fa-folder-plus"
                  class="p-button-rounded p-button-secondary"
                  v-if="
                    !params.isSearch &&
                      amIAllowed('unshelve', slotProps.data.permissionInfo)
                  "
                  @click="unshelveEntity(slotProps.data)"
                  :disabled="
                    amIBlocked('unshelve', slotProps.data.permissionInfo)
                  "
                  v-title="
                    [
                      'Desarquivar',
                      amIBlocked('unshelve', slotProps.data.permissionInfo)
                        ?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                />
              </button-group>
            </template>
          </Column>
        </template>
        <template v-slot:actions>
          <Button
            label="Novo"
            :class="
              'p-button-rounded' + (params.isSearch ? ' p-button-text' : '')
            "
            v-if="amIAllowed('create', 'carreta')"
            @click="newEntity()"
          />
        </template>
      </search-result>
      <div class="register" v-if="showEdit && keyRegister">
        <cadastro-carreta
          :idCarreta="idCarreta"
          @after-register-screen-closed="afterRegisterScreenClosed"
          @after-save-new-entity="afterSaveNewEntity"
          @request-entity-edit="openEntity"
          :parentLoading="stateLoading"
          :key="keyRegister"
          :cliente="cliente"
        />
      </div>
    </template>
  </container>
</template>

<script lang="ts">
import CadastroCarreta from "./CadastroCarreta.vue";
import { PaginationResult, defaultResults } from "../../../pagination";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import EntitySelectorTransportador from "../../transportador/EntitySelectorTransportador.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { RouteLocationNormalizedLoaded, useRouter } from "vue-router";
import {
  CarretaPayload,
  CarretaFilterPayload,
  useCarretaService
} from "../../../modules/business/veiculo/carretaBusiness";
import { useTipoCarroceriaService } from "../../../modules/business/veiculo/carroceriaBusiness";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../../modules/business/cliente/clienteBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import {
  indexesOfElement,
  addSetElement,
  toggleElement,
  format,
  copyObject,
  clearObject
} from "../../../modules/utils";
import EntitySelectorCliente from "../../cliente/EntitySelectorCliente.vue";
import { focusOnLastElement } from "../../../modules/globalAppProperties";
import { useConfirm } from "../../../modules/message";

interface CarretaComponentParams {
  isSearch: boolean;
  selected: Array<CarretaPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idCarreta?: number;
  preFilter?: CarretaFilterPayload;
}

interface CarretaState {
  idCarreta: number | null;
  showEdit: boolean;
  results: PaginationResult<CarretaPayload>;
  filter: CarretaFilterPayload;
  page: number;
  stateReady: boolean;
  params: CarretaComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<CarretaState>({
      idCarreta: null,
      showEdit: false,
      stateReady: false,
      filter: useCarretaService().safeFilterCarreta(),
      page: 0,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idCarreta: props.idCarreta,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple,
        preFilter: props.preFilter
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    copyObject(clearObject(props.preFilter), state.filter);

    const getNome = (e?: CarretaPayload) => {
      return useCarretaService().getNomeCarreta(e);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const executeSearch = () => {
      const { data, loading, searchCarreta } = useCarretaService();
      state.loadings.push(loading);
      return searchCarreta(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const keyRegister = computed(() => {
      return state.showEdit
        ? state.idCarreta
          ? state.idCarreta
          : "new-" +
            Math.random()
              .toString(36)
              .substring(2)
        : null;
    });

    const getParamsRouteEdit = (idCarreta: number) => {
      return {
        name: "carreta-edit",
        params: { idCarreta: idCarreta + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (route.name == "carreta-new") {
        state.showEdit = true;
        state.idCarreta = null;
      } else if (route.name == "carreta-edit") {
        if (route.params && route.params.idCarreta) {
          state.idCarreta = Array.isArray(route.params.idCarreta)
            ? +route.params.idCarreta[0]
            : +route.params.idCarreta;
        } else {
          state.idCarreta = null;
        }
        state.showEdit = true;
      } else {
        state.showEdit = false;
        state.idCarreta = null;
        executeSearch();
      }
    };

    const openEntity = (idCarreta: number, newTab?: boolean) => {
      const routeParams = getParamsRouteEdit(idCarreta);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = () => {
      const routeParams = {
        name: "carreta-new"
      };
      if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const downloadCRLV = (idCarreta: number, newTab?: boolean) => {
      const { downloadCRLV, loading } = useCarretaService();
      state.loadings.push(loading);
      downloadCRLV(idCarreta, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const shelveEntity = (carreta: CarretaPayload) => {
      useConfirm().require({
        message: "Deseja arquivar a Carreta " + getNome(carreta) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (carreta && carreta.idCarreta) {
            const { shelveCarreta, loading } = useCarretaService();
            state.loadings.push(loading);
            shelveCarreta(carreta.idCarreta)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const unshelveEntity = (carreta: CarretaPayload) => {
      useConfirm().require({
        message: "Deseja desarquivar a Carreta " + getNome(carreta) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (carreta && carreta.idCarreta) {
            const { unshelveCarreta, loading } = useCarretaService();
            state.loadings.push(loading);
            unshelveCarreta(carreta.idCarreta)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const confirmSelection = (selected: Array<CarretaPayload>) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const afterRegisterScreenClosed = (carreta?: CarretaPayload) => {
      const routeParams = {
        name: "carreta"
      };
      if (state.params.isSearch) {
        if (state.params.idCarreta) {
          if (carreta && carreta.idCarreta) {
            confirmSelection([carreta]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            carreta &&
            indexesOfElement(
              state.params.selected,
              carreta,
              (ob) => ob && ob.idCarreta
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  carreta,
                  (ob) => ob && ob.idCarreta,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterSaveNewEntity = (idCarreta: number) => {
      openEntity(idCarreta);
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const clearFilter = () => {
      state.filter = useCarretaService().safeFilterCarreta();
      if (props.preFilter) {
        state.filter.numeroEixos = props.preFilter.numeroEixos;
        state.filter.flTipoCarreta = props.preFilter.flTipoCarreta;
        state.filter.idTipoCarroceria = props.preFilter.idTipoCarroceria;
      }
      state.results = defaultResults();
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    const getNomeTransportador = (e?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const getNomeTipo = (e: CarretaPayload) => {
      let tipo = "";
      if (e.flTipoCarreta) {
        tipo +=
          useCarretaService().getTipoCarreta(e.flTipoCarreta)?.descricao ?? "";
      }
      if (e.idTipoCarroceria) {
        tipo +=
          " - " +
            useTipoCarroceriaService().getTipoCarroceria(e.idTipoCarroceria)
              ?.descricao ?? "";
      }
      return tipo ? tipo : "---";
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      state.filter.idTransportador =
        t && t.idTransportador ? t.idTransportador : undefined;
    };

    const deveAbrirTelaCadastro = state.params.idCarreta || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idCarreta) {
        state.idCarreta = state.params.idCarreta;
      } else {
        state.idCarreta = null;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      if (props.cliente && props.cliente.idCliente) {
        state.filter.idCliente = props.cliente.idCliente;
      }
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
      if (!deveAbrirTelaCadastro) {
        submitSearch().then(() => {
          if (
            state.params.preFilter &&
            state.params.preFilter.placaNf &&
            state.results.content.length == 1 &&
            (!props.blockedSelection ||
              !props.blockedSelection(state.results.content[0]))
          ) {
            const arr = [] as Array<CarretaPayload>;
            state.params.selected.forEach((el) => arr.push(el));
            addSetElement(
              arr,
              state.results.content[0],
              (ob) => ob && ob.idCarreta,
              state.params.isMultiple
            );
            confirmSelection(arr);
          } else {
            focusOnLastElement();
          }
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      shelveEntity,
      unshelveEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterSaveNewEntity,
      afterPageChange,
      clearFilter,
      format,
      amIAllowed,
      amIBlocked,
      getNomeTipo,
      getNome,
      getNomeCliente,
      getNomeTransportador,
      downloadCRLV,
      getParamsRouteEdit,
      keyRegister,
      stateLoading,
      confirmSelectionCliente,
      confirmSelectionTransportador,
      ...toRefs(state)
    };
  },
  components: {
    CadastroCarreta,
    EntitySelectorCliente,
    EntitySelectorTransportador
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idCarreta: Number,
    preFilter: Object as PropType<CarretaFilterPayload>,
    blockedSelection: {
      type: Function,
      required: false
    },
    cliente: Object as PropType<ClientePayload>,
    selected: {
      type: Array as PropType<Array<CarretaPayload>>
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
