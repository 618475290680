
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  Ref,
  onMounted
} from "vue";
import {
  TenantPayload,
  useTenantService
} from "../../modules/business/cliente/tenantBusiness";
import { useRouter } from "vue-router";
import { useToast } from "../../modules/message";

interface TenantCadastroState {
  entity: TenantPayload;
  stateReady: boolean;
  confirmacaoSenha: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup() {
    const router = useRouter();
    const state = reactive<TenantCadastroState>({
      entity: useTenantService().safeTenant(),
      stateReady: false,
      confirmacaoSenha: "",
      loadings: []
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const submitSave = () => {
      if (state.confirmacaoSenha !== state.entity.senha) {
        useToast().warning("Os campos de confirmação e senha não sao iguais!");
        return;
      }

      const {
        saveNewTenant,
        loading: loadingCadastro,
        data: dataCadastro
      } = useTenantService();

      state.loadings.push(loadingCadastro);
      saveNewTenant(state.entity)
        .then(() => {
          useToast().success("Cliente criado com sucesso");
          const id = dataCadastro.value;
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          router.push({ name: "root-cliente-edit", params: { idCliente: id } });
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submitSave,
      stateLoading,
      ...toRefs(state)
    };
  },
  components: {}
});
