
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  computed,
  watch
} from "vue";
import CrudModeloVeiculo from "./CrudModeloVeiculoTrator.vue";
import {
  ModeloVeiculoTratorPayload,
  useModeloVeiculoTratorService
} from "../../../../modules/business/veiculo/veiculoTratorBusiness";
import { format } from "../../../../modules/utils";
import { ClientePayload } from "../../../../modules/business/cliente/clienteBusiness";

interface ModeloVeiculoSelectorState {
  stateReady: boolean;
  crudOpen: boolean;
  idModeloVeiculoTratorToView?: number | null;
  entitiesToSelect: Array<ModeloVeiculoTratorPayload>;
  modeloVeiculoTrator?: ModeloVeiculoTratorPayload | null;
  nomeInternal: string | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ModeloVeiculoSelectorState>({
      stateReady: false,
      crudOpen: false,
      idModeloVeiculoTratorToView: null,
      nomeInternal: null,
      entitiesToSelect: []
    });

    const es = ref();

    const preFilter = computed(() => {
      const pf = useModeloVeiculoTratorService().safeFilterModeloVeiculoTrator();
      if (state.nomeInternal) {
        pf.nome = state.nomeInternal;
      }
      return pf;
    });

    watch([props], () => {
      if (
        !props.modeloVeiculoTrator ||
        !props.modeloVeiculoTrator.idModeloVeiculoTrator
      ) {
        if (
          props.idModeloVeiculoTratorFiltered !=
          state.modeloVeiculoTrator?.idModeloVeiculoTrator
        ) {
          state.modeloVeiculoTrator = null;
        }
      }
    });

    const entitiesSelected = computed(() => {
      return props.modeloVeiculoTrator &&
        props.modeloVeiculoTrator.idModeloVeiculoTrator
        ? [props.modeloVeiculoTrator]
        : state.modeloVeiculoTrator &&
          state.modeloVeiculoTrator.idModeloVeiculoTrator
        ? [state.modeloVeiculoTrator]
        : [];
    });

    const clearEntity = () => {
      state.nomeInternal = null;
      state.modeloVeiculoTrator = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idModeloVeiculoTratorToView = null;
      es.value.focus();
    };

    const confirmSelection = (selected: Array<ModeloVeiculoTratorPayload>) => {
      state.modeloVeiculoTrator =
        selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.modeloVeiculoTrator);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.nomeInternal = null;
      state.crudOpen = false;
      state.idModeloVeiculoTratorToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.nomeInternal = null;
      state.crudOpen = false;
      state.idModeloVeiculoTratorToView = null;
      emit("cancel-selection");
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idModeloVeiculoTratorToView = null;
      state.crudOpen = true;
    };

    const onChangeNomeModeloVeiculoTratorFilter = () => {
      if (state.nomeInternal && state.nomeInternal.length >= 3) {
        openSearch();
      } else {
        state.nomeInternal = null;
      }
    };

    const openEntity = (e: ModeloVeiculoTratorPayload) => {
      if (e.idModeloVeiculoTrator) {
        state.idModeloVeiculoTratorToView = e.idModeloVeiculoTrator;
        state.crudOpen = true;
      }
    };

    const getNome = (e?: ModeloVeiculoTratorPayload) => {
      return (
        (e?.marca ? e.marca + " - " : "") +
        useModeloVeiculoTratorService().getNomeModeloVeiculoTrator(e)
      );
    };

    onMounted(async () => {
      state.modeloVeiculoTrator = props.modeloVeiculoTrator;
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      clearEntity,
      confirmSelection,
      cancelSelection,
      es,
      preFilter,
      entitiesSelected,
      onChangeNomeModeloVeiculoTratorFilter,
      format,
      getNome,
      ...toRefs(state)
    };
  },
  components: {
    CrudModeloVeiculo
  },
  props: {
    modeloVeiculoTrator: {
      type: Object as PropType<ModeloVeiculoTratorPayload>
    },
    idModeloVeiculoTratorFiltered: Number,
    cliente: Object as PropType<ClientePayload>,
    canEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Modelo"
    },
    focus: {
      type: Boolean,
      default: false
    }
  }
});
