
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  watch,
  Ref,
  PropType
} from "vue";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { useToast, useConfirm } from "../../../modules/message";
import { useCache } from "../../../modules/cache";
import { useMunicipioService } from "../../../modules/business/common/commonBusiness";
import { useStorage } from "../../../modules/storage";
import {
  formatDateTime,
  clearObject,
  copyObject
} from "../../../modules/utils";
import {
  CarretaPayload,
  ConsultaCarretaPlacaPayload,
  TipoCarretaPayload,
  useCarretaService
} from "../../../modules/business/veiculo/carretaBusiness";
import { useTipoCarroceriaService } from "../../../modules/business/veiculo/carroceriaBusiness";
import EntitySelectorCliente from "../../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import EntitySelectorTransportador from "../../transportador/EntitySelectorTransportador.vue";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import EntitySelectorMunicipio from "../../common/EntitySelectorMunicipio.vue";
import CrudFilaVeiculo from "../../veiculo/CrudFilaVeiculo.vue";

interface CarretaCadastroState {
  entity: CarretaPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
  loadingPlates: boolean;
  lastLoadedPlate: ConsultaCarretaPlacaPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<CarretaCadastroState>({
      entity: useCarretaService().safeCarreta(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: [],
      lastLoadedPlate: null,
      loadingPlates: false
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });
    const stateFullBlocked = computed(() => {
      return !!(
        stateBlocked.value ||
        (amIAllowed("view", "root-cliente") && !state.entity.cliente?.idCliente)
      );
    });

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const tiposCarretas = useCarretaService().getTiposCarreta();

    const tipoCarretaSelecionada = computed(() => {
      if (state.entity.flTipoCarreta) {
        const tipo: TipoCarretaPayload = tiposCarretas.filter(
          (t) => t.flTipoCarreta == state.entity.flTipoCarreta
        )[0];
        return tipo;
      } else {
        return null;
      }
    });

    const possuiCarroceria = computed(() => {
      return !!tipoCarretaSelecionada.value?.unidadeComCarga;
    });

    const tiposCarroceriasFiltrados = computed(() => {
      if (
        tipoCarretaSelecionada.value &&
        tipoCarretaSelecionada.value?.idsCarroceriasAceitas
      ) {
        return useTipoCarroceriaService()
          .getTiposCarroceria()
          .filter(
            (t) =>
              t.idTipoCarroceria &&
              (
                tipoCarretaSelecionada.value?.idsCarroceriasAceitas ?? []
              ).indexOf(t.idTipoCarroceria) > -1
          );
      } else {
        return [];
      }
    });

    watch([tipoCarretaSelecionada], () => {
      if (!tipoCarretaSelecionada.value) {
        state.entity.idTipoCarroceria = null;
      } else if (
        state.entity.idTipoCarroceria &&
        (tipoCarretaSelecionada.value?.idsCarroceriasAceitas ?? []).indexOf(
          state.entity.idTipoCarroceria
        ) <= -1
      ) {
        state.entity.idTipoCarroceria = null;
      } else if (
        tipoCarretaSelecionada.value?.idsCarroceriasAceitas?.length == 1
      ) {
        state.entity.idTipoCarroceria =
          tipoCarretaSelecionada.value.idsCarroceriasAceitas[0];
      }
    });

    const cores = useCache().getCores();

    const setEntity = (entity: CarretaPayload) => {
      state.entity = useCarretaService().safeCarreta(entity);
      const docElement = document.querySelector(
        "#" + state.stateId + "_crlv"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const getTransportadorNome = (e: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      if (t) {
        state.entity.transportador = t;
      } else {
        state.entity.transportador = useCarretaService().safeCarreta().transportador;
      }
    };

    const downloadCRLV = (newTab: boolean, onEnd: Function) => {
      const { downloadCRLV, loading } = useCarretaService();
      if (state.entity.idCarreta) {
        state.loadings.push(loading);
        downloadCRLV(state.entity.idCarreta, newTab)
          .then(() => {
            onEnd();
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            onEnd();
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        onEnd();
      }
    };

    const updateCRLV = () => {
      useConfirm().require({
        message:
          "O documento será atualizado, Deseja alterar o ano Exercício para o ano atual?",
        header: "Confirmação",
        icon: "pi pi-info-circle",
        accept: () => {
          state.entity.anoExercicioCrlv = new Date().getFullYear();
        }
      });
    };

    const loadPlates = (isRefresh: boolean) => {
      if (state.entity.placa?.length == 7) {
        const { loadUsingPlateNumber, loading, data } = useCarretaService();
        state.loadings.push(loading);
        state.loadingPlates = true;
        loadUsingPlateNumber(
          state.entity.placa,
          isRefresh,
          state.entity.cliente
        )
          .then(() => {
            state.lastLoadedPlate = data.value;
            if (state.lastLoadedPlate) {
              const carreta = state.lastLoadedPlate.carreta;
              if (carreta.idCarreta) {
                if (!isRefresh) {
                  emit("request-entity-edit", carreta.idCarreta);
                }
              } else {
                delete carreta["idCarreta"];
                clearObject(carreta);
                if (
                  carreta.municipio &&
                  carreta.municipio.idUnidadeFederal &&
                  carreta.municipio.idUnidadeFederal !=
                    state.entity.municipio.idUnidadeFederal
                ) {
                  state.entity.municipio.idUnidadeFederal =
                    carreta.municipio.idUnidadeFederal;
                }
                copyObject(carreta, state.entity, ["municipio"]);
              }
            }
            state.loadingPlates = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadingPlates = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        useToast().warning("Placa inválida");
      }
    };

    const onChangePlaca = () => {
      if (!state.entity.idCarreta && state.entity.placa?.length == 7) {
        loadPlates(false);
      }
    };

    const save = () => {
      const idCarretaProps = props.idCarreta ?? 0;
      const isInsert = !idCarretaProps;
      const {
        saveNewCarreta,
        updateCarreta,
        loading: loadingCadastro,
        data: dataCadastro
      } = useCarretaService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewCarreta(state.entity)
        : updateCarreta(idCarretaProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Carreta salva com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idCarreta);
          }
        })
        .catch(() => {
          if (state.entity.keyBlDocumentoCrlvTemp) {
            useStorage().deleteTempFile(state.entity.keyBlDocumentoCrlvTemp);
            state.entity.keyBlDocumentoCrlvTemp = null;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const submitSave = () => {
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_crlv"
      ) as HTMLInputElement;
      if (!file || !file.files || file.files.length === 0) {
        save();
      } else {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            state.entity.keyBlDocumentoCrlvTemp = key;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            save();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      }
    };

    onMounted(async () => {
      const { getCarreta, newCarreta, data, loading } = useCarretaService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      (props.idCarreta
        ? getCarreta(props.idCarreta)
        : newCarreta(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      amIAllowed,
      amIBlocked,
      formatDateTime,
      confirmSelectionCliente,
      confirmSelectionTransportador,
      getTransportadorNome,
      stateLoading,
      stateFullBlocked,
      stateBlocked,
      municipiosFiltrados,
      possuiCarroceria,
      tiposCarroceriasFiltrados,
      tiposCarretas,
      tipoCarretaSelecionada,
      cores,
      downloadCRLV,
      updateCRLV,
      onChangePlaca,
      loadPlates,
      afterSetMunicipio,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorTransportador,
    EntitySelectorMunicipio,
    CrudFilaVeiculo
  },
  props: {
    idCarreta: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
