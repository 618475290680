
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  Ref
} from "vue";
import { useToast } from "../../modules/message";
import { PaginationResult, defaultResults } from "../../pagination";
import { useFilaVeiculoTratorService } from "../../modules/business/veiculo/veiculoTratorBusiness";
import { useFilaCarretaService } from "../../modules/business/veiculo/carretaBusiness";
import { FilaVeiculoPayload } from "../../modules/business/veiculo/filaVeiculoBusiness";
import { useStatusService } from "../../modules/business/status/statusBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../modules/business/transportador/transportadorBusiness";
import { format, formatDateTime } from "../../modules/utils";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useConfirm } from "../../modules/message";

interface FilaVeiculoState {
  idFilaVeiculo: number | null;
  showEdit: boolean;
  results: PaginationResult<FilaVeiculoPayload>;
  stateReady: boolean;
  filaVeiculoSelected: Array<number>;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props) {
    if (
      !props.idVeiculo ||
      props.isVeiculoTrator === undefined ||
      props.isVeiculoTrator === null
    ) {
      return;
    }

    const state = reactive<FilaVeiculoState>({
      idFilaVeiculo: null,
      showEdit: false,
      stateReady: false,
      results: defaultResults(),
      filaVeiculoSelected: [],
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const getService = () => {
      //odeio isso, mas por pressa vai assim
      if (props.isVeiculoTrator) {
        const {
          data,
          loading,
          searchFilaVeiculoTrator: searchService,
          playFilaVeiculoTrator: playService,
          markAsOkFilaVeiculoTrator: markAsOkService,
          getNomeFilaVeiculoTrator: getNomeService
        } = useFilaVeiculoTratorService();
        return {
          data,
          loading,
          searchService,
          playService,
          markAsOkService,
          getNomeService
        };
      } else {
        const {
          data,
          loading,
          searchFilaCarreta: searchService,
          playFilaCarreta: playService,
          markAsOkFilaCarreta: markAsOkService,
          getNomeFilaCarreta: getNomeService
        } = useFilaCarretaService();
        return {
          data,
          loading,
          searchService,
          playService,
          markAsOkService,
          getNomeService
        };
      }
    };

    const executeSearch = () => {
      if (!props.idVeiculo) {
        return;
      }
      const { data, loading, searchService } = getService();

      state.loadings.push(loading);
      return searchService(props.idVeiculo)
        .then(() => {
          state.results.content = data.value;
          state.results.totalPages = 1;
          state.results.number = 0;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.results = defaultResults();
      return executeSearch();
    };

    const play = (filaVeiculo: FilaVeiculoPayload) => {
      const { loading, playService, getNomeService } = getService();

      useConfirm().require({
        message: `Deseja solicitar o cadastro para o registro '${getNomeService(
          filaVeiculo
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (props.idVeiculo && filaVeiculo && filaVeiculo.idFilaVeiculo) {
            state.loadings.push(loading);
            playService(props.idVeiculo, filaVeiculo.idFilaVeiculo)
              .then(() => {
                useToast().success("Solicitado Registro com sucesso");
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const playAll = () => {
      const { loading, playService } = getService();

      useConfirm().require({
        message: `Deseja solicitar o cadastro para os registros selecionados?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            props.idVeiculo &&
            state.filaVeiculoSelected &&
            state.filaVeiculoSelected.length > 0
          ) {
            state.loadings.push(loading);
            playService(props.idVeiculo, state.filaVeiculoSelected)
              .then(() => {
                useToast().success("Solicitados Registros com sucesso");
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const markAsOk = (filaVeiculo: FilaVeiculoPayload) => {
      const { loading, markAsOkService, getNomeService } = getService();

      useConfirm().require({
        message: `Deseja marcar o veículo como finalizado o registro '${getNomeService(
          filaVeiculo
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (props.idVeiculo && filaVeiculo && filaVeiculo.idFilaVeiculo) {
            state.loadings.push(loading);
            markAsOkService(props.idVeiculo, filaVeiculo.idFilaVeiculo)
              .then(() => {
                useToast().success(
                  "Registro marcado como finalizado com sucesso"
                );
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const markAllAsOk = () => {
      const { loading, markAsOkService } = getService();

      useConfirm().require({
        message: `Deseja marcar o veículo como finalizado os registros selecionados?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            props.idVeiculo &&
            state.filaVeiculoSelected &&
            state.filaVeiculoSelected.length > 0
          ) {
            state.loadings.push(loading);
            markAsOkService(props.idVeiculo, state.filaVeiculoSelected)
              .then(() => {
                useToast().success(
                  "Registros marcados como finalizados com sucesso"
                );
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const getSituacaoNome = (filaVeiculo: FilaVeiculoPayload) => {
      if (filaVeiculo.statusGovpassOrgao || filaVeiculo.situacao) {
        const statusGovpassOrgao = useStatusService().getStatusOrgaoGovPass(
          filaVeiculo.statusGovpassOrgao
        );
        const situacaoGovpass = statusGovpassOrgao?.descricao;
        return [
          filaVeiculo.situacao != situacaoGovpass
            ? "<b>Situação GovPass</b>: " + situacaoGovpass
            : null,
          filaVeiculo.situacao
            ? [
                //(licencaOrgao.situacao != licencaOrgao.situacaoGovpass ?
                "<b>Situação Órgão</b>: " +
                  //: "")
                  (filaVeiculo.situacao ?? situacaoGovpass) +
                  ""
              ]
                .filter((e) => e)
                .join(" - ")
            : null
        ]
          .filter((e) => e)
          .join(" <br/> ");
      }
      return "---";
    };

    const confirmSelectionFilaVeiculo = (
      selected: Array<FilaVeiculoPayload>,
      added: Array<FilaVeiculoPayload>,
      removed: Array<FilaVeiculoPayload>
    ) => {
      if (added && added.length > 0) {
        added.forEach((fila) => {
          if (
            fila.idFilaVeiculo &&
            state.filaVeiculoSelected.indexOf(fila.idFilaVeiculo) < 0
          ) {
            state.filaVeiculoSelected.push(fila.idFilaVeiculo);
          }
        });
      }
      if (removed && removed.length > 0) {
        removed.forEach((fila) => {
          if (
            fila.idFilaVeiculo &&
            state.filaVeiculoSelected.indexOf(fila.idFilaVeiculo) >= 0
          ) {
            state.filaVeiculoSelected.splice(
              state.filaVeiculoSelected.indexOf(fila.idFilaVeiculo),
              1
            );
          }
        });
      }
      return selected;
    };

    const getNomeTransportador = (e?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    onMounted(async () => {
      state.stateReady = true;
      submitSearch();
    });

    return {
      submitSearch,
      executeSearch,
      play,
      playAll,
      markAsOk,
      markAllAsOk,
      getNomeTransportador,
      confirmSelectionFilaVeiculo,
      getSituacaoNome,
      format,
      formatDateTime,
      stateLoading,
      amIAllowed,
      amIBlocked,
      ...toRefs(state)
    };
  },
  components: {},
  props: {
    idVeiculo: {
      type: Number,
      required: true
    },
    isVeiculoTrator: {
      type: Boolean,
      required: true
    },
    label: String
  }
});
