
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  onUnmounted,
  computed,
  Ref
} from "vue";
import { useStatusService } from "../../modules/business/status/statusBusiness";
import {
  usePainelService,
  PainelRoboPayload,
  PainelRoboFilterPayload
} from "../../modules/business/painel/painelBusiness";
import { useAuth, PermissionPayload } from "../../modules/auth";

interface PainelState {
  stateReady: boolean;
  isOpen: boolean;
  loadings: Array<Ref<boolean>>;
  stateId: string;
  interval?: number | null;
  filter: PainelRoboFilterPayload;
  dadosOrgaos: Array<PainelRoboPayload>;
}

export default defineComponent({
  setup() {
    const state = reactive<PainelState>({
      stateReady: false,
      isOpen: false,
      filter: {},
      dadosOrgaos: [],
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const situacoesDisponiveis = computed(() => {
      return useStatusService()
        .getStatusesOrgaoGovPass()
        .filter((sit) =>
          state.dadosOrgaos.map((d) =>
            Object.keys(d.situacoesLicencas).some((k) => sit.flag == k)
          )
        );
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const openTab = () => {
      if (!state.isOpen) {
        state.isOpen = true;
      }
    };

    const closeTab = () => {
      state.isOpen = false;
    };

    const submitSearch = () => {
      const { getPainelRobo, data, loading } = usePainelService();
      state.loadings.push(loading);
      return getPainelRobo(state.filter)
        .then(() => {
          state.dadosOrgaos = data.value;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const filterBy = () => {
      console.log("filter");
    };

    const stopCadastro = () => {
      console.log("stopCadastro");
    };

    const stopConsulta = () => {
      console.log("stopConsulta");
    };

    const startCadastro = () => {
      console.log("startCadastro");
    };

    const startConsulta = () => {
      console.log("startConsulta");
    };

    onMounted(async () => {
      submitSearch().then(() => {
        state.interval = setInterval(submitSearch, 3 * 60 * 1000);
        state.stateReady = true;
      });
    });

    onUnmounted(() => {
      if (state.interval) {
        clearInterval(state.interval);
        state.interval = null;
      }
    });

    return {
      stateLoading,
      situacoesDisponiveis,
      amIAllowed,
      filterBy,
      stopCadastro,
      stopConsulta,
      startCadastro,
      startConsulta,
      openTab,
      closeTab,
      ...toRefs(state)
    };
  },
  components: {
    //Sidebar
  }
});
