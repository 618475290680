
import CadastroEngenheiro from "./CadastroEngenheiro.vue";
import { PaginationResult, defaultResults } from "../../pagination";
import { useAuth, PermissionPayload } from "../../modules/auth";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { RouteLocationNormalizedLoaded, useRouter } from "vue-router";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../modules/business/cliente/clienteBusiness";
import {
  EngenheiroPayload,
  EngenheiroFilterPayload,
  useEngenheiroService
} from "../../modules/business/engenheiro/engenheiroBusiness";
import {
  indexesOfElement,
  addSetElement,
  toggleElement,
  format,
  copyObject,
  clearObject
} from "../../modules/utils";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import { focusOnLastElement } from "../../modules/globalAppProperties";
import { useConfirm } from "../../modules/message";

interface EngenheiroComponentParams {
  isSearch: boolean;
  selected: Array<EngenheiroPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idEngenheiro?: number;
  preFilter?: EngenheiroFilterPayload;
}

interface EngenheiroState {
  idEngenheiro: number | null;
  showEdit: boolean;
  results: PaginationResult<EngenheiroPayload>;
  filter: EngenheiroFilterPayload;
  page: number;
  stateReady: boolean;
  params: EngenheiroComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<EngenheiroState>({
      idEngenheiro: null,
      showEdit: false,
      stateReady: false,
      filter: useEngenheiroService().safeFilterEngenheiro(),
      page: 0,
      results: defaultResults(),
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idEngenheiro: props.idEngenheiro,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple,
        preFilter: props.preFilter
      },
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    copyObject(clearObject(props.preFilter), state.filter);

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const keyRegister = computed(() => {
      return state.showEdit
        ? state.idEngenheiro
          ? state.idEngenheiro
          : "new-" +
            Math.random()
              .toString(36)
              .substring(2)
        : null;
    });

    const executeSearch = () => {
      const { data, loading, searchEngenheiro } = useEngenheiroService();
      state.loadings.push(loading);
      return searchEngenheiro(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const getParamsRouteEdit = (idEngenheiro: number) => {
      return {
        name: "engenheiro-edit",
        params: { idEngenheiro: idEngenheiro + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (route.name == "engenheiro-new") {
        state.showEdit = true;
        state.idEngenheiro = null;
      } else if (route.name == "engenheiro-edit") {
        if (route.params && route.params.idEngenheiro) {
          state.idEngenheiro = Array.isArray(route.params.idEngenheiro)
            ? +route.params.idEngenheiro[0]
            : +route.params.idEngenheiro;
        } else {
          state.idEngenheiro = null;
        }
        state.showEdit = true;
      } else {
        state.showEdit = false;
        state.idEngenheiro = null;
        executeSearch();
      }
    };

    const confirmSelection = (selected: Array<EngenheiroPayload>) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const deleteEntity = (engenheiro: EngenheiroPayload) => {
      useConfirm().require({
        message: `Deseja excluir o engenheiro '${engenheiro.cpfCnpj} - ${engenheiro.nome}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (engenheiro && engenheiro.idEngenheiro) {
            const { loading, deleteEngenheiro } = useEngenheiroService();
            state.loadings.push(loading);
            deleteEngenheiro(engenheiro.idEngenheiro)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const openEntity = (idEngenheiro: number, newTab?: boolean) => {
      const routeParams = getParamsRouteEdit(idEngenheiro);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = () => {
      const routeParams = {
        name: "engenheiro-new"
      };
      if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const afterRegisterScreenClosed = (engenheiro?: EngenheiroPayload) => {
      const routeParams = {
        name: "engenheiro"
      };
      if (state.params.isSearch) {
        if (state.params.idEngenheiro) {
          if (engenheiro && engenheiro.idEngenheiro) {
            confirmSelection([engenheiro]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            engenheiro &&
            indexesOfElement(
              state.params.selected,
              engenheiro,
              (ob) => ob && ob.idEngenheiro
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  engenheiro,
                  (ob) => ob && ob.idEngenheiro,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterSaveNewEntity = (idEngenheiro: number) => {
      openEntity(idEngenheiro);
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const clearFilter = () => {
      state.filter = useEngenheiroService().safeFilterEngenheiro();
      state.results = defaultResults();
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
    };

    const deveAbrirTelaCadastro =
      state.params.idEngenheiro || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idEngenheiro) {
        state.idEngenheiro = state.params.idEngenheiro;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      if (props.cliente && props.cliente.idCliente) {
        state.filter.idCliente = props.cliente.idCliente;
      }
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
      if (!deveAbrirTelaCadastro) {
        submitSearch().then(() => {
          if (
            state.params.preFilter &&
            state.params.preFilter.cpfCnpj &&
            state.results.content.length == 1
          ) {
            const arr = [] as Array<EngenheiroPayload>;
            state.params.selected.forEach((el) => arr.push(el));
            addSetElement(
              arr,
              state.results.content[0],
              (ob) => ob && ob.idEngenheiro,
              state.params.isMultiple
            );
            confirmSelection(arr);
          } else {
            focusOnLastElement();
          }
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      deleteEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterSaveNewEntity,
      afterPageChange,
      clearFilter,
      keyRegister,
      format,
      amIAllowed,
      amIBlocked,
      getNomeCliente,
      getParamsRouteEdit,
      stateLoading,
      confirmSelectionCliente,
      ...toRefs(state)
    };
  },
  components: {
    CadastroEngenheiro,
    EntitySelectorCliente
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idEngenheiro: Number,
    preFilter: Object as PropType<EngenheiroFilterPayload>,
    cliente: Object as PropType<ClientePayload>,
    selected: {
      type: Array as PropType<Array<EngenheiroPayload>>
    }
  }
});
