<template>
  <input-with-label
    v-if="stateReady"
    :label="label"
    :required="required"
    v-slot="l"
    :id="stateId"
  >
    <Calendar
      v-model="internalValue"
      :id="l.id"
      view="year"
      :required="l.required"
      :minDate="minDate"
      :maxDate="maxDate"
      dateFormat="yy"
      @input="onchange"
    />
  </input-with-label>
</template>

<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */

import { defineComponent, reactive, toRefs, onMounted, watch } from "vue";

interface DatePickerState {
  internalValue?: Date;
  stateReady: boolean;
  stateId: string;
  minDate: Date;
  maxDate: Date;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<DatePickerState>({
      internalValue: props.modelValue
        ? new Date(props.modelValue, 0, 1)
        : undefined,
      stateReady: false,
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2099, 11, 31)
    });

    const onchange = (event: FocusEvent) => {
      if (event) {
        const valueStr = (event?.target as HTMLInputElement)?.value;
        if (/^(19|20)\d{2}$/i.test(valueStr)) {
          const value = +valueStr;
          if (state.internalValue?.getFullYear() != value) {
            state.internalValue = new Date(value, 0, 1);
          }
        }
      }
    };

    onMounted(async () => {
      watch([state], () => {
        if (props.modelValue != state.internalValue?.getFullYear()) {
          emit("update:modelValue", state.internalValue?.getFullYear());
        }
      });
      watch([props], () => {
        if (props.modelValue != state.internalValue?.getFullYear()) {
          state.internalValue = props.modelValue
            ? new Date(props.modelValue, 0, 1)
            : undefined;
        }
      });
      state.stateReady = true;
    });

    return {
      onchange,
      ...toRefs(state)
    };
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Number
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    label: {
      type: String,
      default: "Data"
    }
  },
  components: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
