import { useApiWithAuth } from "@/modules/api";
import { PermissionPayload } from "@/modules/auth";
import {
  MunicipioPayload,
  DocumentoPayload,
  CredencialPayload
} from "@/modules/business/common/commonBusiness";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";
import { format } from "@/modules/utils";

interface TransportadorFilterPayload {
  idCliente?: number;
  nome: string;
  cpfCnpj: string;
  arquivado: boolean;
}

interface TransportadorOrgaoPayload {
  idOrgao?: number | null;
  sigla?: string | null;
  usuario?: string | null;
  senha?: string | null;
}

interface TransportadorPayload {
  idTransportador?: number | null;
  nome?: string | null;
  cpfCnpj?: string | null;
  cep?: string | null;
  logradouro?: string | null;
  numero?: string | null;
  complemento?: string | null;
  bairro?: string | null;
  ativo?: boolean | null;
  flExclusao?: boolean | null;
  municipio: MunicipioPayload;
  telefone?: string | null;
  celular?: string | null;
  email?: string | null;
  rntrc?: string | null;
  cpfCnpjResponsavelPreenchimento?: string | null;
  nomeResponsavelPreenchimento?: string | null;
  telefoneResponsavelPreenchimento?: string | null;
  contratoSocial: DocumentoPayload;
  procuracao: DocumentoPayload;
  usuario: CredencialPayload;
  transportadorOrgaos: Array<TransportadorOrgaoPayload>;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
  transportadorGrupo?: TransportadorPayload;
}

const useTransportadorService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    patch,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const searchTransportador = (
    filter: TransportadorFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/transportador", {
      ...filter,
      ...{
        page: page
      }
    });
  };

  const getTransportador = (idTransportador: number): Promise<void> => {
    return get(`/transportador/${idTransportador}`);
  };

  const newTransportador = (idCliente?: number | null): Promise<void> => {
    return get("/transportador/new", idCliente ? { idCliente: idCliente } : {});
  };

  const shelveTransportador = (idTransportador: number): Promise<void> => {
    return patch("/transportador/" + idTransportador + "/arquivar");
  };

  const unshelveTransportador = (idTransportador: number): Promise<void> => {
    return patch("/transportador/" + idTransportador + "/desarquivar");
  };

  const saveNewTransportador = (
    transportador: TransportadorPayload
  ): Promise<void> => {
    return post("/transportador", transportador);
  };

  const updateTransportador = (
    idTransportador: number,
    transportador: TransportadorPayload
  ): Promise<void> => {
    return put(`/transportador/${idTransportador}`, transportador);
  };

  const downloadContrato = (
    idTransportador: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/transportador/${idTransportador}/contrato-social`
    );
  };

  const downloadProcuracao = (
    idTransportador: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/transportador/${idTransportador}/procuracao`
    );
  };

  const loadUsingCpfCnpj = (
    cpfCnpj: string,
    isRefresh: boolean,
    cliente?: ClientePayload | null
  ): Promise<void> => {
    const params: Record<string, boolean | number> = { refresh: isRefresh };
    if (cliente && cliente.idCliente) {
      params.idCliente = cliente.idCliente;
    }
    return get("/transportador/busca/" + cpfCnpj, params);
  };

  const isTransportadorOrgaoValidoCadastro = (
    idTransportador: number,
    idOrgao: number
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      get("/transportador/" + idTransportador + "/orgao/" + idOrgao)
        .then(() => {
          if (data && data.value && !!data.value) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  const isTransportadorValidoParaGrupoCadastro = (
    idTransportadorGrupo: number,
    idTransportadorFilho?: number | null,
    cliente?: ClientePayload | null
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const params: Record<string, number> = {};
      if (idTransportadorFilho) {
        params.idTransportadorFilho = idTransportadorFilho;
      }
      if (cliente && cliente.idCliente) {
        params.idCliente = cliente.idCliente;
      }
      get(
        "/transportador/" + idTransportadorGrupo + "/validate-group-allowed",
        params
      )
        .then(() => {
          if (data && data.value && !!data.value) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  const safeTransportador = (
    entity?: TransportadorPayload | null
  ): TransportadorPayload => {
    const ret = entity ?? {
      municipio: {},
      contratoSocial: {},
      procuracao: {},
      transportadorOrgaos: [],
      usuario: {}
    };

    ret.contratoSocial = ret.contratoSocial ?? {};
    ret.municipio = ret.municipio ?? {};
    ret.transportadorOrgaos = ret.transportadorOrgaos ?? [];
    ret.usuario = ret.usuario ?? {};
    ret.contratoSocial.keyBlDocumentoTemp = null;
    ret.procuracao.keyBlDocumentoTemp = null;
    return ret;
  };

  const safeFilterTransportador = (): TransportadorFilterPayload => {
    return {
      nome: "",
      cpfCnpj: "",
      arquivado: false
    };
  };

  const getNomeTransportador = (entity?: TransportadorPayload) => {
    if (!entity) {
      return "";
    }
    return (
      format(["###.###.###-##", "##.###.###/####-##"], entity.cpfCnpj) +
      " - " +
      entity?.nome
    );
  };

  return {
    data,
    loading,
    searchTransportador,
    shelveTransportador,
    unshelveTransportador,
    downloadContrato,
    downloadProcuracao,
    saveNewTransportador,
    updateTransportador,
    newTransportador,
    getTransportador,
    safeTransportador,
    safeFilterTransportador,
    getNomeTransportador,
    isTransportadorOrgaoValidoCadastro,
    isTransportadorValidoParaGrupoCadastro,
    loadUsingCpfCnpj
  };
};

export {
  useTransportadorService,
  TransportadorFilterPayload,
  TransportadorOrgaoPayload,
  TransportadorPayload
};
