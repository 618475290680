
import CadastroVeiculoTrator from "./CadastroVeiculoTrator.vue";
import { PaginationResult, defaultResults } from "../../../pagination";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import EntitySelectorTransportador from "../../transportador/EntitySelectorTransportador.vue";
import { useTipoCarroceriaService } from "../../../modules/business/veiculo/carroceriaBusiness";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { RouteLocationNormalizedLoaded, useRouter } from "vue-router";
import {
  VeiculoTratorPayload,
  VeiculoTratorFilterPayload,
  useVeiculoTratorService
} from "../../../modules/business/veiculo/veiculoTratorBusiness";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../../modules/business/cliente/clienteBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import {
  indexesOfElement,
  addSetElement,
  toggleElement,
  format,
  copyObject,
  clearObject
} from "../../../modules/utils";
import EntitySelectorCliente from "../../cliente/EntitySelectorCliente.vue";
import { focusOnLastElement } from "../../../modules/globalAppProperties";
import { useConfirm } from "../../../modules/message";

interface VeiculoTratorComponentParams {
  isSearch: boolean;
  selected: Array<VeiculoTratorPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idVeiculoTrator?: number;
  preFilter?: VeiculoTratorFilterPayload;
}

interface VeiculoTratorState {
  idVeiculoTrator: number | null;
  showEdit: boolean;
  results: PaginationResult<VeiculoTratorPayload>;
  filter: VeiculoTratorFilterPayload;
  page: number;
  stateReady: boolean;
  params: VeiculoTratorComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<VeiculoTratorState>({
      idVeiculoTrator: null,
      showEdit: false,
      stateReady: false,
      filter: useVeiculoTratorService().safeFilterVeiculoTrator(),
      page: 0,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idVeiculoTrator: props.idVeiculoTrator,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple,
        preFilter: props.preFilter
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    copyObject(clearObject(props.preFilter), state.filter);

    const getNome = (e?: VeiculoTratorPayload) => {
      return useVeiculoTratorService().getNomeVeiculoTrator(e);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const executeSearch = () => {
      const { data, loading, searchVeiculoTrator } = useVeiculoTratorService();
      state.loadings.push(loading);
      return searchVeiculoTrator(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const keyRegister = computed(() => {
      return state.showEdit
        ? state.idVeiculoTrator
          ? state.idVeiculoTrator
          : "new-" +
            Math.random()
              .toString(36)
              .substring(2)
        : null;
    });

    const getParamsRouteEdit = (idVeiculoTrator: number) => {
      return {
        name: "veiculo-trator-edit",
        params: { idVeiculoTrator: idVeiculoTrator + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (route.name == "veiculo-trator-new") {
        state.showEdit = true;
        state.idVeiculoTrator = null;
      } else if (route.name == "veiculo-trator-edit") {
        if (route.params && route.params.idVeiculoTrator) {
          state.idVeiculoTrator = Array.isArray(route.params.idVeiculoTrator)
            ? +route.params.idVeiculoTrator[0]
            : +route.params.idVeiculoTrator;
        } else {
          state.idVeiculoTrator = null;
        }
        state.showEdit = true;
      } else {
        state.showEdit = false;
        state.idVeiculoTrator = null;
        executeSearch();
      }
    };

    const openEntity = (idVeiculoTrator: number, newTab?: boolean) => {
      const routeParams = getParamsRouteEdit(idVeiculoTrator);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = () => {
      const routeParams = {
        name: "veiculo-trator-new"
      };
      if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const downloadCRLV = (idVeiculoTrator: number, newTab?: boolean) => {
      const { downloadCRLV, loading } = useVeiculoTratorService();
      state.loadings.push(loading);
      downloadCRLV(idVeiculoTrator, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const shelveEntity = (veiculoTrator: VeiculoTratorPayload) => {
      useConfirm().require({
        message: "Deseja arquivar o Veículo " + getNome(veiculoTrator) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (veiculoTrator && veiculoTrator.idVeiculoTrator) {
            const { shelveVeiculoTrator, loading } = useVeiculoTratorService();
            state.loadings.push(loading);
            shelveVeiculoTrator(veiculoTrator.idVeiculoTrator)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const unshelveEntity = (veiculoTrator: VeiculoTratorPayload) => {
      useConfirm().require({
        message: "Deseja desarquivar o Veículo " + getNome(veiculoTrator) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (veiculoTrator && veiculoTrator.idVeiculoTrator) {
            const {
              unshelveVeiculoTrator,
              loading
            } = useVeiculoTratorService();
            state.loadings.push(loading);
            unshelveVeiculoTrator(veiculoTrator.idVeiculoTrator)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const confirmSelection = (selected: Array<VeiculoTratorPayload>) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const afterRegisterScreenClosed = (
      veiculoTrator?: VeiculoTratorPayload
    ) => {
      const routeParams = {
        name: "veiculo-trator"
      };
      if (state.params.isSearch) {
        if (state.params.idVeiculoTrator) {
          if (veiculoTrator && veiculoTrator.idVeiculoTrator) {
            confirmSelection([veiculoTrator]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            veiculoTrator &&
            indexesOfElement(
              state.params.selected,
              veiculoTrator,
              (ob) => ob && ob.idVeiculoTrator
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  veiculoTrator,
                  (ob) => ob && ob.idVeiculoTrator,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterSaveNewEntity = (idVeiculoTrator: number) => {
      openEntity(idVeiculoTrator);
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const clearFilter = () => {
      state.filter = useVeiculoTratorService().safeFilterVeiculoTrator();
      state.results = defaultResults();
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    const getNomeTransportador = (e?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const getNomeTipo = (e: VeiculoTratorPayload) => {
      let tipo = "";
      if (e.flTipoVeiculoTrator) {
        tipo +=
          useVeiculoTratorService().getTipoVeiculoTrator(e.flTipoVeiculoTrator)
            ?.descricao ?? "";
      }
      if (e.idTipoCarroceria) {
        tipo +=
          " - " +
            useTipoCarroceriaService().getTipoCarroceria(e.idTipoCarroceria)
              ?.descricao ?? "";
      }
      return tipo ? tipo : "---";
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      state.filter.idTransportador =
        t && t.idTransportador ? t.idTransportador : undefined;
    };

    const deveAbrirTelaCadastro =
      state.params.idVeiculoTrator || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idVeiculoTrator) {
        state.idVeiculoTrator = state.params.idVeiculoTrator;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      if (props.cliente && props.cliente.idCliente) {
        state.filter.idCliente = props.cliente.idCliente;
      }
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
      if (!deveAbrirTelaCadastro) {
        submitSearch().then(() => {
          if (
            state.params.preFilter &&
            state.params.preFilter.placaNf &&
            state.results.content.length == 1 &&
            (!props.blockedSelection ||
              !props.blockedSelection(state.results.content[0]))
          ) {
            const arr = [] as Array<VeiculoTratorPayload>;
            state.params.selected.forEach((el) => arr.push(el));
            addSetElement(
              arr,
              state.results.content[0],
              (ob) => ob && ob.idVeiculoTrator,
              state.params.isMultiple
            );
            confirmSelection(arr);
          } else {
            focusOnLastElement();
          }
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      shelveEntity,
      unshelveEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterSaveNewEntity,
      afterPageChange,
      clearFilter,
      format,
      amIAllowed,
      amIBlocked,
      getNome,
      getNomeTipo,
      getNomeCliente,
      getNomeTransportador,
      downloadCRLV,
      getParamsRouteEdit,
      stateLoading,
      keyRegister,
      confirmSelectionCliente,
      confirmSelectionTransportador,
      ...toRefs(state)
    };
  },
  components: {
    CadastroVeiculoTrator,
    EntitySelectorCliente,
    EntitySelectorTransportador
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idVeiculoTrator: Number,
    preFilter: Object as PropType<VeiculoTratorFilterPayload>,
    cliente: Object as PropType<ClientePayload>,
    blockedSelection: {
      type: Function,
      required: false
    },
    selected: {
      type: Array as PropType<Array<VeiculoTratorPayload>>
    }
  }
});
