<template>
  <div>
    <container
      :isModal="true"
      @close-modal="closeScreen()"
      :loading="stateLoading || !stateReady"
    >
      <template v-slot:header>
        <h3>Cadastro do Transportador</h3>
      </template>
      <template v-slot:body>
        <template v-if="stateReady">
          <form @submit.prevent="submitSave">
            <entity-selector-cliente
              :cliente="entity.cliente"
              :canEdit="!entity.cliente?.idCliente && !stateBlocked"
              @confirm-selection="confirmSelectionCliente"
              :required="true"
              :parentLoading="stateBlocked"
            />
            <fieldset
              :disabled="
                stateBlocked ||
                  (amIAllowed('view', 'root-cliente') &&
                    !entity.cliente?.idCliente)
              "
            >
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-3">
                  <div class="p-inputgroup">
                    <input-with-label
                      label="CPF/CNPJ"
                      :required="true"
                      v-slot="l"
                    >
                      <InputText
                        v-model="entity.cpfCnpj"
                        v-maska="['###.###.###-##', '##.###.###/####-##']"
                        v-focus
                        @change="onChangeCpfCnpj()"
                        :id="l.id"
                        :required="l.required"
                        :disabled="!!entity.idTransportador"
                      />
                    </input-with-label>
                    <Button
                      v-if="entity.idTransportador || loadingEntity"
                      :disabled="loadingEntity"
                      v-title="'Recarregar Dados da Placa'"
                      style="min-width: initial"
                      @click="loadEntity(true)"
                      class="p-button-sm p-button-icon-only"
                    >
                      <ProgressSpinner
                        v-if="loadingEntity"
                        style="width: 1.2rem; height: 1.2rem"
                      />
                      <i class="fas fa-sync-alt" style="width: 1.2rem" v-else />
                    </Button>
                  </div>
                  <template v-if="!loadingEntity && lastLoadedEntity">
                    <span
                      style="float: right; color: red"
                      class="p-pr-2"
                      v-if="lastLoadedEntity?.nome === undefined"
                    >
                      <small style="float: right">
                        <em
                          >NÃO FOI POSSÍVEL BUSCAR AS INFORMAÇÕES DO
                          TRANSPORTADOR !</em
                        >
                      </small>
                    </span>
                    <span
                      style="float: right; color: red"
                      class="p-pr-2"
                      v-if="
                        lastLoadedEntity?.idTransportador &&
                          lastLoadedEntity?.idTransportador !==
                            entity.idTransportador
                      "
                    >
                      <small style="float: right">
                        <em>TRANSPORTADOR JÁ CADASTRADO !</em>
                      </small>
                    </span>
                    <span
                      style="float: right; color: green"
                      class="p-pr-2"
                      v-if="
                        lastLoadedEntity?.idTransportador &&
                          lastLoadedEntity?.idTransportador ===
                            entity.idTransportador
                      "
                    >
                      <small style="float: right">
                        <em>DADOS RECARREGADOS COM SUCESSO !</em>
                      </small>
                    </span>
                  </template>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-9">
                  <input-with-label
                    :label="
                      cpfCnpjSemMascara.length > 13 ? 'Razão Social' : 'Nome'
                    "
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.nome"
                      :id="l.id"
                      :required="l.required"
                      maxlength="250"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label label="RNTRC" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.rntrc"
                      :id="l.id"
                      :required="l.required"
                      maxlength="9"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label label="E-mail" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.email"
                      type="email"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      :id="l.id"
                      :required="l.required"
                      maxlength="250"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label
                    label="Telefone"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.telefone"
                      :id="l.id"
                      :required="l.required"
                      v-maska="[
                        '####-####',
                        '#####-####',
                        '(##) ####-####',
                        '(##) #####-####'
                      ]"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label label="Celular" v-slot="l">
                    <InputText
                      v-model="entity.celular"
                      :id="l.id"
                      :required="l.required"
                      v-maska="[
                        '####-####',
                        '#####-####',
                        '(##) ####-####',
                        '(##) #####-####'
                      ]"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6">
                  <input-file
                    label="Contrato Social"
                    downloadTitle="Baixar Contrato Social Atual"
                    :id="stateId + '_contrato'"
                    :hasDownload="!!entity.contratoSocial.idDocumento"
                    @click-download="downloadContrato"
                    accept="application/pdf"
                  />
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6">
                  <input-file
                    label="Procuração"
                    downloadTitle="Baixar Procuração Atual"
                    :id="stateId + '_procuracao'"
                    :hasDownload="!!entity.procuracao.idDocumento"
                    @click-download="downloadProcuracao"
                    accept="application/pdf"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="CEP" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.cep"
                      :id="l.id"
                      :required="l.required"
                      v-maska="'#####-###'"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6">
                  <input-with-label
                    label="Logradouro"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.logradouro"
                      :id="l.id"
                      :required="l.required"
                      maxlength="150"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="Número" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.numero"
                      :id="l.id"
                      :required="l.required"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="Bairro" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.bairro"
                      :id="l.id"
                      :required="l.required"
                      maxlength="150"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-9">
                  <entity-selector-municipio
                    :idUnidadeFederal="entity.municipio.idUnidadeFederal"
                    :idMunicipio="entity.municipio.idMunicipio"
                    :required="true"
                    @after-set-municipio="afterSetMunicipio"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label
                    label="Usuário"
                    subLabel="Acesso ao GovPass"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.usuario.login"
                      :id="l.id"
                      :required="l.required"
                      maxlength="250"
                      :disabled="!entity.idTransportador || hasUser"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label
                    label="Senha"
                    subLabel="Acesso ao GovPass"
                    v-slot="l"
                  >
                    <Password
                      v-model="entity.usuario.novaSenha"
                      :id="l.id"
                      :required="l.required"
                      maxlength="250"
                      :disabled="!entity.idTransportador"
                      toggleMask
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-6">
                  <entity-selector-transportador
                    :transportador="entity.transportadorGrupo"
                    @confirm-selection="confirmSelectionTransportadorGrupo"
                    :cliente="entity.cliente"
                    :label="'Transportador Pai'"
                    :idTransportadorFiltered="
                      entity.transportadorGrupo?.idTransportador
                    "
                  />
                </div>

                <Divider align="left" type="solid" class="p-pl-0">
                  <b>Prenchimento AET</b>
                </Divider>

                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="CPF do Responsável"
                    :required="false"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.cpfCnpjResponsavelPreenchimento"
                      v-maska="'###.###.###-##'"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6">
                  <input-with-label
                    label="Nome do Responsável"
                    :required="false"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.nomeResponsavelPreenchimento"
                      maxlength="250"
                      :id="l.id"
                      :required="l.required"
                      v-focus
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="Telefone do Responsável"
                    :required="false"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.telefoneResponsavelPreenchimento"
                      :id="l.id"
                      :required="l.required"
                      v-maska="[
                        '####-####',
                        '#####-####',
                        '(##) ####-####',
                        '(##) #####-####'
                      ]"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>
              </div>

              <div class="p-formgrid p-grid">
                <search-result
                  :showSelector="false"
                  :results="entity.transportadorOrgaos"
                  :loading="stateLoading"
                  :hidePagination="true"
                  :hideActions="true"
                >
                  <template v-slot:title> Acessos </template>
                  <template v-slot:columns>
                    <Column
                      field="idOrgao"
                      header="Órgão"
                      headerClass="p-header-center"
                      style="width: 6rem; text-align: center"
                    >
                      <template #body="slotProps">
                        <orgao-aet-img :idOrgao="slotProps.data.idOrgao" />
                      </template>
                    </Column>
                    <Column field="usuario" header="Usuário">
                      <template #body="slotProps">
                        <input-with-label
                          label="Usuário"
                          v-slot="l"
                          :required="
                            !!(slotProps.data.usuario || slotProps.data.senha)
                          "
                        >
                          <InputText
                            v-model="slotProps.data.usuario"
                            :id="l.id"
                            :required="l.required"
                            maxlength="250"
                          />
                        </input-with-label>
                      </template>
                    </Column>
                    <Column field="senha" header="Senha">
                      <template #body="slotProps">
                        <input-with-label
                          label="Senha"
                          v-slot="l"
                          :required="
                            !!(slotProps.data.usuario || slotProps.data.senha)
                          "
                        >
                          <Password
                            v-model="slotProps.data.senha"
                            :id="l.id"
                            :required="l.required"
                            maxlength="250"
                            :feedback="false"
                            toggleMask
                          />
                        </input-with-label>
                      </template>
                    </Column>
                  </template>
                </search-result>
              </div>
              <div class="p-formgrid p-grid">
                <crud-art-engenheiro-transportador
                  :transportador="entity"
                  :cliente="entity.cliente"
                  v-if="entity.idTransportador"
                  label="ART - Anotação de Responsabilidade Técnica"
                />
                <span v-else>
                  As ARTs Estarão disponível apos o cadastramento do
                  transportador.
                </span>
              </div>
              <div class="p-col-12">
                <Button type="submit" class="p-button-rounded" label="Salvar" />
              </div>
            </fieldset>
          </form>
        </template>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref,
  PropType
} from "vue";
import { useMunicipioService } from "../../modules/business/common/commonBusiness";
import { useStorage } from "../../modules/storage";
import { useAuth, PermissionPayload } from "../../modules/auth";
import {
  useTransportadorService,
  TransportadorPayload
} from "../../modules/business/transportador/transportadorBusiness";
import { clearObject, copyObject } from "../../modules/utils";
import { useToast } from "../../modules/message";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import CrudArtEngenheiroTransportador from "./art/CrudArtEngenheiroTransportador.vue";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import EntitySelectorMunicipio from "../common/EntitySelectorMunicipio.vue";

// eslint-disable-next-line
declare var require: any; //PQP MEMO

interface TransportadorCadastroState {
  entity: TransportadorPayload;
  stateReady: boolean;
  hasUser: boolean;
  savedEntity: boolean;
  loadings: Array<Ref<boolean>>;
  loadingEntity: boolean;
  lastLoadedEntity: TransportadorPayload | null;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<TransportadorCadastroState>({
      entity: useTransportadorService().safeTransportador(),
      hasUser: false,
      savedEntity: false,
      stateReady: false,
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      lastLoadedEntity: null,
      loadingEntity: false
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const cpfCnpjSemMascara = computed(() => {
      return state.entity.cpfCnpj
        ? state.entity.cpfCnpj?.replace(/\D/g, "")
        : "";
    });

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const loadEntity = (isRefresh: boolean) => {
      const cpfCpnj = state.entity.cpfCnpj?.replace(/\D/g, "");
      if (cpfCpnj && (cpfCpnj.length == 11 || cpfCpnj.length == 14)) {
        const { loadUsingCpfCnpj, loading, data } = useTransportadorService();
        state.loadings.push(loading);
        state.loadingEntity = true;
        loadUsingCpfCnpj(
          cpfCpnj,
          isRefresh,
          state.entity.cliente || props.cliente
        )
          .then(() => {
            const transp = data.value;
            state.lastLoadedEntity = transp;

            if (transp && transp.nome) {
              if (transp.idTransportador) {
                if (!state.entity.idTransportador) {
                  emit("request-entity-edit", transp.idTransportador);
                }
              } else {
                delete transp["idTransportador"];
                delete transp["cpfCnpj"];
                clearObject(transp);
                if (
                  transp.municipio &&
                  transp.municipio.idUnidadeFederal &&
                  transp.municipio.idUnidadeFederal !=
                    state.entity.municipio.idUnidadeFederal
                ) {
                  state.entity.municipio.idUnidadeFederal =
                    transp.municipio.idUnidadeFederal;
                }
                copyObject(transp, state.entity, ["municipio"]);
              }
            }
            state.loadingEntity = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadingEntity = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        useToast().warning("CPF/CNPJ inválido");
      }
    };

    const onChangeCpfCnpj = () => {
      const cpfCpnj = state.entity.cpfCnpj?.replace(/\D/g, "");
      if (
        !state.entity.idTransportador &&
        (cpfCpnj?.length == 11 || cpfCpnj?.length == 14)
      ) {
        loadEntity(false);
      }
    };

    const confirmSelectionTransportadorGrupo = (t?: TransportadorPayload) => {
      if (t) {
        state.entity.transportadorGrupo = t;
      } else {
        state.entity.transportadorGrupo = useTransportadorService().safeTransportador().transportadorGrupo;
      }
      const currentIdTransportador =
        state.entity.transportadorGrupo?.idTransportador;
      if (currentIdTransportador) {
        const {
          isTransportadorValidoParaGrupoCadastro,
          loading
        } = useTransportadorService();
        state.loadings.push(loading);
        isTransportadorValidoParaGrupoCadastro(
          currentIdTransportador,
          state.entity.idTransportador,
          state.entity.cliente || props.cliente
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.entity.transportadorGrupo = useTransportadorService().safeTransportador().transportadorGrupo;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      }
    };

    const setEntity = (entity: TransportadorPayload) => {
      state.entity = useTransportadorService().safeTransportador(entity);

      state.hasUser = !!state.entity.usuario.login;

      const contratoElement = document.querySelector(
        "#" + state.stateId + "_contrato"
      ) as HTMLInputElement;
      if (contratoElement) {
        contratoElement.value = "";
      }

      const procuracaoElement = document.querySelector(
        "#" + state.stateId + "_procuracao"
      ) as HTMLInputElement;
      if (procuracaoElement) {
        procuracaoElement.value = "";
      }
    };

    const downloadContrato = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idTransportador) {
        onEnd();
        return;
      }
      const { downloadContrato, loading } = useTransportadorService();
      state.loadings.push(loading);
      downloadContrato(state.entity.idTransportador, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const downloadProcuracao = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idTransportador) {
        onEnd();
        return;
      }
      const { downloadProcuracao, loading } = useTransportadorService();
      state.loadings.push(loading);
      downloadProcuracao(state.entity.idTransportador, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const deleteTempFiles = () => {
      if (state.entity.contratoSocial.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(
          state.entity.contratoSocial.keyBlDocumentoTemp
        );
        state.entity.contratoSocial.keyBlDocumentoTemp = null;
      }
      if (state.entity.procuracao.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(state.entity.procuracao.keyBlDocumentoTemp);
        state.entity.procuracao.keyBlDocumentoTemp = null;
      }
    };

    const save = () => {
      const idTransportadorProps = props.idTransportador ?? 0;
      const isInsert = !idTransportadorProps;
      const {
        saveNewTransportador,
        updateTransportador,
        loading: loadingCadastro,
        data: dataCadastro
      } = useTransportadorService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewTransportador(state.entity)
        : updateTransportador(idTransportadorProps, state.entity)
      )
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          setEntity(dataCadastro.value);
          state.savedEntity = true;
          useToast().success("Transportador salvo com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idTransportador);
          }
        })
        .catch(() => {
          deleteTempFiles();
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const processFile = (fileElementId: string) => {
      return new Promise<string>((resolve) => {
        const file = document.querySelector(
          "#" + fileElementId
        ) as HTMLInputElement;
        if (!file || !file.files || file.files.length === 0) {
          resolve("");
        } else {
          const formData = new FormData();
          formData.append("file", file.files[0]);
          const storage = useStorage();
          state.loadings.push(storage.loading);
          storage
            .uploadTempFile(formData)
            .then((key: string) => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
              resolve(key);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            });
        }
      });
    };

    const submitSave = () => {
      const arrayPromises = new Array<Promise<string>>();
      arrayPromises.push(
        processFile(state.stateId + "_contrato").then(
          (key) => (state.entity.contratoSocial.keyBlDocumentoTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_procuracao").then(
          (key) => (state.entity.procuracao.keyBlDocumentoTemp = key)
        )
      );
      Promise.all(arrayPromises).then(
        () => {
          save();
        },
        () => {
          deleteTempFiles();
        }
      );
    };

    onMounted(async () => {
      const {
        getTransportador,
        newTransportador,
        data,
        loading
      } = useTransportadorService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      (props.idTransportador
        ? getTransportador(props.idTransportador)
        : newTransportador(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      downloadContrato,
      downloadProcuracao,
      municipiosFiltrados,
      cpfCnpjSemMascara,
      amIAllowed,
      onChangeCpfCnpj,
      loadEntity,
      confirmSelectionCliente,
      confirmSelectionTransportadorGrupo,
      stateLoading,
      stateBlocked,
      afterSetMunicipio,
      ...toRefs(state)
    };
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.EntitySelectorTransportador = require("./EntitySelectorTransportador.vue").default;
    }
  },
  components: {
    CrudArtEngenheiroTransportador,
    EntitySelectorCliente,
    EntitySelectorMunicipio
  },
  props: {
    idTransportador: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
