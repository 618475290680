<template>
  <div>
    <slot />
    <!-- no futuro colocar aqui os avisos globais/alertas persistentes -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
