<template>
  <div class="p-inputgroup" v-if="hasButton && $slots.buttons">
    <slot />
    <slot name="buttons" />
  </div>
  <template v-else>
    <slot />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    hasButton: { type: [String, Number, Object, Boolean], required: true }
  },
  components: {}
});
</script>
