<template>
  <container
    :isModal="params.isSearch"
    @close-modal="cancelSelection()"
    :loading="!stateReady"
  >
    <template v-slot:header>
      <h3>Consulta de Modelos</h3>
    </template>
    <template v-slot:body>
      <div>
        <form @submit.prevent="submitSearch">
          <entity-selector-cliente
            :cliente="cliente"
            :canEdit="!cliente"
            :idClienteFiltered="filter.idCliente"
            @confirm-selection="confirmSelectionCliente"
            :parentLoading="stateLoading || showEdit"
          />
          <fieldset :disabled="stateLoading || showEdit">
            <div class="p-formgrid p-grid">
              <div class="p-field p-fluid p-col-12 p-sm-5 p-md-4 p-lg-3 p-xl-2">
                <input-with-label label="Marca" v-slot="l">
                  <InputText
                    v-model="filter.marca"
                    :id="l.id"
                    :required="l.required"
                    v-focus
                  />
                </input-with-label>
              </div>
              <div
                class="p-field p-fluid p-col-12 p-sm-7 p-md-8 p-lg-9 p-xl-10"
              >
                <input-with-label label="Modelo" v-slot="l">
                  <InputText
                    v-model="filter.nome"
                    :id="l.id"
                    :required="l.required"
                  />
                </input-with-label>
              </div>

              <div class="p-col-12">
                <button-group>
                  <Button
                    type="submit"
                    class="p-button-rounded"
                    label="Buscar"
                  />
                  <Button
                    label="Limpar"
                    class="p-button-rounded p-button-text"
                    @click="clearFilter()"
                  />
                </button-group>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <search-result
        :showSelector="params.isSearch"
        :results="results"
        :selected="params.selected"
        :multiple="params.isMultiple"
        :keyParam="'idModeloVeiculoTrator'"
        :loading="stateLoading"
        :showClient="true"
        @after-page-change="afterPageChange"
        @confirm-selection="confirmSelection"
      >
        <template v-slot:columns>
          <Column field="marca" header="Marca" style="width: 20rem" />
          <Column field="nome" header="Modelo">
            <template #body="slotProps">
              <span v-text="getNome(slotProps.data)" />
            </template>
          </Column>
          <Column :exportable="false" style="width: 10rem; text-align: right">
            <template #body="slotProps">
              <button-group>
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success"
                  v-if="amIAllowed('view', slotProps.data.permissionInfo)"
                  @click="openEntity(slotProps.data.idModeloVeiculoTrator)"
                  @click.middle.prevent.stop="
                    openEntity(
                      slotProps.data.idModeloVeiculoTrator,
                      false,
                      true
                    )
                  "
                />
                <Button
                  icon="fas fa-trash-alt"
                  class="p-button-rounded p-button-danger"
                  v-if="
                    !params.isSearch &&
                      amIAllowed('delete', slotProps.data.permissionInfo)
                  "
                  @click="deleteEntity(slotProps.data)"
                />
              </button-group>
            </template>
          </Column>
        </template>
        <template v-slot:actions>
          <Button
            label="Novo"
            :class="
              'p-button-rounded' + (params.isSearch ? ' p-button-text' : '')
            "
            v-if="amIAllowed('create', 'veiculo-trator-modelo')"
            @click="newEntity()"
          />
        </template>
      </search-result>
      <div class="register" v-if="showEdit">
        <cadastro-modelo-veiculo-trator
          :modeloVeiculoTrator="modeloVeiculoTrator"
          @after-register-screen-closed="afterRegisterScreenClosed"
          @after-save-new-entity="afterSaveNewEntity"
          @generate-copy="generateCopy"
          :parentLoading="stateLoading"
          :key="keyRegister"
          :cliente="cliente"
        />
      </div>
    </template>
  </container>
</template>

<script lang="ts">
import CadastroModeloVeiculoTrator from "./CadastroModeloVeiculoTrator.vue";
import { PaginationResult, defaultResults } from "../../../../pagination";
import { useAuth, PermissionPayload } from "../../../../modules/auth";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { RouteLocationNormalizedLoaded, useRouter } from "vue-router";
import {
  ModeloVeiculoTratorPayload,
  ModeloVeiculoTratorFilterPayload,
  useModeloVeiculoTratorService
} from "../../../../modules/business/veiculo/veiculoTratorBusiness";
import { ClientePayload } from "../../../../modules/business/cliente/clienteBusiness";
import {
  indexesOfElement,
  addSetElement,
  toggleElement,
  format,
  copyObject,
  clearObject
} from "../../../../modules/utils";
import EntitySelectorCliente from "../../../cliente/EntitySelectorCliente.vue";
import { focusOnLastElement } from "../../../../modules/globalAppProperties";
import { useConfirm } from "../../../../modules/message";

interface ModeloVeiculoTratorComponentParams {
  isSearch: boolean;
  selected: Array<ModeloVeiculoTratorPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idModeloVeiculoTrator?: number;
  idModeloVeiculoTratorCopy?: number;
  preFilter?: ModeloVeiculoTratorFilterPayload;
}

interface ModeloVeiculoTratorState {
  modeloVeiculoTrator: ModeloVeiculoTratorPayload | null;
  showEdit: boolean;
  results: PaginationResult<ModeloVeiculoTratorPayload>;
  filter: ModeloVeiculoTratorFilterPayload;
  page: number;
  stateReady: boolean;
  params: ModeloVeiculoTratorComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<ModeloVeiculoTratorState>({
      modeloVeiculoTrator: null,
      showEdit: false,
      stateReady: false,
      filter: useModeloVeiculoTratorService().safeFilterModeloVeiculoTrator(),
      page: 0,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idModeloVeiculoTrator: props.idModeloVeiculoTrator,
        idModeloVeiculoTratorCopy: props.idModeloVeiculoTratorCopy,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple,
        preFilter: props.preFilter
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    copyObject(clearObject(props.preFilter), state.filter);

    const getNome = (e?: ModeloVeiculoTratorPayload) => {
      return useModeloVeiculoTratorService().getNomeModeloVeiculoTrator(e);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const keyRegister = computed(() => {
      return state.showEdit
        ? state.modeloVeiculoTrator?.idModeloVeiculoTrator
          ? state.modeloVeiculoTrator?.idModeloVeiculoTrator
          : "new-" +
            Math.random()
              .toString(36)
              .substring(2)
        : null;
    });

    const executeSearch = () => {
      const {
        data,
        loading,
        searchModeloVeiculoTrator
      } = useModeloVeiculoTratorService();
      state.loadings.push(loading);
      return searchModeloVeiculoTrator(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const getParamsRouteEdit = (idModeloVeiculoTrator: number) => {
      return {
        name: "veiculo-trator-modelo-edit",
        params: { idModeloVeiculoTrator: idModeloVeiculoTrator + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (
        route.name == "veiculo-trator-modelo-new" ||
        route.name == "veiculo-trator-modelo-edit" ||
        route.name == "veiculo-trator-modelo-copy"
      ) {
        const {
          newModeloVeiculoTrator,
          getModeloVeiculoTrator,
          copyModeloVeiculoTrator,
          data,
          loading
        } = useModeloVeiculoTratorService();

        let p: Promise<void>;
        state.loadings.push(loading);
        if (route.name == "veiculo-trator-modelo-new") {
          p = newModeloVeiculoTrator(props.cliente?.idCliente);
        } else if (route.name == "veiculo-trator-modelo-edit") {
          let id: number | undefined;
          if (route.params && route.params.idModeloVeiculoTrator) {
            id = Array.isArray(route.params.idModeloVeiculoTrator)
              ? +route.params.idModeloVeiculoTrator[0]
              : +route.params.idModeloVeiculoTrator;
          }
          if (!id) {
            throw "illegal id";
          }
          p = getModeloVeiculoTrator(id);
        } else {
          let id: number | undefined;
          if (route.params && route.params.idModeloVeiculoTratorCopy) {
            id = Array.isArray(route.params.idModeloVeiculoTratorCopy)
              ? +route.params.idModeloVeiculoTratorCopy[0]
              : +route.params.idModeloVeiculoTratorCopy;
          }
          if (!id) {
            throw "illegal id";
          }
          p = copyModeloVeiculoTrator(id);
        }
        p.then(() => {
          state.modeloVeiculoTrator = data.value;
          state.showEdit = true;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        }).catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
      } else {
        state.showEdit = false;
        state.modeloVeiculoTrator = null;
        executeSearch();
      }
    };

    const openEntity = (
      idModeloVeiculoTrator: number,
      force?: boolean,
      newTab?: boolean
    ) => {
      const routeParams = getParamsRouteEdit(idModeloVeiculoTrator);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch || force) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = (force?: boolean) => {
      const routeParams = {
        name: "veiculo-trator-modelo-new"
      };
      if (state.params.isSearch || force) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const generateCopy = (
      idModeloVeiculoTratorCopy: number,
      force?: boolean
    ) => {
      const routeParams = {
        name: "veiculo-trator-modelo-copy",
        params: { idModeloVeiculoTratorCopy: idModeloVeiculoTratorCopy + "" }
      };
      if (state.params.isSearch || force) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const deleteEntity = (modeloVeiculoTrator: ModeloVeiculoTratorPayload) => {
      useConfirm().require({
        message:
          "Deseja excluir o Modelo " + getNome(modeloVeiculoTrator) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            modeloVeiculoTrator &&
            modeloVeiculoTrator.idModeloVeiculoTrator
          ) {
            const {
              loading,
              deleteModeloVeiculoTrator
            } = useModeloVeiculoTratorService();
            state.loadings.push(loading);
            deleteModeloVeiculoTrator(modeloVeiculoTrator.idModeloVeiculoTrator)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const confirmSelection = (selected: Array<ModeloVeiculoTratorPayload>) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const afterRegisterScreenClosed = (
      modeloVeiculoTrator?: ModeloVeiculoTratorPayload
    ) => {
      const routeParams = {
        name: "veiculo-trator-modelo"
      };
      if (state.params.isSearch) {
        if (state.params.idModeloVeiculoTrator) {
          if (
            modeloVeiculoTrator &&
            modeloVeiculoTrator.idModeloVeiculoTrator
          ) {
            confirmSelection([modeloVeiculoTrator]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            modeloVeiculoTrator &&
            indexesOfElement(
              state.params.selected,
              modeloVeiculoTrator,
              (ob) => ob && ob.idModeloVeiculoTrator
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  modeloVeiculoTrator,
                  (ob) => ob && ob.idModeloVeiculoTrator,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterSaveNewEntity = (idModeloVeiculoTrator: number) => {
      openEntity(idModeloVeiculoTrator);
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const clearFilter = () => {
      state.filter = useModeloVeiculoTratorService().safeFilterModeloVeiculoTrator();
      state.results = defaultResults();
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
    };

    const deveAbrirTelaCadastro =
      state.params.idModeloVeiculoTrator || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idModeloVeiculoTrator) {
        openEntity(state.params.idModeloVeiculoTrator, true);
      } else if (state.params.idModeloVeiculoTratorCopy) {
        generateCopy(state.params.idModeloVeiculoTratorCopy, true);
      } else {
        newEntity(true);
      }
    }

    onMounted(async () => {
      if (props.cliente && props.cliente.idCliente) {
        state.filter.idCliente = props.cliente.idCliente;
      }
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
      if (!deveAbrirTelaCadastro) {
        submitSearch().then(() => {
          if (
            state.params.preFilter &&
            state.params.preFilter.nome &&
            state.results.content.length == 1
          ) {
            const arr = [] as Array<ModeloVeiculoTratorPayload>;
            state.params.selected.forEach((el) => arr.push(el));
            addSetElement(
              arr,
              state.results.content[0],
              (ob) => ob && ob.idModeloVeiculoTrator,
              state.params.isMultiple
            );
            confirmSelection(arr);
          } else {
            focusOnLastElement();
          }
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      deleteEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterSaveNewEntity,
      afterPageChange,
      clearFilter,
      format,
      amIAllowed,
      keyRegister,
      getNome,
      generateCopy,
      getParamsRouteEdit,
      stateLoading,
      confirmSelectionCliente,
      ...toRefs(state)
    };
  },
  components: {
    CadastroModeloVeiculoTrator,
    EntitySelectorCliente
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idModeloVeiculoTrator: Number,
    idModeloVeiculoTratorCopy: Number,
    preFilter: Object as PropType<ModeloVeiculoTratorFilterPayload>,
    cliente: Object as PropType<ClientePayload>,
    selected: {
      type: Array as PropType<Array<ModeloVeiculoTratorPayload>>
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
