
import { Options, Vue } from "vue-class-component";
import CrudArtEngenheiroTransportador from "../../components/transportador/art/CrudArtEngenheiroTransportador.vue";

@Options({
  components: {
    CrudArtEngenheiroTransportador
  }
})
export default class Dashboard extends Vue {}
