<template>
  <div>
    <container
      :isModal="stateAccessFromSearch"
      @close-modal="closeScreen()"
      :loading="stateLoading || !stateReady"
    >
      <template v-slot:header>
        <h3>Cadastro do Cliente</h3>
      </template>
      <template v-slot:body>
        <template v-if="stateReady">
          <form @submit.prevent="submitSave">
            <fieldset :disabled="stateBlocked">
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-4">
                  <input-with-label
                    label="Nome do Responsavel"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.nmResponsavel"
                      :id="l.id"
                      :required="l.required"
                      maxlength="250"
                      v-focus="!amIAllowed('view', 'root-cliente')"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4">
                  <input-with-label
                    label="CPF do Responsavel"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.cpfCnpjResponsavel"
                      v-maska="'###.###.###-##'"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4">
                  <input-file
                    label="Documento do Responsável"
                    downloadTitle="Baixar Documento do Responsável"
                    :id="stateId + '_documentoResponsavel'"
                    :hasDownload="!!entity.documentoResponsavel.idDocumento"
                    @click-download="downloadDocumentoDoResponsavelFile"
                    accept="image/jpeg, image/png, application/pdf"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-sm-8 p-lg-8 p-xl-4">
                  <input-with-label
                    label="Razão Social"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.nome"
                      :id="l.id"
                      :required="l.required"
                      maxlength="250"
                      :disabled="!amIAllowed('view', 'root-cliente')"
                      v-focus="amIAllowed('view', 'root-cliente')"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-sm-4 p-lg-4 p-xl-2">
                  <input-with-label label="CNPJ" v-slot="l">
                    <InputText
                      v-model="entity.cpfCnpj"
                      v-maska="'##.###.###/####-##'"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div
                  class="p-field p-fluid p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-2"
                >
                  <input-with-label label="E-mail" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.emailResponsavel"
                      type="email"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      :id="l.id"
                      :required="l.required"
                      maxlength="100"
                    />
                  </input-with-label>
                </div>
                <div
                  class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-2"
                >
                  <input-with-label label="Telefone" v-slot="l">
                    <InputText
                      v-model="entity.telefone"
                      :id="l.id"
                      :required="l.required"
                      v-maska="[
                        '####-####',
                        '#####-####',
                        '(##) ####-####',
                        '(##) #####-####'
                      ]"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>
                <div
                  class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-2"
                >
                  <input-with-label label="Celular" v-slot="l">
                    <InputText
                      v-model="entity.celular"
                      :id="l.id"
                      :required="l.required"
                      v-maska="[
                        '####-####',
                        '#####-####',
                        '(##) ####-####',
                        '(##) #####-####'
                      ]"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="CEP" v-slot="l">
                    <InputText
                      v-model="entity.cep"
                      :id="l.id"
                      :required="l.required"
                      v-maska="'#####-###'"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6">
                  <input-with-label label="Logradouro" v-slot="l">
                    <InputText
                      v-model="entity.logradouro"
                      maxlength="150"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="Número" v-slot="l">
                    <InputText
                      v-model="entity.numero"
                      maxlength="20"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="Bairro" v-slot="l">
                    <InputText
                      v-model="entity.bairro"
                      maxlength="150"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-9">
                  <entity-selector-municipio
                    :idUnidadeFederal="entity.municipio.idUnidadeFederal"
                    :idMunicipio="entity.municipio.idMunicipio"
                    :required="true"
                    @after-set-municipio="afterSetMunicipio"
                  />
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-file
                    label="Comprovante de Residência"
                    downloadTitle="Baixar Comprovante de Residência"
                    :id="stateId + '_comprovanteResidencia'"
                    :hasDownload="!!entity.comprovanteResidencia.idDocumento"
                    @click-download="downloadComprovanteResidenciaFile"
                    accept="image/jpeg, image/png, application/pdf"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-file
                    label="Logo"
                    downloadTitle="Baixar Logo Atual"
                    :id="stateId + '_logo'"
                    :hasDownload="!!entity.logo.idDocumento"
                    @click-download="downloadLogoFile"
                    accept="image/jpeg, image/png"
                  />
                </div>

                <div
                  class="p-col-12 p-p-0"
                  v-if="amIAllowed('view', 'root-cliente')"
                >
                  <container :isModal="false">
                    <template v-slot:header>
                      <h4>Dados Internos</h4>
                    </template>
                    <template v-slot:body>
                      <fieldset>
                        <div class="p-formgrid p-grid">
                          <div class="p-field p-fluid p-col-12">
                            <entity-selector-engenheiro
                              :engenheiro="entity.engenheiroGovpass"
                              :cliente="entity"
                              :idEngenheiroFiltered="
                                entity.engenheiroGovpass.idEngenheiro
                              "
                              @confirm-selection="
                                confirmSelectionEngenheiroGovpass
                              "
                              :canEdit="
                                entity.engenheiroGovpass?.idEngenheiro &&
                                  amIAllowed(
                                    'edit',
                                    entity.engenheiroGovpass.permissionInfo
                                  )
                              "
                            />
                          </div>
                          <div class="p-field p-fluid p-col-12 p-md-3">
                            <input-with-label
                              label="Nº licencas Franquia"
                              :required="true"
                              v-slot="l"
                            >
                              <InputNumber
                                v-model="entity.franquia"
                                :min="0"
                                :step="1"
                                :minFractionDigits="0"
                                :maxFractionDigits="0"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </div>
                          <div class="p-field p-fluid p-col-12 p-md-3">
                            <input-with-label
                              label="Valor Franquia"
                              subLabel="R$"
                              :required="true"
                              v-slot="l"
                            >
                              <InputNumber
                                v-model="entity.valorFranquia"
                                :min="0"
                                :step="0.01"
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </div>

                          <div class="p-field p-fluid p-col-12 p-md-3">
                            <input-with-label
                              label="Valor Unitario Apos Franquia"
                              subLabel="R$"
                              :required="true"
                              v-slot="l"
                            >
                              <InputNumber
                                v-model="entity.valorUnitarioAposFranquia"
                                :min="0"
                                :step="0.01"
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </div>

                          <div class="p-field p-fluid p-col-12 p-md-3">
                            <input-with-label
                              label="Valor Unitario Projeto"
                              subLabel="R$"
                              :required="true"
                              v-slot="l"
                            >
                              <InputNumber
                                v-model="entity.valorUnitarioProjeto"
                                :min="0"
                                :step="0.01"
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </div>
                          <div class="p-field p-fluid p-col-12">
                            <input-with-label
                              label="Bloqueado?"
                              v-slot="l"
                              :floatLabel="false"
                            >
                              <InputSwitch
                                v-model="entity.bloqueado"
                                :id="l.id"
                                :required="l.required"
                              />
                            </input-with-label>
                          </div>
                        </div>
                      </fieldset>
                    </template>
                  </container>
                </div>

                <div class="p-col-12">
                  <Button
                    type="submit"
                    class="p-button-rounded"
                    label="Salvar"
                  />
                </div>
              </div>
            </fieldset>
          </form>
        </template>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref
} from "vue";
import { useMunicipioService } from "../../modules/business/common/commonBusiness";
import { useStorage } from "../../modules/storage";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useToast } from "../../modules/message";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../modules/business/cliente/clienteBusiness";
import { EngenheiroPayload } from "../../modules/business/engenheiro/engenheiroBusiness";
import EntitySelectorMunicipio from "../common/EntitySelectorMunicipio.vue";

// eslint-disable-next-line
declare var require: any; //PQP MEMO

interface ClienteCadastroState {
  entity: ClientePayload;
  stateReady: boolean;
  savedEntity: boolean;
  loadings: Array<Ref<boolean>>;
  stateAccessFromSearch: boolean;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const safeCliente = (cliente?: ClientePayload | null) => {
      return useClienteService().safeCliente(cliente);
    };

    const idClienteProps = props.idCliente ?? 0;

    const state = reactive<ClienteCadastroState>({
      entity: safeCliente(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      stateAccessFromSearch: !!props.idCliente,
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const setEntity = (entity: ClientePayload) => {
      state.entity = safeCliente(entity);

      const logoElement = document.querySelector(
        "#" + state.stateId + "_logo"
      ) as HTMLInputElement;
      if (logoElement) {
        logoElement.value = "";
      }

      const comprovanteResidenciaElement = document.querySelector(
        "#" + state.stateId + "_comprovanteResidencia"
      ) as HTMLInputElement;
      if (comprovanteResidenciaElement) {
        comprovanteResidenciaElement.value = "";
      }

      const documentoDoResponsavelassinaturaElement = document.querySelector(
        "#" + state.stateId + "_documentoResponsavel"
      ) as HTMLInputElement;
      if (documentoDoResponsavelassinaturaElement) {
        documentoDoResponsavelassinaturaElement.value = "";
      }
    };

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const confirmSelectionEngenheiroGovpass = (eng: EngenheiroPayload) => {
      if (eng) {
        state.entity.engenheiroGovpass = eng;
      } else {
        state.entity.engenheiroGovpass = useClienteService().safeCliente().engenheiroGovpass;
      }
    };

    const downloadComprovanteResidenciaFile = (
      newTab: boolean,
      onEnd: Function
    ) => {
      if (state.entity.comprovanteResidencia.idDocumento) {
        const service = useClienteService();
        const serviceLogado = useClienteLogadoService();
        const loading = state.stateAccessFromSearch
          ? service.loading
          : serviceLogado.loading;
        state.loadings.push(loading);
        (state.stateAccessFromSearch
          ? service.downloadComprovanteResidencia(idClienteProps, newTab)
          : serviceLogado.downloadComprovanteResidencia(newTab)
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const downloadLogoFile = (newTab: boolean, onEnd: Function) => {
      if (state.entity.logo.idDocumento) {
        const service = useClienteService();
        const serviceLogado = useClienteLogadoService();
        const loading = state.stateAccessFromSearch
          ? service.loading
          : serviceLogado.loading;
        state.loadings.push(loading);
        (state.stateAccessFromSearch
          ? service.downloadLogo(idClienteProps, newTab)
          : serviceLogado.downloadLogo(newTab)
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const downloadDocumentoDoResponsavelFile = (
      newTab: boolean,
      onEnd: Function
    ) => {
      if (state.entity.documentoResponsavel.idDocumento) {
        const service = useClienteService();
        const serviceLogado = useClienteLogadoService();
        const loading = state.stateAccessFromSearch
          ? service.loading
          : serviceLogado.loading;
        state.loadings.push(loading);
        (state.stateAccessFromSearch
          ? service.downloadDocumentoDoResponsavel(idClienteProps, newTab)
          : serviceLogado.downloadDocumentoDoResponsavel(newTab)
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const deleteTempFiles = () => {
      if (state.entity.logo.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(state.entity.logo.keyBlDocumentoTemp);
        state.entity.logo.keyBlDocumentoTemp = null;
      }
      if (state.entity.documentoResponsavel.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(
          state.entity.documentoResponsavel.keyBlDocumentoTemp
        );
        state.entity.documentoResponsavel.keyBlDocumentoTemp = null;
      }
      if (state.entity.comprovanteResidencia.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(
          state.entity.comprovanteResidencia.keyBlDocumentoTemp
        );
        state.entity.comprovanteResidencia.keyBlDocumentoTemp = null;
      }
      if (state.entity.procuracao.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(state.entity.procuracao.keyBlDocumentoTemp);
        state.entity.procuracao.keyBlDocumentoTemp = null;
      }
    };

    const save = () => {
      const serviceSave = useClienteService();
      const serviceLogadoSave = useClienteLogadoService();
      const loadingCadastro = state.stateAccessFromSearch
        ? serviceSave.loading
        : serviceLogadoSave.loading;
      const dataCadastro = state.stateAccessFromSearch
        ? serviceSave.data
        : serviceLogadoSave.data;

      state.loadings.push(loadingCadastro);
      (state.stateAccessFromSearch
        ? serviceSave.updateCliente(idClienteProps, state.entity)
        : serviceLogadoSave.updateCliente(state.entity)
      )
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          setEntity(dataCadastro.value);
          state.savedEntity = true;
          useToast().success("Cliente salvo com sucesso");
        })
        .catch(() => {
          deleteTempFiles();
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const processFile = (fileElementId: string) => {
      return new Promise<string>((resolve) => {
        const file = document.querySelector(
          "#" + fileElementId
        ) as HTMLInputElement;
        if (!file || !file.files || file.files.length === 0) {
          resolve("");
        } else {
          const formData = new FormData();
          formData.append("file", file.files[0]);
          const storage = useStorage();
          state.loadings.push(storage.loading);
          storage
            .uploadTempFile(formData)
            .then((key: string) => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
              resolve(key);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            });
        }
      });
    };

    const submitSave = () => {
      const arrayPromises = new Array<Promise<string>>();
      arrayPromises.push(
        processFile(state.stateId + "_logo").then(
          (key) => (state.entity.logo.keyBlDocumentoTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_comprovanteResidencia").then(
          (key) => (state.entity.comprovanteResidencia.keyBlDocumentoTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_documentoResponsavel").then(
          (key) => (state.entity.documentoResponsavel.keyBlDocumentoTemp = key)
        )
      );
      Promise.all(arrayPromises).then(
        () => {
          save();
        },
        () => {
          deleteTempFiles();
        }
      );
    };

    onMounted(async () => {
      const service = useClienteService();
      const serviceLogado = useClienteLogadoService();
      const loading = state.stateAccessFromSearch
        ? service.loading
        : serviceLogado.loading;
      const data = state.stateAccessFromSearch
        ? service.data
        : serviceLogado.data;

      state.loadings.push(loading);

      (state.stateAccessFromSearch
        ? service.getCliente(idClienteProps)
        : serviceLogado.getCliente()
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      downloadDocumentoDoResponsavelFile,
      downloadLogoFile,
      downloadComprovanteResidenciaFile,
      municipiosFiltrados,
      stateLoading,
      stateBlocked,
      amIAllowed,
      afterSetMunicipio,
      confirmSelectionEngenheiroGovpass,
      ...toRefs(state)
    };
  },
  props: {
    idCliente: Number,
    parentLoading: Boolean
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.EntitySelectorEngenheiro = require("../engenheiro/EntitySelectorEngenheiro.vue").default;
    }
  },
  components: {
    EntitySelectorMunicipio
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
