
/* eslint-disable  @typescript-eslint/no-explicit-any */
//TODO KAIO TROCAR OS ENTITYSELECTORS PARA USAR V-MODEL
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRefs,
  onMounted
} from "vue";
import EntitySelectorList from "./EntitySelectorList.vue";

import { focusNext, findAllFocussableChildren } from "../../../modules/utils";
import { useAuth, PermissionPayload } from "../../../modules/auth";

interface EntitySelectorState {
  stateReady: boolean;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EntitySelectorState>({
      stateReady: false,
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2)
    });

    const internalEntities = computed(() => {
      if (props.entities?.length) {
        return props.entities;
      }
      return [];
    });

    const hasEntity = computed(() => {
      if (internalEntities.value.length > 0) {
        return true;
      } else {
        return false;
      }
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const openEntity = (e: any, newTab?: boolean) => {
      emit("open-entity", e, newTab);
    };

    const removeEntity = (e: any) => {
      emit("remove-entity", e);
    };

    const openSearch = () => {
      emit("open-search");
    };

    const focus = () => {
      setTimeout(() => {
        const el = document.querySelector("#" + state.stateId) as HTMLElement;
        if (el) {
          const inputs = findAllFocussableChildren(el, "input:not([disabled])");
          if (inputs && inputs.length && inputs[0].focus) {
            inputs[0].focus();
          } else {
            const elements = findAllFocussableChildren(el);
            if (elements && elements.length && elements[0].focus) {
              elements[0].focus();
            }
          }
        }
      }, 50);
    };

    const next = () => {
      setTimeout(() => {
        const el = document.querySelector("#" + state.stateId) as HTMLElement;
        if (el) {
          const inputs = findAllFocussableChildren(el, "input:not([disabled])");
          if (inputs && inputs.length && inputs[inputs.length - 1].focus) {
            inputs[inputs.length - 1].focus();
          } else {
            const elements = findAllFocussableChildren(el);
            if (elements && elements.length) {
              focusNext(elements[elements.length - 1]);
            }
          }
        }
      }, 50);
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      removeEntity,
      hasEntity,
      amIAllowed,
      focus,
      next,
      internalEntities,
      ...toRefs(state)
    };
  },
  props: {
    entities: {
      type: Array as PropType<Array<any>>
    },
    keyParam: String,
    id: String,
    required: {
      type: Boolean,
      default: false
    },
    idFunction: {
      type: Function
    },
    multiple: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Modelo"
    }
  },
  components: { EntitySelectorList }
});
