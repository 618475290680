<template>
  <sistema>
    <div>
      <container :isModal="false" :loading="!stateReady">
        <template v-slot:header>
          <h3>Importação Documentos dos Veículos</h3>
        </template>
        <template v-slot:body>
          <div class="cadastro" v-if="stateReady">
            <form @submit.prevent="submitSave">
              <entity-selector-cliente
                :cliente="entity.cliente"
                :canEdit="!entity.cliente?.idCliente && !stateLoading"
                @confirm-selection="confirmSelectionCliente"
                :required="true"
                :parentLoading="stateLoading"
              />
              <fieldset
                :disabled="
                  stateLoading ||
                    (amIAllowed('view', 'root-cliente') &&
                      !entity.cliente?.idCliente)
                "
              >
                <div class="p-formgrid p-grid">
                  <div class="p-field p-col-12">
                    <ul>
                      <li>
                        Os documentos devem estar nomeados pela placa do veículo
                        correspondente.
                      </li>
                      <li>
                        Para adicionar vários arquivos de uma só vez, pode
                        utilizar uma pasta compactada em formato zip, com
                        tamanho máximo de 50MB.
                      </li>
                      <li>
                        Caso o veículo tenho algum documento já inserido, este
                        será atualizado pela importação.
                      </li>
                      <li>
                        Ao realizar a importação, será ajustado no cadastro do
                        veículo correspondente o valor do campo de "Ano
                        Exercício à aplicar". Caso este campo esteja vazio, será
                        aplicado o ano atual.
                      </li>
                      <li>
                        Para realizar a importação do(s) documento(s) insira o
                        arquivo abaixo.
                      </li>
                    </ul>
                  </div>
                  <div class="p-field p-fluid p-col-12 p-md-2">
                    <year-picker
                      v-model="entity.anoExercicioCrlv"
                      label="Ano Exercício à aplicar"
                    />
                  </div>
                  <div class="p-field p-col-12 p-md-10">
                    <input-file
                      label="Documento"
                      :id="stateId + '_importacao'"
                      accept="application/zip, application/x-zip-compressed, application/pdf"
                      :hasDownload="false"
                    />
                  </div>

                  <div class="p-col-12">
                    <Button
                      type="submit"
                      class="p-button-rounded"
                      label="Salvar"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
            <div v-if="importReturn">
              <div class="sub-title">
                <h4>Resultados</h4>
              </div>
              <div v-if="importReturn.sucesso && importReturn.sucesso.length">
                <div class="sub-title">
                  <h4>VEÍCULOS ATUALIZADOS COM SUCESSO</h4>
                </div>
                <ul>
                  <li v-for="arquivo in importReturn.sucesso" :key="arquivo">
                    Documento <b>{{ arquivo }}</b> importado. <br />
                  </li>
                </ul>
              </div>
              <div v-if="importReturn.erros && importReturn.erros.length">
                <div class="sub-title">
                  <h4>VEÍCULOS NÃO ATUALIZADOS</h4>
                </div>
                <ul>
                  <li v-for="erro in importReturn.erros" :key="erro.nome">
                    <b>{{ erro.nome }}</b> : {{ erro.erro }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </container>
    </div>
  </sistema>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  Ref,
  onMounted
} from "vue";
import {
  ImportacaoDocumentoPayload,
  ImportacaoDocumentoReturn,
  useImportacaoVeiculoService
} from "../../modules/business/veiculo/importacaoVeiculoBusiness";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import EntitySelectorCliente from "../../components/cliente/EntitySelectorCliente.vue";
import { useStorage } from "../../modules/storage";
import { useToast } from "../../modules/message";

interface ImportacaoDocumentoState {
  entity: ImportacaoDocumentoPayload;
  importReturn: ImportacaoDocumentoReturn | null;
  stateReady: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup() {
    const state = reactive<ImportacaoDocumentoState>({
      entity: { anoExercicioCrlv: new Date().getFullYear() },
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: [],
      importReturn: {}
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const save = () => {
      const {
        importFile,
        loading: loadingCadastro,
        data: dataCadastro
      } = useImportacaoVeiculoService();
      state.loadings.push(loadingCadastro);
      importFile(state.entity)
        .then(() => {
          state.importReturn = dataCadastro.value;
          if (!state.importReturn) {
            useToast().warning("Não foi possível realizar a importação.");
          } else {
            if (state.importReturn.erros && state.importReturn.erros.length) {
              useToast().warning(
                "Arquivo processado com erros. Verifique o retorno."
              );
            } else {
              useToast().success("Importação realizada com sucesso.");
            }
          }
          (document.querySelector(
            "#" + state.stateId + "_importacao"
          ) as HTMLInputElement).value = "";
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        })
        .catch(() => {
          if (state.entity.keyBlDocumentoTemp) {
            useStorage().deleteTempFile(state.entity.keyBlDocumentoTemp);
            state.entity.keyBlDocumentoTemp = null;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const submitSave = () => {
      state.importReturn = null;
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_importacao"
      ) as HTMLInputElement;
      if (!file || !file.files || file.files.length === 0) {
        useToast().warning("É necessário um arquivo.");
      } else {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            state.entity.keyBlDocumentoTemp = key;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            save();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      }
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submitSave,
      stateLoading,
      confirmSelectionCliente,
      amIAllowed,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" />
