<template>
  <input-with-button :hasButton="hasDownload">
    <template v-slot:default>
      <input-with-label
        :label="label"
        :subLabel="subLabel"
        :id="id"
        :required="required"
        v-slot="l"
      >
        <!--<div v-if="readOnly">TODO IMPLEMENTAR</div>
      <FileUpload
        v-else
        :name="l.id"
        @before-upload="beforeUpload"
        @upload="uploadFinish"
        @customUpload="uploadTemp"
        @progress="progress"
        :accept="accept ? accept : null"
        :mode="mode"
        :showUploadButton="false"
        class="p-button-text p-inputwrapper-filled"
        :maxFileSize="50000000"
      />-->
        <input
          type="file"
          class="p-inputtext p-component p-filled"
          style="padding: 0.7rem"
          :id="l.id"
          :required="l.required"
          :disabled="readOnly"
          :accept="accept ? accept : null"
          @change="uploadFinish()"
        />
      </input-with-label>
    </template>
    <template v-slot:buttons>
      <Button
        v-if="hasDownload"
        :disabled="loading"
        v-title="downloadTitle"
        style="min-width: initial"
        @click="download()"
        @click.middle.prevent.stop="download(true)"
        class="p-button-icon-only"
      >
        <ProgressSpinner v-if="loading" style="width: 1.2rem; height: 1.2rem" />
        <i class="fas fa-download" style="width: 1.2rem" v-else />
      </Button>
    </template>
  </input-with-button>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import InputWithButton from "./InputWithButton.vue";

interface InputWithLabelState {
  stateReady: boolean;
  loading: boolean;
}

export default defineComponent({
  setup(_, { emit }) {
    const state = reactive<InputWithLabelState>({
      stateReady: false,
      loading: false
    });

    const download = (newTab?: boolean) => {
      state.loading = true;
      const func = () => {
        state.loading = false;
      };
      emit("click-download", !!newTab, func);
    };

    const beforeUpload = () => {
      //implementar se for usado componente de upload
    };

    const uploadFinish = () => {
      //implementar se for usado componente de upload
      emit("update");
    };

    const uploadTemp = () => {
      //implementar se for usado componente de upload
    };
    const progress = () => {
      //implementar se for usado componente de upload
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      download,
      beforeUpload,
      uploadTemp,
      uploadFinish,
      progress,
      ...toRefs(state)
    };
  },
  emits: ["click-download", "update"],
  props: {
    label: String,
    subLabel: String,
    accept: { type: String },
    mode: {
      type: String,
      default: "basic",
      validator: function(value: string) {
        return ["basic", "advanced"].indexOf(value) !== -1;
      }
    },
    downloadTitle: { type: String, default: "Download" },
    required: [String, Number, Object, Boolean],
    id: { type: String, required: true },
    hasDownload: { type: [String, Number, Object, Boolean], required: true },
    readOnly: Boolean
  },
  components: { InputWithButton }
});
</script>
