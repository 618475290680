import { useApiWithAuth } from "@/modules/api";
import {
  MunicipioPayload,
  DocumentoPayload
} from "@/modules/business/common/commonBusiness";
import {
  EngenheiroPayload,
  useEngenheiroService
} from "@/modules/business/engenheiro/engenheiroBusiness";
import { PermissionPayload, useAuth } from "@/modules/auth";

interface ClienteFilterPayload {
  idCliente?: number | null;
  nome?: string | null;
}

interface ClientePayload {
  idCliente?: number | null;
  nome?: string | null;
  nmResponsavel?: string | null;
  cpfCnpjResponsavel?: string | null;
  emailResponsavel?: string | null;
  cpfCnpj?: string | null;
  cep?: string | null;
  logradouro?: string | null;
  numero?: string | null;
  bairro?: string | null;
  telefone?: string | null;
  celular?: string | null;
  municipio: MunicipioPayload;
  procuracao: DocumentoPayload;
  documentoResponsavel: DocumentoPayload;
  comprovanteResidencia: DocumentoPayload;
  logo: DocumentoPayload;
  engenheiroGovpass: EngenheiroPayload;
  bloqueado?: boolean | null;
  franquia?: number | null;
  valorFranquia?: number | null;
  valorUnitarioAposFranquia?: number | null;
  valorUnitarioProjeto?: number | null;
  permissionInfo?: PermissionPayload;
}

const useClienteService = () => {
  const {
    data,
    loading,
    get,
    put,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const searchCliente = (
    filter: ClienteFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/root/cliente", {
      ...filter,
      ...{
        page: page
      }
    });
  };

  const getCliente = (idCliente: number): Promise<void> => {
    return get(`/root/cliente/${idCliente}`);
  };

  const updateCliente = (
    idCliente: number,
    cliente: ClientePayload
  ): Promise<void> => {
    return put(`/root/cliente/${idCliente}`, cliente);
  };

  const downloadDocumentoDoResponsavel = (
    idCliente: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/root/cliente/${idCliente}/download/documento-responsavel`
    );
  };

  const downloadProcuracao = (
    idCliente: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/root/cliente/${idCliente}/download/procuracao`
    );
  };

  const downloadComprovanteResidencia = (
    idCliente: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/root/cliente/${idCliente}/download/comprovante-residencia`
    );
  };

  const downloadLogo = (idCliente: number, newTab?: boolean): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/root/cliente/${idCliente}/download/logo`
    );
  };

  const safeCliente = (entity?: ClientePayload | null): ClientePayload => {
    const ret = entity ?? {
      municipio: {},
      procuracao: {},
      documentoResponsavel: {},
      comprovanteResidencia: {},
      logo: {},
      engenheiroGovpass: useEngenheiroService().safeEngenheiro()
    };

    ret.municipio = ret.municipio ?? {};
    ret.procuracao = ret.procuracao ?? {};
    ret.documentoResponsavel = ret.documentoResponsavel ?? {};
    ret.comprovanteResidencia = ret.comprovanteResidencia ?? {};
    ret.logo = ret.logo ?? {};
    ret.engenheiroGovpass =
      ret.engenheiroGovpass ?? useEngenheiroService().safeEngenheiro();
    ret.procuracao.keyBlDocumentoTemp = null;
    ret.documentoResponsavel.keyBlDocumentoTemp = null;
    ret.comprovanteResidencia.keyBlDocumentoTemp = null;
    ret.logo.keyBlDocumentoTemp = null;
    return ret;
  };

  const safeFilterCliente = (): ClienteFilterPayload => {
    return {
      nome: "",
      idCliente: null
    };
  };

  const getNomeCliente = (entity?: ClientePayload | null): string => {
    if (!entity) {
      return "";
    }
    return entity?.idCliente + " - " + entity?.nome;
  };

  return {
    data,
    loading,
    searchCliente,
    safeFilterCliente,
    safeCliente,
    downloadComprovanteResidencia,
    downloadProcuracao,
    downloadLogo,
    downloadDocumentoDoResponsavel,
    updateCliente,
    getCliente,
    getNomeCliente
  };
};

const useClienteLogadoService = () => {
  const {
    data,
    loading,
    get,
    put,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const getCliente = (): Promise<void> => {
    return get(`/admin/cliente`);
  };

  const updateCliente = (cliente: ClientePayload): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      put(`/admin/cliente`, cliente)
        .then(() => {
          useAuth()
            .reloadUser(true)
            .then(() => resolve())
            .catch(reject);
        })
        .catch(reject);
    });
  };

  const downloadLogo = (newTab?: boolean): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(`/logo`);
  };

  const downloadDocumentoDoResponsavel = (newTab?: boolean): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/admin/cliente/download/documento-responsavel`
    );
  };

  const downloadProcuracao = (newTab?: boolean): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/admin/cliente/download/procuracao`
    );
  };

  const downloadComprovanteResidencia = (newTab?: boolean): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/admin/cliente/download/comprovante-residencia`
    );
  };

  const safeCliente = (entity?: ClientePayload | null): ClientePayload => {
    return useClienteService().safeCliente(entity);
  };

  const getNomeCliente = (entity?: ClientePayload | null): string => {
    return entity?.nome ?? "";
  };

  return {
    data,
    loading,
    downloadComprovanteResidencia,
    downloadProcuracao,
    downloadLogo,
    downloadDocumentoDoResponsavel,
    updateCliente,
    getCliente,
    safeCliente,
    getNomeCliente
  };
};

export {
  useClienteService,
  useClienteLogadoService,
  ClientePayload,
  ClienteFilterPayload
};
