<template>
  <div>
    <loading />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "../../modules/auth";

export default defineComponent({
  setup() {
    const { logout } = useAuth();
    const router = useRouter();

    onMounted(async () => {
      logout().then(() => router.push({ name: "home" }));
    });
  }
});
</script>
