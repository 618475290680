<template>
  <div v-if="stateReady">
    <div class="p-inputgroup">
      <input-with-label
        :label="label"
        :subLabel="labelComplement"
        :required="required"
        v-slot="l"
        :id="stateId"
      >
        <template v-if="typeSelected == FREE_SELECTION">
          <Calendar
            :id="l.id + '_initial'"
            :required="l.required"
            v-model="internalValue[0]"
            :showIcon="true"
            :minDate="minInitialDateFreeSelection"
            :maxDate="maxInitialDateFreeSelection"
            :showButtonBar="true"
            @keydown="processKeyDownDate"
          />
          <span class="p-p-3"> à </span>
          <Calendar
            :id="l.id + '_final'"
            :required="l.required"
            v-model="internalValue[1]"
            :minDate="minFinalDateFreeSelection"
            :maxDate="maxFinalDateFreeSelection"
            :showIcon="true"
            :showButtonBar="true"
            @keydown="processKeyDownDate"
          />
        </template>
        <Calendar
          v-model="dateMonthSelector"
          view="month"
          dateFormat="mm/yy"
          :id="l.id"
          :required="l.required"
          @date-select="onChange()"
          v-else-if="typeSelected == MONTH"
          :showIcon="true"
          :showButtonBar="true"
        />
        <InputText :disabled="true" v-else v-model="periodString" />
      </input-with-label>

      <Button
        type="button"
        icon="fas fa-cog"
        @click="toggleSelector"
        aria-haspopup="true"
        :aria-controls="stateId + '_overlay_menu'"
        v-show="showTypeSelector"
      />
      <Menu
        :id="stateId + '_overlay_menu'"
        ref="menuSelector"
        :model="[
          {
            label: getTypeLabel(LAST_MONTH),
            command: () => setType(LAST_MONTH)
          },
          { label: getTypeLabel(LAST_WEEK), command: () => setType(LAST_WEEK) },
          { label: getTypeLabel(TODAY), command: () => setType(TODAY) },
          { label: getTypeLabel(MONTH), command: () => setType(MONTH) },
          {
            label: getTypeLabel(FREE_SELECTION),
            command: () => setType(FREE_SELECTION)
          },
          { label: getTypeLabel(NO_DATE), command: () => setType(NO_DATE) }
        ]"
        :popup="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  reactive,
  computed,
  toRefs,
  ref,
  onMounted,
  PropType,
  watch
} from "vue";
import { subDays, addDays } from "date-fns";
import {
  formatDatePeriod,
  DATE_SELECTORS,
  processKeyDownDate
} from "../../../modules/utils";

interface PeriodPickerState {
  internalValue: Array<Date | null | undefined>;
  stateReady: boolean;
  stateId: string;
  typeSelected: string;
  dateMonthSelector: Date;
  today: Date;
}

export default defineComponent({
  setup(props, { emit }) {
    const today = new Date();
    const state = reactive<PeriodPickerState>({
      internalValue: props.start && props.end ? [props.start, props.end] : [],
      stateReady: false,
      typeSelected: props.type ?? DATE_SELECTORS.FREE_SELECTION,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      dateMonthSelector: today,
      today: today
    });

    const onChange = () => {
      today.setHours(0, 0, 0, 0);
      if (state.typeSelected == DATE_SELECTORS.LAST_MONTH) {
        state.internalValue[0] = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getUTCDate()
        );
        state.internalValue[1] = today;
        state.dateMonthSelector = today;
      } else if (state.typeSelected == DATE_SELECTORS.LAST_WEEK) {
        state.internalValue[0] = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getUTCDate() - 7
        );
        state.internalValue[1] = today;
        state.dateMonthSelector = today;
      } else if (state.typeSelected == DATE_SELECTORS.TODAY) {
        state.internalValue[0] = today;
        state.internalValue[1] = today;
        state.dateMonthSelector = today;
      } else if (state.typeSelected == DATE_SELECTORS.MONTH) {
        state.internalValue[0] = state.dateMonthSelector;
        state.internalValue[1] = new Date(
          state.dateMonthSelector.getFullYear(),
          state.dateMonthSelector.getMonth() + 1,
          0
        );
      } else if (state.typeSelected == DATE_SELECTORS.NO_DATE) {
        state.internalValue[0] = null;
        state.internalValue[1] = null;
        state.dateMonthSelector = today;
      } else {
        //FREE-SELECTION
        if (props.maxDifference) {
          //maxDifference
        }
      }
    };

    const processStateChange = () => {
      if (props.start != state.internalValue[0]) {
        emit("update:start", state.internalValue[0]);
      }
      if (props.end != state.internalValue[1]) {
        emit("update:end", state.internalValue[1]);
      }
      if (props.type != state.typeSelected) {
        emit("update:type", state.typeSelected);
      }
    };

    const setType = (type: string) => {
      state.typeSelected = type;
      onChange();
      if (
        type == DATE_SELECTORS.MONTH ||
        type == DATE_SELECTORS.FREE_SELECTION
      ) {
        setTimeout(() => {
          const el = document.getElementById(
            type == DATE_SELECTORS.FREE_SELECTION
              ? state.stateId + "_initial"
              : state.stateId
          );
          if (el) {
            el.focus();
          }
        }, 50);
      }
    };

    const getTypeLabel = (type: string) => {
      if (type == DATE_SELECTORS.LAST_MONTH) {
        return "Últimos 30 dias";
      } else if (type == DATE_SELECTORS.LAST_WEEK) {
        return "Últimos 7 dias";
      } else if (type == DATE_SELECTORS.TODAY) {
        return "Hoje";
      } else if (type == DATE_SELECTORS.MONTH) {
        return "Por Mês";
      } else if (type == DATE_SELECTORS.FREE_SELECTION) {
        return "Seleção Livre";
      } else if (type == DATE_SELECTORS.NO_DATE) {
        return "Não Aplicar";
      }
    };
    const labelComplement = computed(() => {
      return getTypeLabel(state.typeSelected);
    });

    const periodString = computed(() => {
      return formatDatePeriod(state.internalValue[0], state.internalValue[1]);
    });

    const minFinalDateFreeSelection = computed(() => {
      if (state.internalValue[0]) {
        return addDays(state.internalValue[0], 0);
      }
      return null;
    });

    const maxFinalDateFreeSelection = computed(() => {
      if (state.internalValue[0] && props.maxDifference) {
        return addDays(state.internalValue[0], props.maxDifference);
      }
      return null;
    });

    const minInitialDateFreeSelection = computed(() => {
      if (state.internalValue[1] && props.maxDifference) {
        return subDays(state.internalValue[1], props.maxDifference);
      }
      return null;
    });

    const maxInitialDateFreeSelection = computed(() => {
      if (state.internalValue[1]) {
        return subDays(state.internalValue[1], 0);
      }
      return null;
    });

    const menuSelector = ref();

    const toggleSelector = (event: Event) => {
      menuSelector.value.toggle(event);
    };

    const processPropsChange = () => {
      if (props.start != state.internalValue[0]) {
        state.internalValue[0] = props.start;
        onChange();
      }
      if (props.end != state.internalValue[1]) {
        state.internalValue[1] = props.end;
        onChange();
      }
      if (props.type != state.typeSelected) {
        state.typeSelected = props.type || DATE_SELECTORS.FREE_SELECTION;
        onChange();
      }
    };

    onMounted(async () => {
      watch([state], () => {
        processStateChange();
      });
      watch([props], () => {
        processPropsChange();
      });

      onChange();
      processStateChange();
      state.stateReady = true;
    });

    return {
      onChange,
      setType,
      getTypeLabel,
      toggleSelector,
      labelComplement,
      periodString,
      minFinalDateFreeSelection,
      maxFinalDateFreeSelection,
      minInitialDateFreeSelection,
      maxInitialDateFreeSelection,
      menuSelector,
      processKeyDownDate,
      ...DATE_SELECTORS,
      ...toRefs(state)
    };
  },
  emits: ["update:start", "update:end", "update:type"],
  props: {
    start: {
      type: Object as PropType<Date>
    },
    end: {
      type: Object as PropType<Date>
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    label: {
      type: String,
      default: "Período"
    },
    showTypeSelector: {
      type: Boolean,
      default: true
    },
    maxDifference: Number
  },
  components: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.p-calendar.p-component.p-inputwrapper {
  border-radius: 4px;
}
</style>
