
import CadastroModeloVeiculoTrator from "./CadastroModeloVeiculoTrator.vue";
import { PaginationResult, defaultResults } from "../../../../pagination";
import { useAuth, PermissionPayload } from "../../../../modules/auth";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { RouteLocationNormalizedLoaded, useRouter } from "vue-router";
import {
  ModeloVeiculoTratorPayload,
  ModeloVeiculoTratorFilterPayload,
  useModeloVeiculoTratorService
} from "../../../../modules/business/veiculo/veiculoTratorBusiness";
import { ClientePayload } from "../../../../modules/business/cliente/clienteBusiness";
import {
  indexesOfElement,
  addSetElement,
  toggleElement,
  format,
  copyObject,
  clearObject
} from "../../../../modules/utils";
import EntitySelectorCliente from "../../../cliente/EntitySelectorCliente.vue";
import { focusOnLastElement } from "../../../../modules/globalAppProperties";
import { useConfirm } from "../../../../modules/message";

interface ModeloVeiculoTratorComponentParams {
  isSearch: boolean;
  selected: Array<ModeloVeiculoTratorPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idModeloVeiculoTrator?: number;
  idModeloVeiculoTratorCopy?: number;
  preFilter?: ModeloVeiculoTratorFilterPayload;
}

interface ModeloVeiculoTratorState {
  modeloVeiculoTrator: ModeloVeiculoTratorPayload | null;
  showEdit: boolean;
  results: PaginationResult<ModeloVeiculoTratorPayload>;
  filter: ModeloVeiculoTratorFilterPayload;
  page: number;
  stateReady: boolean;
  params: ModeloVeiculoTratorComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<ModeloVeiculoTratorState>({
      modeloVeiculoTrator: null,
      showEdit: false,
      stateReady: false,
      filter: useModeloVeiculoTratorService().safeFilterModeloVeiculoTrator(),
      page: 0,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idModeloVeiculoTrator: props.idModeloVeiculoTrator,
        idModeloVeiculoTratorCopy: props.idModeloVeiculoTratorCopy,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple,
        preFilter: props.preFilter
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    copyObject(clearObject(props.preFilter), state.filter);

    const getNome = (e?: ModeloVeiculoTratorPayload) => {
      return useModeloVeiculoTratorService().getNomeModeloVeiculoTrator(e);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const keyRegister = computed(() => {
      return state.showEdit
        ? state.modeloVeiculoTrator?.idModeloVeiculoTrator
          ? state.modeloVeiculoTrator?.idModeloVeiculoTrator
          : "new-" +
            Math.random()
              .toString(36)
              .substring(2)
        : null;
    });

    const executeSearch = () => {
      const {
        data,
        loading,
        searchModeloVeiculoTrator
      } = useModeloVeiculoTratorService();
      state.loadings.push(loading);
      return searchModeloVeiculoTrator(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const getParamsRouteEdit = (idModeloVeiculoTrator: number) => {
      return {
        name: "veiculo-trator-modelo-edit",
        params: { idModeloVeiculoTrator: idModeloVeiculoTrator + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (
        route.name == "veiculo-trator-modelo-new" ||
        route.name == "veiculo-trator-modelo-edit" ||
        route.name == "veiculo-trator-modelo-copy"
      ) {
        const {
          newModeloVeiculoTrator,
          getModeloVeiculoTrator,
          copyModeloVeiculoTrator,
          data,
          loading
        } = useModeloVeiculoTratorService();

        let p: Promise<void>;
        state.loadings.push(loading);
        if (route.name == "veiculo-trator-modelo-new") {
          p = newModeloVeiculoTrator(props.cliente?.idCliente);
        } else if (route.name == "veiculo-trator-modelo-edit") {
          let id: number | undefined;
          if (route.params && route.params.idModeloVeiculoTrator) {
            id = Array.isArray(route.params.idModeloVeiculoTrator)
              ? +route.params.idModeloVeiculoTrator[0]
              : +route.params.idModeloVeiculoTrator;
          }
          if (!id) {
            throw "illegal id";
          }
          p = getModeloVeiculoTrator(id);
        } else {
          let id: number | undefined;
          if (route.params && route.params.idModeloVeiculoTratorCopy) {
            id = Array.isArray(route.params.idModeloVeiculoTratorCopy)
              ? +route.params.idModeloVeiculoTratorCopy[0]
              : +route.params.idModeloVeiculoTratorCopy;
          }
          if (!id) {
            throw "illegal id";
          }
          p = copyModeloVeiculoTrator(id);
        }
        p.then(() => {
          state.modeloVeiculoTrator = data.value;
          state.showEdit = true;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        }).catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
      } else {
        state.showEdit = false;
        state.modeloVeiculoTrator = null;
        executeSearch();
      }
    };

    const openEntity = (
      idModeloVeiculoTrator: number,
      force?: boolean,
      newTab?: boolean
    ) => {
      const routeParams = getParamsRouteEdit(idModeloVeiculoTrator);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch || force) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = (force?: boolean) => {
      const routeParams = {
        name: "veiculo-trator-modelo-new"
      };
      if (state.params.isSearch || force) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const generateCopy = (
      idModeloVeiculoTratorCopy: number,
      force?: boolean
    ) => {
      const routeParams = {
        name: "veiculo-trator-modelo-copy",
        params: { idModeloVeiculoTratorCopy: idModeloVeiculoTratorCopy + "" }
      };
      if (state.params.isSearch || force) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const deleteEntity = (modeloVeiculoTrator: ModeloVeiculoTratorPayload) => {
      useConfirm().require({
        message:
          "Deseja excluir o Modelo " + getNome(modeloVeiculoTrator) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            modeloVeiculoTrator &&
            modeloVeiculoTrator.idModeloVeiculoTrator
          ) {
            const {
              loading,
              deleteModeloVeiculoTrator
            } = useModeloVeiculoTratorService();
            state.loadings.push(loading);
            deleteModeloVeiculoTrator(modeloVeiculoTrator.idModeloVeiculoTrator)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const confirmSelection = (selected: Array<ModeloVeiculoTratorPayload>) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const afterRegisterScreenClosed = (
      modeloVeiculoTrator?: ModeloVeiculoTratorPayload
    ) => {
      const routeParams = {
        name: "veiculo-trator-modelo"
      };
      if (state.params.isSearch) {
        if (state.params.idModeloVeiculoTrator) {
          if (
            modeloVeiculoTrator &&
            modeloVeiculoTrator.idModeloVeiculoTrator
          ) {
            confirmSelection([modeloVeiculoTrator]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            modeloVeiculoTrator &&
            indexesOfElement(
              state.params.selected,
              modeloVeiculoTrator,
              (ob) => ob && ob.idModeloVeiculoTrator
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  modeloVeiculoTrator,
                  (ob) => ob && ob.idModeloVeiculoTrator,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterSaveNewEntity = (idModeloVeiculoTrator: number) => {
      openEntity(idModeloVeiculoTrator);
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const clearFilter = () => {
      state.filter = useModeloVeiculoTratorService().safeFilterModeloVeiculoTrator();
      state.results = defaultResults();
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
    };

    const deveAbrirTelaCadastro =
      state.params.idModeloVeiculoTrator || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idModeloVeiculoTrator) {
        openEntity(state.params.idModeloVeiculoTrator, true);
      } else if (state.params.idModeloVeiculoTratorCopy) {
        generateCopy(state.params.idModeloVeiculoTratorCopy, true);
      } else {
        newEntity(true);
      }
    }

    onMounted(async () => {
      if (props.cliente && props.cliente.idCliente) {
        state.filter.idCliente = props.cliente.idCliente;
      }
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
      if (!deveAbrirTelaCadastro) {
        submitSearch().then(() => {
          if (
            state.params.preFilter &&
            state.params.preFilter.nome &&
            state.results.content.length == 1
          ) {
            const arr = [] as Array<ModeloVeiculoTratorPayload>;
            state.params.selected.forEach((el) => arr.push(el));
            addSetElement(
              arr,
              state.results.content[0],
              (ob) => ob && ob.idModeloVeiculoTrator,
              state.params.isMultiple
            );
            confirmSelection(arr);
          } else {
            focusOnLastElement();
          }
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      deleteEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterSaveNewEntity,
      afterPageChange,
      clearFilter,
      format,
      amIAllowed,
      keyRegister,
      getNome,
      generateCopy,
      getParamsRouteEdit,
      stateLoading,
      confirmSelectionCliente,
      ...toRefs(state)
    };
  },
  components: {
    CadastroModeloVeiculoTrator,
    EntitySelectorCliente
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idModeloVeiculoTrator: Number,
    idModeloVeiculoTratorCopy: Number,
    preFilter: Object as PropType<ModeloVeiculoTratorFilterPayload>,
    cliente: Object as PropType<ClientePayload>,
    selected: {
      type: Array as PropType<Array<ModeloVeiculoTratorPayload>>
    }
  }
});
