
import { defineComponent, reactive, toRefs, onMounted } from "vue";

import { initGlobalMessageService } from "../../../modules/message";

interface ModalState {
  stateReady: boolean;
  dialogOpen: boolean;
  stateId: string;
}
export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ModalState>({
      stateReady: false,
      dialogOpen: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    initGlobalMessageService();

    onMounted(async () => {
      if (props.isModal) {
        state.dialogOpen = true;
      }

      state.stateReady = true;
    });

    const clickClose = () => {
      if (props.isModal) {
        emit("close-modal");
      }
    };

    return {
      clickClose,
      ...toRefs(state)
    };
  },
  props: {
    isModal: Boolean,
    loading: Boolean
  },
  components: {}
});
