
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    //initGlobalConfirm();
    //initGlobalToast();
    return {};
  },
  components: {}
});
