import { useApiWithAuth } from "@/modules/api";
import { PermissionPayload } from "@/modules/auth";
import {
  DocumentoPayload,
  useOrgaoAetService
} from "@/modules/business/common/commonBusiness";
import { ArtEngenheiroTransportadorPayload } from "@/modules/business/transportador/artEngenheiroTransportadorBusiness";
import { PlanilhaTrechosPayload } from "@/modules/business/trecho/planilhaTrechosBusiness";
import { LicencaFilterReturnPayload } from "@/modules/business/licenca/licencaBusiness";
import { TransportadorPayload } from "@/modules/business/transportador/transportadorBusiness";

interface LicencaOrgaoPayload {
  idLicencaOrgao?: number | null;
  idLicenca?: number | null;
  idOrgao?: number | null;
  idTipoLicencaOrgao?: number | null;
  numeroPedido?: string | null;
  numeroPedidoRenovacao?: string | null;
  observacao?: string | null;
  projeto?: DocumentoPayload | null;
  comprovantePagamentoBoleto?: DocumentoPayload | null;
  art?: ArtEngenheiroTransportadorPayload | null;
  transportadorAuxiliar?: TransportadorPayload;
  planilha?: PlanilhaTrechosPayload | null;
  dtValidadeInicial?: Date | null;
  dtValidadeFinal?: Date | null;
  dtVencimentoBoleto?: Date | null;
  keyBlBoleto?: string | null;
  keyBlAet?: string | null;
  keyBlAetTemp?: string | null;
  keyBlAetRenovacao?: string | null;
  keyBlAetRenovacaoTemp?: string | null;
  timestampSolicitacao?: Date | null;
  timestampCadastro?: Date | null;
  timestampAlteracao?: Date | null;
  timestampBaixouBoleto?: Date | null;
  timestampBaixouAet?: Date | null;
  idFilaLicenca?: number | null;
  situacao?: string | null;
  situacaoGovpass?: string | null;
  timestampUltimaConsulta?: Date | null;
  permissionInfo?: PermissionPayload;
  //Processados
  licenca?: LicencaFilterReturnPayload;
  groupIndex?: number;
}

const useLicencaOrgaoService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    patch,
    downloadFileNewTab,
    downloadFile,
    downloadFilePosting,
    downloadFilePostingNewTab
  } = useApiWithAuth();

  const searchLicencaOrgao = (idLicenca: number): Promise<void> => {
    return get(`/licenca/${idLicenca}/licenca-orgao`);
  };

  const getLicencaOrgao = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return get(`/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}`);
  };

  const newLicencaOrgao = (
    idLicenca: number,
    idCliente?: number | null
  ): Promise<void> => {
    return get(
      `/licenca/${idLicenca}/licenca-orgao/new`,
      idCliente ? { idCliente: idCliente } : {}
    );
  };

  const shelveLicencaOrgao = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return patch(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/arquivar`
    );
  };

  const unshelveLicencaOrgao = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return patch(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/desarquivar`
    );
  };

  const saveNewLicencaOrgao = (
    idLicenca: number,
    licenca: LicencaOrgaoPayload
  ): Promise<void> => {
    return post(`/licenca/${idLicenca}/licenca-orgao`, licenca);
  };

  const updateLicencaOrgao = (
    idLicenca: number,
    idLicencaOrgao: number,
    licenca: LicencaOrgaoPayload
  ): Promise<void> => {
    return put(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}`,
      licenca
    );
  };

  const getOrgaoDefaultData = (
    idLicenca: number,
    idOrgao: number,
    idTipoLicencaOrgao: number,
    idCliente?: number | null
  ): Promise<void> => {
    return get(
      `/licenca/${idLicenca}/licenca-orgao/orgao/${idOrgao}`,
      idCliente
        ? {
            idOrgao: idOrgao,
            idTipoLicencaOrgao: idTipoLicencaOrgao,
            idCliente: idCliente
          }
        : {
            idOrgao: idOrgao,
            idTipoLicencaOrgao: idTipoLicencaOrgao
          }
    );
  };

  const play = (idLicenca: number, idLicencaOrgao: number): Promise<void> => {
    return patch(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/solicitar`
    );
  };

  const replay = (idLicenca: number, idLicencaOrgao: number): Promise<void> => {
    return patch(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/solicitar-novamente`
    );
  };

  const replayForceData = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return patch(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/solicitar-novamente-recadastrando-dados`
    );
  };

  const setPriority = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return patch(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/priorizar-consulta`
    );
  };

  const addToQueue = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return patch(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/adicionar-consulta`
    );
  };

  const downloadAet = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/aet`
    );
  };

  const downloadAetRenovacao = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/aet-renovacao`
    );
  };

  const downloadBoleto = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/boleto`
    );
  };

  const downloadProjeto = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/projeto-tecnico`
    );
  };

  const downloadComprovantePagamentoBoleto = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/boleto-comprovante-pagamento`
    );
  };

  const downloadDocumentosVeiculos = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/documentos-veiculos`
    );
  };

  const processProjeto = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/process/projeto-tecnico`
    );
  };

  const downloadErro = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/erro`
    );
  };

  const getUrlErro = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return get(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/erro`
    );
  };

  const getUrlErroThumbnail = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return get(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/erro/thumbnail`
    );
  };

  const getLogExecucao = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return get(`/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/log`);
  };

  const downloadCapa = (
    idLicenca: number,
    idLicencaOrgao: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/download/capa`
    );
  };

  const searchStretchChanges = (
    idLicenca: number,
    idLicencaOrgao: number
  ): Promise<void> => {
    return get(
      `/licenca/${idLicenca}/licenca-orgao/${idLicencaOrgao}/trechos/alteracoes`
    );
  };

  const downloadZipAets = (
    idLicencaOrgaoList: Array<number>,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFilePostingNewTab : downloadFilePosting)(
      `/licenca/licenca-orgao/download/zip/aet`,
      idLicencaOrgaoList
    );
  };

  const downloadZipBoletos = (
    idLicencaOrgaoList: Array<number>,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFilePostingNewTab : downloadFilePosting)(
      `/licenca/licenca-orgao/download/zip/boleto`,
      idLicencaOrgaoList
    );
  };

  const safeLicencaOrgao = (
    entity?: LicencaOrgaoPayload | null
  ): LicencaOrgaoPayload => {
    return entity ?? {};
  };

  const safeFilterLicencaOrgao = () => {
    return {};
  };

  const getNomeLicencaOrgao = (entity?: LicencaOrgaoPayload) => {
    if (!entity) {
      return "";
    }
    return (
      (entity.numeroPedido ? entity.numeroPedido + "/" : "") +
      useOrgaoAetService().getOrgaoAetSigla(entity.idOrgao)
    );
  };

  return {
    data,
    loading,
    searchLicencaOrgao,
    shelveLicencaOrgao,
    unshelveLicencaOrgao,
    saveNewLicencaOrgao,
    updateLicencaOrgao,
    newLicencaOrgao,
    getLicencaOrgao,
    safeLicencaOrgao,
    safeFilterLicencaOrgao,
    searchStretchChanges,
    addToQueue,
    setPriority,
    getNomeLicencaOrgao,
    getOrgaoDefaultData,
    downloadBoleto,
    downloadAet,
    downloadAetRenovacao,
    downloadErro,
    getLogExecucao,
    getUrlErro,
    getUrlErroThumbnail,
    downloadZipAets,
    downloadZipBoletos,
    downloadProjeto,
    downloadComprovantePagamentoBoleto,
    downloadDocumentosVeiculos,
    processProjeto,
    downloadCapa,
    play,
    replay,
    replayForceData
  };
};

export { useLicencaOrgaoService, LicencaOrgaoPayload };
