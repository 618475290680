import { useApiWithAuth, DownloadViewReturn } from "@/modules/api";
import { useCache } from "@/modules/cache";
import { VeiculoTratorPayload } from "@/modules/business/veiculo/veiculoTratorBusiness";
import { CarretaPayload } from "@/modules/business/veiculo/carretaBusiness";

interface ImageConjuntoTransportadorGabaritoPayload {
  x?: number | null;
  y?: number | null;
  h?: number | null;
  w?: number | null;
}

interface EixoConjuntoTransportadorGabaritoPayload {
  identificador?: string | null;
  distanciaEixoAnterior?: number | null;
  rodas?: number | null;
  positionImage?: ImageConjuntoTransportadorGabaritoPayload | null;
}

interface ConjuntoEixosConjuntoTransportadorGabaritoPayload {
  nuSeqGeral?: number | null;
  nuSeqVeiculo?: number | null;
  identificador?: string | null;
  distanciaConjuntoEixoAnterior?: number | null;
  pesoMaximoConjuntoEixos?: number | null;
  pesoRecomendadoConjuntoEixos?: number | null;
  eixos: Array<EixoConjuntoTransportadorGabaritoPayload>;
  //ATRIBUTOS LICENCA
  pesoConjuntoEixos?: number | null;
}

interface VeiculoConjuntoTransportadorGabaritoPayload {
  nuSeqVeiculo?: number | null;
  tipoVeiculo?: string | null;
  idTipoCarroceria?: number | null;
  conjuntosEixosVeiculo: Array<
    ConjuntoEixosConjuntoTransportadorGabaritoPayload
  >;
  disposicaoEixos?: Array<number> | null;
  bidirecional?: boolean;
  carreta?: boolean;
  veiculoTrator?: boolean;
  //ATRIBUTOS LICENCA
  veiculoTratorDaLicenca?: VeiculoTratorPayload | null;
  carretaDaLicenca?: CarretaPayload | null;
  rodiziosAssociadosDaLicenca?: Array<CarretaPayload> | null;
}

interface GabaritoProcessoOutputPayload {
  idGabarito: number;
  dinamico: boolean;
  conjuntoTransportador?: Array<
    VeiculoConjuntoTransportadorGabaritoPayload
  > | null;
}

interface GabaritoPayload {
  idGabarito: number;
  nome: string;
  qtEixos?: number | null;
  nuUnidadesAcopladas?: number | null;
  altura?: number | null;
  largura?: number | null;
  peso?: number | null;
  comprimentoMin?: number | null;
  comprimentoMax?: number | null;
  tipoCarga?: string | null;
  flExclusao?: boolean | null;
  temEngenheiroResp?: boolean | null;
  temExcessoLateral?: boolean | null;
  temExcessoLongitudinal?: boolean | null;
  conjuntoTransportador?: Array<
    VeiculoConjuntoTransportadorGabaritoPayload
  > | null;
}

export const useGabaritoService = () => {
  const {
    data,
    loading,
    get,
    getPosting,
    downloadToViewPosting
  } = useApiWithAuth();

  const getGabaritosToCache = (): Promise<void> => {
    return get(`/gabarito`);
  };

  const getGabaritos = (): Array<GabaritoPayload> => {
    return useCache()
      .getGabaritos()
      .sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0));
  };

  const getGabaritosAtivos = (): Array<GabaritoPayload> => {
    return getGabaritos().filter((g) => !g.flExclusao);
  };

  const getGabarito = (idGabarito?: number | null): GabaritoPayload | null => {
    if (!idGabarito) {
      return null;
    }

    return getGabaritos().find((a) => a.idGabarito == idGabarito) ?? null;
  };

  const getNomeGabarito = (
    idGabarito?: number | null
  ): string | null | undefined => {
    if (!idGabarito) {
      return null;
    }

    return getGabarito(idGabarito)?.nome;
  };

  const getGabaritosDosVeiculos = (
    conjuntoTransportador: Array<VeiculoConjuntoTransportadorGabaritoPayload>
  ): Promise<void> => {
    return getPosting(`/gabarito/calculo/veiculos`, {
      conjuntoTransportador: conjuntoTransportador
    });
  };

  const downloadImagemGabaritosDosVeiculosExibicao = (
    idGabarito: number,
    conjuntoTransportador: Array<VeiculoConjuntoTransportadorGabaritoPayload>
  ): Promise<DownloadViewReturn> => {
    return downloadToViewPosting(`/gabarito/calculo/veiculos/img`, {
      idGabarito: idGabarito,
      conjuntoTransportador: conjuntoTransportador
    });
  };

  return {
    data,
    loading,
    getGabaritosToCache,
    getGabaritosAtivos,
    getGabaritos,
    getGabarito,
    getNomeGabarito,
    getGabaritosDosVeiculos,
    downloadImagemGabaritosDosVeiculosExibicao
  };
};

export {
  GabaritoPayload,
  VeiculoConjuntoTransportadorGabaritoPayload,
  ConjuntoEixosConjuntoTransportadorGabaritoPayload,
  GabaritoProcessoOutputPayload,
  ImageConjuntoTransportadorGabaritoPayload
};
