import { reactive, toRefs, watch } from "vue";
import { useApiWithAuth, useApi } from "@/modules/api";
import { useCache } from "@/modules/cache";
import { differenceInMinutes } from "@/modules/utils";
import { CredencialPayload } from "./business/common/commonBusiness";

export const AUTH_TOKEN = "access_token";
export const TOKEN_TYPE = "token_type";
export const LAST_LOAD = "last_load";
export const LAST_LOAD_CACHE = "last_load_cache";

export interface AuthorityPayload {
  authority: string;
  info?: string;
}

export interface PermissionPayload {
  securityKey?: string | null;
  authorities?: Array<AuthorityPayload>;
  authoritiesBlocked?: Array<AuthorityPayload>;
}

export interface User {
  userName: string;
  idUsuario: number;
  nome: string;
  idCliente: number;
  nomeCliente: string;
  termosUso: boolean;
  ultimaNotificacaoVisualizada: number;
}

export interface UserAuth extends User {
  [AUTH_TOKEN]: string;
  [TOKEN_TYPE]: string;
  logoUrl: string;
  permissions: Array<PermissionPayload>;
}

interface AuthState {
  authenticating: boolean;
  user?: UserAuth;
  error?: Error;
  lastLoad?: Date;
  lastLoadCache?: Date;
  lastAuth?: string;
}

const lastLoad = window.localStorage.getItem(LAST_LOAD);
const lastLoadCache = window.localStorage.getItem(LAST_LOAD_CACHE);

const state = reactive<AuthState>({
  authenticating: true,
  user: undefined,
  error: undefined,
  lastLoad: lastLoad ? new Date(lastLoad ?? null) : undefined,
  lastLoadCache: lastLoadCache ? new Date(lastLoadCache ?? null) : undefined
});

export const useAuth = () => {
  const getPrincipalPath = () => {
    return "/principal";
  };
  const setUser = (payload: UserAuth): void => {
    state.user = payload;
    window.localStorage.setItem(AUTH_TOKEN, payload[AUTH_TOKEN]);
    window.localStorage.setItem(TOKEN_TYPE, payload[TOKEN_TYPE]);
    state.error = undefined;
  };

  const getAuthToken = (): string => {
    const token = window.localStorage.getItem(AUTH_TOKEN) ?? "";
    const tokenType = window.localStorage.getItem(TOKEN_TYPE) ?? "";
    if (!token || !tokenType) {
      return "";
    }
    return tokenType.charAt(0).toUpperCase() + tokenType.slice(1) + " " + token;
  };

  const reloadUser = (force?: boolean): Promise<User> => {
    return new Promise<User>((resolve, reject) => {
      if (!getAuthToken()) {
        state.authenticating = false;
        reject("Sem auth");
      } else {
        if (
          force ||
          !state.lastLoad ||
          !state.lastLoadCache ||
          !state.user ||
          differenceInMinutes(state.lastLoad, new Date()) > 1 ||
          differenceInMinutes(state.lastLoadCache, new Date()) > 60
        ) {
          const { loading, error, data, get } = useApiWithAuth();
          state.authenticating = true;
          get(getPrincipalPath());

          watch([loading], () => {
            if (state.authenticating === true) {
              state.authenticating = false;
            }
            if (error.value) {
              window.localStorage.removeItem(AUTH_TOKEN);
              window.localStorage.removeItem(TOKEN_TYPE);
              state.user = undefined;
              state.error = error.value;
              reject(error.value);
            } else if (data.value) {
              state.user = data.value;
              state.error = undefined;
              if (state.user) {
                state.lastLoad = new Date();
                window.localStorage.setItem(
                  LAST_LOAD,
                  state.lastLoad.toString()
                );
                if (
                  !state.lastLoadCache ||
                  differenceInMinutes(state.lastLoadCache, new Date()) > 60
                ) {
                  useCache()
                    .loadCaches()
                    .then(() => {
                      state.lastLoadCache = new Date();
                      window.localStorage.setItem(
                        LAST_LOAD_CACHE,
                        state.lastLoadCache.toString()
                      );
                      if (state.user) {
                        resolve(state.user);
                      } else {
                        reject("Resposta Vazia");
                      }
                    })
                    .catch(() => {
                      reject("Erro recarregar caches");
                    });
                } else {
                  resolve(state.user);
                }
              } else {
                reject("Resposta Vazia");
              }
            } else {
              window.localStorage.removeItem(AUTH_TOKEN);
              window.localStorage.removeItem(TOKEN_TYPE);
              state.user = undefined;
              state.error = error.value;
              reject("Resposta Vazia");
            }
          });
        } else {
          resolve(state.user);
        }
      }
    });
  };

  const logout = (): Promise<void> => {
    window.localStorage.removeItem(AUTH_TOKEN);
    window.localStorage.removeItem(TOKEN_TYPE);
    useCache().clearCaches();
    window.localStorage.removeItem(LAST_LOAD);
    window.localStorage.removeItem(LAST_LOAD_CACHE);
    state.lastLoad = undefined;
    state.lastLoadCache = undefined;
    return Promise.resolve((state.user = undefined));
  };

  const login = (cred: CredencialPayload) => {
    return new Promise<void>((resolve, reject) => {
      const { data, post } = useApi();
      post("/login", cred)
        .then(() => {
          setUser(data.value);
          state.lastLoad = new Date();
          window.localStorage.setItem(LAST_LOAD, state.lastLoad.toString());
          useCache()
            .loadCaches()
            .then(() => {
              state.lastLoadCache = new Date();
              window.localStorage.setItem(
                LAST_LOAD_CACHE,
                state.lastLoadCache.toString()
              );
              console.log("Carregou Caches");
              resolve();
            })
            .catch(() => {
              resolve();
            });
          resolve();
        })
        .catch(reject);
    });
  };

  const updateMyPassword = (cred: CredencialPayload) => {
    return new Promise<void>((resolve, reject) => {
      const { patch } = useApiWithAuth();
      patch("/me/redefinir-senha", cred)
        .then(() => {
          const newCred: CredencialPayload = {
            login: state.user?.userName,
            senha: cred.novaSenha
          };
          login(newCred)
            .then(() => {
              resolve();
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  const getPermissionInfo = (
    permissionInfoPrm?: PermissionPayload | string | null
  ) => {
    if (permissionInfoPrm) {
      if (typeof permissionInfoPrm === "string") {
        const perms = state.user?.permissions?.filter(
          (e) => e.securityKey == permissionInfoPrm
        );
        if (perms?.length) {
          return perms[0];
        }
      } else {
        return permissionInfoPrm;
      }
    }
    return null;
  };

  const amIBlocked = (
    authority: string,
    permissionInfoPrm?: PermissionPayload | string | null
  ): AuthorityPayload | undefined => {
    const permissionInfo = getPermissionInfo(permissionInfoPrm);
    if (
      authority &&
      permissionInfo &&
      permissionInfo.authoritiesBlocked &&
      permissionInfo.authoritiesBlocked.length
    ) {
      const auths = permissionInfo.authoritiesBlocked.filter(
        (a) => a.authority == authority
      );
      if (auths.length) {
        return auths[0];
      }
    }
  };

  const amIAllowed = (
    authority: string,
    permissionInfoPrm?: PermissionPayload | string | null
  ): AuthorityPayload | undefined => {
    if (!amIBlocked(authority, permissionInfoPrm)) {
      const permissionInfo = getPermissionInfo(permissionInfoPrm);
      if (
        authority &&
        permissionInfo &&
        permissionInfo.authorities &&
        permissionInfo.authorities.length
      ) {
        const auths = permissionInfo.authorities.filter(
          (a) => a.authority == authority
        );
        if (auths.length) {
          return auths[0];
        }
      }
    }
  };

  return {
    setUser,
    getPrincipalPath,
    reloadUser,
    updateMyPassword,
    login,
    logout,
    amIAllowed,
    amIBlocked,
    getAuthToken,
    ...toRefs(state)
  };
};
