/* eslint-disable  @typescript-eslint/no-explicit-any */

interface PaginationResult<T> {
  content: Array<T>;
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
  numberOfElements: number;
}

const defaultResults = (): PaginationResult<any> => {
  return {
    content: [],
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    number: 0,
    size: 15
  };
};

export { PaginationResult, defaultResults };
