<template>
  <span
    :class="
      (divClass || '') + (floatLabel ? ' p-float-label' : '') + ' p-fluid'
    "
  >
    <slot :id="stateId" :required="required" />

    <label :for="stateId" v-if="showLabel && label?.length">
      <i :class="iconClass" v-if="!!iconClass" />
      {{ label }}
      <small
        v-if="
          subLabel &&
            subLabel.length > 0 &&
            subLabel.length < 7 &&
            !forceSublabelUnder
        "
        v-text="'(' + subLabel + ')'"
      />
      <span v-if="required">*</span>
      <slot name="complement" :id="stateId" :required="required" />
      <template v-if="addRequiredHiddenField && required">
        <input
          type="text"
          :value="hasValue ? 'hasValue' : ''"
          required
          tabindex="-1"
          class="hidden-input"
        />
      </template>
    </label>
    <label
      :for="stateId"
      style="bottom: 0.3rem; top: initial"
      v-if="subLabel && (subLabel.length >= 7 || forceSublabelUnder)"
      class="subLabel"
    >
      <small v-text="subLabel" class="subLabel" />
    </label>
  </span>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";

interface InputWithLabelState {
  stateReady: boolean;
  stateId: string;
}

export default defineComponent({
  setup(props) {
    const state = reactive<InputWithLabelState>({
      stateReady: false,
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2)
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      ...toRefs(state)
    };
  },
  props: {
    iconClass: String,
    divClass: String,
    label: String,
    subLabel: String,
    required: [String, Number, Object, Boolean],
    id: String,
    floatLabel: {
      type: Boolean,
      default: true
    },
    forceSublabelUnder: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    addRequiredHiddenField: {
      type: Boolean,
      default: false
    },
    hasValue: {
      type: Boolean,
      default: false
    }
  },
  components: {}
});
</script>
<style scoped lang="scss">
.subLabel {
  font-size: 0.6rem;
  padding-top: 2px;
}
.p-float-label > .p-inputwrapper-filled ~ .subLabel,
.p-float-label > .p-inputwrapper-focus ~ .subLabel,
.p-float-label > input:focus ~ .subLabel,
.p-float-label > .p-filled ~ .subLabel {
  padding-top: 0px !important;
  bottom: -0.3rem !important;
  top: initial !important;
}
</style>
