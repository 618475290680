<template>
  <sistema>
    <container :isModal="false" :loading="!stateReady || stateLoading">
      <template v-slot:header>
        <h3>Substituição de Senha</h3>
      </template>
      <template v-slot:body>
        <div class="sub-title">
          <h4>
            Por favor preencha os dados abaixo para dar para realizar a
            substituição da senha
          </h4>
        </div>
        <form @submit.prevent="submit">
          <fieldset :disabled="stateLoading">
            <div>
              <div>
                <input-with-label
                  iconClass="fas fa-lock p-mr-1"
                  label="Senha Atual"
                  v-slot="l"
                  :required="true"
                >
                  <Password
                    v-model="credentials.senha"
                    :id="l.id"
                    :required="l.required"
                    :feedback="false"
                    v-focus
                  />
                </input-with-label>
              </div>
              <div>
                <input-with-label
                  iconClass="fas fa-lock p-mr-1"
                  label="Nova Senha"
                  v-slot="l"
                  :required="true"
                >
                  <Password
                    v-model="credentials.novaSenha"
                    :id="l.id"
                    :required="l.required"
                  />
                </input-with-label>
              </div>
              <div>
                <input-with-label
                  iconClass="fas fa-lock p-mr-1"
                  label="Confirmar Senha"
                  v-slot="l"
                  :required="true"
                >
                  <Password
                    v-model="confirmaSenha"
                    :id="l.id"
                    :required="l.required"
                    :feedback="false"
                  />
                </input-with-label>
              </div>

              <small
                class="text-danger"
                v-if="
                  credentials.novaSenha &&
                    confirmaSenha &&
                    credentials.novaSenha != confirmaSenha
                "
                ><b>As senhas devem ser iguais</b></small
              >
            </div>
            <div class="mt-6">
              <Button type="submit" class="p-button-rounded" label="Enviar" />
            </div>
          </fieldset>
        </form>
      </template>
    </container>
  </sistema>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useAuth } from "../../modules/auth";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";
import { useToast } from "../../modules/message";

interface RecoverPayload {
  credentials: CredencialPayload;
  confirmaSenha: string;
  stateLoading: boolean;
  stateReady: boolean;
}

export default defineComponent({
  setup() {
    const state = reactive<RecoverPayload>({
      credentials: {},
      confirmaSenha: "",
      stateLoading: false,
      stateReady: false
    });

    const submit = () => {
      state.stateLoading = true;

      useAuth()
        .updateMyPassword(state.credentials)
        .then(() => {
          useToast().success("Senha atualizada com sucesso.");
          state.credentials = {};
          state.confirmaSenha = "";
          state.stateLoading = false;
        })
        .catch(() => {
          state.stateLoading = false;
        });
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submit,
      ...toRefs(state)
    };
  },
  components: {}
});
</script>
