<template>
  <div v-if="stateReady">
    <input-with-label
      label="UF"
      :required="required"
      :addRequiredHiddenField="true"
      :hasValue="!!unidadeFederalInterno?.idUnidadeFederal"
      v-slot="l"
    >
      <Dropdown
        v-model="unidadeFederalInterno"
        :options="ufs"
        optionLabel="sigla"
        :filter="true"
        filterPlaceholder="Sigla da UF"
        :showClear="true"
        :id="l.id"
        :required="l.required"
        :placeholder="'&nbsp;'"
        :disabled="readOnly"
        v-focus="focus"
      >
        <template #option="slotProps">
          {{ slotProps.option.sigla }}
        </template>
        <template #value="slotProps">
          <div v-if="slotProps.value">
            <span> {{ slotProps.value.sigla }}</span>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
      </Dropdown>
    </input-with-label>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, reactive, onMounted, watch } from "vue";
import {
  useUfService,
  UFPayload
} from "../../modules/business/common/commonBusiness";

//TODO KAIO TROCAR OS ENTITYSELECTORS PARA USAR V-MODEL

interface UFSelectorState {
  stateReady: boolean;
  unidadeFederalInterno?: UFPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<UFSelectorState>({
      stateReady: false,
      unidadeFederalInterno: useUfService().getUf(props.idUnidadeFederal)
    });

    watch([props], () => {
      if (
        props.idUnidadeFederal != state.unidadeFederalInterno?.idUnidadeFederal
      ) {
        state.unidadeFederalInterno = useUfService().getUf(
          props.idUnidadeFederal
        );
      }
    });

    watch([state], () => {
      emit(
        "after-set-uf",
        state.unidadeFederalInterno?.idUnidadeFederal ?? null
      );
    });

    const ufs = useUfService().getUfs();

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      ufs,
      ...toRefs(state)
    };
  },
  props: {
    required: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    idUnidadeFederal: {
      type: Number
    },
    id: {
      type: String
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
