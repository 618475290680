
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref
} from "vue";
import { useMunicipioService } from "../../modules/business/common/commonBusiness";
import { useStorage } from "../../modules/storage";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useToast } from "../../modules/message";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../modules/business/cliente/clienteBusiness";
import { EngenheiroPayload } from "../../modules/business/engenheiro/engenheiroBusiness";
import EntitySelectorMunicipio from "../common/EntitySelectorMunicipio.vue";

// eslint-disable-next-line
declare var require: any; //PQP MEMO

interface ClienteCadastroState {
  entity: ClientePayload;
  stateReady: boolean;
  savedEntity: boolean;
  loadings: Array<Ref<boolean>>;
  stateAccessFromSearch: boolean;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const safeCliente = (cliente?: ClientePayload | null) => {
      return useClienteService().safeCliente(cliente);
    };

    const idClienteProps = props.idCliente ?? 0;

    const state = reactive<ClienteCadastroState>({
      entity: safeCliente(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      stateAccessFromSearch: !!props.idCliente,
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const setEntity = (entity: ClientePayload) => {
      state.entity = safeCliente(entity);

      const logoElement = document.querySelector(
        "#" + state.stateId + "_logo"
      ) as HTMLInputElement;
      if (logoElement) {
        logoElement.value = "";
      }

      const comprovanteResidenciaElement = document.querySelector(
        "#" + state.stateId + "_comprovanteResidencia"
      ) as HTMLInputElement;
      if (comprovanteResidenciaElement) {
        comprovanteResidenciaElement.value = "";
      }

      const documentoDoResponsavelassinaturaElement = document.querySelector(
        "#" + state.stateId + "_documentoResponsavel"
      ) as HTMLInputElement;
      if (documentoDoResponsavelassinaturaElement) {
        documentoDoResponsavelassinaturaElement.value = "";
      }
    };

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const confirmSelectionEngenheiroGovpass = (eng: EngenheiroPayload) => {
      if (eng) {
        state.entity.engenheiroGovpass = eng;
      } else {
        state.entity.engenheiroGovpass = useClienteService().safeCliente().engenheiroGovpass;
      }
    };

    const downloadComprovanteResidenciaFile = (
      newTab: boolean,
      onEnd: Function
    ) => {
      if (state.entity.comprovanteResidencia.idDocumento) {
        const service = useClienteService();
        const serviceLogado = useClienteLogadoService();
        const loading = state.stateAccessFromSearch
          ? service.loading
          : serviceLogado.loading;
        state.loadings.push(loading);
        (state.stateAccessFromSearch
          ? service.downloadComprovanteResidencia(idClienteProps, newTab)
          : serviceLogado.downloadComprovanteResidencia(newTab)
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const downloadLogoFile = (newTab: boolean, onEnd: Function) => {
      if (state.entity.logo.idDocumento) {
        const service = useClienteService();
        const serviceLogado = useClienteLogadoService();
        const loading = state.stateAccessFromSearch
          ? service.loading
          : serviceLogado.loading;
        state.loadings.push(loading);
        (state.stateAccessFromSearch
          ? service.downloadLogo(idClienteProps, newTab)
          : serviceLogado.downloadLogo(newTab)
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const downloadDocumentoDoResponsavelFile = (
      newTab: boolean,
      onEnd: Function
    ) => {
      if (state.entity.documentoResponsavel.idDocumento) {
        const service = useClienteService();
        const serviceLogado = useClienteLogadoService();
        const loading = state.stateAccessFromSearch
          ? service.loading
          : serviceLogado.loading;
        state.loadings.push(loading);
        (state.stateAccessFromSearch
          ? service.downloadDocumentoDoResponsavel(idClienteProps, newTab)
          : serviceLogado.downloadDocumentoDoResponsavel(newTab)
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const deleteTempFiles = () => {
      if (state.entity.logo.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(state.entity.logo.keyBlDocumentoTemp);
        state.entity.logo.keyBlDocumentoTemp = null;
      }
      if (state.entity.documentoResponsavel.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(
          state.entity.documentoResponsavel.keyBlDocumentoTemp
        );
        state.entity.documentoResponsavel.keyBlDocumentoTemp = null;
      }
      if (state.entity.comprovanteResidencia.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(
          state.entity.comprovanteResidencia.keyBlDocumentoTemp
        );
        state.entity.comprovanteResidencia.keyBlDocumentoTemp = null;
      }
      if (state.entity.procuracao.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(state.entity.procuracao.keyBlDocumentoTemp);
        state.entity.procuracao.keyBlDocumentoTemp = null;
      }
    };

    const save = () => {
      const serviceSave = useClienteService();
      const serviceLogadoSave = useClienteLogadoService();
      const loadingCadastro = state.stateAccessFromSearch
        ? serviceSave.loading
        : serviceLogadoSave.loading;
      const dataCadastro = state.stateAccessFromSearch
        ? serviceSave.data
        : serviceLogadoSave.data;

      state.loadings.push(loadingCadastro);
      (state.stateAccessFromSearch
        ? serviceSave.updateCliente(idClienteProps, state.entity)
        : serviceLogadoSave.updateCliente(state.entity)
      )
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          setEntity(dataCadastro.value);
          state.savedEntity = true;
          useToast().success("Cliente salvo com sucesso");
        })
        .catch(() => {
          deleteTempFiles();
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const processFile = (fileElementId: string) => {
      return new Promise<string>((resolve) => {
        const file = document.querySelector(
          "#" + fileElementId
        ) as HTMLInputElement;
        if (!file || !file.files || file.files.length === 0) {
          resolve("");
        } else {
          const formData = new FormData();
          formData.append("file", file.files[0]);
          const storage = useStorage();
          state.loadings.push(storage.loading);
          storage
            .uploadTempFile(formData)
            .then((key: string) => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
              resolve(key);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            });
        }
      });
    };

    const submitSave = () => {
      const arrayPromises = new Array<Promise<string>>();
      arrayPromises.push(
        processFile(state.stateId + "_logo").then(
          (key) => (state.entity.logo.keyBlDocumentoTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_comprovanteResidencia").then(
          (key) => (state.entity.comprovanteResidencia.keyBlDocumentoTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_documentoResponsavel").then(
          (key) => (state.entity.documentoResponsavel.keyBlDocumentoTemp = key)
        )
      );
      Promise.all(arrayPromises).then(
        () => {
          save();
        },
        () => {
          deleteTempFiles();
        }
      );
    };

    onMounted(async () => {
      const service = useClienteService();
      const serviceLogado = useClienteLogadoService();
      const loading = state.stateAccessFromSearch
        ? service.loading
        : serviceLogado.loading;
      const data = state.stateAccessFromSearch
        ? service.data
        : serviceLogado.data;

      state.loadings.push(loading);

      (state.stateAccessFromSearch
        ? service.getCliente(idClienteProps)
        : serviceLogado.getCliente()
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      downloadDocumentoDoResponsavelFile,
      downloadLogoFile,
      downloadComprovanteResidenciaFile,
      municipiosFiltrados,
      stateLoading,
      stateBlocked,
      amIAllowed,
      afterSetMunicipio,
      confirmSelectionEngenheiroGovpass,
      ...toRefs(state)
    };
  },
  props: {
    idCliente: Number,
    parentLoading: Boolean
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.EntitySelectorEngenheiro = require("../engenheiro/EntitySelectorEngenheiro.vue").default;
    }
  },
  components: {
    EntitySelectorMunicipio
  }
});
