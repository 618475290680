
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
  computed,
  watch,
  Ref,
  PropType
} from "vue";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useStorage } from "../../modules/storage";
import {
  round,
  copyObject,
  clearObject,
  cloneObject,
  formatNumber,
  waitUntilStable
} from "../../modules/utils";
import { DownloadViewReturn, refreshDmlToken } from "../../modules/api";
import {
  useLicencaService,
  LicencaPayload
} from "../../modules/business/licenca/licencaBusiness";
import {
  useGabaritoService,
  GabaritoProcessoOutputPayload,
  VeiculoConjuntoTransportadorGabaritoPayload,
  ImageConjuntoTransportadorGabaritoPayload
} from "../../modules/business/licenca/gabaritoBusiness";
import { useToast, useConfirm } from "../../modules/message";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import { EngenheiroPayload } from "../../modules/business/engenheiro/engenheiroBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../modules/business/transportador/transportadorBusiness";
import {
  VeiculoTratorPayload,
  useVeiculoTratorService
} from "../../modules/business/veiculo/veiculoTratorBusiness";
import {
  CarretaPayload,
  useCarretaService
} from "../../modules/business/veiculo/carretaBusiness";
import { MenuModel } from "../../router";
import { useTipoCarroceriaService } from "../../modules/business/veiculo/carroceriaBusiness";
import EntitySelectorVeiculoTrator from "../veiculo/trator/EntitySelectorVeiculoTrator.vue";
import EntitySelectorCarreta from "../veiculo/carreta/EntitySelectorCarreta.vue";
import EntitySelectorMultiCarreta from "../veiculo/carreta/EntitySelectorMultiCarreta.vue";
import EntitySelectorEngenheiro from "../engenheiro/EntitySelectorEngenheiro.vue";
import EntitySelectorTransportador from "../transportador/EntitySelectorTransportador.vue";
import EntitySelectorGabarito from "./gabarito/EntitySelectorGabarito.vue";
import CrudLicencaOrgao from "./orgao/CrudLicencaOrgao.vue";

interface LicencaCadastroState {
  entity: LicencaPayload;
  stateReady: boolean;
  savedEntity: boolean;
  gabaritosLoaded: boolean;
  loadings: Array<Ref<boolean>>;
  gabaritosPermitidos: Array<GabaritoProcessoOutputPayload>;
  stateId: string;
  visualizacaoUrl: DownloadViewReturn | null;
  gabaritoUrl: DownloadViewReturn | null;
  maxVeiculos: number;
  widthImgGabarito: number | null;
  maxDistanciaEixoTandem: number;
}

interface VeiculoDescriptorState {
  label?: string | null;
  subLabel?: string | null;
  full?: string | null;
  menu: Array<MenuModel>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<LicencaCadastroState>({
      entity: useLicencaService().safeLicenca(),
      savedEntity: false,
      stateReady: false,
      loadings: [],
      gabaritosPermitidos: [],
      gabaritosLoaded: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      visualizacaoUrl: null,
      gabaritoUrl: null,
      maxVeiculos: 4,
      maxDistanciaEixoTandem: 2.4,
      widthImgGabarito: null
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };
    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const stateFullBlocked = computed(() => {
      return !!(
        stateBlocked.value ||
        (amIAllowed("view", "root-cliente") && !state.entity.cliente?.idCliente)
      );
    });

    const pesoSomadoEixos = computed(() => {
      let soma = 0;
      if (state.entity.conjuntoTransportador) {
        state.entity.conjuntoTransportador.forEach((veiculo) => {
          if (veiculo.conjuntosEixosVeiculo) {
            veiculo.conjuntosEixosVeiculo.forEach((conjuntoEixo) => {
              if (conjuntoEixo.pesoConjuntoEixos) {
                soma += Number(conjuntoEixo.pesoConjuntoEixos);
              }
            });
          }
        });
      }
      return round(2, soma);
    });

    const totalAcopladas = computed(() => {
      return state.entity.conjuntoTransportador
        .map((c) =>
          c.carreta
            ? (c.rodiziosAssociadosDaLicenca
                ? c.rodiziosAssociadosDaLicenca.length
                : 0) + 1
            : 0
        )
        .reduce((tot, val) => tot + val, 0);
    });

    const possuiCarreta = computed(() => {
      return totalAcopladas.value > 0;
    });

    const balancoTraseiro = computed(() => {
      if (state.entity.conjuntoTransportador && state.entity.comprimentoTotal) {
        let total = state.entity.comprimentoTotal;
        state.entity.conjuntoTransportador.forEach((veiculo) => {
          if (veiculo.conjuntosEixosVeiculo) {
            veiculo.conjuntosEixosVeiculo.forEach((conjuntoEixo) => {
              if (conjuntoEixo.distanciaConjuntoEixoAnterior) {
                total -= conjuntoEixo.distanciaConjuntoEixoAnterior;
                if (conjuntoEixo.eixos) {
                  conjuntoEixo.eixos.forEach((eixo) => {
                    if (eixo.distanciaEixoAnterior) {
                      total -= eixo.distanciaEixoAnterior;
                    }
                  });
                }
              }
            });
          }
        });
        return round(2, total);
      } else {
        return 0;
      }
    });

    const balancoTraseiroMax = computed(() => {
      return 3.5;
    });

    const possuiVeiculoVazio = computed(() => {
      return (
        state.entity.conjuntoTransportador.filter(
          (v) =>
            (!v.veiculoTratorDaLicenca && !v.carretaDaLicenca) ||
            (v.veiculoTratorDaLicenca &&
              !v.veiculoTratorDaLicenca.idVeiculoTrator) ||
            (v.carretaDaLicenca && !v.carretaDaLicenca.idCarreta)
        ).length > 0
      );
    });

    const gabaritosFiltrados = computed(() => {
      if (
        amIAllowed("edit", state.entity.permissionInfo) &&
        !possuiVeiculoVazio.value
      ) {
        if (state.gabaritosPermitidos?.length) {
          const ret = useGabaritoService()
            .getGabaritosAtivos()
            .filter(
              (g) =>
                g.idGabarito &&
                state.gabaritosPermitidos
                  .map((gp) => gp.idGabarito)
                  .indexOf(g.idGabarito) >= 0
            );
          return ret;
        } else {
          return [];
        }
      } else {
        const gab = useGabaritoService().getGabarito(state.entity.idGabarito);
        return gab ? [gab] : [];
      }
    });

    const gabaritoSelecionado = computed(() => {
      return useGabaritoService().getGabarito(state.entity.idGabarito);
    });

    const gabaritoPermitidoSelecionado = computed(() => {
      const g = gabaritoSelecionado.value;
      if (state.gabaritosPermitidos && g) {
        return state.gabaritosPermitidos.filter(
          (gp) => gp.idGabarito == g.idGabarito
        )[0];
      }
      return null;
    });

    const composicaoEixos = computed(() => {
      if (state.entity.conjuntoTransportador) {
        return state.entity.conjuntoTransportador
          .map((v) => {
            if (gabaritoSelecionado.value) {
              const arrDisposicao = [];
              if (v.conjuntosEixosVeiculo?.length) {
                for (let c = 0; c < v.conjuntosEixosVeiculo.length; c++) {
                  const conjunto = v.conjuntosEixosVeiculo[c];

                  for (let e = 0; e < conjunto.eixos.length; e++) {
                    const eixo = conjunto.eixos[e];
                    const currDist =
                      (e == 0
                        ? conjunto.distanciaConjuntoEixoAnterior
                        : eixo.distanciaEixoAnterior) ?? 0;

                    if (
                      (c == 0 && e == 0) ||
                      currDist > state.maxDistanciaEixoTandem
                    ) {
                      arrDisposicao.push(0);
                    }
                    arrDisposicao[arrDisposicao.length - 1] += 1;
                  }
                }
              }

              if (v.veiculoTratorDaLicenca?.idVeiculoTrator) {
                v.disposicaoEixos = useVeiculoTratorService().getTracaoVeiculoTrator(
                  arrDisposicao,
                  v.veiculoTratorDaLicenca.tracao
                )?.disposicaoEixos;
              } else if (v.conjuntosEixosVeiculo?.length) {
                v.disposicaoEixos = useCarretaService().getDisposicaoEixosCarreta(
                  arrDisposicao
                )?.disposicaoEixos;
              }
            } else {
              v.disposicaoEixos = null;
              v.idTipoCarroceria = null;
            }

            return v.disposicaoEixos
              ? v.disposicaoEixos.map(String).join(",")
              : "";
          })
          .join("|");
      }

      return "";
    });

    const getTipoCarreta = (flTipoCarreta?: string | null) => {
      return useCarretaService().getTipoCarreta(flTipoCarreta);
    };

    const getTipoVeiculoTrator = (flTipoVeiculoTrator?: string | null) => {
      return useVeiculoTratorService().getTipoVeiculoTrator(
        flTipoVeiculoTrator
      );
    };

    const getTipoCarroceria = (idTipoCarroceria?: number | null) => {
      return useTipoCarroceriaService().getTipoCarroceria(idTipoCarroceria);
    };

    const clearGabaritoImagem = () => {
      if (state.gabaritoUrl) {
        state.gabaritoUrl.destroy();
        state.gabaritoUrl = null;
      }
    };

    const loadGabaritoImagem = () => {
      clearGabaritoImagem();
      if (state.entity.idGabarito) {
        const {
          downloadImagemGabaritosDosVeiculosExibicao
        } = useGabaritoService();
        // TODO KAIO IMPLEMENTAR REPROCESSADOR DE GABARITOS SE FOR DINAMICO
        downloadImagemGabaritosDosVeiculosExibicao(
          state.entity.idGabarito,
          state.entity.conjuntoTransportador
        ).then((ret) => {
          if (ret) {
            state.gabaritoUrl = ret;
          }
        });
      }
    };

    const processaGabaritosVeiculo = () => {
      if (
        state.entity.conjuntoTransportador.some(
          (v) =>
            !v.veiculoTratorDaLicenca?.idVeiculoTrator &&
            !v.carretaDaLicenca?.idCarreta
        )
      ) {
        state.gabaritosPermitidos = [];
        return Promise.resolve();
      }
      const { getGabaritosDosVeiculos, data, loading } = useGabaritoService();
      state.loadings.push(loading);

      return getGabaritosDosVeiculos(state.entity.conjuntoTransportador)
        .then(() => {
          state.gabaritosPermitidos = data.value ?? [];
          state.gabaritosLoaded = true;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.gabaritosPermitidos = [];
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const afterSetGabarito = (idGabarito?: number | null) => {
      state.entity.idGabarito = idGabarito ? idGabarito : undefined;
    };

    const onloadGabaritoImage = (evt: Event) => {
      state.widthImgGabarito =
        (evt.target as HTMLImageElement)?.naturalWidth ?? null;
    };

    const getPositionReferenteImagem = (
      positionImageStart?: ImageConjuntoTransportadorGabaritoPayload | null,
      positionImageEnd?: ImageConjuntoTransportadorGabaritoPayload | null
    ) => {
      if (!positionImageStart) {
        positionImageStart = positionImageEnd;
      }
      if (!positionImageEnd) {
        positionImageEnd = positionImageStart;
      }

      if (
        positionImageStart?.x &&
        positionImageEnd?.x &&
        state.widthImgGabarito
      ) {
        const xStart = positionImageStart?.x;
        const xEnd =
          (positionImageEnd?.x ? positionImageEnd.x : 0) +
          (positionImageEnd?.w ? positionImageEnd.w : 0);

        return (
          "left:" +
          (100 * ((xEnd - xStart) / 2 + xStart)) / state.widthImgGabarito +
          "%;" + //
          "transform: translate(-50%);height: 3rem; position: absolute;"
        );
      }
      return "";
    };

    const isChangeVeiculoTrator = (
      veiculoTratorComparacao: VeiculoTratorPayload,
      veiculoPosicao?: VeiculoTratorPayload | null
    ) => {
      return (
        veiculoPosicao &&
        (veiculoPosicao.flTipoVeiculoTrator !=
          veiculoTratorComparacao.flTipoVeiculoTrator ||
          veiculoPosicao.idTipoCarroceria !=
            veiculoTratorComparacao.idTipoCarroceria ||
          veiculoPosicao.numeroEixos != veiculoTratorComparacao.numeroEixos)
      );
    };

    const isChangeCarreta = (
      carretaComparacao: CarretaPayload,
      veiculoPosicao?: CarretaPayload | null
    ) => {
      return (
        veiculoPosicao &&
        (veiculoPosicao.flTipoCarreta != carretaComparacao.flTipoCarreta ||
          (
            getTipoCarroceria(veiculoPosicao.idTipoCarroceria)
              ?.carroceriasCompativeis ?? []
          ).indexOf(carretaComparacao.idTipoCarroceria ?? -1) < 0 ||
          veiculoPosicao.numeroEixos != carretaComparacao.numeroEixos)
      );
    };

    const setEntity = (entity: LicencaPayload) => {
      state.entity = useLicencaService().safeLicenca(entity);

      const docElement = document.querySelector(
        "#" + state.stateId + "_notaFiscalCarga"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
    };

    const downloadNotaFiscalCarga = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idLicenca) {
        onEnd();
        return;
      }
      const { downloadNotaFiscalCarga, loading } = useLicencaService();
      state.loadings.push(loading);
      downloadNotaFiscalCarga(state.entity.idLicenca, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const removeVeiculoConjunto = (
      item: VeiculoConjuntoTransportadorGabaritoPayload
    ) => {
      if (item) {
        const cbTrue = () => {
          state.entity.conjuntoTransportador.splice(
            state.entity.conjuntoTransportador.indexOf(item),
            1
          );
          processaGabaritosVeiculo();
        };
        if (
          !item.veiculoTratorDaLicenca?.idVeiculoTrator &&
          !item.carretaDaLicenca?.idCarreta
        ) {
          cbTrue();
        } else {
          useConfirm().require({
            message: item.veiculoTratorDaLicenca?.idVeiculoTrator
              ? "Deseja remover o veículo trator " +
                useVeiculoTratorService().getNomeVeiculoTrator(
                  item.veiculoTratorDaLicenca
                ) +
                "?"
              : item.carretaDaLicenca?.idCarreta
              ? "Deseja remover a carreta " +
                useCarretaService().getNomeCarreta(item.carretaDaLicenca) +
                ", bem como todos os rodízios desta posição?"
              : "Deseja remover o veículo",
            header: "Atenção",
            icon: "pi pi-info-circle",
            acceptClass: "p-button-danger",
            accept: () => {
              cbTrue();
            }
          });
        }
      }
    };

    const confirmSelectionTransportador = (t: TransportadorPayload) => {
      if (t) {
        state.entity.transportador = t;
      } else {
        state.entity.transportador = useLicencaService().safeLicenca().transportador;
      }
    };

    const confirmSelectionVeiculoTrator = (
      item: VeiculoConjuntoTransportadorGabaritoPayload,
      veiculoTrator?: VeiculoTratorPayload
    ) => {
      if (veiculoTrator && veiculoTrator.idVeiculoTrator) {
        if (isChangeVeiculoTrator(veiculoTrator, item.veiculoTratorDaLicenca)) {
          state.entity.idGabarito = null;
        }
        item.veiculoTratorDaLicenca = veiculoTrator;
        if (
          veiculoTrator.transportador?.idTransportador &&
          veiculoTrator.transportador.idTransportador !==
            state.entity.transportador?.idTransportador
        ) {
          const { getTransportador, data, loading } = useTransportadorService();
          getTransportador(veiculoTrator.transportador.idTransportador)
            .then(() => {
              confirmSelectionTransportador(data.value);
              state.loadings.splice(state.loadings.indexOf(loading), 1);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(loading), 1);
            });
        }
      } else {
        item.veiculoTratorDaLicenca = null;
      }
      processaGabaritosVeiculo();
    };

    const confirmSelectionCarreta = (
      item: VeiculoConjuntoTransportadorGabaritoPayload,
      carreta?: CarretaPayload
    ) => {
      if (carreta && carreta.idCarreta) {
        if (isChangeCarreta(carreta, item.carretaDaLicenca)) {
          state.entity.idGabarito = null;
        }
        item.carretaDaLicenca = carreta;
      } else {
        item.carretaDaLicenca = null;
      }
      processaGabaritosVeiculo();
    };

    const confirmSelectionRodizios = (
      item: VeiculoConjuntoTransportadorGabaritoPayload,
      carretas?: Array<CarretaPayload>
    ) => {
      if (carretas && carretas?.length > 0) {
        item.rodiziosAssociadosDaLicenca = carretas;
      } else {
        item.rodiziosAssociadosDaLicenca = [];
      }
    };

    const moveCarretaAntes = (
      item: VeiculoConjuntoTransportadorGabaritoPayload
    ) => {
      if (state.entity.conjuntoTransportador && item && item.nuSeqVeiculo) {
        if (
          item.nuSeqVeiculo < state.entity.conjuntoTransportador.length &&
          item.nuSeqVeiculo > 1
        ) {
          useConfirm().require({
            message: "Deseja mover o veículo para a proxima anterior?",
            header: "Atenção",
            icon: "pi pi-info-circle",
            accept: () => {
              const currIndex = state.entity.conjuntoTransportador.indexOf(
                item
              );
              const currNuSeqVeiculo = item.nuSeqVeiculo;
              const prevIndex = currIndex - 1;
              const prevItem = state.entity.conjuntoTransportador[prevIndex];
              state.entity.conjuntoTransportador.splice(currIndex, 1);
              state.entity.conjuntoTransportador.splice(prevIndex, 0, item);
              item.nuSeqVeiculo = prevItem.nuSeqVeiculo;
              prevItem.nuSeqVeiculo = currNuSeqVeiculo;
              state.entity.idGabarito = null;
              processaGabaritosVeiculo();
            }
          });
        }
      }
    };

    const moveCarretaDepois = (
      item: VeiculoConjuntoTransportadorGabaritoPayload
    ) => {
      if (state.entity.conjuntoTransportador && item && item.nuSeqVeiculo) {
        if (
          item.nuSeqVeiculo < state.entity.conjuntoTransportador.length - 1 &&
          item.nuSeqVeiculo > 0
        ) {
          useConfirm().require({
            message: "Deseja mover o veículo para a proxima posicao?",
            header: "Atenção",
            icon: "pi pi-info-circle",
            accept: () => {
              const currIndex = state.entity.conjuntoTransportador.indexOf(
                item
              );
              const currNuSeqVeiculo = item.nuSeqVeiculo;
              const nextIndex = currIndex + 1;
              const nextItem = state.entity.conjuntoTransportador[nextIndex];
              state.entity.conjuntoTransportador.splice(currIndex, 1);
              state.entity.conjuntoTransportador.splice(nextIndex, 0, item);
              item.nuSeqVeiculo = nextItem.nuSeqVeiculo;
              nextItem.nuSeqVeiculo = currNuSeqVeiculo;
              state.entity.idGabarito = null;
              processaGabaritosVeiculo();
            }
          });
        }
      }
    };

    const promoteRodizio = (
      item: VeiculoConjuntoTransportadorGabaritoPayload,
      rodizio?: CarretaPayload
    ) => {
      if (rodizio && rodizio.idCarreta && item) {
        useConfirm().require({
          message:
            "Deseja promover o rodízio " +
            useCarretaService().getNomeCarreta(rodizio) +
            " para a posicao Principal?",
          header: "Atenção",
          icon: "pi pi-info-circle",
          accept: () => {
            const carreta = copyObject(item.carretaDaLicenca, {});
            if (carreta) {
              item.carretaDaLicenca = copyObject(rodizio, {});
              if (item.rodiziosAssociadosDaLicenca) {
                const indexRodizio = item.rodiziosAssociadosDaLicenca.indexOf(
                  rodizio
                );
                if (indexRodizio > -1) {
                  item.rodiziosAssociadosDaLicenca.splice(
                    indexRodizio,
                    1,
                    carreta
                  );
                }
              } else {
                item.rodiziosAssociadosDaLicenca = [carreta];
              }
              processaGabaritosVeiculo();
            }
          }
        });
      }
    };

    const addVeiculoTrator = () => {
      state.entity.conjuntoTransportador.push({
        veiculoTrator: true,
        nuSeqVeiculo: state.entity.conjuntoTransportador.length,
        conjuntosEixosVeiculo: []
      });
    };

    const addCarreta = () => {
      state.entity.conjuntoTransportador.push({
        carreta: true,
        nuSeqVeiculo: state.entity.conjuntoTransportador.length,
        conjuntosEixosVeiculo: []
      });
    };

    const processVeiculosVisuals = (
      veiculo: VeiculoConjuntoTransportadorGabaritoPayload
    ) => {
      const ret = {
        label: "",
        subLabel: "",
        full: "",
        menu: []
      } as VeiculoDescriptorState;

      let tipo = "" as string | null | undefined;
      let carroceria = "" as string | null | undefined;
      let eixos = 0 as number | null | undefined;

      if (veiculo.veiculoTrator) {
        const t = getTipoVeiculoTrator(
          veiculo.veiculoTratorDaLicenca?.flTipoVeiculoTrator
        );
        tipo = t?.descricao;
        if (t?.idsCarroceriasAceitas?.length) {
          carroceria = getTipoCarroceria(
            veiculo.veiculoTratorDaLicenca?.idTipoCarroceria
          )?.descricao;
        }
        eixos = veiculo.veiculoTratorDaLicenca?.numeroEixos;
      } else if (veiculo.carreta) {
        const t = getTipoCarreta(veiculo.carretaDaLicenca?.flTipoCarreta);
        tipo = t?.descricao;
        if (t?.idsCarroceriasAceitas?.length) {
          carroceria = getTipoCarroceria(
            veiculo.carretaDaLicenca?.idTipoCarroceria
          )?.descricao;
        }
        eixos = veiculo.carretaDaLicenca?.numeroEixos;
        const nuSeqVeiculo = veiculo.nuSeqVeiculo ?? 0;
        if (state.entity.conjuntoTransportador.length > 2 && nuSeqVeiculo > 1) {
          ret.menu.push({
            label: "Mover para Antes",
            icon: "fa-solid fa-arrow-left",
            command: () => {
              moveCarretaAntes(veiculo);
            }
          });
        }
        if (
          state.entity.conjuntoTransportador.length > 2 &&
          nuSeqVeiculo < state.entity.conjuntoTransportador.length - 1 &&
          nuSeqVeiculo > 0
        ) {
          ret.menu.push({
            label: "Mover para Depois",
            icon: "fa-solid fa-arrow-right",
            command: () => {
              moveCarretaDepois(veiculo);
            }
          });
        }
      }

      if (tipo) {
        ret.label += tipo;
        ret.full += tipo;
      }

      if (carroceria) {
        if (carroceria == tipo) {
          ret.full += ret.full ? ", " : "";
        } else {
          ret.subLabel += carroceria;
          ret.full += (ret.full ? ", " : "") + carroceria;
        }
      }

      if (eixos) {
        ret.subLabel +=
          (ret.subLabel ? " " : "") + eixos + (eixos > 1 ? " eixos" : " eixo");
        ret.full +=
          (ret.full ? " " : "") + eixos + (eixos > 1 ? " eixos" : " eixo");
      }

      return ret;
    };

    const veiculosVisuals = computed(() => {
      return state.entity.conjuntoTransportador.map((v) =>
        processVeiculosVisuals(v)
      );
    });

    const buscaTipoCarroceriaValido = (nuSeqVeiculoIgnorado: number) => {
      return state.entity.conjuntoTransportador
        .filter((v) => v.nuSeqVeiculo !== nuSeqVeiculoIgnorado)
        .map(
          (v) =>
            v.idTipoCarroceria ||
            (v.veiculoTratorDaLicenca &&
              getTipoVeiculoTrator(v.veiculoTratorDaLicenca.flTipoVeiculoTrator)
                ?.unidadeComCarga &&
              v.veiculoTratorDaLicenca.idTipoCarroceria) ||
            (v.carretaDaLicenca &&
              getTipoCarreta(v.carretaDaLicenca.flTipoCarreta)
                ?.unidadeComCarga &&
              v.carretaDaLicenca.idTipoCarroceria) ||
            null
        )
        .map((id) => getTipoCarroceria(id))
        .filter((tp) => !tp?.auxiliar)
        .find((tp) => !!tp);
    };

    const possuiCarga = computed(() => {
      return !!buscaTipoCarroceriaValido(
        state.entity.conjuntoTransportador.length
      );
    });

    const blockedSelectionVeiculoTrator = (
      veiculoTrator: VeiculoTratorPayload,
      nuSeq: number
    ) => {
      if (!veiculoTrator) {
        return "Veiculo inválido";
      }
      const veiculoPosicao = state.entity.conjuntoTransportador.find(
        (v) => v.nuSeqVeiculo == nuSeq
      ) as VeiculoConjuntoTransportadorGabaritoPayload;
      if (
        veiculoPosicao.idTipoCarroceria &&
        veiculoTrator.idTipoCarroceria != veiculoPosicao.idTipoCarroceria
      ) {
        return (
          "O gabarito exige a carroceria do tipo " +
          getTipoCarroceria(
            veiculoPosicao.idTipoCarroceria
          )?.descricao?.toUpperCase()
        );
      }
      if (
        getTipoVeiculoTrator(veiculoTrator.flTipoVeiculoTrator)?.unidadeComCarga
      ) {
        const tipoCarroceriaOutrasPosicoes = buscaTipoCarroceriaValido(nuSeq);
        if (
          tipoCarroceriaOutrasPosicoes &&
          !getTipoCarroceria(veiculoTrator.idTipoCarroceria)?.auxiliar &&
          !(
            tipoCarroceriaOutrasPosicoes?.idTipoCarroceria ==
              veiculoTrator.idTipoCarroceria ||
            (
              tipoCarroceriaOutrasPosicoes?.carroceriasCompativeis ?? []
            ).indexOf(veiculoTrator.idTipoCarroceria ?? -1) >= 0
          )
        ) {
          return (
            "A carroceria deve estar entre os tipos " +
            ((tipoCarroceriaOutrasPosicoes?.carroceriasCompativeis ?? [])
              .map((id) => getTipoCarroceria(id)?.descricao?.toUpperCase())
              .join(",") ?? "VAZIO")
          );
        }
      }

      return false;
    };

    const blockedSelectionCarreta = (
      carreta: CarretaPayload,
      nuSeq: number
    ) => {
      if (!carreta) {
        return "Veiculo inválido";
      }

      const veiculoJaPresente = state.entity.conjuntoTransportador.find(
        (v) =>
          (v.nuSeqVeiculo != nuSeq &&
            v.carretaDaLicenca?.idCarreta == carreta.idCarreta) ||
          (v.rodiziosAssociadosDaLicenca &&
            v.rodiziosAssociadosDaLicenca.find(
              (r) => r.idCarreta == carreta.idCarreta
            ))
      );
      if (veiculoJaPresente) {
        return (
          "Carreta já selecionada na posição " +
          veiculoJaPresente.nuSeqVeiculo +
          (veiculoJaPresente.carretaDaLicenca?.idCarreta == carreta.idCarreta
            ? "."
            : " como um rodízio.")
        );
      }
      if (getTipoCarreta(carreta.flTipoCarreta)?.unidadeComCarga) {
        const tipoCarroceriaOutrasPosicoes = buscaTipoCarroceriaValido(nuSeq);
        if (
          tipoCarroceriaOutrasPosicoes &&
          !getTipoCarroceria(carreta.idTipoCarroceria)?.auxiliar &&
          !(
            tipoCarroceriaOutrasPosicoes?.idTipoCarroceria ==
              carreta.idTipoCarroceria ||
            (
              tipoCarroceriaOutrasPosicoes?.carroceriasCompativeis ?? []
            ).indexOf(carreta.idTipoCarroceria ?? -1) >= 0
          )
        ) {
          return (
            "A carroceria estar entre os tipos " +
            ((tipoCarroceriaOutrasPosicoes?.carroceriasCompativeis ?? [])
              .map((id) => getTipoCarroceria(id)?.descricao?.toUpperCase())
              .join(", ") ?? "VAZIO")
          );
        }
      }
      return false;
    };

    const blockedSelectionRodizio = (
      rodizio: CarretaPayload,
      nuSeq: number
    ) => {
      if (rodizio) {
        const veiculoJaPresente = state.entity.conjuntoTransportador.find(
          (v) =>
            v.carretaDaLicenca?.idCarreta == rodizio.idCarreta ||
            (v.nuSeqVeiculo != nuSeq &&
              v.rodiziosAssociadosDaLicenca &&
              v.rodiziosAssociadosDaLicenca.find(
                (r) => r.idCarreta == rodizio.idCarreta
              ))
        );

        if (veiculoJaPresente) {
          return (
            "Carreta já selecionada na posição " +
            veiculoJaPresente.nuSeqVeiculo +
            (veiculoJaPresente.carretaDaLicenca?.idCarreta == rodizio.idCarreta
              ? "."
              : " como um Rodízio.")
          );
        }

        const veiculoPosicao = state.entity.conjuntoTransportador
          .filter((v) => v.nuSeqVeiculo == nuSeq && v.carretaDaLicenca)
          .map((v) => v.carretaDaLicenca)
          .find((c) => !!c) as CarretaPayload;

        if (isChangeCarreta(rodizio, veiculoPosicao)) {
          return (
            "Para ser um rodízio da posição " +
            nuSeq +
            ", O veículo precisa ser do tipo " +
            getTipoCarreta(
              veiculoPosicao.flTipoCarreta
            )?.descricao?.toUpperCase() +
            ", com carroceria entre os tipos " +
            ((
              getTipoCarroceria(veiculoPosicao.idTipoCarroceria)
                ?.carroceriasCompativeis ?? []
            )
              .map((id) => getTipoCarroceria(id)?.descricao?.toUpperCase())
              .join(", ") ?? "VAZIO") +
            ", e ter " +
            veiculoPosicao.numeroEixos +
            " eixos."
          );
        }
      }

      return false;
    };

    const downloadVisualizacao = (newTab?: boolean) => {
      if (!state.entity.idLicenca) {
        return;
      }
      const { downloadVisualizacao, loading } = useLicencaService();
      state.loadings.push(loading);
      downloadVisualizacao(state.entity.idLicenca, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const confirmSelectionEngenheiro = (eng: EngenheiroPayload) => {
      if (eng) {
        state.entity.engenheiro = eng;
      } else {
        state.entity.engenheiro = useLicencaService().safeLicenca().engenheiro;
      }
    };

    const clearVisualizacao = () => {
      if (state.visualizacaoUrl) {
        state.visualizacaoUrl.destroy();
        state.visualizacaoUrl = null;
      }
    };

    const loadVisualizacao = () => {
      if (state.entity.idLicenca) {
        clearVisualizacao();
        const { downloadVisualizacaoExibicao } = useLicencaService();
        downloadVisualizacaoExibicao(state.entity.idLicenca).then((ret) => {
          if (ret) {
            state.visualizacaoUrl = ret;
          }
        });
      }
    };

    watch([refreshDmlToken], () => {
      const token = state.visualizacaoUrl?.url;
      waitUntilStable(
        refreshDmlToken.value,
        () => {
          return refreshDmlToken.value;
        },
        () => {
          const currToken = state.visualizacaoUrl?.url;
          if (token == currToken) {
            loadVisualizacao();
          }
        },
        500
      );
    });

    const deleteTempFiles = () => {
      if (
        state.entity.notaFiscalCarga &&
        state.entity.notaFiscalCarga.keyBlDocumentoTemp
      ) {
        useStorage().deleteTempFile(
          state.entity.notaFiscalCarga.keyBlDocumentoTemp
        );
        state.entity.notaFiscalCarga.keyBlDocumentoTemp = null;
      }
    };

    const save = () => {
      const idLicencaProps = props.idLicenca ?? 0;
      const isInsert = !idLicencaProps;
      const {
        saveNewLicenca,
        updateLicenca,
        loading: loadingCadastro,
        data: dataCadastro
      } = useLicencaService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewLicenca(state.entity)
        : updateLicenca(idLicencaProps, state.entity)
      )
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          setEntity(dataCadastro.value);
          state.savedEntity = true;
          useToast().success("Licença salva com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idLicenca);
          }
        })
        .catch(() => {
          deleteTempFiles();
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const gerarCopia = () => {
      emit("generate-copy", state.entity.idLicenca);
    };

    const processFile = (fileElementId: string) => {
      return new Promise<string>((resolve) => {
        const file = document.querySelector(
          "#" + fileElementId
        ) as HTMLInputElement;
        if (!file || !file.files || file.files.length === 0) {
          resolve("");
        } else {
          const formData = new FormData();
          formData.append("file", file.files[0]);
          const storage = useStorage();
          state.loadings.push(storage.loading);
          storage
            .uploadTempFile(formData)
            .then((key: string) => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
              resolve(key);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            });
        }
      });
    };

    const submitSave = () => {
      const arrayPromises = new Array<Promise<string>>();
      arrayPromises.push(
        processFile(state.stateId + "_notaFiscalCarga").then((key) => {
          state.entity.notaFiscalCarga = state.entity.notaFiscalCarga ?? {};
          return (state.entity.notaFiscalCarga.keyBlDocumentoTemp = key);
        })
      );

      Promise.all(arrayPromises).then(
        () => {
          save();
        },
        () => {
          deleteTempFiles();
        }
      );
    };

    onMounted(async () => {
      const {
        getLicenca,
        newLicenca,
        copyLicenca,
        data,
        loading
      } = useLicencaService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }

      (props.idLicencaCopy
        ? copyLicenca(props.idLicencaCopy)
        : props.idLicenca
        ? getLicenca(props.idLicenca)
        : newLicenca(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;

          if (props.idLicenca) {
            loadVisualizacao();
          }

          processaGabaritosVeiculo().then(() => {
            if (
              !state.entity.idLicenca &&
              state.entity.conjuntoTransportador?.length == 0
            ) {
              addVeiculoTrator();
            }
          });
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    onUnmounted(async () => {
      clearVisualizacao();
      clearGabaritoImagem();
    });

    watch([gabaritosFiltrados], () => {
      if (state.gabaritosLoaded) {
        let idGabarito = state.entity.idGabarito;
        if (state.gabaritosPermitidos.length == 1) {
          idGabarito = state.gabaritosPermitidos[0].idGabarito;
        } else if (
          idGabarito &&
          state.gabaritosPermitidos
            .map((gp) => gp.idGabarito)
            .indexOf(idGabarito) < 0
        ) {
          idGabarito = null;
        }

        if (idGabarito != state.entity.idGabarito) {
          state.entity.idGabarito = idGabarito;
        }
      }
    });

    watch([composicaoEixos], () => {
      if (!possuiVeiculoVazio.value) {
        processaGabaritosVeiculo();
      }
    });

    watch([gabaritoPermitidoSelecionado, gabaritoSelecionado], () => {
      const gp = gabaritoPermitidoSelecionado.value;
      const gs = gabaritoSelecionado.value;
      if (gp && gs && gp.idGabarito == gs.idGabarito) {
        loadGabaritoImagem();
        const conjunto = gp.conjuntoTransportador ?? gs.conjuntoTransportador;
        if (conjunto && conjunto.length) {
          const conjuntoAplicado: Array<VeiculoConjuntoTransportadorGabaritoPayload> = JSON.parse(
            JSON.stringify(conjunto)
          );
          let tipoCargaCarrocerias;
          for (let i = 0; i < conjuntoAplicado.length; i++) {
            const veiculoConjunto = conjuntoAplicado[i];
            const veiculoLicenca = state.entity.conjuntoTransportador[i];
            if (!veiculoLicenca.conjuntosEixosVeiculo) {
              veiculoLicenca.conjuntosEixosVeiculo = [];
            }
            copyObject(clearObject(veiculoConjunto), veiculoLicenca, [
              "veiculoTratorDaLicenca",
              "carretaDaLicenca",
              "rodiziosAssociadosDaLicenca",
              "conjuntosEixosVeiculo"
            ]);
            if (!tipoCargaCarrocerias && veiculoLicenca) {
              tipoCargaCarrocerias = getTipoCarroceria(
                veiculoLicenca.veiculoTratorDaLicenca?.idTipoCarroceria ||
                  veiculoLicenca.carretaDaLicenca?.idTipoCarroceria
              )?.tipoCargaPadrao;
            }

            ///////////////////
            const totalEixos = veiculoConjunto.conjuntosEixosVeiculo
              .map((el) => el.eixos.length)
              .reduce((tot, val) => tot + val, 0);

            let conjuntoAtual = null;
            const conjuntosVeiculo = [];
            let indiceConjuntoAtualConjunto = 0;
            let indiceConjuntoAtualLicenca = 0;
            let indiceEixoConjuntoAtualConjunto = 0;
            let indiceEixoConjuntoAtualLicenca = 0;

            for (let j = 0; j < totalEixos; j++) {
              let conjuntoEixoVeiculoConjunto =
                veiculoConjunto.conjuntosEixosVeiculo[
                  indiceConjuntoAtualConjunto
                ];

              if (
                indiceEixoConjuntoAtualConjunto >=
                conjuntoEixoVeiculoConjunto.eixos.length
              ) {
                indiceConjuntoAtualConjunto++;
                conjuntoEixoVeiculoConjunto =
                  veiculoConjunto.conjuntosEixosVeiculo[
                    indiceConjuntoAtualConjunto
                  ];
                indiceEixoConjuntoAtualConjunto = 0;
                conjuntoAtual = null;
              }

              let conjuntoEixoVeiculoLicenca =
                veiculoLicenca.conjuntosEixosVeiculo[
                  indiceConjuntoAtualLicenca
                ];
              if (conjuntoEixoVeiculoLicenca) {
                if (
                  indiceEixoConjuntoAtualLicenca >=
                  conjuntoEixoVeiculoLicenca.eixos.length
                ) {
                  indiceConjuntoAtualLicenca++;
                  conjuntoEixoVeiculoLicenca =
                    veiculoLicenca.conjuntosEixosVeiculo[
                      indiceConjuntoAtualLicenca
                    ];
                  indiceEixoConjuntoAtualLicenca = 0;
                }
              }

              if (conjuntoAtual == null) {
                conjuntoAtual = copyObject(
                  clearObject(cloneObject(conjuntoEixoVeiculoConjunto)),
                  {},
                  ["eixos", "distanciaConjuntoEixoAnterior"]
                );
                conjuntoAtual.eixos = [];
                conjuntosVeiculo.push(conjuntoAtual);

                if (
                  conjuntoEixoVeiculoLicenca &&
                  conjuntoEixoVeiculoLicenca.pesoConjuntoEixos
                ) {
                  const pesoMaximoConjunto =
                    conjuntoAtual.pesoMaximoConjuntoEixos ?? 50;
                  const pesoAtualConjuntoLicenca =
                    conjuntoEixoVeiculoLicenca.pesoConjuntoEixos ?? 0;
                  if (pesoMaximoConjunto < pesoAtualConjuntoLicenca) {
                    conjuntoAtual.pesoConjuntoEixos =
                      conjuntoAtual.pesoRecomendadoConjuntoEixos;
                  } else if (pesoAtualConjuntoLicenca) {
                    conjuntoAtual.pesoConjuntoEixos = pesoAtualConjuntoLicenca;
                  }
                } else {
                  conjuntoAtual.pesoConjuntoEixos =
                    conjuntoAtual.pesoRecomendadoConjuntoEixos;
                }

                if (!conjuntoAtual.pesoConjuntoEixos) {
                  conjuntoAtual.pesoConjuntoEixos =
                    conjuntoAtual.pesoRecomendadoConjuntoEixos;
                }
              }

              const currEixo = clearObject(
                cloneObject(
                  conjuntoEixoVeiculoConjunto.eixos[
                    indiceEixoConjuntoAtualConjunto
                  ]
                )
              );

              const currEixoLicenca = conjuntoEixoVeiculoLicenca
                ? conjuntoEixoVeiculoLicenca.eixos[
                    indiceEixoConjuntoAtualLicenca
                  ]
                : null;
              if (currEixoLicenca) {
                if (indiceEixoConjuntoAtualConjunto == 0) {
                  if (indiceEixoConjuntoAtualLicenca > 0) {
                    conjuntoAtual.distanciaConjuntoEixoAnterior =
                      currEixoLicenca.distanciaEixoAnterior;
                  } else {
                    conjuntoAtual.distanciaConjuntoEixoAnterior =
                      conjuntoEixoVeiculoLicenca.distanciaConjuntoEixoAnterior;
                  }
                } else if (indiceEixoConjuntoAtualLicenca == 0) {
                  currEixo.distanciaEixoAnterior =
                    conjuntoEixoVeiculoLicenca.distanciaConjuntoEixoAnterior;
                } else {
                  currEixo.distanciaEixoAnterior =
                    currEixoLicenca.distanciaEixoAnterior;
                }

                if (currEixoLicenca.rodas) {
                  currEixo.rodas = currEixoLicenca.rodas;
                }
              }
              conjuntoAtual.eixos.push(currEixo);

              indiceEixoConjuntoAtualConjunto++;
              indiceEixoConjuntoAtualLicenca++;
            }

            veiculoLicenca.conjuntosEixosVeiculo = conjuntosVeiculo;

            /////////////
            /*
            for (
              let j = 0;
              j < veiculoConjunto.conjuntosEixosVeiculo.length;
              j++
            ) {
              const conjuntoEixoVeiculoConjunto =
                veiculoConjunto.conjuntosEixosVeiculo[j];
              if (veiculoLicenca.conjuntosEixosVeiculo.length <= j) {
                veiculoLicenca.conjuntosEixosVeiculo.push({ eixos: [] });
              }
              const conjuntoEixoVeiculoLicenca =
                veiculoLicenca.conjuntosEixosVeiculo[j];
              copyObject(
                clearObject(conjuntoEixoVeiculoConjunto),
                conjuntoEixoVeiculoLicenca,
                ["eixos"]
              );
              if (!conjuntoEixoVeiculoLicenca.pesoConjuntoEixos) {
                conjuntoEixoVeiculoLicenca.pesoConjuntoEixos =
                  conjuntoEixoVeiculoConjunto.pesoRecomendadoConjuntoEixos;
              }
              for (
                let k = 0;
                k < conjuntoEixoVeiculoConjunto.eixos.length;
                k++
              ) {
                const eixoConjunto = conjuntoEixoVeiculoConjunto.eixos[k];
                if (conjuntoEixoVeiculoLicenca.eixos.length <= k) {
                  conjuntoEixoVeiculoLicenca.eixos.push({});
                }
                const eixoLicenca = conjuntoEixoVeiculoLicenca.eixos[k];
                copyObject(clearObject(eixoConjunto), eixoLicenca, ["rodas"]);
                if (!eixoLicenca.rodas) {
                  eixoLicenca.rodas = eixoConjunto.rodas;
                }
              }
              if (
                conjuntoEixoVeiculoConjunto.eixos.length <
                conjuntoEixoVeiculoLicenca.eixos.length
              ) {
                const diff =
                  conjuntoEixoVeiculoLicenca.eixos.length -
                  conjuntoEixoVeiculoConjunto.eixos.length;
                conjuntoEixoVeiculoLicenca.eixos.splice(diff * -1, diff);
              }
            }



            if (
              veiculoConjunto.conjuntosEixosVeiculo.length <
              veiculoLicenca.conjuntosEixosVeiculo.length
            ) {
              const diff =
                veiculoLicenca.conjuntosEixosVeiculo.length -
                veiculoConjunto.conjuntosEixosVeiculo.length;
              veiculoLicenca.conjuntosEixosVeiculo.splice(diff * -1, diff);
            }
            */
            ///////////////////
          }
          if (
            conjuntoAplicado.length < state.entity.conjuntoTransportador.length
          ) {
            const diff =
              state.entity.conjuntoTransportador.length -
              conjuntoAplicado.length;
            state.entity.conjuntoTransportador.splice(diff * -1, diff);
          }
          const tiposPadrao = useGabaritoService()
            .getGabaritosAtivos()
            .map((g) => g.tipoCarga)
            .filter((e) => !!e);
          if (tipoCargaCarrocerias) {
            tiposPadrao.push(tipoCargaCarrocerias);
          }
          if (
            !state.entity.tipoCarga ||
            tiposPadrao.indexOf(state.entity.tipoCarga) >= 0
          ) {
            state.entity.tipoCarga = tipoCargaCarrocerias
              ? tipoCargaCarrocerias
              : gs.tipoCarga;
          }
        }
      }
    });

    return {
      submitSave,
      gerarCopia,
      closeScreen,
      amIAllowed,
      amIBlocked,
      formatNumber,
      confirmSelectionCliente,
      stateLoading,
      stateBlocked,
      stateFullBlocked,
      composicaoEixos,
      addVeiculoTrator,
      addCarreta,
      removeVeiculoConjunto,
      blockedSelectionVeiculoTrator,
      blockedSelectionCarreta,
      blockedSelectionRodizio,
      confirmSelectionVeiculoTrator,
      confirmSelectionCarreta,
      confirmSelectionEngenheiro,
      confirmSelectionTransportador,
      confirmSelectionRodizios,
      getTipoVeiculoTrator,
      getTipoCarreta,
      getTipoCarroceria,
      downloadNotaFiscalCarga,
      promoteRodizio,
      moveCarretaAntes,
      moveCarretaDepois,
      downloadVisualizacao,
      gabaritosFiltrados,
      gabaritoSelecionado,
      gabaritoPermitidoSelecionado,
      afterSetGabarito,
      possuiVeiculoVazio,
      pesoSomadoEixos,
      possuiCarreta,
      possuiCarga,
      totalAcopladas,
      veiculosVisuals,
      balancoTraseiro,
      balancoTraseiroMax,
      onloadGabaritoImage,
      getPositionReferenteImagem,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorCarreta,
    EntitySelectorMultiCarreta,
    EntitySelectorVeiculoTrator,
    EntitySelectorEngenheiro,
    EntitySelectorTransportador,
    EntitySelectorGabarito,
    CrudLicencaOrgao
  },
  props: {
    idLicenca: Number,
    idLicencaCopy: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
