import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalizedLoaded
} from "vue-router";
import { watch } from "vue";
import Home from "../views/Home.vue";
import Engenheiro from "../views/engenheiro/Engenheiro.vue";
import Dashboard from "../views/cliente/Dashboard.vue";
import Cliente from "../views/cliente/Cliente.vue";
import ClienteAdmin from "../views/cliente/ClienteAdmin.vue";
import Tenant from "../views/cliente/Tenant.vue";
import Transportador from "../views/transportador/Transportador.vue";
import PlanilhaTrechos from "../views/trecho/PlanilhaTrechos.vue";
import ModeloVeiculoTrator from "../views/veiculo/ModeloVeiculoTrator.vue";
import Carreta from "../views/veiculo/Carreta.vue";
import Licenca from "../views/licenca/Licenca.vue";
import CombinacaoVeiculo from "../views/licenca/CombinacaoVeiculo.vue";
import Faturamento from "../views/licenca/Faturamento.vue";
import VeiculoTrator from "../views/veiculo/VeiculoTrator.vue";
import ImportacaoVeiculo from "../views/veiculo/ImportacaoVeiculo.vue";
import Login from "../views/auth/Login.vue";
import Logout from "../views/auth/Logout.vue";
import ChangeMyPassword from "../views/auth/ChangeMyPassword.vue";
import ChangePasswordWithToken from "../views/auth/ChangePasswordWithToken.vue";
import RequestRecover from "../views/auth/RequestRecover.vue";
import { useAuth } from "../modules/auth";
import { useToast } from "../modules/message";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      authenticated: true
    },
    component: Home
  },
  {
    path: "/me/password",
    name: "me-change-password",
    meta: {
      authenticated: true
    },
    component: ChangeMyPassword
  },
  {
    path: "/root/dashboard",
    name: "root-dashboard",
    component: Dashboard
  },
  {
    path: "/root/cliente",
    name: "root-cliente",
    component: Cliente
  },
  {
    path: "/root/cliente/:idCliente",
    name: "root-cliente-edit",
    meta: {
      securityKey: "root-cliente",
      authority: "edit"
    },
    component: Cliente,
    props(route) {
      const idCliente: number = +route.params.idCliente;
      return { idCliente: idCliente };
    }
  },
  {
    path: "/root/tenant",
    name: "root-tenant",
    component: Tenant
  },
  {
    path: "/admin/cliente",
    name: "admin-cliente",
    component: ClienteAdmin
  },
  {
    path: "/usuario",
    name: "usuario",
    component: Home
  },
  {
    path: "/veiculo/trator/modelo",
    name: "veiculo-trator-modelo",
    component: ModeloVeiculoTrator
  },
  {
    path: "/veiculo/trator/modelo/:idModeloVeiculoTrator",
    name: "veiculo-trator-modelo-edit",
    meta: {
      securityKey: "veiculo-trator-modelo",
      authority: "edit"
    },
    component: ModeloVeiculoTrator,
    props(route) {
      const idModeloVeiculoTrator: number = +route.params.idModeloVeiculoTrator;
      return { idModeloVeiculoTrator: idModeloVeiculoTrator };
    }
  },
  {
    path: "/veiculo/trator/modelo/novo",
    name: "veiculo-trator-modelo-new",
    meta: {
      securityKey: "veiculo-trator-modelo",
      authority: "create"
    },
    component: ModeloVeiculoTrator,
    props: {
      isNew: true
    }
  },
  {
    path: "/veiculo/trator/modelo/copia/:idModeloVeiculoTratorCopy",
    name: "veiculo-trator-modelo-copy",
    meta: {
      securityKey: "veiculo-trator-modelo",
      authority: "create"
    },
    component: ModeloVeiculoTrator,
    props(route) {
      const idModeloVeiculoTratorCopy: number = +route.params
        .idModeloVeiculoTratorCopy;
      return {
        isNew: true,
        idModeloVeiculoTratorCopy: idModeloVeiculoTratorCopy
      };
    }
  },
  {
    path: "/veiculo/trator",
    name: "veiculo-trator",
    component: VeiculoTrator
  },
  {
    path: "/veiculo/trator/:idVeiculoTrator",
    name: "veiculo-trator-edit",
    meta: {
      securityKey: "veiculo-trator",
      authority: "edit"
    },
    component: VeiculoTrator,
    props(route) {
      const idVeiculoTrator: number = +route.params.idVeiculoTrator;
      return { idVeiculoTrator: idVeiculoTrator };
    }
  },
  {
    path: "/veiculo/trator/novo",
    name: "veiculo-trator-new",
    meta: {
      securityKey: "veiculo-trator",
      authority: "create"
    },
    component: VeiculoTrator,
    props: {
      isNew: true
    }
  },
  {
    path: "/veiculo/carreta",
    name: "carreta",
    component: Carreta
  },
  {
    path: "/veiculo/carreta/:idCarreta",
    name: "carreta-edit",
    meta: {
      securityKey: "carreta",
      authority: "edit"
    },
    component: Carreta,
    props(route) {
      const idCarreta: number = +route.params.idCarreta;
      return { idCarreta: idCarreta };
    }
  },
  {
    path: "/veiculo/carreta/novo",
    name: "carreta-new",
    meta: {
      securityKey: "carreta",
      authority: "create"
    },
    component: Carreta,
    props: {
      isNew: true
    }
  },
  {
    path: "/veiculo/importacao-lote",
    name: "veiculo-importacao-lote",
    component: ImportacaoVeiculo
  },
  {
    path: "/transportador",
    name: "transportador",
    component: Transportador
  },
  {
    path: "/transportador/:idTransportador",
    name: "transportador-edit",
    meta: {
      securityKey: "transportador",
      authority: "edit"
    },
    component: Transportador,
    props(route) {
      const idTransportador: number = +route.params.idTransportador;
      return { idTransportador: idTransportador };
    }
  },
  {
    path: "/transportador/novo",
    name: "transportador-new",
    meta: {
      securityKey: "transportador",
      authority: "create"
    },
    component: Transportador,
    props: {
      isNew: true
    }
  },
  {
    path: "/engenheiro",
    name: "engenheiro",
    component: Engenheiro
  },
  {
    path: "/engenheiro/novo",
    name: "engenheiro-new",
    meta: {
      securityKey: "engenheiro",
      authority: "create"
    },
    component: Engenheiro,
    props: {
      isNew: true
    }
  },
  {
    path: "/engenheiro/:idEngenheiro",
    name: "engenheiro-edit",
    meta: {
      securityKey: "engenheiro",
      authority: "edit"
    },
    component: Engenheiro,
    props(route) {
      const idEngenheiro: number = +route.params.idEngenheiro;
      return { idEngenheiro: idEngenheiro };
    }
  },
  {
    path: "/licenca",
    name: "licenca",
    component: Licenca
  },
  {
    path: "/licenca/novo",
    name: "licenca-new",
    meta: {
      securityKey: "licenca",
      authority: "create"
    },
    component: Licenca,
    props: {
      isNew: true
    }
  },
  {
    path: "/licenca/:idLicenca",
    name: "licenca-edit",
    meta: {
      securityKey: "licenca",
      authority: "edit"
    },
    component: Licenca,
    props(route) {
      const idLicenca: number = +route.params.idLicenca;
      return { idLicenca: idLicenca };
    }
  },
  {
    path: "/licenca/copia/:idLicencaCopy",
    name: "licenca-copy",
    meta: {
      securityKey: "licenca",
      authority: "create"
    },
    component: Licenca,
    props(route) {
      const idLicencaCopy: number = +route.params.idLicencaCopy;
      return {
        isNew: true,
        idLicencaCopy: idLicencaCopy
      };
    }
  },
  {
    path: "/licenca/combinacao-veiculos",
    name: "licenca-combinacao-veiculos",
    component: CombinacaoVeiculo
  },
  {
    path: "/trechos/planilha",
    name: "trechos-planilha",
    component: PlanilhaTrechos
  },
  {
    path: "/trechos/planilha/novo",
    name: "trechos-planilha-new",
    meta: {
      securityKey: "trechos-planilha",
      authority: "create"
    },
    component: PlanilhaTrechos,
    props: {
      isNew: true
    }
  },
  {
    path: "/trechos/planilha/:idPlanilhaTrechos",
    name: "trechos-planilha-edit",
    meta: {
      securityKey: "trechos-planilha",
      authority: "edit"
    },
    component: PlanilhaTrechos,
    props(route) {
      const idPlanilhaTrechos: number = +route.params.idPlanilhaTrechos;
      return { idPlanilhaTrechos: idPlanilhaTrechos };
    }
  },
  {
    path: "/trechos/planilha/copia/:idPlanilhaTrechosCopy",
    name: "trechos-planilha-copy",
    meta: {
      securityKey: "trechos-planilha",
      authority: "create"
    },
    component: PlanilhaTrechos,
    props(route) {
      const idPlanilhaTrechosCopy: number = +route.params.idPlanilhaTrechosCopy;
      return {
        isNew: true,
        idPlanilhaTrechosCopy: idPlanilhaTrechosCopy
      };
    }
  },
  {
    path: "/relatorio/faturamento",
    name: "relatorio-faturamento",
    component: Faturamento
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { open: true }
  },
  {
    path: "/login/recover/request",
    name: "recover-request",
    component: RequestRecover,
    meta: { open: true }
  },
  {
    path: "/login/recover/:token",
    name: "recover",
    component: ChangePasswordWithToken,
    meta: { open: true },
    props(route) {
      const token: string = Array.isArray(route.params.token)
        ? route.params.token[0]
        : route.params.token;
      return {
        token: token,
        withToken: true
      };
    }
  },
  {
    path: "/login/new/:token",
    name: "login-new",
    component: ChangePasswordWithToken,
    meta: { open: true },
    props(route) {
      const token: string = Array.isArray(route.params.token)
        ? route.params.token[0]
        : route.params.token;
      return {
        token: token
      };
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authenticated: true
    },
    component: Logout
  },
  {
    path: "/*",
    redirect: "/",
    meta: {
      open: true
    }
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const { authenticating, user, reloadUser } = useAuth();

router.beforeEach((to, from, next) => {
  const processRoutes = () => {
    if (!to.meta.open) {
      if (user?.value) {
        const authority: string =
          to.meta.authority && typeof to.meta.authority == "string"
            ? to.meta.authority
            : "view";
        const key: string | undefined =
          to.meta.securityKey && typeof to.meta.securityKey == "string"
            ? to.meta.securityKey
            : to.name?.toString();

        if (!to.meta.authenticated && !useAuth().amIAllowed(authority, key)) {
          useToast().warning("Acesso invalido, redirecionando para home.");
          next({ name: "home" });
        } else if (to.name === "home") {
          if (useAuth().amIAllowed("view", "root-dashboard")) {
            next({ name: "root-dashboard" });
          } else {
            next({ name: "licenca" });
          }
        } else {
          const currUser = user.value.idUsuario;
          reloadUser().then((user) => {
            if (currUser !== user.idUsuario) {
              useToast().warning(
                "Detectada alteração de usuário, redirecionando para home."
              );
              next({ name: "home" });
            } else if (!user.termosUso && to.name !== "admin-cliente") {
              //Redirecionado para finalizar dados cadastrais
              next({ name: "admin-cliente" });
            } else {
              next();
            }
          });
        }
      } else if (authenticating.value === false) {
        useToast().info("Realize o login para acessar o sistema.");
        next({ name: "login" });
      } else {
        next();
      }
    } else {
      next();
    }
  };

  if (authenticating.value === false) {
    processRoutes();
  } else {
    const unwatch = watch([authenticating], () => {
      if (authenticating.value === false) {
        unwatch();
        processRoutes();
      }
    });
  }
});

reloadUser();

export default router;

export interface MenuEvent {
  originalEvent?: PointerEvent;
  item?: MenuModel;
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface MenuModel {
  label?: string;
  icon?: string;
  separator?: boolean;
  items?: Array<MenuModel>;
  to?: Partial<RouteLocationNormalizedLoaded>;
  command?: (event?: MenuEvent) => any;
  disabled?: boolean;
}
