<template>
  <div @click="stateReady && openTab()">
    <i class="fa-solid fa-solar-panel fa-lg" />

    <container
      :isModal="true"
      @close-modal="closeTab()"
      :loading="stateLoading || !stateReady"
      v-if="isOpen"
    >
      <template v-slot:header>
        <h3>Painel</h3>
      </template>
      <template v-slot:body>
        <div>
          <form @submit.prevent="submitSearch">
            <entity-selector-cliente
              :cliente="cliente"
              :canEdit="!cliente"
              :idClienteFiltered="filter.idCliente"
              @confirm-selection="confirmSelectionCliente"
              :parentLoading="stateLoading || showEdit"
            />
          </form>
        </div>
        <search-result
          :showSelector="false"
          :results="dadosOrgaos"
          :loading="stateLoading"
          :hidePagination="true"
          v-if="dadosOrgaos && dadosOrgaos.length > 0"
        >
          <template v-slot:columns>
            <Column
              field="idOrgao"
              header="Órgão/Situação"
              headerClass="p-header-center"
              style="width: 9rem; text-align: center"
            >
              <template #body="slotProps">
                <Button @click.stop="filterBy()" class="p-button-link">
                  <orgao-aet-img
                    :idOrgao="slotProps.data.idOrgao"
                    :click="slotProps.data.idOrgao == 1 ? filterBy : null"
                  />
                </Button>
              </template>
            </Column>

            <Column
              v-for="situacao of situacoesDisponiveis"
              :key="situacao.flag"
              style="text-align: center"
            >
              <template #header>
                <div class="p-col-12">
                  <Button
                    class="p-button-link"
                    @click="filterBy()"
                    :label="situacao.descricao"
                  />
                </div>
              </template>
              <template #body="slotProps">
                <Button
                  class="p-button-link"
                  @click="filterBy()"
                  v-if="
                    slotProps.data.situacoesLicencas &&
                      slotProps.data.situacoesLicencas[situacao.flag]
                  "
                  :label="slotProps.data.situacoesLicencas[situacao.flag]"
                />
                <span v-else v-text="0" />
              </template>
            </Column>
          </template>
          <template v-slot:actions>
            <button-group v-if="amIAllowed('edit', 'root-robo')">
              <Button
                class="p-button-rounded p-button-success"
                @click="stopConsulta"
                label="Parar Consulta"
              />
              <Button
                class="p-button-rounded p-button-danger"
                @click="startConsulta"
                label="Iniciar Consulta"
              />
              <Button
                class="p-button-rounded p-button-success"
                @click="stopCadastro"
                label="Parar Cadastro"
              />
              <Button
                class="p-button-rounded p-button-danger"
                @click="startCadastro"
                label="Iniciar Cadastro"
              />
            </button-group>
          </template>
        </search-result>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  onUnmounted,
  computed,
  Ref
} from "vue";
import { useStatusService } from "../../modules/business/status/statusBusiness";
import {
  usePainelService,
  PainelRoboPayload,
  PainelRoboFilterPayload
} from "../../modules/business/painel/painelBusiness";
import { useAuth, PermissionPayload } from "../../modules/auth";

interface PainelState {
  stateReady: boolean;
  isOpen: boolean;
  loadings: Array<Ref<boolean>>;
  stateId: string;
  interval?: number | null;
  filter: PainelRoboFilterPayload;
  dadosOrgaos: Array<PainelRoboPayload>;
}

export default defineComponent({
  setup() {
    const state = reactive<PainelState>({
      stateReady: false,
      isOpen: false,
      filter: {},
      dadosOrgaos: [],
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const situacoesDisponiveis = computed(() => {
      return useStatusService()
        .getStatusesOrgaoGovPass()
        .filter((sit) =>
          state.dadosOrgaos.map((d) =>
            Object.keys(d.situacoesLicencas).some((k) => sit.flag == k)
          )
        );
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const openTab = () => {
      if (!state.isOpen) {
        state.isOpen = true;
      }
    };

    const closeTab = () => {
      state.isOpen = false;
    };

    const submitSearch = () => {
      const { getPainelRobo, data, loading } = usePainelService();
      state.loadings.push(loading);
      return getPainelRobo(state.filter)
        .then(() => {
          state.dadosOrgaos = data.value;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const filterBy = () => {
      console.log("filter");
    };

    const stopCadastro = () => {
      console.log("stopCadastro");
    };

    const stopConsulta = () => {
      console.log("stopConsulta");
    };

    const startCadastro = () => {
      console.log("startCadastro");
    };

    const startConsulta = () => {
      console.log("startConsulta");
    };

    onMounted(async () => {
      submitSearch().then(() => {
        state.interval = setInterval(submitSearch, 3 * 60 * 1000);
        state.stateReady = true;
      });
    });

    onUnmounted(() => {
      if (state.interval) {
        clearInterval(state.interval);
        state.interval = null;
      }
    });

    return {
      stateLoading,
      situacoesDisponiveis,
      amIAllowed,
      filterBy,
      stopCadastro,
      stopConsulta,
      startCadastro,
      startConsulta,
      openTab,
      closeTab,
      ...toRefs(state)
    };
  },
  components: {
    //Sidebar
  }
});
</script>
