<template>
  <div v-if="stateReady" class="p-d-flex">
    <div class="p-col-fixed p-pl-0" style="width: 7rem">
      <entity-selector-uf
        :idUnidadeFederal="idUnidadeFederalInterno"
        :required="required"
        @after-set-uf="afterSetUF"
      />
    </div>
    <div class="p-col p-pr-0">
      <input-with-label
        :label="label"
        :required="required"
        :addRequiredHiddenField="true"
        :hasValue="!!municipioInterno?.idMunicipio"
        v-slot="l"
      >
        <Dropdown
          v-model="municipioInterno"
          :options="municipiosFiltrados"
          optionLabel="nome"
          :filter="true"
          filterPlaceholder="Procure Pelo Nome"
          :showClear="true"
          :id="l.id"
          :required="l.required"
          :placeholder="'&nbsp;'"
          :disabled="readOnly"
        >
          <template #option="slotProps">
            {{ slotProps.option.nome }}
          </template>
          <template #value="slotProps">
            <div v-if="slotProps.value">
              <span> {{ slotProps.value.nome }}</span>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
        </Dropdown>
      </input-with-label>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useMunicipioService,
  MunicipioPayload
} from "../../modules/business/common/commonBusiness";
import EntitySelectorUf from "./EntitySelectorUf.vue";

interface MunicipioSelectorState {
  stateReady: boolean;
  municipioInterno?: MunicipioPayload | null;
  idUnidadeFederalInterno?: number | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<MunicipioSelectorState>({
      stateReady: false,
      municipioInterno: useMunicipioService().getMunicipio(
        props.idUnidadeFederal,
        props.idMunicipio
      ),
      idUnidadeFederalInterno: props.idUnidadeFederal
    });

    watch([props], () => {
      if (props.idUnidadeFederal != state.idUnidadeFederalInterno) {
        state.idUnidadeFederalInterno = props.idUnidadeFederal;
      }
      if (props.idMunicipio != state.municipioInterno?.idMunicipio) {
        state.municipioInterno = useMunicipioService().getMunicipio(
          props.idMunicipio
        );
      }
    });

    watch([state], () => {
      const idUnidadeFederal = state.idUnidadeFederalInterno ?? null;
      if (!idUnidadeFederal || props.idUnidadeFederal !== idUnidadeFederal) {
        state.municipioInterno = null;
      }
      if (props.idUnidadeFederal !== idUnidadeFederal) {
        emit("after-set-municipio", idUnidadeFederal, null);
      } else {
        const idMunicipio = state.municipioInterno?.idMunicipio ?? null;
        if (props.idMunicipio !== idMunicipio) {
          emit("after-set-municipio", idUnidadeFederal, idMunicipio);
        }
      }
    });

    const afterSetUF = (idUnidadeFederal?: number | null) => {
      if (idUnidadeFederal !== state.idUnidadeFederalInterno) {
        state.idUnidadeFederalInterno = idUnidadeFederal;
      }
    };

    const municipiosFiltrados = computed(() => {
      if (state.idUnidadeFederalInterno) {
        return useMunicipioService().getMunicipios(
          state.idUnidadeFederalInterno
        );
      } else {
        return [];
      }
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      municipiosFiltrados,
      afterSetUF,
      ...toRefs(state)
    };
  },
  props: {
    required: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    idMunicipio: {
      type: Number
    },
    idUnidadeFederal: {
      type: Number
    },
    label: {
      type: String,
      default: "Município"
    }
  },
  components: {
    EntitySelectorUf
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
