
import {
  defineComponent,
  toRefs,
  ref,
  Ref,
  reactive,
  onMounted,
  PropType,
  computed,
  watch
} from "vue";
import CrudCarreta from "./CrudCarreta.vue";
import {
  CarretaPayload,
  useCarretaService
} from "../../../modules/business/veiculo/carretaBusiness";
import {
  format,
  isArrayEquals,
  AutoCompleteInputEvent,
  waitUntilStable,
  addSetElement
} from "../../../modules/utils";

import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";

interface EntitySelectorMultiCarretaState {
  stateReady: boolean;
  crudOpen: boolean;
  idCarretaToView?: number | null;
  entitiesToSelect: Array<CarretaPayload>;
  carretas?: Array<CarretaPayload> | null;
  carretasAutocomplete: Array<CarretaPayload>;
  lastSearchedQuery?: string | null;
  autocompleteSearchRunning: boolean;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EntitySelectorMultiCarretaState>({
      stateReady: false,
      crudOpen: false,
      idCarretaToView: null,
      carretasAutocomplete: [],
      entitiesToSelect: [],
      autocompleteSearchRunning: false
    });

    const es = ref();
    const carretaAutoComplete = ref() as Ref<CarretaPayload | null | string>;

    const preFilter = computed(() => {
      const pf = useCarretaService().safeFilterCarreta();
      if (
        typeof carretaAutoComplete.value === "string" &&
        carretaAutoComplete.value.trim().length > 0
      ) {
        pf.placaNf = carretaAutoComplete.value;
      }
      return pf;
    });

    const filteredCarretas = computed(() => {
      return state.crudOpen
        ? []
        : state.carretasAutocomplete.filter(
            (c) => !props.blockedSelection || !props.blockedSelection(c)
          );
    });

    watch([props], () => {
      if (!props.carretas || !props.carretas.length) {
        if (
          !isArrayEquals(
            (props.idCarretasFiltered &&
              props.idCarretasFiltered.map((c) => c)) ||
              [],
            (state.carretas && state.carretas.map((c) => c.idCarreta)) ?? []
          )
        ) {
          state.carretas = null;
        }
      }
    });

    const entitiesSelected = computed(() => {
      return props.carretas
        ? props.carretas
        : state.carretas
        ? state.carretas
        : [];
    });

    const confirmSelection = (selected: Array<CarretaPayload>) => {
      state.carretas = selected ? selected : null;
      emit("confirm-selection", state.carretas);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      carretaAutoComplete.value = null;
      state.crudOpen = false;
      state.idCarretaToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      carretaAutoComplete.value = null;
      state.crudOpen = false;
      state.idCarretaToView = null;
      emit("cancel-selection");
    };

    const removeEntity = (selected: CarretaPayload) => {
      if (state.carretas) {
        if (selected && selected.idCarreta) {
          state.carretas.splice(
            state.carretas.map((c) => c.idCarreta).indexOf(selected.idCarreta),
            1
          );
        } else {
          state.carretas.splice(0, 1);
        }
        confirmSelection(state.carretas);
      }
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idCarretaToView = null;
      state.crudOpen = true;
    };

    const openEntity = (e: CarretaPayload) => {
      if (e.idCarreta) {
        state.idCarretaToView = e.idCarreta;
        state.crudOpen = true;
      }
    };

    const onChangePlacaCarretaFilter = () => {
      if (
        typeof carretaAutoComplete.value === "string" &&
        carretaAutoComplete.value.trim().length > 0 &&
        state.lastSearchedQuery !== carretaAutoComplete.value.trim()
      ) {
        openSearch();
      } else {
        carretaAutoComplete.value = null;
      }
      state.lastSearchedQuery = null;
    };

    const getNome = (e?: CarretaPayload) => {
      return useCarretaService().getNomeCarreta(e);
    };

    const executeSearchAutoComplete = () => {
      if (!state.autocompleteSearchRunning) {
        state.autocompleteSearchRunning = true;
        waitUntilStable(
          carretaAutoComplete.value,
          () => {
            return carretaAutoComplete.value;
          },
          () => {
            if (
              typeof carretaAutoComplete.value === "string" &&
              carretaAutoComplete.value.trim().length >= 3 &&
              !state.crudOpen
            ) {
              const { data, searchCarreta } = useCarretaService();
              state.lastSearchedQuery = carretaAutoComplete.value.trim();
              searchCarreta(
                {
                  placaNf: carretaAutoComplete.value.trim(),
                  idCliente: props.cliente?.idCliente
                    ? props.cliente.idCliente
                    : undefined,
                  arquivado: false
                },
                0
              )
                .then(() => {
                  state.carretasAutocomplete = data.value.content;
                  state.autocompleteSearchRunning = false;
                })
                .catch(() => {
                  state.autocompleteSearchRunning = false;
                });
            } else {
              state.carretasAutocomplete = [];
              state.autocompleteSearchRunning = false;
            }
          },
          300
        );
      }
    };

    const selectAutoComplete = (
      event: AutoCompleteInputEvent<CarretaPayload | string>
    ) => {
      if (event && event.value) {
        if (typeof event.value !== "string" && event.value.idCarreta) {
          const arr = [] as Array<CarretaPayload>;
          entitiesSelected.value.forEach((el) => arr.push(el));
          addSetElement(arr, event.value, (ob) => ob && ob.idCarreta, true);
          confirmSelection(arr);
        }
      }
      carretaAutoComplete.value = null;
    };

    onMounted(async () => {
      state.carretas = props.carretas ?? [];
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      confirmSelection,
      cancelSelection,
      executeSearchAutoComplete,
      selectAutoComplete,
      onChangePlacaCarretaFilter,
      filteredCarretas,
      preFilter,
      entitiesSelected,
      es,
      carretaAutoComplete,
      removeEntity,
      format,
      getNome,
      ...toRefs(state)
    };
  },
  components: {
    CrudCarreta
  },
  props: {
    carretas: {
      type: Array as PropType<Array<CarretaPayload>>
    },
    idCarretasFiltered: Array as PropType<Array<number>>,
    cliente: Object as PropType<ClientePayload>,
    blockedSelection: {
      type: Function,
      required: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Carretas"
    },
    labelEmpty: {
      type: String,
      default: "Selecione as Carretas"
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    focus: {
      type: Boolean,
      default: false
    }
  }
});
