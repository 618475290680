<template>
  <container :isModal="true" @close-modal="close()" :loading="!stateReady">
    <template v-slot:header>
      <h3>Consistência de Trechos</h3>
    </template>
    <template v-slot:body>
      <div class="p-grid p-mt-1" v-if="!stateLoading">
        <div class="p-col-12 p-lg-6">
          <search-result
            :showSelector="false"
            :results="resultsAdicionados"
            :loading="stateLoading"
            @after-page-change="afterPageChangeAdicionados"
          >
            <template v-slot:title>
              <div class="sub-title">
                <h4>Adicionados</h4>
              </div>
            </template>
            <template v-slot:columns>
              <Column field="idUnidadeFederal" header="UF" style="width: 3rem">
                <template #body="slotProps">
                  <span v-text="getSiglaUf(slotProps.data.idUnidadeFederal)" />
                </template>
              </Column>
              <Column field="rodovia" header="Rodovia" style="width: 10rem" />
              <Column field="kmInicial" header="Início">
                <template #body="slotProps">
                  <span
                    v-text="
                      slotProps.data.kmInicial +
                        ' - ' +
                        slotProps.data.nmTrechoInicial
                    "
                  />
                </template>
              </Column>
              <Column field="kmFinal" header="Fim">
                <template #body="slotProps">
                  <span
                    v-text="
                      slotProps.data.kmFinal +
                        ' - ' +
                        slotProps.data.nmTrechoFinal
                    "
                  />
                </template>
              </Column>
            </template>
            <template v-slot:empty> Sem Trechos Adicionados </template>
          </search-result>
        </div>
        <div class="p-col-12 p-lg-6">
          <search-result
            :showSelector="false"
            :results="resultsRemovidos"
            :loading="stateLoading"
            @after-page-change="afterPageChangeRemovidos"
          >
            <template v-slot:title>
              <div class="sub-title">
                <h4>Removidos</h4>
              </div>
            </template>
            <template v-slot:columns>
              <Column field="idUnidadeFederal" header="UF" style="width: 3rem">
                <template #body="slotProps">
                  <span v-text="getSiglaUf(slotProps.data.idUnidadeFederal)" />
                </template>
              </Column>
              <Column field="rodovia" header="Rodovia" style="width: 10rem" />
              <Column field="kmInicial" header="Início">
                <template #body="slotProps">
                  <span
                    v-text="
                      slotProps.data.kmInicial +
                        ' - ' +
                        slotProps.data.nmTrechoInicial
                    "
                  />
                </template>
              </Column>
              <Column field="kmFinal" header="Fim">
                <template #body="slotProps">
                  <span
                    v-text="
                      slotProps.data.kmFinal +
                        ' - ' +
                        slotProps.data.nmTrechoFinal
                    "
                  />
                </template>
              </Column>
            </template>
            <template v-slot:empty> Sem Trechos Removidos </template>
          </search-result>
        </div>
      </div>
      <div>
        <search-result
          :showSelector="false"
          :results="resultsSelecionados"
          :loading="stateLoading"
          @after-page-change="afterPageChange"
        >
          <template v-slot:title>
            <div class="sub-title" v-if="!stateLoading">
              <h4>Selecionados</h4>
            </div>
          </template>
          <template v-slot:columns>
            <Column field="idUnidadeFederal" header="UF" style="width: 3rem">
              <template #body="slotProps">
                <span v-text="getSiglaUf(slotProps.data.idUnidadeFederal)" />
              </template>
            </Column>
            <Column field="rodovia" header="Rodovia" style="width: 10rem" />
            <Column field="kmInicial" header="Início">
              <template #body="slotProps">
                <span
                  v-text="
                    slotProps.data.kmInicial +
                      ' - ' +
                      slotProps.data.nmTrechoInicial
                  "
                />
              </template>
            </Column>

            <Column field="kmFinal" header="Fim">
              <template #body="slotProps">
                <span
                  v-text="
                    slotProps.data.kmFinal +
                      ' - ' +
                      slotProps.data.nmTrechoFinal
                  "
                />
              </template>
            </Column>
          </template>
        </search-result>
      </div>
    </template>
  </container>
</template>

<script lang="ts">
import { defaultResults } from "../../../pagination";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  Ref
} from "vue";
import { useUfService } from "../../../modules/business/common/commonBusiness";
import { useLicencaOrgaoService } from "../../../modules/business/licenca/licencaOrgaoBusiness";
import { TrechoDiferencaPayload } from "../../../modules/business/trecho/planilhaTrechosBusiness";
interface DiferencasTrechosState {
  idLicenca: number;
  idLicencaOrgao: number;
  diferencaRetorno?: TrechoDiferencaPayload;
  page: number;
  pageRemovidos: number;
  pageAdicionados: number;
  stateReady: boolean;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<DiferencasTrechosState>({
      idLicenca: props.idLicenca,
      idLicencaOrgao: props.idLicencaOrgao,
      stateReady: false,
      pageRemovidos: 0,
      pageAdicionados: 0,
      page: 0,
      loadings: []
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const submitSearch = () => {
      const { data, loading, searchStretchChanges } = useLicencaOrgaoService();
      state.loadings.push(loading);
      return searchStretchChanges(props.idLicenca, props.idLicencaOrgao)
        .then(() => {
          state.diferencaRetorno = data.value;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const resultsAdicionados = computed(() => {
      if (!state.diferencaRetorno) {
        return defaultResults();
      }
      return {
        content: state.diferencaRetorno.trechosAdicionados.slice(
          state.pageAdicionados * 15,
          (state.pageAdicionados + 1) * 15
        ),
        size: 15,
        totalPages: Math.ceil(
          (state.diferencaRetorno?.trechosAdicionados?.length ?? 0) / 15.0
        ),
        totalElements: state.diferencaRetorno?.trechosAdicionados?.length ?? 0,
        number: state.pageAdicionados
      };
    });

    const resultsRemovidos = computed(() => {
      if (!state.diferencaRetorno) {
        return defaultResults();
      }
      return {
        content: state.diferencaRetorno.trechosRemovidos.slice(
          state.pageRemovidos * 15,
          (state.pageRemovidos + 1) * 15
        ),
        size: 15,
        totalPages: Math.ceil(
          (state.diferencaRetorno?.trechosRemovidos?.length ?? 0) / 15.0
        ),
        totalElements: state.diferencaRetorno?.trechosRemovidos?.length ?? 0,
        number: state.pageRemovidos
      };
    });

    const resultsSelecionados = computed(() => {
      if (!state.diferencaRetorno) {
        return defaultResults();
      }
      return {
        content: state.diferencaRetorno.trechosSelecionados.slice(
          state.page * 15,
          (state.page + 1) * 15
        ),
        size: 15,
        totalPages: Math.ceil(
          (state.diferencaRetorno?.trechosSelecionados?.length ?? 0) / 15.0
        ),
        totalElements: state.diferencaRetorno?.trechosSelecionados?.length ?? 0,
        number: state.page
      };
    });

    const afterPageChangeAdicionados = (page: number) => {
      state.pageAdicionados = page;
    };

    const afterPageChangeRemovidos = (page: number) => {
      state.pageRemovidos = page;
    };

    const afterPageChange = (page: number) => {
      state.page = page;
    };

    const getSiglaUf = (idUnidadeFederal: number) => {
      return useUfService().getUfSigla(idUnidadeFederal);
    };

    const close = () => {
      emit("close-screen");
    };

    onMounted(async () => {
      state.stateReady = true;
      submitSearch();
    });

    return {
      submitSearch,
      close,
      stateLoading,
      resultsAdicionados,
      getSiglaUf,
      resultsRemovidos,
      resultsSelecionados,
      afterPageChangeAdicionados,
      afterPageChangeRemovidos,
      afterPageChange,
      ...toRefs(state)
    };
  },
  components: {},
  props: {
    idLicenca: {
      type: Number,
      required: true
    },
    idLicencaOrgao: {
      type: Number,
      required: true
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
