import { useApiWithAuth } from "@/modules/api";

interface TenantPayload {
  nome?: string | null;
  login?: string | null;
  senha?: string | null;
}

const useTenantService = () => {
  const { data, loading, post } = useApiWithAuth();

  const saveNewTenant = (cliente: TenantPayload): Promise<void> => {
    return post("/root/tenant", cliente);
  };

  const safeTenant = (entity?: TenantPayload | null): TenantPayload => {
    return entity ?? {};
  };

  return {
    data,
    loading,
    saveNewTenant,
    safeTenant
  };
};

export { useTenantService, TenantPayload };
