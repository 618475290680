<template>
  <div class="p-inputtext-sm root-div">
    <message-container />
    <loading v-if="authenticating && !systemEnabled" />
    <template v-else>
      <div v-if="!!user" class="main-container">
        <top-nav />
        <div class="container-with-menu-over">
          <div>
            <div class="p-d-flex p-flex-md-row p-jc-center">
              <div v-if="systemEnabled" class="view-container">
                <router-view />
              </div>
              <loading v-else applyCustomLoading />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="unsigned-screen">
        <div class="bg bg-morning" />
        <router-view class="content" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { systemEnabled } from "./modules/api";
import { setLanguage } from "./modules/utils";
import { useAuth } from "./modules/auth";
import { usePrimeVue } from "primevue/config";

import TopNav from "./components/layout/TopNav.vue";

export default defineComponent({
  components: { TopNav },
  setup() {
    const { authenticating, user } = useAuth();
    setLanguage(navigator.language, usePrimeVue().config.locale);
    return { systemEnabled, authenticating, user };
  }
});
</script>
<style lang="scss">
.main-logo {
  content: url(#{$frontend-assets-base-url}/logo.svg);
}

.main-selo {
  content: url(#{$frontend-assets-base-url}/selo.svg);
}

.main-loading {
  min-height: 30rem;
  cursor: wait !important;
  background-image: url(#{$frontend-assets-base-url}/loading-truck.gif);
  background-size: 25% !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-container {
  -webkit-animation: fadein 0.8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.8s; /* Firefox < 16 */
  -ms-animation: fadein 0.8s; /* Internet Explorer */
  -o-animation: fadein 0.8s; /* Opera < 12.1 */
  animation: fadein 0.8s;
}

.view-container {
  background-color: white;
  width: 94%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  transition: all 0.3s ease;
  border-radius: 1rem;
  @media (min-width: 992px) and (max-width: 1440px) {
    width: 100% !important;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    margin-top: 0rem;
    border-radius: 0.3rem;
  }
  @media (max-width: 992px) {
    width: 100% !important;
    padding-left: 0rem;
    padding-right: 0rem;
    margin-top: 0rem;
    border-radius: 0rem;
  }
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0px;
  background: $bg-color;
}

.table-line-info {
  > td {
    background: rgba($secondary, 0.2);
  }
}
.table-line-success {
  > td {
    background: rgba($primary-success, 0.3);
  }
}
.table-line-warning {
  > td {
    background: rgba(240, 230, 0, 0.2);
  }
}
.table-line-danger {
  color: red !important;
  > td {
    background: rgba(240, 0, 0, 0.1);
  }
}

.table-line-disabled {
  cursor: default !important;
  pointer-events: none;
  > td {
    background: rgba(grey, 0.15);
    color: grey;
    .p-checkbox-box {
      border: 1px solid grey;
      background-color: lightgray;
      display: none !important;
    }
    button {
      pointer-events: initial;
    }

    &.blocked-column {
      color: red !important;
    }
  }
}

/* Escondido seletor 
.p-column-header-content {
  > .p-checkbox {
    display: none !important;
  }
}*/

.p-button {
  &:enabled {
    &:not(.p-button-text):not(.p-datepicker-trigger):not(.p-button-outlined):not(.p-button-link) {
      background: $primary !important;
      &:hover {
        background: darken($primary, 10%) !important;
      }
      &.p-button-secondary {
        background: $secondary !important;
        &:hover {
          background: lighten($secondary, 10%) !important;
        }
      }
      &.p-button-info {
        background: $info !important;
        &:hover {
          background: darken($info, 10%) !important;
        }
      }
      &.p-button-danger {
        background: $danger !important;
        &:hover {
          background: darken($danger, 10%) !important;
        }
      }
      &.p-button-success {
        background: $success !important;
        &:hover {
          background: darken($success, 10%) !important;
        }
      }
    }
  }
  &.p-disabled {
    pointer-events: all;
  }
}

.p-weak {
  filter: brightness(70%);
}

.c-toast-container {
  z-index: 999999 !important;
}
.p-toast {
  z-index: 999999 !important;
}

fieldset {
  border-width: 0px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  padding: 0.4rem 0.4rem 1rem 0.4rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.top-icons {
  height: 3.3rem;
  img {
    height: 100%;
  }
}

.p-menubar-fixed {
  background: white !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  padding: 1rem 0rem 1rem 0.5rem !important;
  width: 100% !important;
  height: 3.5em !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  z-index: 500 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-width: 0px 1px 1px 1px !important;
}

.p-menubar-fixed .p-submenu-list {
  width: 16rem !important;
}

.container-with-menu-over {
  margin-top: 3.5rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.body-container {
  border-radius: 2px;
  transition: all 0.3s ease;
  overflow: auto;
  height: 100%;
}

.unsigned-screen {
  .bg {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg-morning {
    background-image: url(#{$frontend-assets-base-url}/bg-unsigned-morning.jpg);
  }

  .content {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2;
  }
}

.p-datatable-table {
  width: 100% !important;
  .p-rowgroup-header {
    > td {
      padding: 0 !important;
    }
  }
}

.rowgroup-group-data {
  width: 100%;
  background: $bg-color !important;
  > div {
    background: $bg-color !important;
  }
  > td {
    border-top: 0px;
  }
}

.p-inputtext-sm .p-multiselect-label {
  font-size: 0.875rem;
  padding: 0.875rem 0.875rem !important;
}

.p-buttonset .p-button-rounded.p-button:last-of-type.p-button:first-of-type {
  border-radius: 2rem;
}

.p-buttonset
  .p-button-icon-only.p-button-rounded.p-button:last-of-type.p-button:first-of-type {
  border-radius: 50%;
}

.p-inputgroup {
  .p-float-label {
    + .p-buttonset {
      .p-button-icon-only.p-button-rounded.p-button:first-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    :first-child.p-inputwrapper {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.p-dialog {
  .p-dialog-header {
    padding: 0.5rem 1.5rem !important;
  }
}

.p-button:not(.p-button-icon-only):not(.p-button-link) {
  min-width: 10rem;
}

.p-button:not(.p-button-icon-only):not(.p-button-link) + .p-button-icon-only {
  height: initial;
}

.p-column-title {
  min-width: 6rem;
}

.text-ellipsis {
  @media (min-width: 60rem) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.dialog-internal {
  background-color: white;
  min-height: 90% !important;
  max-height: 90% !important;
  width: calc(100% - 5rem);
  transition: all 0.3s ease;
  @media (max-width: 992px) {
    max-height: calc(100% - 1rem) !important;
    min-height: calc(100% - 1rem) !important;
    width: calc(100% - 1rem) !important;
  }
}

.dynamic-grid-row {
  @media (max-width: 992px) {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  > div {
    @media (max-width: 992px) {
      width: 100% !important;
      height: initial !important;
      position: initial !important;
      left: initial !important;
      transform: initial !important;
      flex-grow: 1;
      flex-basis: 0;
      padding: 0.5rem !important;
    }
  }
}

.dynamic-grid-header {
  @media (max-width: 992px) {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  > .p-col-fixed {
    @media (max-width: 992px) {
      align-self: flex-end;
    }
  }
}

.hidden-input {
  width: 1px;
  opacity: 0;
  padding: 0px;
  margin: 0px;
  border: 0px;
}

.overlay-panel-menu {
  > .p-overlaypanel-content {
    padding: 0px !important;
  }
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
  border-radius: 50% !important;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  word-break: break-word;
  white-space: normal !important;
  text-align: justify;
}
.p-datatable-resizable .p-datatable-tbody {
  > tr > td {
    border-color: $bg-color !important;
  }
}

.p-selectable-row + :not(.p-rowgroup-footer:last-of-type) {
  .rowgroup-group-data {
    background-color: $bg-color !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    > div {
      background: repeating-linear-gradient(
        45deg,
        $bg-color,
        $bg-color 0.5rem,
        #e4e4e4 0.5rem,
        #e4e4e4 1rem
      ) !important;
    }
  }
}

.p-datatable-resizable .p-datatable-thead > tr > th.p-header-center > div {
  justify-content: center;
}
</style>
