import { PermissionPayload } from "@/modules/auth";
import { VeiculoTratorPayload } from "@/modules/business/veiculo/veiculoTratorBusiness";
import { CarretaPayload } from "@/modules/business/veiculo/carretaBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "@/modules/business/transportador/transportadorBusiness";
import { useOrgaoAetService } from "@/modules/business/common/commonBusiness";

interface FilaVeiculoPayload {
  idFilaVeiculo: number | null;
  idOrgao: number | null;
  transportador: TransportadorPayload;
  timestampUltimaExecucao?: Date | null;
  veiculo: VeiculoTratorPayload | CarretaPayload;
  usuario?: string | null;
  situacao?: string | null;
  statusGovpassOrgao?: string | null;
  permissionInfo?: PermissionPayload;
}

const useFilaVeiculoService = () => {
  const getNomeFilaVeiculo = (entity?: FilaVeiculoPayload) => {
    if (!entity) {
      return "";
    }
    return (
      useOrgaoAetService().getOrgaoAetSigla(entity.idOrgao) +
      " - " +
      useTransportadorService().getNomeTransportador(entity.transportador) +
      (entity.usuario
        ? " (USUARIO: " + entity.usuario ?? +")"
        : " (AINDA NAO CADASTRADO)")
    );
  };

  return {
    getNomeFilaVeiculo
  };
};

export { FilaVeiculoPayload, useFilaVeiculoService };
