import { useApiWithAuth } from "@/modules/api";
import { useCache } from "@/modules/cache";

interface TipoLicencaOrgaoPayload {
  idTipoLicencaOrgao?: number | null;
  descricao?: string | null;

  armazenamento?: boolean | null;
  possuiCamposRenovacao?: boolean | null;
}

export const useTipoLicencaOrgaoService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getTiposLicencaOrgaoToCache = (): Promise<void> => {
    return get(`/licenca/licenca-orgao/tipo`);
  };

  const getTiposLicencaOrgao = (): Array<TipoLicencaOrgaoPayload> => {
    return useCache().getTiposLicencaOrgaos();
  };

  const getTipoLicencaOrgao = (
    idTipoLicencaOrgao?: number | null
  ): TipoLicencaOrgaoPayload | null => {
    if (!idTipoLicencaOrgao) {
      return null;
    }

    return (
      getTiposLicencaOrgao().find(
        (a) => a.idTipoLicencaOrgao == idTipoLicencaOrgao
      ) ?? null
    );
  };

  return {
    data,
    loading,
    getTiposLicencaOrgaoToCache,
    getTiposLicencaOrgao,
    getTipoLicencaOrgao
  };
};

export { TipoLicencaOrgaoPayload };
