
import { Options, Vue } from "vue-class-component";
import CrudEngenheiro from "../../components/engenheiro/CrudEngenheiro.vue";

@Options({
  props: {
    isNew: Boolean,
    idEngenheiro: Number
  },
  components: {
    CrudEngenheiro
  }
})
export default class Engenheiro extends Vue {
  isNew?: boolean;
  idEngenheiro?: number;
}
