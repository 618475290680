<template>
  <div>
    <div
      v-if="stateReady"
      :class="{ 'p-flex-column': hasEntity }"
      :id="stateId"
    >
      <div v-if="hasEntity">
        <entity-selector-list
          :entities="internalEntities"
          :keyParam="keyParam"
          :multiple="multiple"
        >
          <template v-slot="slotProps">
            <div class="p-inputgroup">
              <template v-if="$slots.selected">
                <slot
                  name="selected"
                  :entity="slotProps.entity"
                  :index="slotProps.index"
                />
                <Button
                  class="p-button-sm p-button-success"
                  icon="pi pi-pencil "
                  @click="
                    amIAllowed('view', slotProps.entity.permissionInfo) &&
                      openEntity(slotProps.entity)
                  "
                  @click.middle.stop="
                    amIAllowed('view', slotProps.entity.permissionInfo) &&
                      openEntity(slotProps.entity, true)
                  "
                  v-title="'Visualizar'"
                  :disabled="
                    !amIAllowed('view', slotProps.entity.permissionInfo)
                  "
                />
              </template>
              <input-with-label
                :label="label"
                :required="required"
                :showLabel="showLabel ? slotProps.index == 0 : false"
                v-slot="l"
                v-else
              >
                <Button
                  class="
                    p-button-outlined
                    p-inputtext-sm
                    p-inputtext
                    p-inputwrapper-filled
                    left-button-group
                  "
                  @click="
                    amIAllowed('view', slotProps.entity.permissionInfo) &&
                      openEntity(slotProps.entity)
                  "
                  @click.middle.prevent.stop="
                    amIAllowed('view', slotProps.entity.permissionInfo) &&
                      openEntity(slotProps.entity, true)
                  "
                  v-title="'Visualizar'"
                  :disabled="
                    !amIAllowed('view', slotProps.entity.permissionInfo)
                  "
                  :id="l.id"
                >
                  <i
                    class="pi pi-pencil p-mr-1"
                    v-if="amIAllowed('view', slotProps.entity.permissionInfo)"
                  />
                  <slot name="id" :entity="slotProps.entity" />
                </Button>
              </input-with-label>
              <Button
                class="p-button-sm"
                icon="fas fa-search-plus"
                @click="openSearch()"
                v-title="'Procurar'"
                v-if="
                  canEdit &&
                    hasEntity &&
                    (!multiple || !$slots['not-selected']) &&
                    internalEntities.length <= 1
                "
              />
              <Button
                class="p-button-danger p-button-sm"
                icon="far fa-times-circle"
                v-title="'Remover Seleção'"
                @click="removeEntity(slotProps.entity)"
                v-if="canEdit"
              />
            </div>
          </template>
        </entity-selector-list>
      </div>
      <template v-if="multiple || !hasEntity">
        <div class="p-inputgroup">
          <slot name="not-selected">
            <input-with-label
              :label="label"
              :required="required"
              :addRequiredHiddenField="true"
              :hasValue="hasEntity"
              v-slot="l"
            >
              <Button
                class="
                  p-button-outlined
                  p-inputtext-sm
                  p-inputtext
                  p-inputwrapper-filled
                  left-button-group
                "
                @click="openSearch()"
                v-title="'Procurar'"
                :id="l.id"
              >
                Clique para abrir a consulta
              </Button>
            </input-with-label>
          </slot>

          <Button
            class="p-button-sm"
            icon="fas fa-search-plus"
            @click="openSearch()"
            v-title="'Procurar'"
            v-if="canEdit"
          />
        </div>
      </template>
      <div v-if="canEdit" style="width: 100px">
        <Button
          class="p-button-rounded p-button-sm"
          @click="openSearch()"
          v-title="'Procurar'"
          v-if="
            canEdit &&
              hasEntity &&
              (!multiple || !$slots['not-selected']) &&
              internalEntities.length > 1
          "
        >
          <i class="fas fa-search-plus" />
          <span v-text="'Procurar'" class="p-ml-1" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
//TODO KAIO TROCAR OS ENTITYSELECTORS PARA USAR V-MODEL
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRefs,
  onMounted
} from "vue";
import EntitySelectorList from "./EntitySelectorList.vue";

import { focusNext, findAllFocussableChildren } from "../../../modules/utils";
import { useAuth, PermissionPayload } from "../../../modules/auth";

interface EntitySelectorState {
  stateReady: boolean;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EntitySelectorState>({
      stateReady: false,
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2)
    });

    const internalEntities = computed(() => {
      if (props.entities?.length) {
        return props.entities;
      }
      return [];
    });

    const hasEntity = computed(() => {
      if (internalEntities.value.length > 0) {
        return true;
      } else {
        return false;
      }
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const openEntity = (e: any, newTab?: boolean) => {
      emit("open-entity", e, newTab);
    };

    const removeEntity = (e: any) => {
      emit("remove-entity", e);
    };

    const openSearch = () => {
      emit("open-search");
    };

    const focus = () => {
      setTimeout(() => {
        const el = document.querySelector("#" + state.stateId) as HTMLElement;
        if (el) {
          const inputs = findAllFocussableChildren(el, "input:not([disabled])");
          if (inputs && inputs.length && inputs[0].focus) {
            inputs[0].focus();
          } else {
            const elements = findAllFocussableChildren(el);
            if (elements && elements.length && elements[0].focus) {
              elements[0].focus();
            }
          }
        }
      }, 50);
    };

    const next = () => {
      setTimeout(() => {
        const el = document.querySelector("#" + state.stateId) as HTMLElement;
        if (el) {
          const inputs = findAllFocussableChildren(el, "input:not([disabled])");
          if (inputs && inputs.length && inputs[inputs.length - 1].focus) {
            inputs[inputs.length - 1].focus();
          } else {
            const elements = findAllFocussableChildren(el);
            if (elements && elements.length) {
              focusNext(elements[elements.length - 1]);
            }
          }
        }
      }, 50);
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      removeEntity,
      hasEntity,
      amIAllowed,
      focus,
      next,
      internalEntities,
      ...toRefs(state)
    };
  },
  props: {
    entities: {
      type: Array as PropType<Array<any>>
    },
    keyParam: String,
    id: String,
    required: {
      type: Boolean,
      default: false
    },
    idFunction: {
      type: Function
    },
    multiple: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Modelo"
    }
  },
  components: { EntitySelectorList }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.left-button-group {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
</style>
