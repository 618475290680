
import { defineComponent, reactive, toRefs } from "vue";

import { useRouter } from "vue-router";
import { useApi } from "../../modules/api";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";
import { useToast } from "../../modules/message";

interface RecoverPayload {
  credentials: CredencialPayload;
  token: string;
  confirmaSenha: string;
  loading: boolean;
}

export default defineComponent({
  setup(props) {
    const router = useRouter();

    if (!props.token) {
      useToast().warning("Token inválido, redirecionando para login.");
      router.push({ name: "login" });
    }

    const state = reactive<RecoverPayload>({
      credentials: {},
      token: props.token ?? "",
      confirmaSenha: "",
      loading: false
    });

    const back = () => {
      router.push({ name: "login" });
    };

    const submit = () => {
      state.loading = true;
      useApi()
        .post("/login/password/token/" + state.token, state.credentials)
        .then(() => {
          useToast().success(
            "Senha atualizada com sucesso. Redirecionando para tela de login.."
          );
          back();
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };
    return {
      submit,
      back,
      ...toRefs(state)
    };
  },
  props: {
    token: String
  }
});
