import { useApiWithAuth } from "@/modules/api";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";

interface NotificacaoMetadataPayload {
  idLicenca?: number | null;
  idLicencaOrgao?: number | null;
  idTransportador?: number | null;
  idCarreta?: number | null;
  idVeiculoTrator?: number | null;
  idOrgao?: number | null;
}

interface NotificacaoTotalPayload {
  total?: number | null;
  idUltimaNotificacaoDisponivel?: number | null;
}

interface NotificacaoPayload {
  idNotificacao: number;
  tipo: string;
  topico?: string | null;
  mensagem?: string | null;
  timestampCadastro?: Date | null;
  assunto?: string | null;
  metadata: NotificacaoMetadataPayload;
  cliente?: ClientePayload | null;
  //processados frond
  loading?: boolean | null;
}

const useNotificacaoService = () => {
  const { data, loading, get, patch } = useApiWithAuth();

  const findUnread = (): Promise<void> => {
    return get(`/notificacao/unread`);
  };

  const findUnreadTotal = (): Promise<void> => {
    return get(`/notificacao/unread/total`);
  };

  const read = (idNotificacao: number): Promise<void> => {
    return patch(`/notificacao/read/${idNotificacao}`);
  };

  return {
    data,
    loading,
    findUnread,
    read,
    findUnreadTotal
  };
};

export {
  useNotificacaoService,
  NotificacaoTotalPayload,
  NotificacaoPayload,
  NotificacaoMetadataPayload
};
