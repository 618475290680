
import { Options, Vue } from "vue-class-component";
import CadastroCliente from "../../components/cliente/CadastroCliente.vue";

@Options({
  components: {
    CadastroCliente
  }
})
export default class ClienteAdmin extends Vue {}
