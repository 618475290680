
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  watch,
  Ref,
  PropType
} from "vue";
import { useStorage } from "../../../modules/storage";
import {
  useLicencaOrgaoService,
  LicencaOrgaoPayload
} from "../../../modules/business/licenca/licencaOrgaoBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import { useTipoLicencaOrgaoService } from "../../../modules/business/licenca/tipoLicencaOrgaoBusiness";
import { useToast } from "../../../modules/message";
import { useOrgaoAetService } from "../../../modules/business/common/commonBusiness";
import EntitySelectorArtEngenheiroTransportador from "../../transportador/art/EntitySelectorArtEngenheiroTransportador.vue";
import EntitySelectorOrgaoAet from "../../common/EntitySelectorOrgaoAet.vue";
import EntitySelectorPlanilhaTrechos from "../../trecho/EntitySelectorPlanilhaTrechos.vue";
import EntitySelectorTransportador from "../../transportador/EntitySelectorTransportador.vue";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import { PlanilhaTrechosPayload } from "../../../modules/business/trecho/planilhaTrechosBusiness";
import { ArtEngenheiroTransportadorPayload } from "../../../modules/business/transportador/artEngenheiroTransportadorBusiness";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { DATE_SELECTORS } from "../../../modules/utils";
import { addDays } from "date-fns";

interface LicencaOrgaoCadastroState {
  entity: LicencaOrgaoPayload;
  stateReady: boolean;
  hasUser: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
  temArt: boolean;
  temTransportadorAuxiliar: boolean;
  numeroDiasValidadeDefault?: number | null;
  tipoPeriodo: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<LicencaOrgaoCadastroState>({
      entity: useLicencaOrgaoService().safeLicencaOrgao(),
      hasUser: false,
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: [],
      temArt: false,
      temTransportadorAuxiliar: false,
      tipoPeriodo: DATE_SELECTORS.FREE_SELECTION
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const tipo = computed(() => {
      return useTipoLicencaOrgaoService().getTipoLicencaOrgao(
        state.entity.idTipoLicencaOrgao
      );
    });

    const canEditNumeroPdf = computed(() => {
      return (
        tipo.value?.armazenamento ||
        amIAllowed("edit_numero_pdf", state.entity?.permissionInfo)
      );
    });

    const orgao = computed(() => {
      return useOrgaoAetService().getOrgaoAetSafe(state.entity.idOrgao);
    });

    const tiposLicenca = computed(() => {
      return useTipoLicencaOrgaoService()
        .getTiposLicencaOrgao()
        .filter(
          (t) =>
            t.idTipoLicencaOrgao &&
            orgao.value.tiposLicencaOrgaoPermitidos.indexOf(
              t.idTipoLicencaOrgao
            ) >= 0
        );
    });

    watch([tiposLicenca], () => {
      if (
        tiposLicenca.value.filter(
          (t) => t.idTipoLicencaOrgao == state.entity.idTipoLicencaOrgao
        ).length <= 0
      ) {
        state.entity.idTipoLicencaOrgao = null;
      }
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value ||
        !useAuth().amIAllowed("edit", state.entity?.permissionInfo)
      );
    });

    const onchangeDtValidadeInicial = (data?: Date | null) => {
      if (data && state.numeroDiasValidadeDefault) {
        const dataFinal = addDays(data, state.numeroDiasValidadeDefault);
        if (dataFinal) {
          state.entity.dtValidadeFinal = dataFinal;
        }
      }
    };

    const processOrgaoData = () => {
      state.temArt = false;
      state.temTransportadorAuxiliar = false;
      if (state.entity.idOrgao && state.entity.idTipoLicencaOrgao) {
        const { getOrgaoDefaultData, data, loading } = useLicencaOrgaoService();
        state.loadings.push(loading);
        return getOrgaoDefaultData(
          props.idLicenca,
          state.entity.idOrgao,
          state.entity.idTipoLicencaOrgao
        )
          .then(() => {
            const ret = data.value;
            state.temArt = !!ret.temArt;
            state.temTransportadorAuxiliar = !!ret.temTransportadorAuxiliar;
            if (
              ret.planilhaDefault?.idPlanilhaTrechos &&
              !state.entity.planilha?.idPlanilhaTrechos
            ) {
              state.entity.planilha = ret.planilhaDefault;
            }

            if (
              ret.artDefault?.idArtEngenheiroTransportador &&
              !state.entity.art?.idArtEngenheiroTransportador
            ) {
              state.entity.art = ret.artDefault;
            }

            let mustRunChangeDate = false;
            if (ret.dtValidadeInicialDefault && !state.entity.idLicencaOrgao) {
              state.entity.dtValidadeInicial = ret.dtValidadeInicialDefault;
              mustRunChangeDate = true;
            }
            if (
              state.numeroDiasValidadeDefault != ret.numeroDiasValidadeDefault
            ) {
              state.numeroDiasValidadeDefault = ret.numeroDiasValidadeDefault;
              mustRunChangeDate = true;
            }

            if (mustRunChangeDate) {
              onchangeDtValidadeInicial(state.entity.dtValidadeInicial);
            }
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      }
      return Promise.resolve();
    };

    const afterSetOrgao = (idOrgao?: number | null) => {
      state.entity.idOrgao = idOrgao ? idOrgao : undefined;
      if (
        !state.entity.idOrgao ||
        state.entity.planilha?.idOrgao != state.entity.idOrgao
      ) {
        state.entity.planilha = null;
      }
      processOrgaoData();
    };

    const confirmSelectionPlanilha = (p: PlanilhaTrechosPayload) => {
      if (p) {
        state.entity.planilha = p;
      } else {
        state.entity.planilha = useLicencaOrgaoService().safeLicencaOrgao().planilha;
      }
    };
    const confirmSelectionArt = (art: ArtEngenheiroTransportadorPayload) => {
      if (art) {
        state.entity.art = art;
      } else {
        state.entity.art = useLicencaOrgaoService().safeLicencaOrgao().art;
      }
    };

    const confirmSelectionTransportadorAuxiliar = (
      transportadorAuxiliar: TransportadorPayload
    ) => {
      if (transportadorAuxiliar) {
        state.entity.transportadorAuxiliar = transportadorAuxiliar;
      } else {
        state.entity.transportadorAuxiliar = useTransportadorService().safeTransportador();
      }
    };

    const setEntity = (entity: LicencaOrgaoPayload) => {
      state.entity = useLicencaOrgaoService().safeLicencaOrgao(entity);
      let docElement = document.querySelector(
        "#" + state.stateId + "_aet"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
      docElement = document.querySelector(
        "#" + state.stateId + "_aetRenovacao"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
      docElement = document.querySelector(
        "#" + state.stateId + "_projeto"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
      docElement = document.querySelector(
        "#" + state.stateId + "_comprovantePagamentoBoleto"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
    };

    const downloadAet = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idLicencaOrgao) {
        onEnd();
        return;
      }
      const { downloadAet, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadAet(props.idLicenca, state.entity.idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const downloadAetRenovacao = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idLicencaOrgao) {
        onEnd();
        return;
      }
      const { downloadAetRenovacao, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadAetRenovacao(props.idLicenca, state.entity.idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const downloadProjeto = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idLicencaOrgao) {
        onEnd();
        return;
      }
      const { downloadProjeto, loading } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadProjeto(props.idLicenca, state.entity.idLicencaOrgao, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const downloadComprovantePagamentoBoleto = (
      newTab: boolean,
      onEnd: Function
    ) => {
      if (!state.entity.idLicencaOrgao) {
        onEnd();
        return;
      }
      const {
        downloadComprovantePagamentoBoleto,
        loading
      } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadComprovantePagamentoBoleto(
        props.idLicenca,
        state.entity.idLicencaOrgao,
        newTab
      )
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const deleteTempFiles = () => {
      if (state.entity.keyBlAetTemp) {
        useStorage().deleteTempFile(state.entity.keyBlAetTemp);
        state.entity.keyBlAetTemp = null;
      }
      if (state.entity.keyBlAetRenovacaoTemp) {
        useStorage().deleteTempFile(state.entity.keyBlAetRenovacaoTemp);
        state.entity.keyBlAetRenovacaoTemp = null;
      }
      if (state.entity.projeto && state.entity.projeto.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(state.entity.projeto.keyBlDocumentoTemp);
        state.entity.projeto.keyBlDocumentoTemp = null;
      }
    };

    const save = () => {
      if (!props.idLicenca) {
        return;
      }
      const {
        saveNewLicencaOrgao,
        updateLicencaOrgao,
        loading: loadingCadastro,
        data: dataCadastro
      } = useLicencaOrgaoService();
      state.loadings.push(loadingCadastro);
      (props.idLicencaOrgao
        ? updateLicencaOrgao(
            props.idLicenca,
            props.idLicencaOrgao,
            state.entity
          )
        : saveNewLicencaOrgao(props.idLicenca, state.entity)
      )
        .then(() => {
          if (!props.idLicenca) {
            return;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          setEntity(dataCadastro.value);
          state.savedEntity = true;
          useToast().success("Licença salva com sucesso");
        })
        .catch(() => {
          deleteTempFiles();
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const processFile = (fileElementId: string) => {
      return new Promise<string>((resolve) => {
        const file = document.querySelector(
          "#" + fileElementId
        ) as HTMLInputElement;
        if (!file || !file.files || file.files.length === 0) {
          resolve("");
        } else {
          const formData = new FormData();
          formData.append("file", file.files[0]);
          const storage = useStorage();
          state.loadings.push(storage.loading);
          storage
            .uploadTempFile(formData)
            .then((key: string) => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
              resolve(key);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            });
        }
      });
    };

    const submitSave = () => {
      const arrayPromises = new Array<Promise<string>>();
      arrayPromises.push(
        processFile(state.stateId + "_aet").then(
          (key) => (state.entity.keyBlAetTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_aetRenovacao").then(
          (key) => (state.entity.keyBlAetRenovacaoTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_projeto").then((key) => {
          state.entity.projeto = state.entity.projeto ?? {};
          return (state.entity.projeto.keyBlDocumentoTemp = key);
        })
      );
      arrayPromises.push(
        processFile(state.stateId + "_comprovantePagamentoBoleto").then(
          (key) => {
            state.entity.comprovantePagamentoBoleto =
              state.entity.comprovantePagamentoBoleto ?? {};
            return (state.entity.comprovantePagamentoBoleto.keyBlDocumentoTemp = key);
          }
        )
      );
      Promise.all(arrayPromises).then(
        () => {
          save();
        },
        () => {
          deleteTempFiles();
        }
      );
    };

    watch([tipo], () => {
      if (tipo.value?.idTipoLicencaOrgao) {
        processOrgaoData();
      }
    });

    onMounted(async () => {
      if (!props.idLicenca) {
        return;
      }
      const {
        getLicencaOrgao,
        newLicencaOrgao,
        data,
        loading
      } = useLicencaOrgaoService();
      state.loadings.push(loading);

      (props.idLicencaOrgao
        ? getLicencaOrgao(props.idLicenca, props.idLicencaOrgao)
        : newLicencaOrgao(props.idLicenca)
      )
        .then(() => {
          setEntity(data.value);
          processOrgaoData().then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            state.stateReady = true;
          });
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      amIAllowed,
      canEditNumeroPdf,
      downloadProjeto,
      downloadComprovantePagamentoBoleto,
      downloadAetRenovacao,
      downloadAet,
      confirmSelectionPlanilha,
      confirmSelectionArt,
      confirmSelectionTransportadorAuxiliar,
      afterSetOrgao,
      orgao,
      tipo,
      stateLoading,
      stateBlocked,
      tiposLicenca,
      onchangeDtValidadeInicial,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorOrgaoAet,
    EntitySelectorPlanilhaTrechos,
    EntitySelectorArtEngenheiroTransportador,
    EntitySelectorTransportador
  },
  props: {
    idLicenca: {
      type: Number,
      required: true
    },
    transportador: {
      type: Object as PropType<TransportadorPayload>,
      required: true
    },
    idEngenheiro: {
      type: Number,
      required: true
    },
    idLicencaOrgao: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
