<template>
  <div v-if="stateReady">
    <div>
      <entity-selector
        :entities="entitiesSelected"
        :keyParam="'idCarreta'"
        :required="required"
        :canEdit="canEdit"
        :label="label"
        :showLabel="showLabel"
        ref="es"
        @open-entity="openEntity"
        @open-search="openSearch"
        @remove-entity="clearEntity"
      >
        <template v-slot:id="slotProps">
          <span v-text="getNome(slotProps.entity)" />
        </template>
        <template v-slot:selected="slotProps">
          <input-with-label
            :label="label"
            :subLabel="subLabel"
            :forceSublabelUnder="true"
            :required="required"
            iconClass="fa fa-truck-loading m-2"
            v-slot="l"
          >
            <input
              type="text"
              class="p-inputtext p-component p-filled"
              style="opacity: 1"
              :disabled="true"
              :id="l.id"
              :required="l.required"
              :value="getNome(slotProps.entity)"
            />
          </input-with-label>
        </template>
        <template v-slot:not-selected>
          <input-with-label
            :label="label + ' (Placa/NF)'"
            :subLabel="subLabel"
            iconClass="fas fa-truck-loading m-2"
            v-slot="l"
            :required="required"
          >
            <InputText
              v-model="placaNfInternal"
              :id="l.id"
              :required="l.required"
              @change="onChangePlacaCarretaFilter"
              v-focus="focus"
            />
          </input-with-label>
        </template>
      </entity-selector>
      <crud-carreta
        v-if="crudOpen"
        :isSearch="true"
        :idCarreta="idCarretaToView"
        :selected="entitiesToSelect"
        :isMultiple="false"
        :cliente="cliente"
        :preFilter="preFilter"
        :blockedSelection="blockedSelection"
        @confirm-selection="confirmSelection"
        @cancel-selection="cancelSelection"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  computed,
  watch
} from "vue";
import CrudCarreta from "./CrudCarreta.vue";
import {
  CarretaPayload,
  useCarretaService
} from "../../../modules/business/veiculo/carretaBusiness";
import { format } from "../../../modules/utils";

import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";

interface EntitySelectorCarretaState {
  stateReady: boolean;
  crudOpen: boolean;
  idCarretaToView?: number | null;
  entitiesToSelect: Array<CarretaPayload>;
  carreta?: CarretaPayload | null;
  placaNfInternal: string | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EntitySelectorCarretaState>({
      stateReady: false,
      crudOpen: false,
      idCarretaToView: null,
      placaNfInternal: null,
      entitiesToSelect: []
    });

    const es = ref();

    const preFilter = computed(() => {
      const pf = useCarretaService().safeFilterCarreta();
      if (state.placaNfInternal) {
        pf.placaNf = state.placaNfInternal;
      }
      return pf;
    });

    watch([props], () => {
      if (!props.carreta || !props.carreta.idCarreta) {
        if (props.idCarretaFiltered != state.carreta?.idCarreta) {
          state.carreta = null;
        }
      }
    });

    const entitiesSelected = computed(() => {
      return props.carreta && props.carreta.idCarreta
        ? [props.carreta]
        : state.carreta && state.carreta.idCarreta
        ? [state.carreta]
        : [];
    });

    const clearEntity = () => {
      state.placaNfInternal = null;
      state.carreta = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idCarretaToView = null;
      es.value.focus();
    };

    const confirmSelection = (selected: Array<CarretaPayload>) => {
      state.carreta = selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.carreta);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.placaNfInternal = null;
      state.crudOpen = false;
      state.idCarretaToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.placaNfInternal = null;
      state.crudOpen = false;
      state.idCarretaToView = null;
      emit("cancel-selection");
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idCarretaToView = null;
      state.crudOpen = true;
    };

    const onChangePlacaCarretaFilter = () => {
      if (state.placaNfInternal && state.placaNfInternal.length > 0) {
        openSearch();
      } else {
        state.placaNfInternal = null;
      }
    };

    const openEntity = (e: CarretaPayload) => {
      if (e.idCarreta) {
        state.idCarretaToView = e.idCarreta;
        state.crudOpen = true;
      }
    };

    const getNome = (e?: CarretaPayload) => {
      return useCarretaService().getNomeCarreta(e);
    };

    onMounted(async () => {
      state.carreta = props.carreta;
      state.stateReady = true;
      if (props.openOnCreate && !state.carreta?.idCarreta) {
        openSearch();
      }
    });

    return {
      openEntity,
      openSearch,
      clearEntity,
      confirmSelection,
      cancelSelection,
      preFilter,
      es,
      entitiesSelected,
      onChangePlacaCarretaFilter,
      format,
      getNome,
      ...toRefs(state)
    };
  },
  components: {
    CrudCarreta
  },
  props: {
    carreta: {
      type: Object as PropType<CarretaPayload>
    },
    idCarretaFiltered: Number,
    cliente: Object as PropType<ClientePayload>,
    blockedSelection: {
      type: Function,
      required: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    openOnCreate: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Carreta"
    },
    subLabel: {
      type: String,
      default: ""
    },
    focus: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
