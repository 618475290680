import { clearObject } from "@/modules/utils";
import { useApiWithAuth } from "@/modules/api";
import { useCache } from "@/modules/cache";

interface LicencaFaturamentoFilterPayload {
  idCliente?: number;
  idTransportador?: number;
  dtInicial?: Date;
  dtFinal?: Date;
  flSituacao?: string;
}

interface TipoLicencaFaturamentoPayload {
  flag?: string;
  descricao?: string;
}

const useLicencaFaturamentoService = () => {
  const {
    data,
    loading,
    get,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const downloadFaturamento = (
    filter: LicencaFaturamentoFilterPayload,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/licenca/faturamento/download`,
      {
        ...clearObject(filter)
      }
    );
  };

  const getTiposLicencaFaturamentoToCache = (): Promise<void> => {
    return get(`/licenca/faturamento/tipo`);
  };

  const getTiposLicencaFaturamento = (): Array<TipoLicencaFaturamentoPayload> => {
    return useCache().getTiposLicencaFaturamento();
  };

  const safeFilterLicencaFaturamento = () => {
    return {};
  };

  return {
    data,
    loading,
    safeFilterLicencaFaturamento,
    getTiposLicencaFaturamentoToCache,
    getTiposLicencaFaturamento,
    downloadFaturamento
  };
};

export {
  useLicencaFaturamentoService,
  LicencaFaturamentoFilterPayload,
  TipoLicencaFaturamentoPayload
};
