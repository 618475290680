
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  PropType,
  Ref
} from "vue";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useToast } from "../../modules/message";
import { useStorage } from "../../modules/storage";
import { useUfService } from "../../modules/business/common/commonBusiness";
import {
  PlanilhaTrechosPayload,
  TrechoPayload,
  usePlanilhaTrechosService
} from "../../modules/business/trecho/planilhaTrechosBusiness";
import EntitySelectorOrgaoAet from "../common/EntitySelectorOrgaoAet.vue";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import { useOrgaoAetService } from "../../modules/business/common/commonBusiness";
import EntitySelectorUf from "../common/EntitySelectorUf.vue";
import { MenuEvent } from "../../router";

interface PlanilhaTrechosCadastroState {
  entity: PlanilhaTrechosPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  numeroLicencaImportacao: string;
  loadings: Array<Ref<boolean>>;
  loadingTrechos: boolean;
  mostraImportacaoLicenca: boolean;
  mostraDescricaoTrechos: boolean;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<PlanilhaTrechosCadastroState>({
      entity: usePlanilhaTrechosService().safePlanilhaTrechos(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      numeroLicencaImportacao: "",
      loadings: [],
      mostraImportacaoLicenca: false,
      mostraDescricaoTrechos: false,
      loadingTrechos: false
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };
    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const stateFullBlocked = computed(() => {
      return (
        stateBlocked.value ||
        (amIAllowed("view", "root-cliente") && !state.entity.cliente?.idCliente)
      );
    });

    const afterSetOrgao = (idOrgao?: number | null) => {
      state.entity.idOrgao = idOrgao;
      //limpar
      state.mostraImportacaoLicenca = false;
      state.mostraDescricaoTrechos = false;
      if (state.entity.idOrgao) {
        const orgao = useOrgaoAetService().getOrgaoAet(state.entity.idOrgao);
        if (orgao) {
          if (orgao.permiteDownloadTrechosLicenca) {
            state.mostraImportacaoLicenca = true;
          }
          if (orgao.permiteDescricaoTrechos) {
            state.mostraDescricaoTrechos = true;
          }
        }
      }
    };

    const setEntity = (entity?: PlanilhaTrechosPayload) => {
      state.entity = usePlanilhaTrechosService().safePlanilhaTrechos(entity);
      const docElement = document.querySelector(
        "#" + state.stateId + "_fileImport"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }

      afterSetOrgao(state.entity.idOrgao);
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const afterSetUFTrecho = (
      trecho: TrechoPayload,
      idUnidadeFederal?: number | null
    ) => {
      if (idUnidadeFederal !== trecho.idUnidadeFederal) {
        trecho.idUnidadeFederal = idUnidadeFederal;
      }
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const getUfSigla = (idUnidadeFederal?: number) => {
      return useUfService().getUfSigla(idUnidadeFederal);
    };

    const submitSave = () => {
      const idPlanilhaTrechosProps =
        (props.planilhaTrechos && props.planilhaTrechos.idPlanilhaTrechos) ?? 0;
      const isInsert = !idPlanilhaTrechosProps;
      const {
        saveNewPlanilhaTrechos,
        updatePlanilhaTrechos,
        loading: loadingCadastro,
        data: dataCadastro
      } = usePlanilhaTrechosService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewPlanilhaTrechos(state.entity)
        : updatePlanilhaTrechos(idPlanilhaTrechosProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Planilha salva com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idPlanilhaTrechos);
          }
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const atribuiTrechosCarga = (trechos?: Array<TrechoPayload> | null) => {
      if (trechos && trechos.length) {
        state.entity.trechos = trechos;
        useToast().success("Carregado com sucesso");
      } else {
        useToast().success(
          "Carregado com sucesso, porém nenhum trecho foi encontrado. Mantendo os trechos antigos..."
        );
      }
    };

    const getModeloMenu = () => {
      return [
        {
          label: "Baixar Arquivo de Exemplo",
          icon: "fas fa-download",
          command: (event?: MenuEvent) => {
            if (event?.originalEvent) {
              event.originalEvent.preventDefault();
            }
            const { getDefaultFile, loading } = usePlanilhaTrechosService();
            state.loadings.push(loading);
            getDefaultFile(event?.originalEvent?.button == 1)
              .then(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      ];
    };

    const loadFromFile = () => {
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_fileImport"
      ) as HTMLInputElement;
      if (file && file.files && file.files.length > 0) {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        state.loadingTrechos = true;
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            const trechos = usePlanilhaTrechosService();
            state.loadings.push(trechos.loading);
            trechos
              .loadFromFile(key)
              .then(() => {
                atribuiTrechosCarga(trechos.data.value);
                state.loadings.splice(
                  state.loadings.indexOf(trechos.loading),
                  1
                );
                state.loadingTrechos = false;
                file.value = "";
                useStorage().deleteTempFile(key);
              })
              .catch(() => {
                state.loadings.splice(
                  state.loadings.indexOf(trechos.loading),
                  1
                );
                state.loadingTrechos = false;
                useStorage().deleteTempFile(key);
              });
            state.loadingTrechos = false;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          })
          .catch(() => {
            state.loadingTrechos = false;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      } else {
        useToast().warning("Selecione um arquivo.");
      }
    };

    const loadFromLicenca = () => {
      if (state.numeroLicencaImportacao && state.entity.idOrgao) {
        const trechos = usePlanilhaTrechosService();
        state.loadings.push(trechos.loading);
        state.loadingTrechos = true;
        trechos
          .loadFromLicenca(state.entity.idOrgao, state.numeroLicencaImportacao)
          .then(() => {
            atribuiTrechosCarga(trechos.data.value);
            state.loadings.splice(state.loadings.indexOf(trechos.loading), 1);
            state.loadingTrechos = false;
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(trechos.loading), 1);
            state.loadingTrechos = false;
          });
      }
    };

    const addTrecho = () => {
      state.entity.trechos.push({});
    };

    const gerarCopia = () => {
      emit("generate-copy", state.entity.idPlanilhaTrechos);
    };

    const removeTrecho = (trecho: TrechoPayload) => {
      state.entity.trechos.splice(state.entity.trechos.indexOf(trecho), 1);
    };

    onMounted(async () => {
      setEntity(props.planilhaTrechos);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      state.stateReady = true;
    });

    return {
      submitSave,
      closeScreen,
      amIAllowed,
      confirmSelectionCliente,
      stateLoading,
      stateBlocked,
      stateFullBlocked,
      loadFromFile,
      loadFromLicenca,
      afterSetOrgao,
      addTrecho,
      removeTrecho,
      gerarCopia,
      getUfSigla,
      getModeloMenu,
      afterSetUFTrecho,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorOrgaoAet,
    EntitySelectorUf
  },
  props: {
    planilhaTrechos: Object as PropType<PlanilhaTrechosPayload>,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
