import { useApiWithAuth } from "@/modules/api";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";
import { useCache } from "@/modules/cache";
import { PermissionPayload } from "@/modules/auth";
import {
  MunicipioPayload,
  CorPayload
} from "@/modules/business/common/commonBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "@/modules/business/transportador/transportadorBusiness";
import {
  FilaVeiculoPayload,
  useFilaVeiculoService
} from "@/modules/business/veiculo/filaVeiculoBusiness";

interface CarretaFilterPayload {
  idCliente?: number;
  placaNf: string;
  idTransportador?: number | null;
  numeroEixos?: number | null;
  flTipoCarreta?: string | null;
  idTipoCarroceria?: number | null;
  arquivado: boolean;
}

interface TipoCarretaPayload {
  flTipoCarreta?: string | null;
  descricao?: string | null;
  idsCarroceriasAceitas?: Array<number> | null;
  unidadeComCarga?: boolean | null;
  pesoMaximo?: number | null;
}

interface DisposicaoEixosCarretaPayload {
  id: string;
  eixosTotal: number;
  variante: boolean;
  disposicaoEixos: Array<number>;
}

interface CarretaPayload {
  idCarreta?: number | null;
  placa?: string | null;
  notaFiscal?: string | null;
  notaFiscalChaveAcesso?: string | null;
  marca?: string | null;
  modelo?: string | null;
  flExclusao?: boolean | null;
  renavam?: string | null;
  numeroEixos?: number | null;
  peso?: number | null;
  ano?: number | null;
  chassi?: string | null;
  idCor?: number | null;
  anoExercicioCrlv?: number | null;
  nomeProprietarioCrlv?: string | null;
  keyBlDocumentoCrlv?: string | null;
  keyBlDocumentoCrlvTemp?: string | null;
  idTipoCarroceria?: number | null;
  flTipoCarreta?: string | null;
  cor: CorPayload;
  municipio: MunicipioPayload;
  transportador: TransportadorPayload;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
}

interface ConsultaCarretaPlacaPayload {
  carreta: CarretaPayload;
  caminhao: boolean;
}

const useCarretaService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    patch,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const searchCarreta = (
    filter: CarretaFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/veiculo/carreta", {
      ...filter,
      ...{
        page: page
      }
    });
  };

  const getCarreta = (idCarreta: number): Promise<void> => {
    return get(`/veiculo/carreta/${idCarreta}`);
  };

  const newCarreta = (idCliente?: number | null): Promise<void> => {
    return get(
      "/veiculo/carreta/new",
      idCliente ? { idCliente: idCliente } : {}
    );
  };

  const shelveCarreta = (idCarreta: number): Promise<void> => {
    return patch("/veiculo/carreta/" + idCarreta + "/arquivar");
  };

  const unshelveCarreta = (idCarreta: number): Promise<void> => {
    return patch("/veiculo/carreta/" + idCarreta + "/desarquivar");
  };

  const saveNewCarreta = (carreta: CarretaPayload): Promise<void> => {
    return post("/veiculo/carreta", carreta);
  };

  const updateCarreta = (
    idCarreta: number,
    carreta: CarretaPayload
  ): Promise<void> => {
    return put(`/veiculo/carreta/${idCarreta}`, carreta);
  };

  const getDisposicoesEixosCarretaToCache = (): Promise<void> => {
    return get(`/veiculo/carreta/eixos`);
  };

  const getDisposicoesEixosCarreta = (
    apenasPrincipal?: boolean
  ): Array<DisposicaoEixosCarretaPayload> => {
    return useCache()
      .getDisposicoesEixosCarreta()
      .filter((e) => !apenasPrincipal || !e.variante);
  };

  const getDisposicaoEixosCarreta = (
    param?: string | null | Array<number>
  ): DisposicaoEixosCarretaPayload | null => {
    if (!param || (Array.isArray(param) && !param.length)) {
      return null;
    }
    const isArray = Array.isArray(param);
    return (
      getDisposicoesEixosCarreta(!isArray).find((a) => {
        if (isArray) {
          let isEq = param.length == a.disposicaoEixos.length;
          if (isEq) {
            for (let i = 0; i < param.length; i++) {
              if (param[i] != a.disposicaoEixos[i]) {
                isEq = false;
                break;
              }
            }
          }
          return isEq;
        } else {
          return a.id == param && !a.variante;
        }
      }) ?? null
    );
  };

  const getTiposCarretaToCache = (): Promise<void> => {
    return get(`/veiculo/carreta/tipo`);
  };

  const getTiposCarreta = (): Array<TipoCarretaPayload> => {
    return useCache().getTiposCarreta();
  };

  const getTipoCarreta = (
    flTipoCarreta?: string | null
  ): TipoCarretaPayload | null => {
    if (!flTipoCarreta) {
      return null;
    }

    return (
      getTiposCarreta().find((a) => a.flTipoCarreta == flTipoCarreta) ?? null
    );
  };

  const safeCarreta = (entity?: CarretaPayload | null): CarretaPayload => {
    const ret = entity ?? {
      transportador: useTransportadorService().safeTransportador(),
      cor: {},
      municipio: {}
    };
    ret.transportador =
      ret.transportador ?? useTransportadorService().safeTransportador();
    ret.cor = ret.cor ?? {};
    ret.municipio = ret.municipio ?? {};
    return ret;
  };

  const safeFilterCarreta = (): CarretaFilterPayload => {
    return {
      placaNf: "",
      arquivado: false
    };
  };

  const downloadCRLV = (idCarreta: number, newTab?: boolean): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/veiculo/carreta/${idCarreta}/download/crlv`
    );
  };

  const loadUsingPlateNumber = (
    placa: string,
    isRefresh: boolean,
    cliente?: ClientePayload | null
  ): Promise<void> => {
    const params: Record<string, boolean | number> = { refresh: isRefresh };
    if (cliente && cliente.idCliente) {
      params.idCliente = cliente.idCliente;
    }
    return get("/veiculo/carreta/busca/" + placa, params);
  };

  const getNomeCarreta = (entity?: CarretaPayload) => {
    if (!entity) {
      return "";
    }
    return entity.placa ? entity.placa : entity.notaFiscal;
  };

  return {
    data,
    loading,
    searchCarreta,
    saveNewCarreta,
    updateCarreta,
    newCarreta,
    getCarreta,
    safeCarreta,
    shelveCarreta,
    unshelveCarreta,
    downloadCRLV,
    loadUsingPlateNumber,
    getNomeCarreta,
    getTiposCarretaToCache,
    getTiposCarreta,
    getTipoCarreta,
    getDisposicoesEixosCarretaToCache,
    getDisposicoesEixosCarreta,
    getDisposicaoEixosCarreta,
    safeFilterCarreta
  };
};

const useFilaCarretaService = () => {
  const { data, loading, get, patch } = useApiWithAuth();

  const searchFilaCarreta = (idVeiculo: number): Promise<void> => {
    return get(`/veiculo/carreta/${idVeiculo}/fila`);
  };

  const playFilaCarreta = (
    idVeiculo: number,
    idFilaVeiculo: number | Array<number>
  ): Promise<void> => {
    if (Array.isArray(idFilaVeiculo)) {
      return patch(
        `/veiculo/carreta/${idVeiculo}/fila/solicitar`,
        idFilaVeiculo
      );
    }
    return patch(
      `/veiculo/carreta/${idVeiculo}/fila/${idFilaVeiculo}/solicitar`
    );
  };

  const markAsOkFilaCarreta = (
    idVeiculo: number,
    idFilaVeiculo: number | Array<number>
  ): Promise<void> => {
    if (Array.isArray(idFilaVeiculo)) {
      return patch(
        `/veiculo/carreta/${idVeiculo}/fila/finalizar`,
        idFilaVeiculo
      );
    }
    return patch(
      `/veiculo/carreta/${idVeiculo}/fila/${idFilaVeiculo}/finalizar`
    );
  };

  const getNomeFilaCarreta = (entity?: FilaVeiculoPayload) => {
    return useFilaVeiculoService().getNomeFilaVeiculo(entity);
  };

  return {
    data,
    loading,
    searchFilaCarreta,
    playFilaCarreta,
    markAsOkFilaCarreta,
    getNomeFilaCarreta
  };
};

export { useFilaVeiculoService };

export {
  useCarretaService,
  useFilaCarretaService,
  ConsultaCarretaPlacaPayload,
  CarretaFilterPayload,
  CarretaPayload,
  DisposicaoEixosCarretaPayload,
  TipoCarretaPayload
};
