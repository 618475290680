<template>
  <sistema>
    <div>
      <cadastro-cliente />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CadastroCliente from "../../components/cliente/CadastroCliente.vue";

@Options({
  components: {
    CadastroCliente
  }
})
export default class ClienteAdmin extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
