<template>
  <div>
    <container
      :isModal="true"
      @close-modal="closeScreen()"
      :loading="stateLoading || !stateReady"
    >
      <template v-slot:header>
        <h3>Cadastro do Engenheiro</h3>
      </template>
      <template v-slot:body>
        <template v-if="stateReady">
          <form @submit.prevent="submitSave">
            <entity-selector-cliente
              :cliente="entity.cliente"
              :canEdit="!entity.cliente?.idCliente && !stateBlocked"
              @confirm-selection="confirmSelectionCliente"
              :required="true"
              :parentLoading="stateBlocked"
            />
            <fieldset
              :disabled="
                stateBlocked ||
                  (amIAllowed('view', 'root-cliente') &&
                    !entity.cliente?.idCliente)
              "
            >
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-6">
                  <input-with-label label="Nome" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.nome"
                      maxlength="250"
                      :id="l.id"
                      :required="l.required"
                      v-focus
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label label="CPF" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.cpfCnpj"
                      v-maska="'###.###.###-##'"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-3">
                  <input-with-label label="E-mail" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.email"
                      type="email"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      :id="l.id"
                      :required="l.required"
                      maxlength="250"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="Telefone"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.telefone"
                      :id="l.id"
                      :required="l.required"
                      v-maska="[
                        '####-####',
                        '#####-####',
                        '(##) ####-####',
                        '(##) #####-####'
                      ]"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="Celular" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.celular"
                      :id="l.id"
                      :required="l.required"
                      v-maska="[
                        '####-####',
                        '#####-####',
                        '(##) ####-####',
                        '(##) #####-####'
                      ]"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="CREA" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.crea"
                      :id="l.id"
                      :required="l.required"
                      maxlength="15"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label
                    label="Registro Nacional (RNP)"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.registroNacional"
                      :id="l.id"
                      :required="l.required"
                      maxlength="15"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="CEP" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.cep"
                      :id="l.id"
                      :required="l.required"
                      v-maska="'#####-###'"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6">
                  <input-with-label
                    label="Logradouro"
                    :required="true"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.logradouro"
                      :id="l.id"
                      :required="l.required"
                      maxlength="150"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <input-with-label label="Número" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.numero"
                      :id="l.id"
                      :required="l.required"
                      maxlength="20"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-3 p-xl-3">
                  <input-with-label label="Bairro" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.bairro"
                      :id="l.id"
                      :required="l.required"
                      maxlength="150"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-9 p-xl-6">
                  <entity-selector-municipio
                    :idUnidadeFederal="entity.municipio.idUnidadeFederal"
                    :idMunicipio="entity.municipio.idMunicipio"
                    :required="true"
                    @after-set-municipio="afterSetMunicipio"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-12 p-xl-3">
                  <input-file
                    label="Imagem da Assinatura"
                    subLabel="máx 200Kb, usado no projeto técnico"
                    downloadTitle="Baixar Assinatura Atual"
                    :id="stateId + '_assinatura'"
                    :hasDownload="!!entity.assinatura.idDocumentoEngenheiro"
                    @click-download="downloadAssinatura"
                    accept="image/jpeg, image/png"
                  />
                </div>

                <div class="p-field p-col-12">
                  <search-result
                    :showSelector="false"
                    :results="entity.orgaos"
                    :loading="stateLoading"
                    :hidePagination="true"
                    :hideActions="true"
                  >
                    <template v-slot:title> Acessos </template>
                    <template v-slot:columns>
                      <Column
                        field="idOrgao"
                        header="Órgão"
                        headerClass="p-header-center"
                        style="width: 6rem; text-align: center"
                      >
                        <template #body="slotProps">
                          <orgao-aet-img :idOrgao="slotProps.data.idOrgao" />
                        </template>
                      </Column>
                      <Column field="usuario" header="Usuário">
                        <template #body="slotProps">
                          <input-with-label
                            label="Usuário"
                            v-slot="l"
                            :required="
                              !!(slotProps.data.usuario || slotProps.data.senha)
                            "
                          >
                            <InputText
                              v-model="slotProps.data.usuario"
                              :id="l.id"
                              :required="l.required"
                              maxlength="250"
                            />
                          </input-with-label>
                        </template>
                      </Column>
                      <Column field="senha" header="Senha">
                        <template #body="slotProps">
                          <input-with-label
                            label="Senha"
                            v-slot="l"
                            :required="
                              !!(slotProps.data.usuario || slotProps.data.senha)
                            "
                          >
                            <Password
                              v-model="slotProps.data.senha"
                              :id="l.id"
                              :required="l.required"
                              maxlength="250"
                              :feedback="false"
                              toggleMask
                            />
                          </input-with-label>
                        </template>
                      </Column>
                      <Column
                        field="aprovacao"
                        header="Aprovação Automática do e-Projeto"
                      >
                        <template #body="slotProps">
                          <InputSwitch
                            v-model="slotProps.data.aprovacao"
                            v-title="
                              'Ao selecionar essa opção, o GovPass APROVARÁ do e-projeto no órgão'
                            "
                          />
                        </template>
                      </Column>
                    </template>
                  </search-result>
                </div>

                <div
                  class="p-field p-col-12"
                  v-if="
                    amIAllowed('edit_art_credentials', entity.permissionInfo) &&
                      !!entity.orgaosArt
                  "
                >
                  <search-result
                    :showSelector="false"
                    :results="entity.orgaosArt"
                    :loading="stateLoading"
                    :hidePagination="true"
                    :hideActions="true"
                  >
                    <template v-slot:title> Acessos Cadastro ART </template>
                    <template v-slot:columns>
                      <Column
                        field="idOrgao"
                        header="Órgão"
                        headerClass="p-header-center"
                        style="width: 6rem; text-align: center"
                      >
                        <template #body="slotProps">
                          <orgao-art-img :idOrgao="slotProps.data.idOrgao" />
                        </template>
                      </Column>
                      <Column field="usuario" header="Usuário">
                        <template #body="slotProps">
                          <input-with-label
                            label="Usuário"
                            v-slot="l"
                            :required="
                              !!(slotProps.data.usuario || slotProps.data.senha)
                            "
                          >
                            <InputText
                              v-model="slotProps.data.usuario"
                              :id="l.id"
                              :required="l.required"
                              maxlength="250"
                            />
                          </input-with-label>
                        </template>
                      </Column>
                      <Column field="senha" header="Senha">
                        <template #body="slotProps">
                          <input-with-label
                            label="Senha"
                            v-slot="l"
                            :required="
                              !!(slotProps.data.usuario || slotProps.data.senha)
                            "
                          >
                            <Password
                              v-model="slotProps.data.senha"
                              :id="l.id"
                              :required="l.required"
                              maxlength="250"
                              :feedback="false"
                              toggleMask
                            />
                          </input-with-label>
                        </template>
                      </Column>
                    </template>
                  </search-result>
                </div>

                <div class="p-col-12">
                  <Button
                    type="submit"
                    class="p-button-rounded"
                    label="Salvar"
                  />
                </div>
              </div>
            </fieldset>
          </form>
        </template>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref,
  PropType
} from "vue";
import { useMunicipioService } from "../../modules/business/common/commonBusiness";
import { useStorage } from "../../modules/storage";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useToast } from "../../modules/message";
import {
  EngenheiroPayload,
  useEngenheiroService
} from "../../modules/business/engenheiro/engenheiroBusiness";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import EntitySelectorMunicipio from "../common/EntitySelectorMunicipio.vue";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";

interface EngenheiroCadastroState {
  entity: EngenheiroPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EngenheiroCadastroState>({
      entity: useEngenheiroService().safeEngenheiro(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const setEntity = (entity: EngenheiroPayload) => {
      state.entity = useEngenheiroService().safeEngenheiro(entity);
      const assinElement = document.querySelector(
        "#" + state.stateId + "_assinatura"
      ) as HTMLInputElement;
      if (assinElement) {
        assinElement.value = "";
      }
    };

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const downloadAssinatura = (newTab: boolean, onEnd: Function) => {
      if (state.entity.assinatura.idDocumentoEngenheiro) {
        const { downloadAssinatura, loading } = useEngenheiroService();
        state.loadings.push(loading);
        downloadAssinatura(
          state.entity.assinatura.idDocumentoEngenheiro ?? 0,
          newTab
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
            onEnd();
          });
      } else {
        onEnd();
      }
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const save = () => {
      const idEngenheiroProps = props.idEngenheiro ?? 0;
      const isInsert = !idEngenheiroProps;
      const {
        saveNewEngenheiro,
        updateEngenheiro,
        loading: loadingCadastro,
        data: dataCadastro
      } = useEngenheiroService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewEngenheiro(state.entity)
        : updateEngenheiro(idEngenheiroProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Engenheiro salvo com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idEngenheiro);
          }
        })
        .catch(() => {
          if (state.entity.assinatura.keyBlDocumentoTemp) {
            useStorage().deleteTempFile(
              state.entity.assinatura.keyBlDocumentoTemp
            );
            state.entity.assinatura.keyBlDocumentoTemp = null;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const submitSave = () => {
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_assinatura"
      ) as HTMLInputElement;

      if (!file || !file.files || file.files.length === 0) {
        save();
      } else {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            state.entity.assinatura.keyBlDocumentoTemp = key;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            save();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      }
    };

    onMounted(async () => {
      const {
        getEngenheiro,
        newEngenheiro,
        data,
        loading
      } = useEngenheiroService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      (props.idEngenheiro
        ? getEngenheiro(props.idEngenheiro)
        : newEngenheiro(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      downloadAssinatura,
      municipiosFiltrados,
      amIAllowed,
      confirmSelectionCliente,
      stateLoading,
      stateBlocked,
      afterSetMunicipio,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorMunicipio
  },
  props: {
    idEngenheiro: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
