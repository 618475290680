<template>
  <div>
    <container
      :isModal="true"
      @close-modal="closeScreen()"
      :loading="stateLoading || !stateReady"
    >
      <template v-slot:header>
        <h3>Cadastro do Carreta</h3>
      </template>
      <template v-slot:body>
        <template v-if="stateReady">
          <form @submit.prevent="submitSave">
            <entity-selector-cliente
              :cliente="entity.cliente"
              :canEdit="!entity.cliente?.idCliente && !stateBlocked"
              @confirm-selection="confirmSelectionCliente"
              :required="true"
              :parentLoading="stateBlocked"
            />
            <fieldset :disabled="stateFullBlocked">
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-6 p-xl-3">
                  <div class="p-inputgroup">
                    <input-with-label
                      label="Placa"
                      v-slot="l"
                      :required="entity.placa || !entity.notaFiscal"
                    >
                      <InputText
                        v-model="entity.placa"
                        maxlength="7"
                        minlength="7"
                        v-maska="{
                          mask: 'AAA#H###',
                          tokens: {
                            H: { pattern: /[0-9a-zA-Z]/, uppercase: true }
                          }
                        }"
                        @change="onChangePlaca()"
                        :id="l.id"
                        :required="l.required"
                        v-focus
                      />
                    </input-with-label>
                    <Button
                      v-if="entity.idCarreta || loadingPlates"
                      :disabled="loadingPlates"
                      v-title="'Recarregar Dados da Placa'"
                      style="min-width: initial"
                      @click="loadPlates(true)"
                      class="p-button-sm p-button-icon-only"
                    >
                      <ProgressSpinner
                        v-if="loadingPlates"
                        style="width: 1.2rem; height: 1.2rem"
                      />
                      <i class="fas fa-sync-alt" style="width: 1.2rem" v-else />
                    </Button>
                  </div>
                  <template v-if="!loadingPlates && lastLoadedPlate">
                    <span style="float: right; color: red" class="p-pr-2">
                      <small
                        v-if="lastLoadedPlate?.carreta?.renavam === undefined"
                      >
                        <em
                          >NÃO FOI POSSÍVEL BUSCAR AS INFORMAÇÕES DO VEÍCULO
                          !</em
                        >
                      </small>
                      <small v-if="lastLoadedPlate?.carreta?.idCarreta">
                        <em>A CARRETA JÁ ESTA CADASTRADA !</em>
                      </small>
                      <small v-if="lastLoadedPlate?.caminhao === false">
                        <em
                          >A <b>PLACA</b> INFORMADA PERTENCE À UM
                          <b>CARRO</b> OU <b>MOTO</b> !</em
                        >
                      </small>
                    </span>
                  </template>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-xl-3">
                  <input-with-label
                    label="Nota Fiscal"
                    subLabel="veículos novos sem placa"
                    :required="!entity.placa"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.notaFiscal"
                      maxlength="50"
                      v-maska="{
                        mask: 'H*',
                        tokens: { H: { pattern: /./, uppercase: true } }
                      }"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-xl-6">
                  <input-with-label
                    label="Chave de Acesso(NF)"
                    subLabel="apenas números, sem os espaços"
                    :required="entity.notaFiscal"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.notaFiscalChaveAcesso"
                      minlength="44"
                      maxlength="44"
                      v-maska="'#*'"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-xl-6">
                  <entity-selector-transportador
                    :transportador="entity.transportador"
                    label="Proprietário"
                    @confirm-selection="confirmSelectionTransportador"
                    :required="true"
                    :cliente="entity.cliente"
                    :idTransportadorFiltered="
                      entity.transportador.idTransportador
                    "
                  />
                </div>
                <div class="p-field p-fluid p-col-12 p-md-4 p-xl-2">
                  <input-with-label label="Marca" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.marca"
                      minlength="3"
                      maxlength="50"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-8 p-xl-4">
                  <input-with-label label="Modelo" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.modelo"
                      minlength="3"
                      maxlength="50"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-xl-3">
                  <input-with-label label="RENAVAM" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.renavam"
                      maxlength="11"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-xl-3">
                  <input-with-label label="Chassi" :required="true" v-slot="l">
                    <InputText
                      v-model="entity.chassi"
                      v-maska="{
                        mask: 'HHHHHHHHHHHHHHHHH',
                        tokens: {
                          H: { pattern: /[0-9a-zA-Z]/, uppercase: true }
                        }
                      }"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-6 p-xl-3">
                  <year-picker
                    v-model="entity.ano"
                    label="Ano De Fabricação"
                    :required="true"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6 p-xl-3">
                  <entity-selector-municipio
                    :idUnidadeFederal="entity.municipio.idUnidadeFederal"
                    :idMunicipio="entity.municipio.idMunicipio"
                    :required="true"
                    @after-set-municipio="afterSetMunicipio"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-4 p-xl-2">
                  <input-with-label
                    label="Número de Eixos"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.numeroEixos"
                      :min="1"
                      :max="5"
                      :step="1"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-4 p-xl-2">
                  <input-with-label
                    label="Peso/Tara"
                    subLabel="kg"
                    :required="true"
                    v-slot="l"
                  >
                    <InputNumber
                      v-model="entity.peso"
                      :min="1000"
                      :max="tipoCarretaSelecionada?.pesoMaximo ?? 24999"
                      :step="1"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-4 p-xl-2">
                  <input-with-label label="Cor" :required="true" v-slot="l">
                    <Dropdown
                      v-model="entity.cor.idCor"
                      :options="cores"
                      optionLabel="descricao"
                      optionValue="idCor"
                      :placeholder="'&nbsp;'"
                      :showClear="true"
                      :id="l.id"
                      :required="l.required"
                      :disabled="stateFullBlocked"
                    />
                  </input-with-label>
                </div>

                <div
                  class="p-field p-fluid p-col-12 p-md-6"
                  :class="possuiCarroceria ? 'p-xl-3' : ''"
                >
                  <input-with-label
                    label="Tipo De Carreta"
                    :required="true"
                    v-slot="l"
                  >
                    <Dropdown
                      v-model="entity.flTipoCarreta"
                      :options="tiposCarretas"
                      optionLabel="descricao"
                      optionValue="flTipoCarreta"
                      :placeholder="'&nbsp;'"
                      :showClear="true"
                      :id="l.id"
                      :required="l.required"
                      :disabled="stateFullBlocked"
                    />
                  </input-with-label>
                </div>
                <div
                  v-if="possuiCarroceria"
                  class="p-field p-fluid p-col-12 p-md-6 p-xl-3"
                >
                  <input-with-label
                    label="Tipo De Carroceria"
                    :required="true"
                    v-slot="l"
                  >
                    <Dropdown
                      v-model="entity.idTipoCarroceria"
                      :options="tiposCarroceriasFiltrados"
                      optionLabel="descricao"
                      optionValue="idTipoCarroceria"
                      :placeholder="'&nbsp;'"
                      :showClear="true"
                      :id="l.id"
                      :required="l.required"
                      :disabled="stateFullBlocked"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12">
                  <input-file
                    label="Documento"
                    downloadTitle="Baixar CRLV"
                    :id="stateId + '_crlv'"
                    :hasDownload="!!entity.keyBlDocumentoCrlv"
                    @click-download="downloadCRLV"
                    @update="updateCRLV"
                    accept="image/jpeg, image/png, application/pdf"
                  />
                </div>
                <div class="p-field p-fluid p-col-12 p-md-3">
                  <year-picker
                    v-model="entity.anoExercicioCrlv"
                    label="Ano Exercício do Documento"
                    :required="
                      !!entity.keyBlDocumentoCrlv ||
                        !!entity.keyBlDocumentoCrlvTemp
                    "
                  />
                </div>
                <div class="p-field p-fluid p-col-12 p-md-9">
                  <input-with-label
                    label="Nome Proprietário Conforme Documento"
                    subLabel="Apenas para se nome for abreviado no doc"
                    v-slot="l"
                  >
                    <InputText
                      v-model="entity.nomeProprietarioCrlv"
                      maxlength="250"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>
              </div>
              <div class="p-formgrid p-grid" v-if="entity.idCarreta">
                <crud-fila-veiculo
                  :idVeiculo="entity.idCarreta"
                  :isVeiculoTrator="false"
                  label="Cadastro nos Órgaos"
                  :key="stateId"
                />
              </div>

              <div class="p-col-12">
                <Button type="submit" class="p-button-rounded" label="Salvar" />
              </div>
            </fieldset>
          </form>
        </template>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  watch,
  Ref,
  PropType
} from "vue";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { useToast, useConfirm } from "../../../modules/message";
import { useCache } from "../../../modules/cache";
import { useMunicipioService } from "../../../modules/business/common/commonBusiness";
import { useStorage } from "../../../modules/storage";
import {
  formatDateTime,
  clearObject,
  copyObject
} from "../../../modules/utils";
import {
  CarretaPayload,
  ConsultaCarretaPlacaPayload,
  TipoCarretaPayload,
  useCarretaService
} from "../../../modules/business/veiculo/carretaBusiness";
import { useTipoCarroceriaService } from "../../../modules/business/veiculo/carroceriaBusiness";
import EntitySelectorCliente from "../../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import EntitySelectorTransportador from "../../transportador/EntitySelectorTransportador.vue";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import EntitySelectorMunicipio from "../../common/EntitySelectorMunicipio.vue";
import CrudFilaVeiculo from "../../veiculo/CrudFilaVeiculo.vue";

interface CarretaCadastroState {
  entity: CarretaPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
  loadingPlates: boolean;
  lastLoadedPlate: ConsultaCarretaPlacaPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<CarretaCadastroState>({
      entity: useCarretaService().safeCarreta(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: [],
      lastLoadedPlate: null,
      loadingPlates: false
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });
    const stateFullBlocked = computed(() => {
      return !!(
        stateBlocked.value ||
        (amIAllowed("view", "root-cliente") && !state.entity.cliente?.idCliente)
      );
    });

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const tiposCarretas = useCarretaService().getTiposCarreta();

    const tipoCarretaSelecionada = computed(() => {
      if (state.entity.flTipoCarreta) {
        const tipo: TipoCarretaPayload = tiposCarretas.filter(
          (t) => t.flTipoCarreta == state.entity.flTipoCarreta
        )[0];
        return tipo;
      } else {
        return null;
      }
    });

    const possuiCarroceria = computed(() => {
      return !!tipoCarretaSelecionada.value?.unidadeComCarga;
    });

    const tiposCarroceriasFiltrados = computed(() => {
      if (
        tipoCarretaSelecionada.value &&
        tipoCarretaSelecionada.value?.idsCarroceriasAceitas
      ) {
        return useTipoCarroceriaService()
          .getTiposCarroceria()
          .filter(
            (t) =>
              t.idTipoCarroceria &&
              (
                tipoCarretaSelecionada.value?.idsCarroceriasAceitas ?? []
              ).indexOf(t.idTipoCarroceria) > -1
          );
      } else {
        return [];
      }
    });

    watch([tipoCarretaSelecionada], () => {
      if (!tipoCarretaSelecionada.value) {
        state.entity.idTipoCarroceria = null;
      } else if (
        state.entity.idTipoCarroceria &&
        (tipoCarretaSelecionada.value?.idsCarroceriasAceitas ?? []).indexOf(
          state.entity.idTipoCarroceria
        ) <= -1
      ) {
        state.entity.idTipoCarroceria = null;
      } else if (
        tipoCarretaSelecionada.value?.idsCarroceriasAceitas?.length == 1
      ) {
        state.entity.idTipoCarroceria =
          tipoCarretaSelecionada.value.idsCarroceriasAceitas[0];
      }
    });

    const cores = useCache().getCores();

    const setEntity = (entity: CarretaPayload) => {
      state.entity = useCarretaService().safeCarreta(entity);
      const docElement = document.querySelector(
        "#" + state.stateId + "_crlv"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const getTransportadorNome = (e: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      if (t) {
        state.entity.transportador = t;
      } else {
        state.entity.transportador = useCarretaService().safeCarreta().transportador;
      }
    };

    const downloadCRLV = (newTab: boolean, onEnd: Function) => {
      const { downloadCRLV, loading } = useCarretaService();
      if (state.entity.idCarreta) {
        state.loadings.push(loading);
        downloadCRLV(state.entity.idCarreta, newTab)
          .then(() => {
            onEnd();
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            onEnd();
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        onEnd();
      }
    };

    const updateCRLV = () => {
      useConfirm().require({
        message:
          "O documento será atualizado, Deseja alterar o ano Exercício para o ano atual?",
        header: "Confirmação",
        icon: "pi pi-info-circle",
        accept: () => {
          state.entity.anoExercicioCrlv = new Date().getFullYear();
        }
      });
    };

    const loadPlates = (isRefresh: boolean) => {
      if (state.entity.placa?.length == 7) {
        const { loadUsingPlateNumber, loading, data } = useCarretaService();
        state.loadings.push(loading);
        state.loadingPlates = true;
        loadUsingPlateNumber(
          state.entity.placa,
          isRefresh,
          state.entity.cliente
        )
          .then(() => {
            state.lastLoadedPlate = data.value;
            if (state.lastLoadedPlate) {
              const carreta = state.lastLoadedPlate.carreta;
              if (carreta.idCarreta) {
                if (!isRefresh) {
                  emit("request-entity-edit", carreta.idCarreta);
                }
              } else {
                delete carreta["idCarreta"];
                clearObject(carreta);
                if (
                  carreta.municipio &&
                  carreta.municipio.idUnidadeFederal &&
                  carreta.municipio.idUnidadeFederal !=
                    state.entity.municipio.idUnidadeFederal
                ) {
                  state.entity.municipio.idUnidadeFederal =
                    carreta.municipio.idUnidadeFederal;
                }
                copyObject(carreta, state.entity, ["municipio"]);
              }
            }
            state.loadingPlates = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadingPlates = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        useToast().warning("Placa inválida");
      }
    };

    const onChangePlaca = () => {
      if (!state.entity.idCarreta && state.entity.placa?.length == 7) {
        loadPlates(false);
      }
    };

    const save = () => {
      const idCarretaProps = props.idCarreta ?? 0;
      const isInsert = !idCarretaProps;
      const {
        saveNewCarreta,
        updateCarreta,
        loading: loadingCadastro,
        data: dataCadastro
      } = useCarretaService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewCarreta(state.entity)
        : updateCarreta(idCarretaProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Carreta salva com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idCarreta);
          }
        })
        .catch(() => {
          if (state.entity.keyBlDocumentoCrlvTemp) {
            useStorage().deleteTempFile(state.entity.keyBlDocumentoCrlvTemp);
            state.entity.keyBlDocumentoCrlvTemp = null;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const submitSave = () => {
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_crlv"
      ) as HTMLInputElement;
      if (!file || !file.files || file.files.length === 0) {
        save();
      } else {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            state.entity.keyBlDocumentoCrlvTemp = key;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            save();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      }
    };

    onMounted(async () => {
      const { getCarreta, newCarreta, data, loading } = useCarretaService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      (props.idCarreta
        ? getCarreta(props.idCarreta)
        : newCarreta(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      amIAllowed,
      amIBlocked,
      formatDateTime,
      confirmSelectionCliente,
      confirmSelectionTransportador,
      getTransportadorNome,
      stateLoading,
      stateFullBlocked,
      stateBlocked,
      municipiosFiltrados,
      possuiCarroceria,
      tiposCarroceriasFiltrados,
      tiposCarretas,
      tipoCarretaSelecionada,
      cores,
      downloadCRLV,
      updateCRLV,
      onChangePlaca,
      loadPlates,
      afterSetMunicipio,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorTransportador,
    EntitySelectorMunicipio,
    CrudFilaVeiculo
  },
  props: {
    idCarreta: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
