
import CadastroCliente from "./CadastroCliente.vue";
import { PaginationResult, defaultResults } from "../../pagination";
import { useAuth, PermissionPayload } from "../../modules/auth";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { useRouter, RouteLocationNormalizedLoaded } from "vue-router";
import {
  ClientePayload,
  ClienteFilterPayload,
  useClienteService
} from "../../modules/business/cliente/clienteBusiness";
import {
  indexesOfElement,
  addSetElement,
  toggleElement,
  format,
  copyObject,
  clearObject
} from "../../modules/utils";
import { useConfirm } from "../../modules/message";

interface ClienteComponentParams {
  isSearch: boolean;
  selected: Array<ClientePayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idCliente?: number;
  preFilter?: ClienteFilterPayload;
}

interface ClienteState {
  idCliente: number | null;
  showEdit: boolean;
  results: PaginationResult<ClientePayload>;
  filter: ClienteFilterPayload;
  page: number;
  stateReady: boolean;
  loadings: Array<Ref<boolean>>;
  params: ClienteComponentParams;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<ClienteState>({
      idCliente: null,
      showEdit: false,
      stateReady: false,
      filter: useClienteService().safeFilterCliente(),
      page: 0,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idCliente: props.idCliente,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple,
        preFilter: props.preFilter
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    copyObject(clearObject(props.preFilter), state.filter);

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const executeSearch = () => {
      const { data, loading, searchCliente } = useClienteService();
      state.loadings.push(loading);
      return searchCliente(state.filter, state.page)
        .then(() => {
          state.results = data.value;
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const getParamsRouteEdit = (idCliente: number) => {
      return {
        name: "root-cliente-edit",
        params: { idCliente: idCliente + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (route.name == "root-cliente-edit") {
        if (route.params && route.params.idCliente) {
          state.idCliente = Array.isArray(route.params.idCliente)
            ? +route.params.idCliente[0]
            : +route.params.idCliente;
        } else {
          state.idCliente = null;
        }
        state.showEdit = true;
      } else {
        state.showEdit = false;
        state.idCliente = null;
        executeSearch();
      }
    };

    const confirmSelection = (selected: Array<ClientePayload>) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const openEntity = (idCliente: number, newTab?: boolean) => {
      const routeParams = getParamsRouteEdit(idCliente);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = () => {
      router.push({ name: "root-tenant" });
    };

    const afterRegisterScreenClosed = (cliente?: ClientePayload) => {
      const routeParams = {
        name: "root-cliente"
      };
      if (state.params.isSearch) {
        if (state.params.idCliente) {
          if (cliente && cliente.idCliente) {
            confirmSelection([cliente]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            cliente &&
            indexesOfElement(
              state.params.selected,
              cliente,
              (ob) => ob && ob.idCliente
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  cliente,
                  (ob) => ob && ob.idCliente,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
            toggleElement(
              state.params.selected,
              cliente,
              (ob) => ob && ob.idCliente,
              state.params.isMultiple
            );
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterPageChange = (page: number) => {
      state.page = page;
      executeSearch();
    };

    const clearFilter = () => {
      state.filter = useClienteService().safeFilterCliente();
      state.results = defaultResults();
    };

    const deveAbrirTelaCadastro = state.params.idCliente || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idCliente) {
        state.idCliente = state.params.idCliente;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
      if (!deveAbrirTelaCadastro) {
        submitSearch().then(() => {
          if (
            state.params.preFilter &&
            state.params.preFilter.idCliente &&
            state.results.content.length == 1
          ) {
            const arr = [] as Array<ClientePayload>;
            state.params.selected.forEach((el) => arr.push(el));
            addSetElement(
              arr,
              state.results.content[0],
              (ob) => ob && ob.idCliente,
              state.params.isMultiple
            );
            confirmSelection(arr);
          }
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterPageChange,
      clearFilter,
      amIAllowed,
      format,
      getParamsRouteEdit,
      stateLoading,
      ...toRefs(state)
    };
  },
  components: {
    CadastroCliente
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idCliente: Number,
    preFilter: Object as PropType<ClienteFilterPayload>,
    selected: {
      type: Array as PropType<Array<ClientePayload>>
    }
  }
});
