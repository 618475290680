/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { computed, ref, watch } from "vue";
import { useAuth } from "@/modules/auth";
import { useCache } from "@/modules/cache";
import {
  processObject,
  parseISODate,
  toUTCDate,
  toUTCDateTime
} from "@/modules/utils";
import { useRouter } from "@/modules/globalAppProperties";
import { useToast } from "@/modules/message";

const { cacheLoaded } = useCache();

export const systemEnabled = computed(() => {
  return !!cacheLoaded?.value;
});

export interface DownloadViewReturn {
  url: string;
  destroy: Function;
}

export const refreshDmlToken = ref(
  Math.random()
    .toString(36)
    .substring(2)
);

export const useApi = (defaultAuth?: boolean) => {
  const { getAuthToken } = useAuth();
  const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? "/aet"
        : "https://aet-api.govpass.com.br/aet"
  });

  const data = ref();
  const responseHeaders = ref();
  const loading = ref(false);
  const error = ref();

  api.interceptors.request.use((request: AxiosRequestConfig) => {
    if (request) {
      if (!request.headers) {
        request.headers = {};
      }
      request.headers["Cache-Control"] = "no-cache";
      if (request.data) {
        if (request.data instanceof FormData) {
          request.headers["Content-Type"] = "multipart/form-data";
        } else {
          request.data = JSON.parse(JSON.stringify(request.data));
          processObject((key: string, obj: any) => {
            //Chumbados campos Data
            if (
              key.indexOf("data") == 0 ||
              key.indexOf("dt") == 0 ||
              key.indexOf("date") == 0
            ) {
              const ret = toUTCDate(obj);
              if (ret) {
                return ret;
              }
            }
            if (key.indexOf("timestamp") == 0 || key.indexOf("ts") == 0) {
              const ret = toUTCDateTime(obj);
              if (ret) {
                return ret;
              }
            }
          }, request.data);
        }
      }
    }
    return request;
  });

  api.interceptors.response.use((response: AxiosResponse) => {
    if (response && response.data) {
      if (
        response.status == 200 &&
        typeof response.data.totalElements !== "undefined" &&
        response.data.totalElements == 0
      ) {
        useToast().info(
          "Consulta realizada com sucesso, mas não retornou nenhum resultado.",
          { life: 3000 }
        );
      }
      processObject((key: string, obj: any) => {
        //Chumbados campos Data
        if (
          key.indexOf("data") == 0 ||
          key.indexOf("dt") == 0 ||
          key.indexOf("date") == 0 ||
          key.indexOf("timestamp") == 0 ||
          key.indexOf("ts") == 0
        ) {
          const ret = parseISODate(obj);
          if (ret) {
            return ret;
          }
        }
      }, response.data);
    }
    return response;
  });

  const processAuthHeaders = (configPrm?: AxiosRequestConfig) => {
    const config: AxiosRequestConfig = configPrm ?? {};
    if (defaultAuth) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = getAuthToken();
    }
    return config;
  };

  const post = (
    endpoint: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return new Promise<void>((resolve, reject) => {
      loading.value = true;
      error.value = undefined;
      api
        .post(endpoint, payload, processAuthHeaders(config))
        .then((res: AxiosResponse) => {
          responseHeaders.value = res.headers;
          data.value = res.data;
          refreshDmlToken.value = Math.random()
            .toString(36)
            .substring(2);
          resolve();
        })
        .catch((e) => {
          error.value = e;
          reject(e);
          throw e;
        })
        .finally(() => (loading.value = false));
    });
  };
  const put = (
    endpoint: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return new Promise<void>((resolve, reject) => {
      loading.value = true;
      error.value = undefined;
      api
        .put(endpoint, payload, processAuthHeaders(config))
        .then((res: AxiosResponse) => {
          responseHeaders.value = res.headers;
          data.value = res.data;
          refreshDmlToken.value = Math.random()
            .toString(36)
            .substring(2);
          resolve();
        })
        .catch((e) => {
          error.value = e;
          reject(e);
          throw e;
        })
        .finally(() => (loading.value = false));
    });
  };
  const patch = (
    endpoint: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return new Promise<void>((resolve, reject) => {
      loading.value = true;
      error.value = undefined;
      api
        .patch(endpoint, payload, processAuthHeaders(config))
        .then((res: AxiosResponse) => {
          responseHeaders.value = res.headers;
          data.value = res.data;
          refreshDmlToken.value = Math.random()
            .toString(36)
            .substring(2);
          resolve();
        })
        .catch((e) => {
          error.value = e;
          reject(e);
          throw e;
        })
        .finally(() => (loading.value = false));
    });
  };
  const get = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return new Promise<void>((resolve, reject) => {
      loading.value = true;
      error.value = undefined;

      let queryString = "";

      if (query) {
        queryString =
          "?" +
          Object.entries(query)
            .filter(
              ([key, value]) =>
                typeof value !== "undefined" &&
                value !== "" &&
                value != null &&
                typeof key !== "undefined"
            )
            .map(([key, value]) => {
              if (
                key.indexOf("data") == 0 ||
                key.indexOf("dt") == 0 ||
                key.indexOf("date") == 0
              ) {
                if (value instanceof Date) {
                  value = isNaN(value.getTime())
                    ? null
                    : value.toISOString().substring(0, 10);
                }
              }
              return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            })
            .join("&");
      }
      api
        .get(endpoint + queryString, processAuthHeaders(config))
        .then((res: AxiosResponse) => {
          responseHeaders.value = res.headers;
          data.value = res.data;
          resolve();
        })
        .catch((e) => {
          error.value = e;
          reject(e);
          throw e;
        })
        .finally(() => (loading.value = false));
    });
  };

  const getPosting = (
    endpoint: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return new Promise<void>((resolve, reject) => {
      loading.value = true;
      error.value = undefined;
      api
        .post(endpoint, payload, processAuthHeaders(config))
        .then((res: AxiosResponse) => {
          responseHeaders.value = res.headers;
          data.value = res.data;
          resolve();
        })
        .catch((e) => {
          error.value = e;
          reject(e);
          throw e;
        })
        .finally(() => (loading.value = false));
    });
  };

  const del = (endpoint: string, config?: AxiosRequestConfig) => {
    return new Promise<void>((resolve, reject) => {
      loading.value = true;
      error.value = undefined;
      api
        .delete(endpoint, processAuthHeaders(config))
        .then((res: AxiosResponse) => {
          responseHeaders.value = res.headers;
          data.value = res.data;
          refreshDmlToken.value = Math.random()
            .toString(36)
            .substring(2);
          resolve();
        })
        .catch((e) => {
          error.value = e;
          reject(e);
          throw e;
        })
        .finally(() => (loading.value = false));
    });
  };

  const internalDownloadFileInternal = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig,
    newtab?: boolean,
    isPost?: boolean,
    directDownload?: boolean
  ) => {
    return new Promise<void | DownloadViewReturn>((resolve, reject) => {
      const conf = config ?? {};
      if (!conf.headers) {
        conf.headers = {};
      }
      conf.responseType = "arraybuffer";
      conf.headers["Cache-Control"] = "no-cache";
      if (!newtab) {
        conf.headers["Content-Disposition"] = "attachment";
      }
      (isPost ? getPosting : get)(endpoint, query, conf)
        .then(() => {
          const urlManager = window.URL || window.webkitURL;
          const contentType =
            responseHeaders.value["Content-Type"] ||
            responseHeaders.value["content-type"];
          if (directDownload) {
            const urlResult = urlManager.createObjectURL(
              new Blob([data.value], {
                type: contentType
              })
            );
            resolve({
              url: urlResult,
              destroy: () => urlManager.revokeObjectURL(urlResult)
            } as DownloadViewReturn);
          } else {
            const link = document.createElement("a");
            link.style.display = "none";
            document.body.appendChild(link);
            if (newtab) {
              link.target = "_blank";
            }

            const isTextPlain =
              contentType == "text/plain" || contentType == "application/json";
            if (isTextPlain) {
              link.href = new TextDecoder().decode(new Uint8Array(data.value));
            } else {
              const file = new Blob([data.value], {
                type: contentType
              });
              link.href = urlManager.createObjectURL(file);
              const contentDisposition =
                responseHeaders.value["Content-Disposition"] ||
                responseHeaders.value["content-disposition"];
              link.download = contentDisposition
                .split(";")[1]
                .trim()
                .split("=")[1]
                .replaceAll('"', "");
            }

            link.click();
            if (!isTextPlain) {
              window.URL.revokeObjectURL(link.href);
            }
            link.remove();
            resolve();
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const downloadFile = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return internalDownloadFileInternal(
      endpoint,
      query,
      config,
      false,
      false,
      false
    ).then((d) => d as void);
  };

  const downloadFileNewTab = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return internalDownloadFileInternal(
      endpoint,
      query,
      config,
      true,
      false,
      false
    ).then((d) => d as void);
  };

  const downloadFilePosting = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return internalDownloadFileInternal(
      endpoint,
      query,
      config,
      false,
      true,
      false
    ).then((d) => d as void);
  };

  const downloadFilePostingNewTab = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return internalDownloadFileInternal(
      endpoint,
      query,
      config,
      true,
      true,
      false
    ).then((d) => d as void);
  };

  const downloadToView = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return internalDownloadFileInternal(
      endpoint,
      query,
      config,
      false,
      false,
      true
    ).then((d) => d as DownloadViewReturn);
  };

  const downloadToViewPosting = (
    endpoint: string,
    query?: Record<string, any>,
    config?: AxiosRequestConfig
  ) => {
    return internalDownloadFileInternal(
      endpoint,
      query,
      config,
      false,
      true,
      true
    ).then((d) => d as DownloadViewReturn);
  };

  const errorMessage = computed(() => {
    if (error.value) {
      return error.value.message;
    }
    return "";
  });

  const errorDetails = computed(() => {
    if (error.value && error.value.response) {
      return error.value.response.data.message;
    }
    return "";
  });

  const errorFields = computed(() => {
    if (error.value && Array.isArray(error.value.response.data.message)) {
      return (error.value.response.data.message as string[]).reduce(
        (acc: Record<string, any>, msg: string) => {
          const [field] = msg.split(" ");

          if (!acc[field]) {
            acc[field] = [];
          }

          acc[field].push(msg);

          return acc;
        },
        {}
      );
    }
    return [];
  });

  const computedClasses = (key: string) => {
    if (errorFields.value?.hasOwnProperty(key)) {
      return ["border-red-600", "bg-red-200", "text-red-900"];
    }
    return ["border-grey-600", "bg-white", "text-gray-900"];
  };

  watch([error], () => {
    if (error.value && error.value.response) {
      const response = error.value.response;
      const request = error.value.request;
      let responseData = response.data;
      if (responseData instanceof ArrayBuffer) {
        responseData = JSON.parse(
          new TextDecoder("utf-8").decode(responseData)
        );
      }
      let responseError = "";
      responseError =
        (responseData.errors && responseData.errors[0].defaultMessage) ||
        responseData.message ||
        response.status + " - " + (responseData.error || response.statusText);
      responseError = responseError.replace(/(?:\r\n|\r|\n)/g, "<br/>");
      if (responseData.imageBase64) {
        const link =
          "<img src=data:image/png;base64," + responseData.imageBase64 + " />";
        responseError +=
          ' (<a href=\'javascript:window.open("").document.write("' +
          link +
          "\")'>clique aqui para mais detalhes</a>)";
      }
      if (response.status >= 500) {
        useToast().error(`<b>Ops! </b>${responseError}`, { life: 30000 });
      } else {
        useToast().warning(`<b>Ops! </b>${responseError}`, { life: 30000 });
      }

      const router = useRouter();
      if (
        response.status === 401 &&
        router &&
        router.currentRoute.value.name != "login" &&
        (request.responseURL ? request.responseURL : "").indexOf(
          useAuth().getPrincipalPath()
        ) >= 0
      ) {
        router.push({ name: "login" });
      }
    }
  });

  return {
    loading,
    data,
    error,
    get,
    post,
    getPosting,
    put,
    patch,
    del,
    downloadFile,
    downloadFileNewTab,
    downloadFilePosting,
    downloadFilePostingNewTab,
    downloadToView,
    downloadToViewPosting,
    responseHeaders,
    errorMessage,
    errorDetails,
    errorFields,
    computedClasses
  };
};

export const useApiWithAuth = () => {
  return useApi(true);
};
