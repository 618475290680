import { useApiWithAuth } from "@/modules/api";
import { ref } from "vue";

export const useStorage = () => {
  const loading = ref(false);

  const uploadTempFile = (formData: FormData): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const { data, post } = useApiWithAuth();
      loading.value = true;
      post("/storage/temp", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(() => {
          loading.value = false;
          resolve(data.value);
        })
        .catch((e) => {
          loading.value = false;
          reject(e);
        });
    });
  };

  const deleteTempFile = (key: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { del } = useApiWithAuth();
      loading.value = true;

      del(`/storage/temp/${key}`)
        .then(() => {
          loading.value = false;
          resolve();
        })
        .catch((e) => {
          loading.value = false;
          reject(e);
        });
    });
  };

  return {
    loading,
    uploadTempFile,
    deleteTempFile
  };
};
