<template>
  <div v-if="stateReady">
    <div>
      <entity-selector
        :entities="entitiesSelected"
        :keyParam="'idPlanilhaTrechos'"
        :required="required"
        :canEdit="canEdit"
        :label="label"
        :showLabel="showLabel"
        ref="es"
        @open-entity="openEntity"
        @open-search="openSearch"
        @remove-entity="clearEntity"
      >
        <template v-slot:id="slotProps">
          <span v-text="getNome(slotProps.entity)" />
        </template>
        <template v-slot:selected="slotProps">
          <input-with-label :label="label" :required="required" v-slot="l">
            <input
              type="text"
              class="p-inputtext p-component p-filled"
              style="opacity: 1"
              :disabled="true"
              :id="l.id"
              :required="l.required"
              :value="getNome(slotProps.entity)"
            />
          </input-with-label>
        </template>
      </entity-selector>
      <crud-planilha-trechos
        v-if="crudOpen"
        :isSearch="true"
        :idPlanilhaTrechos="idPlanilhaTrechosToView"
        :selected="entitiesToSelect"
        :cliente="cliente"
        :preFilter="preFilter"
        @confirm-selection="confirmSelection"
        @cancel-selection="cancelSelection"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  computed
} from "vue";
import CrudPlanilhaTrechos from "./CrudPlanilhaTrechos.vue";
import {
  PlanilhaTrechosPayload,
  usePlanilhaTrechosService
} from "../../modules/business/trecho/planilhaTrechosBusiness";
import { format } from "../../modules/utils";

import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";

interface ModeloVeiculoSelectorState {
  stateReady: boolean;
  crudOpen: boolean;
  idPlanilhaTrechosToView?: number | null;
  entitiesToSelect: Array<PlanilhaTrechosPayload>;
  planilhaTrechos?: PlanilhaTrechosPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ModeloVeiculoSelectorState>({
      stateReady: false,
      crudOpen: false,
      idPlanilhaTrechosToView: null,
      entitiesToSelect: []
    });

    const es = ref();

    const preFilter = computed(() => {
      const pf = usePlanilhaTrechosService().safeFilterPlanilhaTrecho();
      if (props.idOrgao) {
        pf.idOrgao = props.idOrgao;
      }
      return pf;
    });

    const entitiesSelected = computed(() => {
      return props.planilhaTrechos && props.planilhaTrechos.idPlanilhaTrechos
        ? [props.planilhaTrechos]
        : state.planilhaTrechos && state.planilhaTrechos.idPlanilhaTrechos
        ? [state.planilhaTrechos]
        : [];
    });

    const clearEntity = () => {
      state.planilhaTrechos = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idPlanilhaTrechosToView = null;
      es.value.focus();
    };

    const confirmSelection = (selected: Array<PlanilhaTrechosPayload>) => {
      state.planilhaTrechos =
        selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.planilhaTrechos);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idPlanilhaTrechosToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idPlanilhaTrechosToView = null;
      emit("cancel-selection");
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idPlanilhaTrechosToView = null;
      state.crudOpen = true;
    };

    const openEntity = (e: PlanilhaTrechosPayload) => {
      if (e.idPlanilhaTrechos) {
        state.idPlanilhaTrechosToView = e.idPlanilhaTrechos;
        state.crudOpen = true;
      }
    };

    const getNome = (e?: PlanilhaTrechosPayload) => {
      return usePlanilhaTrechosService().getNomePlanilhaTrechos(e);
    };

    onMounted(async () => {
      state.planilhaTrechos = props.planilhaTrechos;
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      clearEntity,
      confirmSelection,
      cancelSelection,
      preFilter,
      es,
      entitiesSelected,
      format,
      getNome,
      ...toRefs(state)
    };
  },
  components: {
    CrudPlanilhaTrechos
  },
  props: {
    planilhaTrechos: {
      type: Object as PropType<PlanilhaTrechosPayload>
    },
    idOrgao: {
      type: Number
    },
    cliente: Object as PropType<ClientePayload>,
    canEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Planilha de trechos"
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
