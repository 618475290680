
import { Options, Vue } from "vue-class-component";
import CrudCliente from "../../components/cliente/CrudCliente.vue";

@Options({
  props: {
    idCliente: Number
  },
  components: {
    CrudCliente
  }
})
export default class Cliente extends Vue {
  idCliente?: number;
}
