import { clearObject } from "@/modules/utils";
import { useApiWithAuth } from "@/modules/api";
import { MunicipioPayload } from "@/modules/business/common/commonBusiness";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";
import { format } from "@/modules/utils";
import { PermissionPayload } from "@/modules/auth";

interface EngenheiroFilterPayload {
  idCliente?: number;
  nome: string;
  cpfCnpj: string;
  email: string;
}

interface EngenheiroOrgaoPayload {
  idEngenheiroOrgao?: number | null;
  idEngenheiro?: number | null;
  idOrgao?: number | null;
  usuario?: string | null;
  senha?: string | null;
  aprovacao?: boolean | null;
}

interface EngenheiroDocumentoPayload {
  idDocumentoEngenheiro?: number | null;
  idEngenheiro?: number | null;
  flTipoDocumento?: string | null;
  keyBlDocumento?: string | null;
  keyBlDocumentoTemp?: string | null;
}

interface EngenheiroPayload {
  idEngenheiro?: number | null;
  nome?: string | null;
  cpfCnpj?: string | null;
  cep?: string | null;
  logradouro?: string | null;
  numero?: string | null;
  bairro?: string | null;
  municipio: MunicipioPayload;
  telefone?: string | null;
  celular?: string | null;
  email?: string | null;
  crea?: string | null;
  registroNacional?: string | null;
  assinatura: EngenheiroDocumentoPayload;
  orgaos: Array<EngenheiroOrgaoPayload>;
  orgaosArt?: Array<EngenheiroOrgaoPayload> | null;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
}

const useEngenheiroService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    del,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const searchEngenheiro = (
    filter: EngenheiroFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/engenheiro", {
      ...clearObject(filter),
      ...{
        page: page
      }
    });
  };

  const getEngenheiro = (idEngenheiro: number): Promise<void> => {
    return get(`/engenheiro/${idEngenheiro}`);
  };

  const newEngenheiro = (idCliente?: number | null): Promise<void> => {
    return get("/engenheiro/new", idCliente ? { idCliente: idCliente } : {});
  };

  const deleteEngenheiro = (idEngenheiro: number): Promise<void> => {
    return del("/engenheiro/" + idEngenheiro);
  };

  const saveNewEngenheiro = (engenheiro: EngenheiroPayload): Promise<void> => {
    return post("/engenheiro", engenheiro);
  };

  const updateEngenheiro = (
    idEngenheiro: number,
    engenheiro: EngenheiroPayload
  ): Promise<void> => {
    return put(`/engenheiro/${idEngenheiro}`, engenheiro);
  };

  const downloadAssinatura = (
    idDocumentoEngenheiro: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/engenheiro/documentos/${idDocumentoEngenheiro}`
    );
  };

  const safeEngenheiro = (
    entity?: EngenheiroPayload | null
  ): EngenheiroPayload => {
    const ret = entity ?? {
      municipio: {},
      assinatura: {},
      orgaos: [],
      orgaosArt: []
    };

    ret.assinatura = ret.assinatura ?? {};
    ret.municipio = ret.municipio ?? {};
    ret.orgaos = ret.orgaos ?? [];
    ret.orgaosArt = ret.orgaosArt ?? [];
    ret.assinatura.keyBlDocumentoTemp = null;
    return ret;
  };

  const getNomeEngenheiro = (entity?: EngenheiroPayload) => {
    if (!entity) {
      return "";
    }
    return (
      format(["###.###.###-##", "##.###.###/####-##"], entity.cpfCnpj) +
      " - " +
      entity?.nome
    );
  };

  const safeFilterEngenheiro = () => {
    return {
      nome: "",
      cpfCnpj: "",
      email: ""
    };
  };

  return {
    data,
    loading,
    searchEngenheiro,
    deleteEngenheiro,
    saveNewEngenheiro,
    updateEngenheiro,
    newEngenheiro,
    getEngenheiro,
    safeEngenheiro,
    safeFilterEngenheiro,
    downloadAssinatura,
    getNomeEngenheiro
  };
};

export {
  useEngenheiroService,
  EngenheiroFilterPayload,
  EngenheiroDocumentoPayload,
  EngenheiroPayload
};
