<template>
  <sistema>
    <div>
      <crud-carreta :isSearch="false" :isNew="isNew" :idCarreta="idCarreta" />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudCarreta from "../../components/veiculo/carreta/CrudCarreta.vue";

@Options({
  props: {
    isNew: Boolean,
    idCarreta: Number
  },
  components: {
    CrudCarreta
  }
})
export default class Carreta extends Vue {
  isNew?: boolean;
  idCarreta?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
