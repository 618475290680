
import { Options, Vue } from "vue-class-component";
import CrudLicenca from "../../components/licenca/CrudLicenca.vue";

@Options({
  props: {
    isNew: Boolean,
    idLicenca: Number,
    idLicencaCopy: Number
  },
  components: {
    CrudLicenca
  }
})
export default class Licenca extends Vue {
  isNew?: boolean;
  idLicenca?: number;
  idLicencaCopy?: number;
}
