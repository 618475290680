import { reactive, toRefs } from "vue";
import { useApiWithAuth } from "@/modules/api";
import {
  UFPayload,
  MunicipioPayload,
  CorPayload,
  OrgaoPayload,
  useOrgaoAetService,
  useOrgaoArtService,
  useUfService,
  useMunicipioService
} from "@/modules/business/common/commonBusiness";

import {
  useVeiculoTratorService,
  TipoVeiculoTratorPayload,
  DirecaoVeiculoTratorPayload,
  TracaoPayload
} from "@/modules/business/veiculo/veiculoTratorBusiness";
import {
  useCarretaService,
  TipoCarretaPayload,
  DisposicaoEixosCarretaPayload
} from "@/modules/business/veiculo/carretaBusiness";

import {
  useTipoCarroceriaService,
  TipoCarroceriaPayload
} from "@/modules/business/veiculo/carroceriaBusiness";

import {
  useGabaritoService,
  GabaritoPayload
} from "@/modules/business/licenca/gabaritoBusiness";

import {
  useTipoLicencaOrgaoService,
  TipoLicencaOrgaoPayload
} from "@/modules/business/licenca/tipoLicencaOrgaoBusiness";

import {
  useStatusService,
  StatusPayload
} from "@/modules/business/status/statusBusiness";

import {
  useLicencaFaturamentoService,
  TipoLicencaFaturamentoPayload
} from "@/modules/business/licenca/licencaFaturamentoBusiness";

export const UFS_KEY = "cache_ufs";
export const MUNICIPIO_KEY = "cache_municipios";
export const TRACOES_VEICULO_TRATOR_KEY = "cache_tracoes";
export const DIRECOES_VEICULO_TRATOR_KEY = "cache_direcaoVeiculoTrator";
export const TIPOS_VEICULO_TRATOR_KEY = "cache_tiposVeiculoTrator";
export const TIPOS_CARROCERIA_KEY = "cache_tiposCarrocerias";
export const TIPOS_CARRETA_KEY = "cache_tiposCarretas";
export const DISPOSICOES_EIXOS_CARRETA_KEY = "cache_disposicoesEixosCarretas";
export const CORES_KEY = "cache_cores";
export const ORGAOS_AET_KEY = "cache_orgaos";
export const ORGAOS_ART_KEY = "cache_orgaos_art";
export const GABARITOS_KEY = "cache_gabaritos";
export const TIPOS_LICENCAS_KEY = "cache_tipos_licencas";
export const STATUS_ORGAO_GOVPASS_KEY = "cache_statuses_orgao_govpass";
export const STATUS_ORGAO_KEY = "cache_statuses_orgao";
export const STATUS_ROBO_KEY = "cache_statuses_robo";
export const TIPOS_FATURAMENTO_KEY = "cache_tipos_faturamentos_licencas";

interface CacheState {
  cacheLoaded: boolean;
}

const state = reactive<CacheState>({
  cacheLoaded: !!window.localStorage.getItem(MUNICIPIO_KEY)
});

export const useCache = () => {
  const clearCaches = (): Promise<void> => {
    state.cacheLoaded = false;
    window.localStorage.removeItem(UFS_KEY);
    window.localStorage.removeItem(MUNICIPIO_KEY);
    window.localStorage.removeItem(TRACOES_VEICULO_TRATOR_KEY);
    window.localStorage.removeItem(TIPOS_CARROCERIA_KEY);
    window.localStorage.removeItem(TIPOS_CARRETA_KEY);
    window.localStorage.removeItem(DISPOSICOES_EIXOS_CARRETA_KEY);
    window.localStorage.removeItem(TIPOS_VEICULO_TRATOR_KEY);
    window.localStorage.removeItem(DIRECOES_VEICULO_TRATOR_KEY);
    window.localStorage.removeItem(ORGAOS_AET_KEY);
    window.localStorage.removeItem(ORGAOS_ART_KEY);
    window.localStorage.removeItem(GABARITOS_KEY);
    window.localStorage.removeItem(TIPOS_LICENCAS_KEY);
    window.localStorage.removeItem(STATUS_ORGAO_GOVPASS_KEY);
    window.localStorage.removeItem(STATUS_ORGAO_KEY);
    window.localStorage.removeItem(STATUS_ROBO_KEY);
    window.localStorage.removeItem(TIPOS_FATURAMENTO_KEY);

    return Promise.resolve();
  };

  const loadCaches = (): Promise<void> => {
    state.cacheLoaded = false;
    return new Promise((resolve, reject) => {
      const arrayPromises = new Array<Promise<void>>();

      const ufsReq = useUfService();

      arrayPromises.push(
        new Promise((resolveUfs, rejectUfs) =>
          ufsReq.getUfsToCache().then(() => {
            window.localStorage.setItem(
              UFS_KEY,
              JSON.stringify(ufsReq.data.value)
            );
            const munic = useMunicipioService();
            munic.getMunicipiosToCache().then(() => {
              window.localStorage.setItem(
                MUNICIPIO_KEY,
                JSON.stringify(munic.data.value)
              );
              resolveUfs();
            }, rejectUfs);
          })
        )
      );

      const cores = useApiWithAuth();
      arrayPromises.push(
        cores.get("/cor").then(() => {
          window.localStorage.setItem(
            CORES_KEY,
            JSON.stringify(cores.data.value)
          );
        })
      );

      const tiposTracaoVeiculoTrator = useVeiculoTratorService();
      arrayPromises.push(
        tiposTracaoVeiculoTrator.getTracoesVeiculoTratorToCache().then(() => {
          window.localStorage.setItem(
            TRACOES_VEICULO_TRATOR_KEY,
            JSON.stringify(tiposTracaoVeiculoTrator.data.value)
          );
        })
      );

      const tiposVeiculoTrator = useVeiculoTratorService();
      arrayPromises.push(
        tiposVeiculoTrator.getTiposVeiculoTratorToCache().then(() => {
          window.localStorage.setItem(
            TIPOS_VEICULO_TRATOR_KEY,
            JSON.stringify(tiposVeiculoTrator.data.value)
          );
        })
      );

      const direcoesVeiculoTracao = useVeiculoTratorService();
      arrayPromises.push(
        direcoesVeiculoTracao.getDirecoesDisponiveis().then(() => {
          window.localStorage.setItem(
            DIRECOES_VEICULO_TRATOR_KEY,
            JSON.stringify(direcoesVeiculoTracao.data.value)
          );
        })
      );

      const tiposCarrocerias = useTipoCarroceriaService();
      arrayPromises.push(
        tiposCarrocerias.getTiposCarroceriaToCache().then(() => {
          window.localStorage.setItem(
            TIPOS_CARROCERIA_KEY,
            JSON.stringify(tiposCarrocerias.data.value)
          );
        })
      );

      const tiposCarreta = useCarretaService();
      arrayPromises.push(
        tiposCarreta.getTiposCarretaToCache().then(() => {
          window.localStorage.setItem(
            TIPOS_CARRETA_KEY,
            JSON.stringify(tiposCarreta.data.value)
          );
        })
      );

      const disposicoesEixosCarreta = useCarretaService();
      arrayPromises.push(
        disposicoesEixosCarreta.getDisposicoesEixosCarretaToCache().then(() => {
          window.localStorage.setItem(
            DISPOSICOES_EIXOS_CARRETA_KEY,
            JSON.stringify(disposicoesEixosCarreta.data.value)
          );
        })
      );

      const orgaosAet = useOrgaoAetService();
      arrayPromises.push(
        orgaosAet.getOrgaosAetToCache().then(() => {
          window.localStorage.setItem(
            ORGAOS_AET_KEY,
            JSON.stringify(orgaosAet.data.value)
          );
        })
      );

      const orgaosArt = useOrgaoArtService();
      arrayPromises.push(
        orgaosArt.getOrgaosArtToCache().then(() => {
          window.localStorage.setItem(
            ORGAOS_ART_KEY,
            JSON.stringify(orgaosArt.data.value)
          );
        })
      );

      const gabaritos = useGabaritoService();
      arrayPromises.push(
        gabaritos.getGabaritosToCache().then(() => {
          window.localStorage.setItem(
            GABARITOS_KEY,
            JSON.stringify(gabaritos.data.value)
          );
        })
      );

      const licencasTipos = useTipoLicencaOrgaoService();
      arrayPromises.push(
        licencasTipos.getTiposLicencaOrgaoToCache().then(() => {
          window.localStorage.setItem(
            TIPOS_LICENCAS_KEY,
            JSON.stringify(licencasTipos.data.value)
          );
        })
      );

      const statusOrgaoGovPass = useStatusService();
      arrayPromises.push(
        statusOrgaoGovPass.getStatusesOrgaoGovPassToCache().then(() => {
          window.localStorage.setItem(
            STATUS_ORGAO_GOVPASS_KEY,
            JSON.stringify(statusOrgaoGovPass.data.value)
          );
        })
      );

      const statusOrgao = useStatusService();
      arrayPromises.push(
        statusOrgao.getStatusesOrgaoToCache().then(() => {
          window.localStorage.setItem(
            STATUS_ORGAO_KEY,
            JSON.stringify(statusOrgao.data.value)
          );
        })
      );

      const statusRobo = useStatusService();
      arrayPromises.push(
        statusRobo.getStatusesRoboToCache().then(() => {
          window.localStorage.setItem(
            STATUS_ROBO_KEY,
            JSON.stringify(statusRobo.data.value)
          );
        })
      );

      const licencasFaturamentoTipos = useLicencaFaturamentoService();
      arrayPromises.push(
        licencasFaturamentoTipos
          .getTiposLicencaFaturamentoToCache()
          .then(() => {
            window.localStorage.setItem(
              TIPOS_FATURAMENTO_KEY,
              JSON.stringify(licencasFaturamentoTipos.data.value)
            );
          })
      );

      Promise.all(arrayPromises).then(
        () => {
          state.cacheLoaded = true;
          resolve();
        },
        () => {
          state.cacheLoaded = true;
          reject();
        }
      );
    });
  };

  const getUfs = (): Array<UFPayload> => {
    return JSON.parse(window.localStorage.getItem(UFS_KEY) ?? "[]");
  };

  const getMunicipios = (idUnidadeFederal: number): Array<MunicipioPayload> => {
    return JSON.parse(window.localStorage.getItem(MUNICIPIO_KEY) ?? "{}")[
      idUnidadeFederal
    ];
  };

  const getTracoesVeiculoTrator = (): Array<TracaoPayload> => {
    return JSON.parse(
      window.localStorage.getItem(TRACOES_VEICULO_TRATOR_KEY) ?? "[]"
    );
  };

  const getDirecoesVeiculoTrator = (): Array<DirecaoVeiculoTratorPayload> => {
    return JSON.parse(
      window.localStorage.getItem(DIRECOES_VEICULO_TRATOR_KEY) ?? "[]"
    );
  };

  const getTiposVeiculoTrator = (): Array<TipoVeiculoTratorPayload> => {
    return JSON.parse(
      window.localStorage.getItem(TIPOS_VEICULO_TRATOR_KEY) ?? "[]"
    );
  };

  const getTiposCarroceria = (): Array<TipoCarroceriaPayload> => {
    return JSON.parse(
      window.localStorage.getItem(TIPOS_CARROCERIA_KEY) ?? "[]"
    );
  };

  const getTiposCarreta = (): Array<TipoCarretaPayload> => {
    return JSON.parse(window.localStorage.getItem(TIPOS_CARRETA_KEY) ?? "[]");
  };

  const getDisposicoesEixosCarreta = (): Array<DisposicaoEixosCarretaPayload> => {
    return JSON.parse(
      window.localStorage.getItem(DISPOSICOES_EIXOS_CARRETA_KEY) ?? "[]"
    );
  };

  const getCores = (): Array<CorPayload> => {
    return JSON.parse(window.localStorage.getItem(CORES_KEY) ?? "[]");
  };

  const getOrgaosAet = (): Array<OrgaoPayload> => {
    return JSON.parse(window.localStorage.getItem(ORGAOS_AET_KEY) ?? "[]");
  };

  const getOrgaosArt = (): Array<OrgaoPayload> => {
    return JSON.parse(window.localStorage.getItem(ORGAOS_ART_KEY) ?? "[]");
  };

  const getGabaritos = (): Array<GabaritoPayload> => {
    return JSON.parse(window.localStorage.getItem(GABARITOS_KEY) ?? "[]");
  };

  const getTiposLicencaOrgaos = (): Array<TipoLicencaOrgaoPayload> => {
    return JSON.parse(window.localStorage.getItem(TIPOS_LICENCAS_KEY) ?? "[]");
  };

  const getStatusesOrgaoGovPass = (): Array<StatusPayload> => {
    return JSON.parse(
      window.localStorage.getItem(STATUS_ORGAO_GOVPASS_KEY) ?? "[]"
    );
  };

  const getStatusesOrgao = (): Array<StatusPayload> => {
    return JSON.parse(window.localStorage.getItem(STATUS_ORGAO_KEY) ?? "[]");
  };

  const getStatusesRobo = (): Array<StatusPayload> => {
    return JSON.parse(window.localStorage.getItem(STATUS_ROBO_KEY) ?? "[]");
  };

  const getTiposLicencaFaturamento = (): Array<TipoLicencaFaturamentoPayload> => {
    return JSON.parse(
      window.localStorage.getItem(TIPOS_FATURAMENTO_KEY) ?? "[]"
    );
  };

  return {
    clearCaches,
    loadCaches,
    getUfs,
    getMunicipios,
    getOrgaosAet,
    getOrgaosArt,
    getCores,
    getTracoesVeiculoTrator,
    getDirecoesVeiculoTrator,
    getTiposVeiculoTrator,
    getTiposCarroceria,
    getTiposCarreta,
    getDisposicoesEixosCarreta,
    getGabaritos,
    getTiposLicencaOrgaos,
    getTiposLicencaFaturamento,
    getStatusesOrgaoGovPass,
    getStatusesOrgao,
    getStatusesRobo,
    ...toRefs(state)
  };
};
