<template>
  <sistema>
    <div>
      <crud-licenca
        :isSearch="false"
        :isNew="isNew"
        :idLicenca="idLicenca"
        :idLicencaCopy="idLicencaCopy"
      />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudLicenca from "../../components/licenca/CrudLicenca.vue";

@Options({
  props: {
    isNew: Boolean,
    idLicenca: Number,
    idLicencaCopy: Number
  },
  components: {
    CrudLicenca
  }
})
export default class Licenca extends Vue {
  isNew?: boolean;
  idLicenca?: number;
  idLicencaCopy?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
