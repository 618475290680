
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref
} from "vue";
import CadastroArtEngenheiroTransportador from "./CadastroArtEngenheiroTransportador.vue";
import CadastroArtEngenheiroTransportadorRequest from "./CadastroArtEngenheiroTransportadorRequest.vue";
import { PaginationResult, defaultResults } from "../../../pagination";
import {
  useArtEngenheiroTransportadorService,
  useArtEngenheiroTransportadorRequestService,
  ArtEngenheiroTransportadorPayload
} from "../../../modules/business/transportador/artEngenheiroTransportadorBusiness";
import {
  useEngenheiroService,
  EngenheiroPayload
} from "../../../modules/business/engenheiro/engenheiroBusiness";
import {
  VeiculoTratorPayload,
  useVeiculoTratorService
} from "../../../modules/business/veiculo/veiculoTratorBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import {
  indexesOfElement,
  toggleElement,
  format,
  formatDate
} from "../../../modules/utils";
import { isPast } from "date-fns";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { useConfirm } from "../../../modules/message";

interface ArtEngenheiroTransportadorComponentParams {
  isSearch: boolean;
  selected: Array<ArtEngenheiroTransportadorPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  isFila: boolean;
  idArtEngenheiroTransportador?: number;
}

interface ArtEngenheiroTransportadorState {
  idArtEngenheiroTransportador: number | null;
  idTransportadorEdicao: number | null;
  showEdit: boolean;
  showRequestNew: boolean;
  results: PaginationResult<ArtEngenheiroTransportadorPayload>;
  stateReady: boolean;
  params: ArtEngenheiroTransportadorComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ArtEngenheiroTransportadorState>({
      idArtEngenheiroTransportador: null,
      idTransportadorEdicao: null,
      showEdit: false,
      showRequestNew: false,
      stateReady: false,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        isFila: !props.transportador?.idTransportador,
        idArtEngenheiroTransportador: props.idArtEngenheiroTransportador,
        selected: props.selected ?? []
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const executeSearchDefault = () => {
      if (!props.transportador?.idTransportador) {
        return;
      }
      const {
        data,
        loading,
        searchArtEngenheiroTransportador
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      return searchArtEngenheiroTransportador(
        props.transportador.idTransportador,
        props.idEngenheiro
      )
        .then(() => {
          state.results.content = data.value;
          state.results.totalPages = 1;
          state.results.number = 0;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const executeSearchArtRequest = () => {
      const {
        data,
        loading,
        searchArtEngenheiroTransportadorRequest
      } = useArtEngenheiroTransportadorRequestService();
      state.loadings.push(loading);
      return searchArtEngenheiroTransportadorRequest()
        .then(() => {
          state.results.content = data.value;
          state.results.totalPages = 1;
          state.results.number = 0;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const executeSearch = () => {
      return props.transportador?.idTransportador
        ? executeSearchDefault()
        : executeSearchArtRequest();
    };

    const submitSearch = () => {
      state.results = defaultResults();
      return executeSearch();
    };

    const openEntity = (art: ArtEngenheiroTransportadorPayload) => {
      state.idArtEngenheiroTransportador =
        art.idArtEngenheiroTransportador ?? null;
      state.idTransportadorEdicao =
        props.transportador?.idTransportador ??
        art.transportador.idTransportador ??
        null;
      state.showEdit = true;
    };

    const getNomeTransportador = (t?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(t);
    };

    const getNomeEngenheiro = (e?: EngenheiroPayload) => {
      return useEngenheiroService().getNomeEngenheiro(e);
    };

    const getNomeVeiculoTrator = (e?: VeiculoTratorPayload) => {
      return useVeiculoTratorService().getNomeVeiculoTrator(e);
    };

    const getNome = (e?: ArtEngenheiroTransportadorPayload) => {
      return useArtEngenheiroTransportadorService().getNomeArtEngenheiroTransportador(
        e
      );
    };

    const getSituacao = (e?: ArtEngenheiroTransportadorPayload) => {
      return useArtEngenheiroTransportadorService().getSituacaoArtEngenheiroTransportador(
        e
      );
    };

    const newEntity = () => {
      if (!props.transportador?.idTransportador) {
        return;
      }
      state.idArtEngenheiroTransportador = null;
      state.idTransportadorEdicao =
        props.transportador?.idTransportador ?? null;
      state.showEdit = true;
    };

    const newArtPlay = () => {
      state.showRequestNew = true;
    };

    const requestNewArt = () => {
      useConfirm().require({
        message:
          "Deseja solicitar uma nova art para o transportador " +
          useTransportadorService().getNomeTransportador(props.transportador) +
          "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (props.transportador?.idTransportador) {
            const {
              loading,
              requestNewArtEngenheiroTransportador
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            requestNewArtEngenheiroTransportador(
              props.transportador.idTransportador
            )
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const deleteEntity = (art: ArtEngenheiroTransportadorPayload) => {
      useConfirm().require({
        message:
          "Deseja excluir o art " +
          useArtEngenheiroTransportadorService().getNomeArtEngenheiroTransportador(
            art
          ) +
          "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (art && art.idArtEngenheiroTransportador) {
            const {
              loading,
              deleteArtEngenheiroTransportador
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            deleteArtEngenheiroTransportador(art)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const confirmSelection = (
      selected: Array<ArtEngenheiroTransportadorPayload>
    ) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const isBotaoBoletoPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("download_boleto", art.permissionInfo);
    };

    const isBotaoArtOriginalPresente = (
      art: ArtEngenheiroTransportadorPayload
    ) => {
      return !!amIAllowed("download_original", art.permissionInfo);
    };

    const isBotaoPlayPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("play", art.permissionInfo);
    };

    const isBotaoReplayPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("replay", art.permissionInfo);
    };

    const isBotaoAddMonitoramentoPresente = (
      art: ArtEngenheiroTransportadorPayload
    ) => {
      return !!amIAllowed("add_monitoring", art.permissionInfo);
    };

    const isBotaoArtPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("download", art.permissionInfo);
    };

    const play = (art: ArtEngenheiroTransportadorPayload) => {
      useConfirm().require({
        message: `Deseja solicitar a art para o Engenheiro '${getNomeEngenheiro(
          art.engenheiro
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (art && art.idArtEngenheiroTransportador) {
            const {
              playArtEngenheiroTransportador,
              loading
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            playArtEngenheiroTransportador(art)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const addMonitoring = (art: ArtEngenheiroTransportadorPayload) => {
      useConfirm().require({
        message: `Deseja adicionar a art '${getNome(art)}' no monitoramento?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (art && art.idArtEngenheiroTransportador) {
            const {
              addMonitoringArtEngenheiroTransportador,
              loading
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            addMonitoringArtEngenheiroTransportador(art)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const downloadBoleto = (
      art: ArtEngenheiroTransportadorPayload,
      newTab?: boolean
    ) => {
      const {
        downloadBoletoArtEngenheiroTransportador,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      downloadBoletoArtEngenheiroTransportador(art, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadOriginal = (
      art: ArtEngenheiroTransportadorPayload,
      newTab?: boolean
    ) => {
      const {
        downloadOriginalArtEngenheiroTransportador,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      downloadOriginalArtEngenheiroTransportador(art, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadArtFile = (
      art: ArtEngenheiroTransportadorPayload,
      newTab?: boolean
    ) => {
      const {
        downloadArtEngenheiroTransportador,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      downloadArtEngenheiroTransportador(art, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const returnToDefault = () => {
      state.idArtEngenheiroTransportador = null;
      state.idTransportadorEdicao = null;
      state.showEdit = false;
      state.showRequestNew = false;
      executeSearch();
    };

    const afterRegisterScreenClosed = (
      art?: ArtEngenheiroTransportadorPayload
    ) => {
      if (state.params.idArtEngenheiroTransportador && state.params.isSearch) {
        if (art && art.idArtEngenheiroTransportador) {
          confirmSelection([art]);
        } else {
          cancelSelection();
        }
      } else {
        if (
          art &&
          state.params.isSearch &&
          indexesOfElement(
            state.params.selected,
            art,
            (ob) => ob && ob.idArtEngenheiroTransportador
          ).length <= 0
        ) {
          useConfirm().require({
            message: "Deseja selecionar o registro salvo?",
            header: "Confirmação",
            icon: "pi pi-info-circle",
            accept: () => {
              toggleElement(
                state.params.selected,
                art,
                (ob) => ob && ob.idArtEngenheiroTransportador,
                state.params.isMultiple
              );
              returnToDefault();
            },
            reject: () => {
              returnToDefault();
            }
          });
        } else {
          returnToDefault();
        }
      }
    };
    CadastroArtEngenheiroTransportadorRequest;
    const afterRequestScreenClosed = () => {
      returnToDefault();
    };

    const deveAbrirTelaCadastro =
      props.transportador?.idTransportador &&
      (state.params.idArtEngenheiroTransportador || state.params.isNew);
    if (deveAbrirTelaCadastro) {
      state.idTransportadorEdicao =
        props.transportador?.idTransportador ?? null;
      if (state.params.idArtEngenheiroTransportador) {
        state.idArtEngenheiroTransportador =
          state.params.idArtEngenheiroTransportador;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      state.stateReady = true;
      if (!deveAbrirTelaCadastro) {
        submitSearch();
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      newArtPlay,
      requestNewArt,
      deleteEntity,
      downloadBoleto,
      downloadArtFile,
      downloadOriginal,
      play,
      addMonitoring,
      amIAllowed,
      amIBlocked,
      isBotaoPlayPresente,
      isBotaoReplayPresente,
      isBotaoAddMonitoramentoPresente,
      isBotaoBoletoPresente,
      isBotaoArtOriginalPresente,
      isBotaoArtPresente,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterRequestScreenClosed,
      getNome,
      getSituacao,
      getNomeVeiculoTrator,
      getNomeEngenheiro,
      getNomeTransportador,
      format,
      formatDate,
      isPast,
      stateLoading,
      ...toRefs(state)
    };
  },
  components: {
    CadastroArtEngenheiroTransportador,
    CadastroArtEngenheiroTransportadorRequest
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    transportador: {
      type: Object as PropType<TransportadorPayload>
    },
    idEngenheiro: Number,
    cliente: Object as PropType<ClientePayload>,
    idArtEngenheiroTransportador: Number,
    selected: {
      type: Array as PropType<Array<ArtEngenheiroTransportadorPayload>>
    },
    label: String
  }
});
