
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import EntitySelectorTransportador from "../../components/transportador/EntitySelectorTransportador.vue";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { formatDate, DATE_SELECTORS } from "../../modules/utils";
import {
  useLicencaFaturamentoService,
  LicencaFaturamentoFilterPayload,
  TipoLicencaFaturamentoPayload
} from "../../modules/business/licenca/licencaFaturamentoBusiness";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import EntitySelectorCliente from "../../components/cliente/EntitySelectorCliente.vue";
import { TransportadorPayload } from "../../modules/business/transportador/transportadorBusiness";

interface CombinacaoVeiculoState {
  filter: LicencaFaturamentoFilterPayload;
  cliente?: ClientePayload | null;
  stateLoading: boolean;
  stateReady: boolean;
  tiposDisponiveis: Array<TipoLicencaFaturamentoPayload>;
  periodoFixo: string;
}

export default defineComponent({
  setup() {
    const state = reactive<CombinacaoVeiculoState>({
      filter: { flSituacao: "F" },
      cliente: null,
      stateLoading: false,
      stateReady: false,
      tiposDisponiveis: useLicencaFaturamentoService().getTiposLicencaFaturamento(),
      periodoFixo: DATE_SELECTORS.MONTH
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateBlocked = computed(() => {
      if (state.stateLoading) {
        return true;
      } else if (amIAllowed("view", "root-cliente")) {
        return !state.filter.idCliente;
      }
      return false;
    });

    const executeSearch = () => {
      state.stateLoading = true;
      const { downloadFaturamento } = useLicencaFaturamentoService();
      downloadFaturamento(state.filter)
        .then(() => {
          state.stateLoading = false;
        })
        .catch(() => {
          state.stateLoading = false;
        });
    };

    const submitSearch = () => {
      return executeSearch();
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
      state.cliente = c;
      state.filter = { idCliente: state.filter.idCliente, flSituacao: "F" };
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      state.filter.idTransportador =
        t && t.idTransportador ? t.idTransportador : undefined;
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submitSearch,
      amIAllowed,
      confirmSelectionCliente,
      confirmSelectionTransportador,
      formatDate,
      stateBlocked,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorTransportador,
    EntitySelectorCliente
  }
});
