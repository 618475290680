<template>
  <sistema>
    <div>
      <crud-planilha-trechos
        :isSearch="false"
        :isNew="isNew"
        :idPlanilhaTrechos="idPlanilhaTrechos"
        :idPlanilhaTrechosCopy="idPlanilhaTrechosCopy"
      />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudPlanilhaTrechos from "../../components/trecho/CrudPlanilhaTrechos.vue";

@Options({
  props: {
    isNew: Boolean,
    idPlanilhaTrechos: Number,
    idPlanilhaTrechosCopy: Number
  },
  components: {
    CrudPlanilhaTrechos
  }
})
export default class PlanilhaTrechos extends Vue {
  isNew?: boolean;
  idPlanilhaTrechos?: number;
  idPlanilhaTrechosCopy?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
