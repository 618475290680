
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref,
  PropType
} from "vue";
import {
  useArtEngenheiroTransportadorRequestService,
  ArtEngenheiroTransportadorRequestPayload
} from "../../../modules/business/transportador/artEngenheiroTransportadorBusiness";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { EngenheiroPayload } from "../../../modules/business/engenheiro/engenheiroBusiness";
import { TransportadorPayload } from "@/modules/business/transportador/transportadorBusiness";
import { useToast } from "../../../modules/message";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import EntitySelectorEngenheiro from "../../engenheiro/EntitySelectorEngenheiro.vue";
import EntitySelectorOrgaoArt from "../../common/EntitySelectorOrgaoArt.vue";
import EntitySelectorCliente from "../../cliente/EntitySelectorCliente.vue";

// eslint-disable-next-line
declare var require: any; //PQP MEMO

interface ArtEngenheiroTransportadorRequestState {
  entity: ArtEngenheiroTransportadorRequestPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ArtEngenheiroTransportadorRequestState>({
      entity: useArtEngenheiroTransportadorRequestService().safeArtEngenheiroTransportadorRequest(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!stateLoading.value;
    });

    const setEntity = (entity: ArtEngenheiroTransportadorRequestPayload) => {
      state.entity = useArtEngenheiroTransportadorRequestService().safeArtEngenheiroTransportadorRequest(
        entity
      );
      const docElement = document.querySelector(
        "#" + state.stateId + "_documento"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }
    };

    const closeScreen = () => {
      emit("after-register-screen-closed");
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const confirmSelectionEngenheiro = (eng: EngenheiroPayload) => {
      if (eng) {
        state.entity.engenheiro = eng;
      } else {
        state.entity.engenheiro = useArtEngenheiroTransportadorRequestService().safeArtEngenheiroTransportadorRequest().engenheiro;
      }
    };

    const afterSetOrgao = (idOrgao?: number | null) => {
      state.entity.idOrgao = idOrgao;
    };

    const confirmSelectionTransportador = (
      transportador: TransportadorPayload
    ) => {
      if (transportador) {
        state.entity.transportador = transportador;
      } else {
        state.entity.transportador = useArtEngenheiroTransportadorRequestService().safeArtEngenheiroTransportadorRequest().transportador;
      }
    };

    const submitSave = () => {
      const {
        saveNewArtEngenheiroTransportadorRequest,
        loading: loadingCadastro
      } = useArtEngenheiroTransportadorRequestService();
      state.loadings.push(loadingCadastro);
      saveNewArtEngenheiroTransportadorRequest(state.entity)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          useToast().success("ART salva com sucesso");
          closeScreen();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    onMounted(async () => {
      const {
        newArtEngenheiroTransportadorRequest,
        data,
        loading
      } = useArtEngenheiroTransportadorRequestService();
      state.loadings.push(loading);

      newArtEngenheiroTransportadorRequest()
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      confirmSelectionCliente,
      confirmSelectionEngenheiro,
      confirmSelectionTransportador,
      afterSetOrgao,
      stateLoading,
      stateBlocked,
      amIAllowed,
      ...toRefs(state)
    };
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.EntitySelectorTransportador = require("../EntitySelectorTransportador.vue").default;
    }
  },
  components: {
    EntitySelectorEngenheiro,
    EntitySelectorOrgaoArt,
    EntitySelectorCliente
  },
  props: {
    parentLoading: Boolean,
    cliente: Object as PropType<ClientePayload>
  }
});
