<template>
  <input-with-label
    v-if="stateReady"
    :label="label"
    :required="required"
    v-slot="l"
    :id="stateId"
  >
    <Calendar
      v-model="internalValue"
      :id="l.id"
      :required="l.required"
      :minDate="minDate"
      :maxDate="maxDate"
      :showIcon="true"
      :showButtonBar="true"
      @keydown="processKeyDownDate"
    />
  </input-with-label>
</template>

<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */

import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  PropType,
  watch
} from "vue";

import { processKeyDownDate } from "../../../modules/utils";

interface DatePickerState {
  internalValue?: Date;
  stateReady: boolean;
  stateId: string;
  minDate: Date;
  maxDate: Date;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<DatePickerState>({
      internalValue: props.modelValue,
      stateReady: false,
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2),
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2050, 11, 31)
    });

    onMounted(async () => {
      watch([state], () => {
        if (props.modelValue != state.internalValue) {
          emit("update:modelValue", state.internalValue);
        }
      });
      watch([props], () => {
        if (props.modelValue != state.internalValue) {
          state.internalValue = props.modelValue;
        }
      });
      state.stateReady = true;
    });

    return {
      processKeyDownDate,
      ...toRefs(state)
    };
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object as PropType<Date>
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    label: {
      type: String,
      default: "Data"
    }
  },
  components: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
