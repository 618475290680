
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref
} from "vue";
import { useAuth, PermissionPayload } from "../../../../modules/auth";
import { useToast } from "../../../../modules/message";
import {
  ModeloVeiculoTratorPayload,
  useModeloVeiculoTratorService
} from "../../../../modules/business/veiculo/veiculoTratorBusiness";
import EntitySelectorCliente from "../../../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../../../modules/business/cliente/clienteBusiness";
import { PropType } from "vue";

interface ModeloVeiculoTratorCadastroState {
  entity: ModeloVeiculoTratorPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ModeloVeiculoTratorCadastroState>({
      entity: useModeloVeiculoTratorService().safeModeloVeiculoTrator(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: []
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const setEntity = (entity?: ModeloVeiculoTratorPayload) => {
      state.entity = useModeloVeiculoTratorService().safeModeloVeiculoTrator(
        entity
      );
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const save = () => {
      const idModeloVeiculoTratorProps =
        (props.modeloVeiculoTrator &&
          props.modeloVeiculoTrator.idModeloVeiculoTrator) ||
        0;
      const isInsert = !idModeloVeiculoTratorProps;
      const {
        saveNewModeloVeiculoTrator,
        updateModeloVeiculoTrator,
        loading: loadingCadastro,
        data: dataCadastro
      } = useModeloVeiculoTratorService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewModeloVeiculoTrator(state.entity)
        : updateModeloVeiculoTrator(idModeloVeiculoTratorProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Modelo salvo com sucesso");
          if (isInsert) {
            emit(
              "after-save-new-entity",
              dataCadastro.value.idModeloVeiculoTrator
            );
          }
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const gerarCopia = () => {
      emit("generate-copy", state.entity.idModeloVeiculoTrator);
    };

    const submitSave = () => {
      save();
    };

    onMounted(async () => {
      setEntity(props.modeloVeiculoTrator);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      state.stateReady = true;
    });

    return {
      submitSave,
      closeScreen,
      gerarCopia,
      amIAllowed,
      confirmSelectionCliente,
      stateLoading,
      stateBlocked,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente
  },
  props: {
    modeloVeiculoTrator: Object as PropType<ModeloVeiculoTratorPayload>,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean,
    canGenerateCopy: Boolean
  }
});
