<template>
  <template v-if="stateReady">
    <img
      v-if="idOrgao && orgaoImagePath"
      :style="orgaoImagePath"
      :alt="orgaoStr"
      v-title="orgaoStr"
    />

    <span v-else v-text="'---'" />
  </template>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  computed,
  inject
} from "vue";
import { useOrgaoArtService } from "../../modules/business/common/commonBusiness";

interface OrgaoArtImgState {
  stateReady: boolean;
}

export default defineComponent({
  setup(props) {
    const state = reactive<OrgaoArtImgState>({
      stateReady: false
    });
    const staticAssetsUrl = inject("staticAssetsUrl");

    const orgaoStr = computed(() => {
      return useOrgaoArtService().getOrgaoArtStr(props.idOrgao);
    });

    const orgaoImagePath = computed(() => {
      const orgaoSigla = useOrgaoArtService().getOrgaoArtSigla(props.idOrgao);
      if (orgaoSigla) {
        return (
          "content: url(" +
          staticAssetsUrl +
          "/img/" +
          orgaoSigla.toLowerCase() +
          "/logo.png);"
        );
      }
      return "";
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      orgaoStr,
      orgaoImagePath,
      ...toRefs(state)
    };
  },
  props: {
    idOrgao: {
      type: Number
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
img {
  max-height: 3rem;
  height: 100%;
}
</style>
