
import { defineComponent, reactive, toRefs, ref, PropType } from "vue";
import { MenuModel } from "../../../router";

interface ButtonGroupState {
  stateId: string;
}
export default defineComponent({
  setup(props) {
    const state = reactive<ButtonGroupState>({
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2)
    });

    const menu = ref();

    const toggle = (event: Event) => {
      menu.value.toggle(event);
    };

    return {
      toggle,
      menu,
      ...toRefs(state)
    };
  },
  props: {
    applyGroup: { type: Boolean, default: true },
    className: { type: String },
    menuButtonClass: { type: String },
    menuItems: Array as PropType<Array<MenuModel>>,
    id: {
      type: String
    }
  },
  components: {}
});
