<template>
  <container :isModal="false" :loading="!stateReady">
    <template v-slot:body>
      <search-result
        :showSelector="false"
        :results="results"
        :keyParam="'idFilaVeiculo'"
        :loading="stateLoading"
        :hidePagination="true"
        @confirm-selection="confirmSelectionFilaVeiculo"
      >
        <template v-slot:title v-if="!!label">{{ label }}</template>
        <template v-slot:columns>
          <Column
            field="idOrgao"
            header="Órgão"
            headerClass="p-header-center"
            style="width: 6rem; text-align: center"
          >
            <template #body="slotProps">
              <orgao-aet-img :idOrgao="slotProps.data.idOrgao" />
            </template>
          </Column>
          <Column
            field="transportador"
            header="Transportador"
            style="width: 30rem"
          >
            <template #body="slotProps">
              <span
                class="text-ellipsis"
                v-text="getNomeTransportador(slotProps.data.transportador)"
                :title="getNomeTransportador(slotProps.data.transportador)"
              />
            </template>
          </Column>
          <Column field="situacao" header="Situação">
            <template #body="slotProps">
              <div class="p-grid p-m-1">
                <div
                  class="p-col-12 p-as-center text-ellipsis"
                  v-html="getSituacaoNome(slotProps.data)"
                />
              </div>
            </template>
          </Column>

          <Column field="usuario" header="Usuario" style="width: 10rem">
            <template #body="slotProps">
              <span
                v-text="slotProps.data.usuario ? slotProps.data.usuario : '-'"
              />
            </template>
          </Column>
          <Column
            field="timestampUltimaExecucao"
            header="Data Última Execução"
            style="width: 13rem"
          >
            <template #body="slotProps">
              <span
                v-text="
                  slotProps.data.timestampUltimaExecucao
                    ? formatDateTime(slotProps.data.timestampUltimaExecucao)
                    : '-'
                "
              />
            </template>
          </Column>

          <Column :exportable="false" style="width: 10rem; text-align: right">
            <template #body="slotProps">
              <button-group>
                <Button
                  :icon="
                    slotProps.data.timestampUltimaExecucao
                      ? 'fas fa-redo'
                      : 'fas fa-play'
                  "
                  class="p-button-rounded"
                  v-title="
                    [
                      slotProps.data.timestampUltimaExecucao
                        ? 'Cadastrar Novamente no Órgão'
                        : 'Cadastrar Veículo no Órgão',
                      amIAllowed('play', slotProps.data.permissionInfo)?.info,
                      amIBlocked('play', slotProps.data.permissionInfo)?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                  :disabled="
                    !!amIBlocked('play', slotProps.data.permissionInfo)?.info
                  "
                  @click="play(slotProps.data)"
                />
                <Button
                  icon="fa-solid fa-circle-check"
                  class="p-button-rounded p-button-success"
                  v-title="
                    [
                      'Marcar como Cadastrado',
                      amIAllowed('mark_as_ok', slotProps.data.permissionInfo)
                        ?.info,
                      amIBlocked('mark_as_ok', slotProps.data.permissionInfo)
                        ?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                  :disabled="
                    !!amIBlocked('mark_as_ok', slotProps.data.permissionInfo)
                      ?.info
                  "
                  @click="markAsOk(slotProps.data)"
                />
              </button-group>
            </template>
          </Column>
        </template>
      </search-result>
    </template>
  </container>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  Ref
} from "vue";
import { useToast } from "../../modules/message";
import { PaginationResult, defaultResults } from "../../pagination";
import { useFilaVeiculoTratorService } from "../../modules/business/veiculo/veiculoTratorBusiness";
import { useFilaCarretaService } from "../../modules/business/veiculo/carretaBusiness";
import { FilaVeiculoPayload } from "../../modules/business/veiculo/filaVeiculoBusiness";
import { useStatusService } from "../../modules/business/status/statusBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../modules/business/transportador/transportadorBusiness";
import { format, formatDateTime } from "../../modules/utils";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { useConfirm } from "../../modules/message";

interface FilaVeiculoState {
  idFilaVeiculo: number | null;
  showEdit: boolean;
  results: PaginationResult<FilaVeiculoPayload>;
  stateReady: boolean;
  filaVeiculoSelected: Array<number>;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props) {
    if (
      !props.idVeiculo ||
      props.isVeiculoTrator === undefined ||
      props.isVeiculoTrator === null
    ) {
      return;
    }

    const state = reactive<FilaVeiculoState>({
      idFilaVeiculo: null,
      showEdit: false,
      stateReady: false,
      results: defaultResults(),
      filaVeiculoSelected: [],
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const getService = () => {
      //odeio isso, mas por pressa vai assim
      if (props.isVeiculoTrator) {
        const {
          data,
          loading,
          searchFilaVeiculoTrator: searchService,
          playFilaVeiculoTrator: playService,
          markAsOkFilaVeiculoTrator: markAsOkService,
          getNomeFilaVeiculoTrator: getNomeService
        } = useFilaVeiculoTratorService();
        return {
          data,
          loading,
          searchService,
          playService,
          markAsOkService,
          getNomeService
        };
      } else {
        const {
          data,
          loading,
          searchFilaCarreta: searchService,
          playFilaCarreta: playService,
          markAsOkFilaCarreta: markAsOkService,
          getNomeFilaCarreta: getNomeService
        } = useFilaCarretaService();
        return {
          data,
          loading,
          searchService,
          playService,
          markAsOkService,
          getNomeService
        };
      }
    };

    const executeSearch = () => {
      if (!props.idVeiculo) {
        return;
      }
      const { data, loading, searchService } = getService();

      state.loadings.push(loading);
      return searchService(props.idVeiculo)
        .then(() => {
          state.results.content = data.value;
          state.results.totalPages = 1;
          state.results.number = 0;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.results = defaultResults();
      return executeSearch();
    };

    const play = (filaVeiculo: FilaVeiculoPayload) => {
      const { loading, playService, getNomeService } = getService();

      useConfirm().require({
        message: `Deseja solicitar o cadastro para o registro '${getNomeService(
          filaVeiculo
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (props.idVeiculo && filaVeiculo && filaVeiculo.idFilaVeiculo) {
            state.loadings.push(loading);
            playService(props.idVeiculo, filaVeiculo.idFilaVeiculo)
              .then(() => {
                useToast().success("Solicitado Registro com sucesso");
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const playAll = () => {
      const { loading, playService } = getService();

      useConfirm().require({
        message: `Deseja solicitar o cadastro para os registros selecionados?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            props.idVeiculo &&
            state.filaVeiculoSelected &&
            state.filaVeiculoSelected.length > 0
          ) {
            state.loadings.push(loading);
            playService(props.idVeiculo, state.filaVeiculoSelected)
              .then(() => {
                useToast().success("Solicitados Registros com sucesso");
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const markAsOk = (filaVeiculo: FilaVeiculoPayload) => {
      const { loading, markAsOkService, getNomeService } = getService();

      useConfirm().require({
        message: `Deseja marcar o veículo como finalizado o registro '${getNomeService(
          filaVeiculo
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (props.idVeiculo && filaVeiculo && filaVeiculo.idFilaVeiculo) {
            state.loadings.push(loading);
            markAsOkService(props.idVeiculo, filaVeiculo.idFilaVeiculo)
              .then(() => {
                useToast().success(
                  "Registro marcado como finalizado com sucesso"
                );
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const markAllAsOk = () => {
      const { loading, markAsOkService } = getService();

      useConfirm().require({
        message: `Deseja marcar o veículo como finalizado os registros selecionados?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (
            props.idVeiculo &&
            state.filaVeiculoSelected &&
            state.filaVeiculoSelected.length > 0
          ) {
            state.loadings.push(loading);
            markAsOkService(props.idVeiculo, state.filaVeiculoSelected)
              .then(() => {
                useToast().success(
                  "Registros marcados como finalizados com sucesso"
                );
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const getSituacaoNome = (filaVeiculo: FilaVeiculoPayload) => {
      if (filaVeiculo.statusGovpassOrgao || filaVeiculo.situacao) {
        const statusGovpassOrgao = useStatusService().getStatusOrgaoGovPass(
          filaVeiculo.statusGovpassOrgao
        );
        const situacaoGovpass = statusGovpassOrgao?.descricao;
        return [
          filaVeiculo.situacao != situacaoGovpass
            ? "<b>Situação GovPass</b>: " + situacaoGovpass
            : null,
          filaVeiculo.situacao
            ? [
                //(licencaOrgao.situacao != licencaOrgao.situacaoGovpass ?
                "<b>Situação Órgão</b>: " +
                  //: "")
                  (filaVeiculo.situacao ?? situacaoGovpass) +
                  ""
              ]
                .filter((e) => e)
                .join(" - ")
            : null
        ]
          .filter((e) => e)
          .join(" <br/> ");
      }
      return "---";
    };

    const confirmSelectionFilaVeiculo = (
      selected: Array<FilaVeiculoPayload>,
      added: Array<FilaVeiculoPayload>,
      removed: Array<FilaVeiculoPayload>
    ) => {
      if (added && added.length > 0) {
        added.forEach((fila) => {
          if (
            fila.idFilaVeiculo &&
            state.filaVeiculoSelected.indexOf(fila.idFilaVeiculo) < 0
          ) {
            state.filaVeiculoSelected.push(fila.idFilaVeiculo);
          }
        });
      }
      if (removed && removed.length > 0) {
        removed.forEach((fila) => {
          if (
            fila.idFilaVeiculo &&
            state.filaVeiculoSelected.indexOf(fila.idFilaVeiculo) >= 0
          ) {
            state.filaVeiculoSelected.splice(
              state.filaVeiculoSelected.indexOf(fila.idFilaVeiculo),
              1
            );
          }
        });
      }
      return selected;
    };

    const getNomeTransportador = (e?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    onMounted(async () => {
      state.stateReady = true;
      submitSearch();
    });

    return {
      submitSearch,
      executeSearch,
      play,
      playAll,
      markAsOk,
      markAllAsOk,
      getNomeTransportador,
      confirmSelectionFilaVeiculo,
      getSituacaoNome,
      format,
      formatDateTime,
      stateLoading,
      amIAllowed,
      amIBlocked,
      ...toRefs(state)
    };
  },
  components: {},
  props: {
    idVeiculo: {
      type: Number,
      required: true
    },
    isVeiculoTrator: {
      type: Boolean,
      required: true
    },
    label: String
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
