
import { Options, Vue } from "vue-class-component";
import CrudTransportador from "../../components/transportador/CrudTransportador.vue";

@Options({
  props: {
    isNew: Boolean,
    idTransportador: Number
  },
  components: {
    CrudTransportador
  }
})
export default class Transportador extends Vue {
  isNew?: boolean;
  idTransportador?: number;
}
