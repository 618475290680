<template>
  <sistema>
    <div>
      <crud-modelo-veiculo-trator
        :isSearch="false"
        :isNew="isNew"
        :idModeloVeiculoTrator="idModeloVeiculoTrator"
        :idModeloVeiculoTratorCopy="idModeloVeiculoTratorCopy"
      />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudModeloVeiculoTrator from "../../components/veiculo/trator/modelo/CrudModeloVeiculoTrator.vue";

@Options({
  props: {
    isNew: Boolean,
    idModeloVeiculoTrator: Number,
    idModeloVeiculoTratorCopy: Number
  },
  components: {
    CrudModeloVeiculoTrator
  }
})
export default class ModeloVeiculoTrator extends Vue {
  isNew?: boolean;
  idModeloVeiculoTrator?: number;
  idModeloVeiculoTratorCopy?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
