
import { Options, Vue } from "vue-class-component";
import CrudCarreta from "../../components/veiculo/carreta/CrudCarreta.vue";

@Options({
  props: {
    isNew: Boolean,
    idCarreta: Number
  },
  components: {
    CrudCarreta
  }
})
export default class Carreta extends Vue {
  isNew?: boolean;
  idCarreta?: number;
}
