<template>
  <sistema>
    <div>
      <crud-veiculo-trator
        :isSearch="false"
        :isNew="isNew"
        :idVeiculoTrator="idVeiculoTrator"
      />
    </div>
  </sistema>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudVeiculoTrator from "../../components/veiculo/trator/CrudVeiculoTrator.vue";

@Options({
  props: {
    isNew: Boolean,
    idVeiculoTrator: Number
  },
  components: {
    CrudVeiculoTrator
  }
})
export default class VeiculoTrator extends Vue {
  isNew?: boolean;
  idVeiculoTrator?: number;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
