import { createApp, ObjectDirective } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import AutoComplete from "primevue/autocomplete";
import Listbox from "primevue/listbox";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Password from "primevue/password";
import BadgeDirective from "primevue/badgedirective";
import ProgressSpinner from "primevue/progressspinner";
import FileUpload from "primevue/fileupload";
import DataTable from "primevue/datatable";
import Paginator from "primevue/paginator";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Toolbar from "primevue/toolbar";
import Textarea from "primevue/textarea";
import SplitButton from "primevue/splitbutton";
import ToggleButton from "primevue/togglebutton";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import Toast from "primevue/toast";
import Panel from "primevue/panel";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import ScrollPanel from "primevue/scrollpanel";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Skeleton from "primevue/skeleton";
import OverlayPanel from "primevue/overlaypanel";
import Tooltip from "primevue/tooltip";
import Divider from "primevue/divider";
import Calendar from "primevue/calendar";
import ToastService from "primevue/toastservice";

//LAYOUT
import ButtonGroup from "@/components/layout/global/ButtonGroup.vue";
import Container from "@/components/layout/global/Container.vue";
import DatePicker from "@/components/layout/global/DatePicker.vue";
import EntitySelector from "@/components/layout/global/EntitySelector.vue";
import InputWithLabel from "@/components/layout/global/InputWithLabel.vue";
import InputWithButton from "@/components/layout/global/InputWithButton.vue";
import Loading from "@/components/layout/global/Loading.vue";
import PeriodPicker from "@/components/layout/global/PeriodPicker.vue";
import YearPicker from "@/components/layout/global/YearPicker.vue";
import InputFile from "@/components/layout/global/InputFile.vue";
import SearchResult from "@/components/layout/global/SearchResult.vue";
import Sistema from "@/components/layout/global/Sistema.vue";
import MessageToast from "@/components/layout/global/MessageToast.vue";
import MessageContainer from "@/components/layout/global/MessageContainer.vue";

//COMUNS
import OrgaoAetImg from "@/components/common/OrgaoAetImg.vue";
import OrgaoArtImg from "@/components/common/OrgaoArtImg.vue";

import { maska } from "maska";
import Toaster from "@meforma/vue-toaster";
import { ObserveVisibility } from "vue3-observe-visibility2";

import "primevue/resources/themes/md-light-indigo/theme.css"; //core css
//import "primevue/resources/themes/mdc-light-indigo/theme.css"; //core css
//import "primevue/resources/themes/saga-green/theme.css"; //core css
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";

//TODO KAIO https://primefaces.org/primevue/showcase/#/locale para datas

const app = createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue, { ripple: true })
  .directive("maska", maska)
  .directive("observe-visibility", ObserveVisibility)
  .directive("focus", {
    mounted: (el, binding) => {
      if (binding.value || typeof binding.value === "undefined") {
        app.config.globalProperties.$lastElementFocus = el;
        el.focus();
      }
    }
  })
  .component("Dialog", Dialog)
  .component("Button", Button)
  .component("InputText", InputText)
  .component("InputNumber", InputNumber)
  .component("InputSwitch", InputSwitch)
  .component("AutoComplete", AutoComplete)
  .component("Listbox", Listbox)
  .component("Dropdown", Dropdown)
  .component("MultiSelect", MultiSelect)
  .component("Password", Password)
  .component("ProgressSpinner", ProgressSpinner)
  .component("FileUpload", FileUpload)
  .component("DataTable", DataTable)
  .component("Paginator", Paginator)
  .component("Column", Column)
  .component("ColumnGroup", ColumnGroup)
  .component("Row", Row)
  .component("Toolbar", Toolbar)
  .component("Panel", Panel)
  .component("Textarea", Textarea)
  .component("ToggleButton", ToggleButton)
  .component("SplitButton", SplitButton)
  .component("ConfirmPopup", ConfirmPopup)
  .component("ConfirmDialog", ConfirmDialog)
  .component("Toast", Toast)
  .component("Calendar", Calendar)
  .component("OverlayPanel", OverlayPanel)
  .component("TabPanel", TabPanel)
  .component("TabView", TabView)
  .component("ScrollPanel", ScrollPanel)
  .component("Menu", Menu)
  .component("Menubar", Menubar)
  .component("Skeleton", Skeleton)
  .component("Divider", Divider)
  .directive("badge", BadgeDirective)
  .directive("tooltip", Tooltip)
  .use(ConfirmationService)

  .component("button-group", ButtonGroup)
  .component("container", Container)
  .component("date-picker", DatePicker)
  .component("entity-selector", EntitySelector)
  .component("input-with-label", InputWithLabel)
  .component("input-with-button", InputWithButton)
  .component("input-file", InputFile)
  .component("loading", Loading)
  .component("period-picker", PeriodPicker)
  .component("year-picker", YearPicker)
  .component("search-result", SearchResult)
  .component("sistema", Sistema)
  .component("message-toast", MessageToast)
  .component("message-container", MessageContainer)
  .component("orgao-aet-img", OrgaoAetImg)
  .component("orgao-art-img", OrgaoArtImg);

app.config.globalProperties.$internalRouter = router;
app.config.globalProperties.$internalConfirm = null;
app.config.globalProperties.$useOldToaster = false;
if (app.config.globalProperties.$useOldToaster) {
  //Quando esse cara for totalmente convertido para Composition, Tirar isso aqui
  //https://github.com/MeForma/vue-toaster/issues/7
  app.use(Toaster);
  app.provide("toast", app.config.globalProperties.$toast);
} else {
  app.use(ToastService);
}
app.provide(
  "staticAssetsUrl",
  "https://govpassaet.blob.core.windows.net/static-files"
);

app.directive("title", {
  mounted(el, options) {
    if (el) {
      if (el.disabled) {
        el.title = options.value;
      } else {
        delete el.title;
      }
    }
  },
  beforeMount(el, options, vnode, prevVNode) {
    const tt = app._context.directives["tooltip"] as ObjectDirective;
    if (tt && tt.beforeMount) {
      tt.beforeMount(el, options, vnode, prevVNode);
    }
  },
  unmounted(el, options, vnode, prevVNode) {
    const tt = app._context.directives["tooltip"] as ObjectDirective;
    if (tt && tt.unmounted) {
      tt.unmounted(el, options, vnode, prevVNode);
    }
  },
  updated(el, options, vnode, prevVNode) {
    const tt = app._context.directives["tooltip"] as ObjectDirective;
    if (tt && tt.updated) {
      tt.updated(el, options, vnode, prevVNode);
    }
    if (el) {
      if (el.disabled) {
        el.title = options.value;
      } else {
        delete el.title;
      }
    }
  }
});

app.mount("#app");

export default app;
