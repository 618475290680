
import { Options, Vue } from "vue-class-component";
import CrudVeiculoTrator from "../../components/veiculo/trator/CrudVeiculoTrator.vue";

@Options({
  props: {
    isNew: Boolean,
    idVeiculoTrator: Number
  },
  components: {
    CrudVeiculoTrator
  }
})
export default class VeiculoTrator extends Vue {
  isNew?: boolean;
  idVeiculoTrator?: number;
}
