
import { computed, defineComponent, ref } from "vue";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { MenuModel } from "../../router";
import Notificacao from "../notificacao/Notificacao.vue";
import Painel from "../painel/Painel.vue";

export default defineComponent({
  setup() {
    const { user } = useAuth();
    const initials = computed(
      () =>
        user?.value &&
        [user.value.userName?.substr(0, 1)].filter((e) => !!e).join("")
    );

    const logoUrl = computed(() => {
      return user?.value && user.value.logoUrl;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const menus = [] as Array<MenuModel>;
    if (
      amIAllowed("view", "root-dashboard") ||
      amIAllowed("view", "root-cliente")
    ) {
      const menuRoot = {
        label: "Root",
        icon: "fas fa-brain",
        items: [] as Array<MenuModel>
      };
      if (amIAllowed("view", "root-dashboard")) {
        menuRoot.items.push({
          label: "Dashboard",
          icon: "fas fa-tachometer-alt",
          to: { name: "root-dashboard" }
        });
      }
      if (amIAllowed("view", "root-cliente")) {
        menuRoot.items.push({
          label: "Clientes",
          icon: "fas fa-users",
          to: { name: "root-cliente" }
        });
      }
      menus.push(menuRoot);
    }

    if (
      amIAllowed("view", "licenca") ||
      amIAllowed("view", "licenca-combinacao-veiculos") ||
      amIAllowed("view", "relatorio-faturamento")
    ) {
      const menuLicenca = {
        label: "Licenças",
        icon: "fas fa-file-alt",
        items: [] as Array<MenuModel>
      };
      if (amIAllowed("view", "licenca")) {
        menuLicenca.items.push({
          label: "Licenças",
          icon: "fas fa-file-alt",
          to: { name: "licenca" }
        });
      }
      if (
        amIAllowed("view", "licenca-combinacao-veiculos") ||
        amIAllowed("view", "relatorio-faturamento")
      ) {
        const menuLicencaConsulta = {
          label: "Consultas",
          icon: "far fa-chart-bar",
          items: [] as Array<MenuModel>
        };
        if (amIAllowed("view", "licenca-combinacao-veiculos")) {
          menuLicencaConsulta.items.push({
            label: "Combinação dos Veículos",
            to: { name: "licenca-combinacao-veiculos" }
          });
        }
        if (amIAllowed("view", "relatorio-faturamento")) {
          menuLicencaConsulta.items.push({
            label: "Faturamento",
            to: { name: "relatorio-faturamento" }
          });
        }
        menuLicenca.items.push({ separator: true });
        menuLicenca.items.push(menuLicencaConsulta);
      }
      menus.push(menuLicenca);
    }

    if (
      amIAllowed("view", "veiculo-trator") ||
      amIAllowed("view", "carreta") ||
      amIAllowed("view", "veiculo-trator-modelo") ||
      amIAllowed("view", "veiculo-importacao-lote")
    ) {
      const menuVeiculo = {
        label: "Veículos",
        icon: "fas fa-truck",
        items: [] as Array<MenuModel>
      };
      if (amIAllowed("view", "veiculo-trator-modelo")) {
        menuVeiculo.items.push({
          label: "Modelos",
          icon: "fas fa-file-invoice",
          to: { name: "veiculo-trator-modelo" }
        });
      }
      if (amIAllowed("view", "veiculo-trator")) {
        menuVeiculo.items.push({
          label: "Veículos Tratores",
          icon: "fas fa-truck",
          to: { name: "veiculo-trator" }
        });
      }

      if (amIAllowed("view", "carreta")) {
        menuVeiculo.items.push({ separator: true });
        menuVeiculo.items.push({
          label: "Carretas",
          icon: "fas fa-truck-loading",
          to: { name: "carreta" }
        });
      }
      if (amIAllowed("view", "veiculo-importacao-lote")) {
        menuVeiculo.items.push({ separator: true });
        menuVeiculo.items.push({
          label: "Atualização de documentos em lote",
          icon: "fas fa-file-import",
          to: { name: "veiculo-importacao-lote" }
        });
      }

      menus.push(menuVeiculo);
    }
    if (amIAllowed("view", "transportador")) {
      menus.push({
        label: "Transportadores",
        icon: "fas fa-briefcase",
        to: { name: "transportador" }
      });
    }
    if (amIAllowed("view", "engenheiro")) {
      menus.push({
        label: "Engenheiros",
        icon: "fas fa-hard-hat",
        to: { name: "engenheiro" }
      });
    }
    if (amIAllowed("view", "trechos-planilha")) {
      menus.push({
        label: "Planilhas de Trechos",
        icon: "fas fa-road",
        to: { name: "trechos-planilha" }
      });
    }

    const menusUser = [] as Array<MenuModel>;
    if (amIAllowed("view", "admin-cliente")) {
      menusUser.push({
        label: "Configurações",
        icon: "fas fa-user-cog",
        to: { name: "admin-cliente" }
      });
    }
    if (amIAllowed("view", "usuario")) {
      menusUser.push({
        label: "Usuários",
        icon: "fas fa-users",
        to: { name: "usuario" }
      });
    }
    menusUser.push({
      label: "Minha Senha",
      icon: "fas fa-key",
      to: { name: "me-change-password" }
    });
    menusUser.push({
      label: "Sair",
      icon: "pi pi-fw pi-power-off",
      to: { name: "logout" }
    });

    const menusRefs = ref(menus);
    const menusUserRefs = ref(menusUser);
    const menuUser = ref();

    const toggleComponentMenu = (evt: Event) => {
      ((evt.target as HTMLElement)?.firstElementChild as HTMLElement)?.click();
    };
    const toggleUserMenu = (event: Event) => {
      menuUser.value.toggle(event);
    };

    return {
      user,
      initials,
      menusRefs,
      menusUserRefs,
      menuUser,
      logoUrl,
      toggleComponentMenu,
      toggleUserMenu,
      amIAllowed
    };
  },

  components: {
    Notificacao,
    Painel
  }
});
