<template>
  <span
    :class="'p-buttonset ' + (className ? className : '')"
    v-if="applyGroup"
    :id="stateId"
  >
    <slot />
    <template v-if="menuItems && menuItems.length">
      <Button
        icon="pi pi-chevron-down"
        @click="toggle"
        :class="menuButtonClass ? menuButtonClass : ''"
        aria-haspopup="true"
        :aria-controls="stateId + '_overlay_menu'"
        v-title="'Mais opções'"
      />
      <Menu
        :id="stateId + 'overlay_menu'"
        ref="menu"
        :model="menuItems"
        :popup="true"
      />
    </template>
  </span>
  <div v-else :class="className ? className : ''" :id="stateId">
    <slot />
    <template v-if="menuItems && menuItems.length">
      <Button
        icon="pi pi-chevron-down"
        @click="toggle"
        :class="menuButtonClass ? menuButtonClass : ''"
        aria-haspopup="true"
        :aria-controls="stateId + '_overlay_menu'"
        v-title="'Mais opções'"
      />
      <Menu
        :id="stateId + 'overlay_menu'"
        ref="menu"
        :model="menuItems"
        :popup="true"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref, PropType } from "vue";
import { MenuModel } from "../../../router";

interface ButtonGroupState {
  stateId: string;
}
export default defineComponent({
  setup(props) {
    const state = reactive<ButtonGroupState>({
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2)
    });

    const menu = ref();

    const toggle = (event: Event) => {
      menu.value.toggle(event);
    };

    return {
      toggle,
      menu,
      ...toRefs(state)
    };
  },
  props: {
    applyGroup: { type: Boolean, default: true },
    className: { type: String },
    menuButtonClass: { type: String },
    menuItems: Array as PropType<Array<MenuModel>>,
    id: {
      type: String
    }
  },
  components: {}
});
</script>
