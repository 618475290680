<template>
  <div>
    <ConfirmDialog />
    <message-toast group="top-right" />
    <message-toast group="top-center" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    //initGlobalConfirm();
    //initGlobalToast();
    return {};
  },
  components: {}
});
</script>
