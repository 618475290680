/* eslint-disable  @typescript-eslint/no-explicit-any */
import { reactive } from "vue";
import { mask, tokens } from "maska";
import { useToast } from "@/modules/message";
import {
  format as formatDateInternal,
  Locale as _Locale,
  parseISO,
  differenceInMinutes as differenceInMinutesInternal,
  differenceInDays as differenceInDaysInternal
  //formatDistance,
  // formatRelative,
  //subDays
} from "date-fns";
import { ptBR, enUS } from "date-fns/locale";
import { PrimeVueLocaleOptions } from "primevue/config";

const isoDatePattern = /^(\d{4})(?:-?W(\d+)(?:-?(\d+)D?)|(?:-(\d+))-(\d+))(?:[T ](\d+):(\d+)(?::(\d+)(?:\.(\d+))?)?)?(?:Z(-?\d*))?$/;

export type Locale = _Locale;

export const localeOptions = reactive({
  localeStr: "en-US",
  defaultDateTimeLocale: enUS,
  defaultDateFormatStr: enUS.formatLong?.date({
    width: "short"
  }),
  originalLocaleStrings: null as PrimeVueLocaleOptions | null | undefined
});

export const DATE_SELECTORS = {
  LAST_MONTH: "LAST_MONTH",
  LAST_WEEK: "LAST_WEEK",
  TODAY: "TODAY",
  FREE_SELECTION: "FREE_SELECTION",
  MONTH: "MONTH",
  NO_DATE: "NO_DATE"
};

export interface AutoCompleteInputEvent<T> {
  originalEvent?: InputEvent;
  query?: string;
  value?: T;
}

const iterateOverObject = (cb: Function, o?: any | null) => {
  if (!o) {
    return;
  }
  Object.keys(o).forEach((k: string) => {
    if (o[k] !== null && typeof o[k] === "object") {
      iterateOverObject(cb, o[k]);
      return;
    } else {
      const ret = cb(k, o[k]);
      if (typeof ret !== "undefined") {
        o[k] = ret;
      }
    }
  });
};

export const indexesOfElement = <T>(
  array: Array<T>,
  obj: T,
  propertySelector: (ob: T) => any
): Array<number> => {
  const arr = new Array<number>();
  const objKey = propertySelector(obj);
  array.forEach((val, i) => {
    if (propertySelector(val) === objKey) {
      arr.push(i);
    }
  });
  return arr;
};

export const toggleElement = <T>(
  array: Array<T>,
  element: T,
  propertySelector: (ob: T) => any,
  allowMultiple?: boolean
) => {
  if (indexesOfElement(array, element, propertySelector).length > 0) {
    indexesOfElement(array, element, propertySelector)
      .reverse()
      .forEach((index) => {
        array.splice(index, 1);
      });
  } else {
    if (!allowMultiple) {
      array.splice(0, array.length);
    }
    array.push(element);
  }
};

export const addSetElement = <T>(
  array: Array<T>,
  element: T,
  propertySelector: (ob: T) => any,
  allowMultiple?: boolean
) => {
  if (indexesOfElement(array, element, propertySelector).length <= 0) {
    if (!allowMultiple) {
      array.splice(0, array.length);
    }
    array.push(element);
  }
};

export const getArrayExclude = <T>(
  propertySelector: (ob: T) => any,
  arrayTest?: Array<T> | null,
  elementsIgnored?: Array<T> | null
) => {
  if (!elementsIgnored || elementsIgnored.length <= 0) {
    return [];
  } else if (!arrayTest || arrayTest.length <= 0) {
    return elementsIgnored;
  }
  const diff = [] as Array<T>;
  elementsIgnored.forEach((v) => {
    if (indexesOfElement(arrayTest, v, propertySelector).length <= 0) {
      diff.push(v);
    }
  });
  return diff;
};

export const waitUntilStable = <T>(
  currentToken: T,
  getterFunction: () => T,
  cb: Function,
  timeout?: number
) => {
  setTimeout(
    () => {
      const token = getterFunction();
      if (currentToken == token) {
        cb();
      } else {
        waitUntilStable(token, getterFunction, cb, timeout);
      }
    },
    timeout ? timeout : 1000
  );
};

export const parseISODate = (obj?: string | null) => {
  if (obj && typeof obj === "string" && obj.match(isoDatePattern)) {
    let date = parseISO(obj);
    //A LINHA DE CIMA DEVERIA RESOLVER, POREM O BACKEND NAO MANDA UM ISO VALIDO (SEM TIMEZONE)
    //LINHAS ABAIXO EH GAMBI ATE RESOLVER A QUESTAO DO ISO NO BACKEND

    if (!date || isNaN(date.getTime())) {
      date = new Date(obj);
      if (obj.length <= 10) {
        date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      }
    }

    if (date && !isNaN(date.getTime())) {
      return date;
    }
  }
  return null;
};

export const toUTCDate = (obj?: string | Date | null) => {
  //sempre sem time
  if (
    obj &&
    ((obj instanceof Date && isNaN(obj.getTime())) ||
      (typeof obj === "string" && obj.match(isoDatePattern)))
  ) {
    let date = new Date(obj);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    if (date && !isNaN(date.getTime())) {
      return date.toISOString().split("T")[0];
    }
  }
  return null;
};

export const toUTCDateTime = (obj?: string | Date | null) => {
  //sempre sem time
  if (
    obj &&
    ((obj instanceof Date && isNaN(obj.getTime())) ||
      (typeof obj === "string" && obj.match(isoDatePattern)))
  ) {
    let date = new Date(obj);
    const time = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    if (date && !isNaN(date.getTime())) {
      return new Date(
        new Date(date.toISOString().split("T")[0]).getTime() + time
      ).toISOString();
    }
  }
  return null;
};

export const format = (mascara: string | Array<string>, input: any) => {
  if (input) {
    return mask(
      input,
      Array.isArray(mascara) ? JSON.stringify(mascara) : mascara,
      tokens
    );
  }
  return "";
};

export const formatNumber = (
  input?: number | null,
  options?: Intl.NumberFormatOptions
) => {
  if (input || input === 0) {
    return input.toLocaleString(
      localeOptions.defaultDateTimeLocale.code,
      options
    );
  }
  return "";
};

export const formatDate = (input?: Date | null, mask?: string) => {
  if (input) {
    return formatDateInternal(input, mask ? mask : "P", {
      locale: localeOptions.defaultDateTimeLocale
    });
  }
  return "";
};

export const formatTime = (input?: Date | null, mask?: string) => {
  if (input) {
    return formatDateInternal(input, mask ? mask : "p", {
      locale: localeOptions.defaultDateTimeLocale
    });
  }
  return "";
};
export const formatDateTime = (input?: Date | null, mask?: string) => {
  if (input) {
    return formatDateInternal(input, mask ? mask : "Pp", {
      locale: localeOptions.defaultDateTimeLocale
    });
  }
  return "";
};

export const formatDatePeriod = (
  input1?: Date | null,
  input2?: Date | null,
  mask?: string
): string => {
  if (!input1 && !input2) {
    return "---";
  }
  const dt1Formatted = formatDate(input1, mask);
  const dt2Formatted = formatDate(input2, mask);
  if (dt1Formatted == dt2Formatted) {
    return dt1Formatted;
  }
  let ret = "";
  if (input1) {
    ret += "De " + dt1Formatted + " ";
  }
  if (input2) {
    ret += "Até " + dt2Formatted;
  }
  return ret;
};

export const processKeyDownDate = (event: KeyboardEvent) => {
  //PRECISA MELHORAR ESSE CARA
  if (event.target && /^[0-9]$/i.test(event.key)) {
    const currValue = (event?.target as HTMLInputElement)?.value;
    const arrPosicoes = [...localeOptions.defaultDateFormatStr].map((c) =>
      /^[a-zA-Z ]$/i.test(c) ? "" : c
    );
    for (let i = 0; i < arrPosicoes.length; i++) {
      if (arrPosicoes[i] && currValue.length == i) {
        (event.target as HTMLInputElement).value = currValue + arrPosicoes[i];
        break;
      }
    }
  }
};

export const differenceInMinutes = (d1: Date, d2: Date) => {
  return Math.abs(differenceInMinutesInternal(d1, d2));
};
export const differenceInDays = (d1: Date, d2: Date) => {
  return Math.abs(differenceInDaysInternal(d1, d2));
};

export const round = (decimal: number, num?: number | null): number | null => {
  if (typeof num == "undefined" || num == null) {
    return null;
  }
  return (
    (Math.round(
      Number((Math.abs(num) * Math.pow(10, decimal)).toPrecision(15))
    ) /
      Math.pow(10, decimal)) *
    Math.sign(num)
  );
};

export const findAllFocussableChildren = (
  el: HTMLElement,
  querySelector?: string
) => {
  return Array.prototype.filter.call(
    el.querySelectorAll(
      querySelector
        ? querySelector
        : 'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'
    ),
    function(element: HTMLElement) {
      return (
        element.offsetWidth > 0 || element.offsetHeight > 0 || element === el
      );
    }
  );
};

export const focusNext = (el: HTMLElement) => {
  if (el) {
    const base = el.closest("form") || el.closest("body");
    if (base) {
      const focussable = findAllFocussableChildren(base);
      const index = focussable.indexOf(el);
      if (index > -1) {
        const nextElement = focussable[index + 1] || focussable[0];
        nextElement.focus();
      }
    }
  }
};
export const processObject = (cb: Function, o?: any | null) => {
  iterateOverObject(cb, o);
  return o;
};

export const clearObject = (obj: any) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        obj[key] = clearObject(obj[key]);
      }
    });
  }
  return obj;
};

export const copyObject = (
  source: any,
  target: any,
  ignoredProperties?: Array<string>
) => {
  if (source) {
    if (target) {
      Object.keys(source).forEach((key) => {
        if (!ignoredProperties || ignoredProperties.indexOf(key) < 0) {
          if (target[key] && typeof target[key] == typeof source[key]) {
            if (typeof source[key] == "object") {
              copyObject(source[key], target[key], ignoredProperties);
            } else {
              target[key] = source[key];
            }
          } else {
            target[key] = source[key];
          }
        }
      });
    } else {
      return source;
    }
  }
  return target;
};

export const cloneObject = (source: any) => {
  if (typeof source !== "undefined") {
    if (typeof source == "object") {
      return JSON.parse(JSON.stringify(source));
    }
  }
  return source;
};

export const setLanguage = (
  locale: string,
  localeConfig: PrimeVueLocaleOptions | undefined
) => {
  if (!localeOptions.originalLocaleStrings) {
    localeOptions.originalLocaleStrings = cloneObject(localeConfig);
  }
  if (locale == "pt-BR") {
    localeOptions.localeStr = "pt-BR";
    localeOptions.defaultDateTimeLocale = ptBR;
    if (localeConfig) {
      localeConfig.startsWith = "Começa com";
      localeConfig.contains = "Contém";
      localeConfig.notContains = "Não contém";
      localeConfig.endWith = "Termina com";
      localeConfig.equals = "Igual";
      localeConfig.notEquals = "Diferente";
      localeConfig.noFilter = "Sem filtro";
      localeConfig.lt = "Menor que";
      localeConfig.lte = "Menor ou igual a";
      localeConfig.gt = "Maior que";
      localeConfig.gte = "Maior ou igual a";
      localeConfig.dateIs = "Data é";
      localeConfig.dateIsNot = "Data não é";
      localeConfig.dateBefore = "Data é anterior";
      localeConfig.dateAfter = "Data é posterior";
      localeConfig.clear = "Limpar";
      localeConfig.apply = "Aplicar";
      localeConfig.matchAll = "Combinar todos";
      localeConfig.matchAny = "Combinar qualquer";
      localeConfig.addRule = "Adicionar regra";
      localeConfig.removeRule = "Remover regra";
      localeConfig.accept = "Sim";
      localeConfig.reject = "Não";
      localeConfig.choose = "Escolha";
      localeConfig.upload = "Carregar arquivo";
      localeConfig.cancel = "Cancelar";
      localeConfig.dayNames = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado"
      ];
      localeConfig.dayNamesShort = [
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sáb"
      ];
      localeConfig.dayNamesMin = ["Dm", "Sg", "Te", "Qa", "Qi", "Sx", "Sa"];
      localeConfig.monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];
      localeConfig.monthNamesShort = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ];
      localeConfig.today = "Hoje";
      localeConfig.weekHeader = "Sem";
      localeConfig.firstDayOfWeek = 0;
      localeConfig.dateFormat = "dd/mm/yy";
      localeConfig.weak = "Fraca";
      localeConfig.medium = "Média";
      localeConfig.strong = "Forte";
      localeConfig.passwordPrompt = "Digite a senha";
      localeConfig.emptyFilterMessage = "Nenhum resultado encontrado";
      localeConfig.emptyMessage = "Nenhuma opção disponível";
    }
  } else {
    localeOptions.localeStr = "en-US";
    localeOptions.defaultDateTimeLocale = enUS;
    if (localeConfig) {
      copyObject(localeOptions.originalLocaleStrings, localeConfig);
    }
  }

  localeOptions.defaultDateFormatStr = localeOptions.defaultDateTimeLocale.formatLong?.date(
    {
      width: "short"
    }
  );
};

export const isArrayContainsAll = <T>(
  source?: Array<T> | null,
  target?: Array<T> | null
) => {
  if (!target || target.length <= 0) {
    return !source || source.length <= 0;
  } else if (!source || source.length <= 0) {
    return false;
  }
  return target.every((v) => source.includes(v));
};

export const isArrayEquals = <T>(
  source?: Array<T> | null,
  target?: Array<T> | null
) => {
  return (
    isArrayContainsAll(source, target) && isArrayContainsAll(target, source)
  );
};

export const copyToClipboard = <T>(data?: string | null) => {
  if (data) {
    window.navigator.clipboard.writeText(data);
    useToast().success("Id copiado com sucesso!");
  }
};
