<template>
  <div v-if="stateReady">
    <div>
      <entity-selector
        :entities="entitiesSelected"
        :keyParam="'idEngenheiro'"
        :required="required"
        :canEdit="canEdit"
        :label="label"
        :showLabel="showLabel"
        @open-entity="openEntity"
        @open-search="openSearch"
        @remove-entity="clearEntity"
        ref="es"
      >
        <template v-slot:id="slotProps">
          <span v-text="getNomeEngenheiro(slotProps.entity)" />
        </template>
        <template v-slot:selected="slotProps">
          <input-with-label
            :label="label"
            :required="required"
            v-slot="l"
            iconClass="fa fa-briefcase m-2"
          >
            <input
              type="text"
              class="p-inputtext p-component p-filled"
              style="opacity: 1"
              :disabled="true"
              :id="l.id"
              :required="l.required"
              :value="getNomeEngenheiro(slotProps.entity)"
            />
          </input-with-label>
        </template>
        <template v-slot:not-selected>
          <input-with-label
            :label="label + ' (CPF)'"
            :required="required"
            iconClass="fa fa-briefcase m-2"
            v-slot="l"
          >
            <InputText
              v-model="cpfCnpjInternal"
              v-maska="'###.###.###-##'"
              v-focus="focus"
              @change="onChangeCpfCnpjEngenheiroFilter"
              :id="l.id"
              :required="l.required"
            />
          </input-with-label>
        </template>
      </entity-selector>
      <crud-engenheiro
        v-if="crudOpen"
        :isSearch="true"
        :idEngenheiro="idEngenheiroToView"
        :cliente="cliente"
        :selected="entitiesToSelect"
        :preFilter="preFilter"
        @confirm-selection="confirmSelection"
        @cancel-selection="cancelSelection"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  computed,
  watch
} from "vue";
import CrudEngenheiro from "./CrudEngenheiro.vue";
import {
  EngenheiroPayload,
  useEngenheiroService
} from "../../modules/business/engenheiro/engenheiroBusiness";
import { format } from "../../modules/utils";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";

interface EngenheiroSelectorState {
  stateReady: boolean;
  crudOpen: boolean;
  idEngenheiroToView?: number | null;
  cpfCnpjInternal: string | null;
  entitiesToSelect: Array<EngenheiroPayload>;
  engenheiro?: EngenheiroPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EngenheiroSelectorState>({
      stateReady: false,
      crudOpen: false,
      idEngenheiroToView: null,
      cpfCnpjInternal: null,
      entitiesToSelect: []
    });

    const es = ref();

    const preFilter = computed(() => {
      const pf = useEngenheiroService().safeFilterEngenheiro();
      if (state.cpfCnpjInternal) {
        pf.cpfCnpj = state.cpfCnpjInternal;
      }
      return pf;
    });

    watch([props], () => {
      if (!props.engenheiro || !props.engenheiro.idEngenheiro) {
        if (props.idEngenheiroFiltered != state.engenheiro?.idEngenheiro) {
          state.engenheiro = null;
        }
      }
    });

    const entitiesSelected = computed(() => {
      return props.engenheiro && props.engenheiro.idEngenheiro
        ? [props.engenheiro]
        : state.engenheiro && state.engenheiro.idEngenheiro
        ? [state.engenheiro]
        : [];
    });

    const clearEntity = () => {
      state.cpfCnpjInternal = null;
      state.engenheiro = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idEngenheiroToView = null;
      es.value.focus();
    };

    const confirmSelection = (selected: Array<EngenheiroPayload>) => {
      state.engenheiro = selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.engenheiro);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.cpfCnpjInternal = null;
      state.crudOpen = false;
      state.idEngenheiroToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.cpfCnpjInternal = null;
      state.crudOpen = false;
      state.idEngenheiroToView = null;
      emit("cancel-selection");
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idEngenheiroToView = null;
      state.crudOpen = true;
    };

    const openEntity = (e: EngenheiroPayload) => {
      if (e.idEngenheiro) {
        state.idEngenheiroToView = e.idEngenheiro;
        state.crudOpen = true;
      }
    };

    const onChangeCpfCnpjEngenheiroFilter = () => {
      if (state.cpfCnpjInternal && state.cpfCnpjInternal.length == 14) {
        openSearch();
      }
    };

    const getNomeEngenheiro = (e?: EngenheiroPayload) => {
      return useEngenheiroService().getNomeEngenheiro(e);
    };

    onMounted(async () => {
      state.engenheiro = props.engenheiro;
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      clearEntity,
      confirmSelection,
      cancelSelection,
      entitiesSelected,
      es,
      preFilter,
      onChangeCpfCnpjEngenheiroFilter,
      getNomeEngenheiro,
      format,
      ...toRefs(state)
    };
  },
  components: {
    CrudEngenheiro
  },
  props: {
    engenheiro: {
      type: Object as PropType<EngenheiroPayload>
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    idEngenheiroFiltered: Number,
    cliente: Object as PropType<ClientePayload>,
    required: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Engenheiro"
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
