
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useAuth } from "../../modules/auth";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";
import { useToast } from "../../modules/message";

interface RecoverPayload {
  credentials: CredencialPayload;
  confirmaSenha: string;
  stateLoading: boolean;
  stateReady: boolean;
}

export default defineComponent({
  setup() {
    const state = reactive<RecoverPayload>({
      credentials: {},
      confirmaSenha: "",
      stateLoading: false,
      stateReady: false
    });

    const submit = () => {
      state.stateLoading = true;

      useAuth()
        .updateMyPassword(state.credentials)
        .then(() => {
          useToast().success("Senha atualizada com sucesso.");
          state.credentials = {};
          state.confirmaSenha = "";
          state.stateLoading = false;
        })
        .catch(() => {
          state.stateLoading = false;
        });
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submit,
      ...toRefs(state)
    };
  },
  components: {}
});
