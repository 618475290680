<template>
  <template v-if="stateReady">
    <fieldset
      :disabled="parentLoading"
      v-if="amIAllowed('view', 'root-cliente')"
    >
      <div>
        <entity-selector
          :entities="entitiesSelected"
          :keyParam="'idCliente'"
          :required="required"
          :canEdit="canEdit"
          :label="label"
          :showLabel="showLabel"
          ref="es"
          @open-entity="openEntity"
          @open-search="openSearch"
          @remove-entity="clearEntity"
        >
          <template v-slot:id="slotProps">
            <span v-text="getNomeCliente(slotProps.entity)" />
          </template>

          <template v-slot:selected="slotProps">
            <input-with-label
              :label="label"
              :required="required"
              v-slot="l"
              iconClass="fa fa-user m-2"
            >
              <input
                type="text"
                class="p-inputtext p-component p-filled"
                style="opacity: 1"
                :disabled="true"
                :id="l.id"
                :required="l.required"
                :value="getNomeCliente(slotProps.entity)"
              />
            </input-with-label>
          </template>
          <template v-slot:not-selected>
            <input-with-label
              :label="label"
              :required="required"
              iconClass="fa fa-user m-2"
              v-slot="l"
            >
              <InputText
                v-model="idClienteInternal"
                v-maska="'#*'"
                v-focus="focus"
                :id="l.id"
                :required="l.required"
                @change="onChangeIdClienteFilter"
              />
            </input-with-label>
          </template>
        </entity-selector>
        <crud-cliente
          v-if="crudOpen"
          :isSearch="true"
          :idCliente="idClienteToView"
          :selected="entitiesToSelect"
          :preFilter="preFilter"
          @confirm-selection="confirmSelection"
          @cancel-selection="cancelSelection"
        />
      </div>
    </fieldset>
  </template>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  watch,
  computed
} from "vue";
import CrudCliente from "./CrudCliente.vue";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../modules/business/cliente/clienteBusiness";
import { format } from "../../modules/utils";
import { useAuth, PermissionPayload } from "../../modules/auth";

interface ClienteSelectorState {
  stateReady: boolean;
  crudOpen: boolean;
  idClienteToView?: number | null;
  idClienteInternal: number | null;
  entitiesToSelect: Array<ClientePayload>;
  cliente?: ClientePayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ClienteSelectorState>({
      stateReady: false,
      crudOpen: false,
      idClienteToView: null,
      entitiesToSelect: [],
      idClienteInternal: null
    });

    const es = ref();

    const preFilter = computed(() => {
      const pf = useClienteService().safeFilterCliente();
      if (state.idClienteInternal) {
        pf.idCliente = state.idClienteInternal;
      }
      return pf;
    });

    watch([props], () => {
      if (!props.cliente || !props.cliente.idCliente) {
        if (props.idClienteFiltered != state.cliente?.idCliente) {
          state.cliente = null;
        }
      }
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const entitiesSelected = computed(() => {
      return props.cliente && props.cliente.idCliente
        ? [props.cliente]
        : state.cliente && state.cliente.idCliente
        ? [state.cliente]
        : [];
    });

    const clearEntity = () => {
      state.idClienteInternal = null;
      state.cliente = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idClienteToView = null;
      es.value.focus();
    };

    const confirmSelection = (selected: Array<ClientePayload>) => {
      state.cliente = selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.cliente);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idClienteToView = null;
      state.idClienteInternal = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idClienteToView = null;
      state.idClienteInternal = null;
      emit("cancel-selection");
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idClienteToView = null;
      state.crudOpen = true;
    };

    const openEntity = (e: ClientePayload) => {
      if (e.idCliente) {
        state.idClienteToView = e.idCliente;
        state.crudOpen = true;
      }
    };

    const onChangeIdClienteFilter = () => {
      if (amIAllowed("view", "root-cliente") && state.idClienteInternal) {
        openSearch();
      }
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    onMounted(async () => {
      state.cliente = props.cliente;
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      clearEntity,
      confirmSelection,
      cancelSelection,
      preFilter,
      es,
      entitiesSelected,
      format,
      amIAllowed,
      onChangeIdClienteFilter,
      getNomeCliente,
      ...toRefs(state)
    };
  },
  components: {
    CrudCliente
  },
  props: {
    cliente: {
      type: Object as PropType<ClientePayload>
    },
    idClienteFiltered: Number,
    canEdit: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Cliente"
    },
    parentLoading: Boolean
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
