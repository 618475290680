
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  Ref,
  onMounted
} from "vue";
import {
  ImportacaoDocumentoPayload,
  ImportacaoDocumentoReturn,
  useImportacaoVeiculoService
} from "../../modules/business/veiculo/importacaoVeiculoBusiness";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import EntitySelectorCliente from "../../components/cliente/EntitySelectorCliente.vue";
import { useStorage } from "../../modules/storage";
import { useToast } from "../../modules/message";

interface ImportacaoDocumentoState {
  entity: ImportacaoDocumentoPayload;
  importReturn: ImportacaoDocumentoReturn | null;
  stateReady: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
}

export default defineComponent({
  setup() {
    const state = reactive<ImportacaoDocumentoState>({
      entity: { anoExercicioCrlv: new Date().getFullYear() },
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: [],
      importReturn: {}
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const save = () => {
      const {
        importFile,
        loading: loadingCadastro,
        data: dataCadastro
      } = useImportacaoVeiculoService();
      state.loadings.push(loadingCadastro);
      importFile(state.entity)
        .then(() => {
          state.importReturn = dataCadastro.value;
          if (!state.importReturn) {
            useToast().warning("Não foi possível realizar a importação.");
          } else {
            if (state.importReturn.erros && state.importReturn.erros.length) {
              useToast().warning(
                "Arquivo processado com erros. Verifique o retorno."
              );
            } else {
              useToast().success("Importação realizada com sucesso.");
            }
          }
          (document.querySelector(
            "#" + state.stateId + "_importacao"
          ) as HTMLInputElement).value = "";
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        })
        .catch(() => {
          if (state.entity.keyBlDocumentoTemp) {
            useStorage().deleteTempFile(state.entity.keyBlDocumentoTemp);
            state.entity.keyBlDocumentoTemp = null;
          }
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const submitSave = () => {
      state.importReturn = null;
      const formData = new FormData();
      const file = document.querySelector(
        "#" + state.stateId + "_importacao"
      ) as HTMLInputElement;
      if (!file || !file.files || file.files.length === 0) {
        useToast().warning("É necessário um arquivo.");
      } else {
        formData.append("file", file.files[0]);
        const storage = useStorage();
        state.loadings.push(storage.loading);
        storage
          .uploadTempFile(formData)
          .then((key: string) => {
            state.entity.keyBlDocumentoTemp = key;
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            save();
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
          });
      }
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      submitSave,
      stateLoading,
      confirmSelectionCliente,
      amIAllowed,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente
  }
});
