
import { defineComponent } from "vue";
import { systemEnabled } from "./modules/api";
import { setLanguage } from "./modules/utils";
import { useAuth } from "./modules/auth";
import { usePrimeVue } from "primevue/config";

import TopNav from "./components/layout/TopNav.vue";

export default defineComponent({
  components: { TopNav },
  setup() {
    const { authenticating, user } = useAuth();
    setLanguage(navigator.language, usePrimeVue().config.locale);
    return { systemEnabled, authenticating, user };
  }
});
