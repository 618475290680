
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "../../modules/auth";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";

interface LoginState {
  credenciais: CredencialPayload;
  loading: boolean;
}

export default defineComponent({
  setup() {
    const router = useRouter();
    const state = reactive<LoginState>({
      credenciais: {},
      loading: false
    });
    const submit = () => {
      state.loading = true;
      useAuth()
        .login(state.credenciais)
        .then(() => router.push({ name: "home" }))
        .then(() => {
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };
    return {
      submit,
      ...toRefs(state)
    };
  },
  components: {}
});
