
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import InputWithButton from "./InputWithButton.vue";

interface InputWithLabelState {
  stateReady: boolean;
  loading: boolean;
}

export default defineComponent({
  setup(_, { emit }) {
    const state = reactive<InputWithLabelState>({
      stateReady: false,
      loading: false
    });

    const download = (newTab?: boolean) => {
      state.loading = true;
      const func = () => {
        state.loading = false;
      };
      emit("click-download", !!newTab, func);
    };

    const beforeUpload = () => {
      //implementar se for usado componente de upload
    };

    const uploadFinish = () => {
      //implementar se for usado componente de upload
      emit("update");
    };

    const uploadTemp = () => {
      //implementar se for usado componente de upload
    };
    const progress = () => {
      //implementar se for usado componente de upload
    };

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      download,
      beforeUpload,
      uploadTemp,
      uploadFinish,
      progress,
      ...toRefs(state)
    };
  },
  emits: ["click-download", "update"],
  props: {
    label: String,
    subLabel: String,
    accept: { type: String },
    mode: {
      type: String,
      default: "basic",
      validator: function(value: string) {
        return ["basic", "advanced"].indexOf(value) !== -1;
      }
    },
    downloadTitle: { type: String, default: "Download" },
    required: [String, Number, Object, Boolean],
    id: { type: String, required: true },
    hasDownload: { type: [String, Number, Object, Boolean], required: true },
    readOnly: Boolean
  },
  components: { InputWithButton }
});
