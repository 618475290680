<template>
  <container
    :isModal="params.isSearch"
    @close-modal="cancelSelection()"
    :loading="!stateReady"
  >
    <template v-slot:header v-if="stateReady && params.isSearch">
      <h3>Consulta de ARTS</h3>
    </template>
    <template v-slot:body>
      <search-result
        :showSelector="params.isSearch"
        :results="results"
        :selected="params.selected"
        :keyParam="'idArtEngenheiroTransportador'"
        :loading="stateLoading"
        :breakpoint="'90rem'"
        :hidePagination="true"
        :showClient="params.isFila"
        :getClientFunction="(data) => data.transportador?.cliente"
        :rowClassFunction="
          (data) => {
            return { 'table-line-danger': isPast(data.dataVencimento) };
          }
        "
        @confirm-selection="confirmSelection"
      >
        <template v-slot:title v-if="!!label">{{ label }}</template>
        <template v-slot:columns>
          <Column field="art" header="Número da ART" style="width: 13rem">
            <template #body="slotProps">
              <span v-text="getNome(slotProps.data)" />
            </template>
          </Column>
          <Column
            field="dataVencimento"
            header="Data de Vencimento"
            style="width: 13rem"
            v-if="!params.isFila"
          >
            <template #body="slotProps">
              <span v-text="formatDate(slotProps.data.dataVencimento)" />
            </template>
          </Column>
          <Column
            field="transportador"
            header="Transportador"
            v-if="params.isFila"
          >
            <template #body="slotProps">
              <span
                v-text="getNomeTransportador(slotProps.data.transportador)"
              />
            </template>
          </Column>
          <Column field="engenheiro" header="Engenheiro">
            <template #body="slotProps">
              <span v-text="getNomeEngenheiro(slotProps.data.engenheiro)" />
            </template>
          </Column>

          <Column
            field="multipla"
            header="Multipla?"
            v-if="!params.isFila"
            style="width: 10rem"
          >
            <template #body="slotProps">
              <span v-text="slotProps.data.multipla ? 'SIM' : 'NÃO'" />
            </template>
          </Column>
          <Column
            field="numeroRIV"
            header="Número RIV"
            v-if="!params.isFila"
            style="width: 10rem"
          >
            <template #body="slotProps">
              <span v-text="slotProps.data.numeroRIV" />
            </template>
          </Column>
          <Column
            field="veiculoTratorRIV"
            header="Veiculo Trator"
            style="width: 13rem"
            v-if="!params.isFila"
          >
            <template #body="slotProps">
              <span
                v-text="getNomeVeiculoTrator(slotProps.data.veiculoTratorRIV)"
              />
            </template>
          </Column>
          <Column
            field="situacao"
            header="Situacao"
            v-if="
              amIAllowed('play', 'art') ||
                amIAllowed('request_art', transportador?.permissionInfo)
            "
          >
            <template #body="slotProps">
              <span v-text="getSituacao(slotProps.data)" />
            </template>
          </Column>
          <Column :exportable="false" style="width: 13rem; text-align: right">
            <template #body="slotProps">
              <button-group
                :menuItems="
                  [
                    isBotaoBoletoPresente(slotProps.data)
                      ? {
                          label: 'Baixar Boleto',
                          icon: 'fas fa-dollar-sign',
                          command: () => downloadBoleto(slotProps.data)
                        }
                      : null,
                    isBotaoAddMonitoramentoPresente(slotProps.data)
                      ? {
                          label: 'Mover para consulta',
                          icon: 'fa-solid fa-forward',
                          command: () => addMonitoring(slotProps.data)
                        }
                      : null,
                    isBotaoArtOriginalPresente(slotProps.data)
                      ? {
                          label: 'Baixar Art Original',
                          icon: 'fa-solid fa-copy',
                          command: () => downloadOriginal(slotProps.data)
                        }
                      : null
                  ].filter((a) => a != null)
                "
                menuButtonClass="p-button-rounded p-button-info"
              >
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success"
                  v-title="
                    [
                      'Visualizar',
                      amIAllowed('view', slotProps.data.permissionInfo)?.info,
                      amIBlocked('view', slotProps.data.permissionInfo)?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                  :disabled="
                    !!amIBlocked('view', slotProps.data.permissionInfo)?.info
                  "
                  @click="openEntity(slotProps.data)"
                />

                <Button
                  icon="fas fa-play"
                  class="p-button-rounded"
                  v-title="
                    [
                      'Play',
                      amIAllowed('play', slotProps.data.permissionInfo)?.info,
                      amIBlocked('play', slotProps.data.permissionInfo)?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                  :disabled="
                    !!amIBlocked('play', slotProps.data.permissionInfo)?.info
                  "
                  @click="play(slotProps.data)"
                  v-if="isBotaoPlayPresente(slotProps.data)"
                />
                <Button
                  icon="fas fa-redo"
                  class="p-button-rounded"
                  v-title="
                    [
                      'Replay',
                      amIAllowed('replay', slotProps.data.permissionInfo)?.info,
                      amIBlocked('replay', slotProps.data.permissionInfo)?.info
                    ]
                      .filter((e) => e)
                      .join(' - ')
                  "
                  :disabled="
                    !!amIBlocked('replay', slotProps.data.permissionInfo)?.info
                  "
                  @click="play(slotProps.data)"
                  v-else-if="isBotaoReplayPresente(slotProps.data)"
                />

                <Button
                  icon="fas fa-dollar-sign"
                  class="p-button-rounded p-button-danger"
                  v-title="'Baixar Boleto'"
                  @click="downloadBoleto(slotProps.data)"
                  @click.middle.prevent.stop="
                    downloadBoleto(slotProps.data, true)
                  "
                  v-if="isBotaoBoletoPresente(slotProps.data)"
                />

                <Button
                  icon="fas fa-download"
                  class="p-button-rounded"
                  @click="downloadArtFile(slotProps.data)"
                  @click.middle.prevent.stop="
                    downloadArtFile(slotProps.data, true)
                  "
                  v-if="isBotaoArtPresente(slotProps.data)"
                />

                <Button
                  icon="fas fa-download"
                  class="p-button-rounded p-button-secondary"
                  v-title="'Baixar Art Original'"
                  @click="downloadOriginal(slotProps.data)"
                  @click.middle.prevent.stop="
                    downloadOriginal(slotProps.data, true)
                  "
                  v-else-if="isBotaoArtOriginalPresente(slotProps.data)"
                />
                <Button
                  icon="fas fa-trash-alt"
                  class="p-button-rounded p-button-danger"
                  v-if="
                    !params.isSearch &&
                      amIAllowed('delete', slotProps.data.permissionInfo)
                  "
                  @click="deleteEntity(slotProps.data)"
                />
              </button-group>
            </template>
          </Column>
        </template>
        <template v-slot:actions>
          <Button
            label="Novo"
            :class="
              'p-button-rounded' + (params.isSearch ? ' p-button-text' : '')
            "
            v-if="amIAllowed('create_art', transportador?.permissionInfo)"
            @click="newEntity()"
          />
          <Button
            label="Solicitar Nova ART"
            :class="
              'p-button-rounded' +
                (params.isSearch || !params.isFila ? ' p-button-text' : '')
            "
            v-if="amIAllowed('request_art', transportador?.permissionInfo)"
            @click="requestNewArt()"
          />
          <Button
            label="Solicitar Nova ART"
            :class="
              'p-button-rounded' +
                (params.isSearch || !params.isFila ? ' p-button-text' : '')
            "
            v-if="
              amIAllowed('play', 'art') &&
                !amIAllowed('request_art', transportador?.permissionInfo)
            "
            @click="newArtPlay()"
          />
        </template>
      </search-result>
      <div class="register" v-if="showEdit && idTransportadorEdicao">
        <cadastro-art-engenheiro-transportador
          :idArtEngenheiroTransportador="idArtEngenheiroTransportador"
          :idTransportador="idTransportadorEdicao"
          :idEngenheiro="idEngenheiro"
          @after-register-screen-closed="afterRegisterScreenClosed"
          :parentLoading="stateLoading"
          :cliente="cliente"
        />
      </div>
      <div class="register" v-else-if="showRequestNew">
        <cadastro-art-engenheiro-transportador-request
          @after-register-screen-closed="afterRequestScreenClosed"
          :parentLoading="stateLoading"
          :cliente="cliente"
        />
      </div>
    </template>
  </container>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref
} from "vue";
import CadastroArtEngenheiroTransportador from "./CadastroArtEngenheiroTransportador.vue";
import CadastroArtEngenheiroTransportadorRequest from "./CadastroArtEngenheiroTransportadorRequest.vue";
import { PaginationResult, defaultResults } from "../../../pagination";
import {
  useArtEngenheiroTransportadorService,
  useArtEngenheiroTransportadorRequestService,
  ArtEngenheiroTransportadorPayload
} from "../../../modules/business/transportador/artEngenheiroTransportadorBusiness";
import {
  useEngenheiroService,
  EngenheiroPayload
} from "../../../modules/business/engenheiro/engenheiroBusiness";
import {
  VeiculoTratorPayload,
  useVeiculoTratorService
} from "../../../modules/business/veiculo/veiculoTratorBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import {
  indexesOfElement,
  toggleElement,
  format,
  formatDate
} from "../../../modules/utils";
import { isPast } from "date-fns";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { useConfirm } from "../../../modules/message";

interface ArtEngenheiroTransportadorComponentParams {
  isSearch: boolean;
  selected: Array<ArtEngenheiroTransportadorPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  isFila: boolean;
  idArtEngenheiroTransportador?: number;
}

interface ArtEngenheiroTransportadorState {
  idArtEngenheiroTransportador: number | null;
  idTransportadorEdicao: number | null;
  showEdit: boolean;
  showRequestNew: boolean;
  results: PaginationResult<ArtEngenheiroTransportadorPayload>;
  stateReady: boolean;
  params: ArtEngenheiroTransportadorComponentParams;
  loadings: Array<Ref<boolean>>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<ArtEngenheiroTransportadorState>({
      idArtEngenheiroTransportador: null,
      idTransportadorEdicao: null,
      showEdit: false,
      showRequestNew: false,
      stateReady: false,
      results: defaultResults(),
      loadings: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        isFila: !props.transportador?.idTransportador,
        idArtEngenheiroTransportador: props.idArtEngenheiroTransportador,
        selected: props.selected ?? []
      },
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const executeSearchDefault = () => {
      if (!props.transportador?.idTransportador) {
        return;
      }
      const {
        data,
        loading,
        searchArtEngenheiroTransportador
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      return searchArtEngenheiroTransportador(
        props.transportador.idTransportador,
        props.idEngenheiro
      )
        .then(() => {
          state.results.content = data.value;
          state.results.totalPages = 1;
          state.results.number = 0;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const executeSearchArtRequest = () => {
      const {
        data,
        loading,
        searchArtEngenheiroTransportadorRequest
      } = useArtEngenheiroTransportadorRequestService();
      state.loadings.push(loading);
      return searchArtEngenheiroTransportadorRequest()
        .then(() => {
          state.results.content = data.value;
          state.results.totalPages = 1;
          state.results.number = 0;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const executeSearch = () => {
      return props.transportador?.idTransportador
        ? executeSearchDefault()
        : executeSearchArtRequest();
    };

    const submitSearch = () => {
      state.results = defaultResults();
      return executeSearch();
    };

    const openEntity = (art: ArtEngenheiroTransportadorPayload) => {
      state.idArtEngenheiroTransportador =
        art.idArtEngenheiroTransportador ?? null;
      state.idTransportadorEdicao =
        props.transportador?.idTransportador ??
        art.transportador.idTransportador ??
        null;
      state.showEdit = true;
    };

    const getNomeTransportador = (t?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(t);
    };

    const getNomeEngenheiro = (e?: EngenheiroPayload) => {
      return useEngenheiroService().getNomeEngenheiro(e);
    };

    const getNomeVeiculoTrator = (e?: VeiculoTratorPayload) => {
      return useVeiculoTratorService().getNomeVeiculoTrator(e);
    };

    const getNome = (e?: ArtEngenheiroTransportadorPayload) => {
      return useArtEngenheiroTransportadorService().getNomeArtEngenheiroTransportador(
        e
      );
    };

    const getSituacao = (e?: ArtEngenheiroTransportadorPayload) => {
      return useArtEngenheiroTransportadorService().getSituacaoArtEngenheiroTransportador(
        e
      );
    };

    const newEntity = () => {
      if (!props.transportador?.idTransportador) {
        return;
      }
      state.idArtEngenheiroTransportador = null;
      state.idTransportadorEdicao =
        props.transportador?.idTransportador ?? null;
      state.showEdit = true;
    };

    const newArtPlay = () => {
      state.showRequestNew = true;
    };

    const requestNewArt = () => {
      useConfirm().require({
        message:
          "Deseja solicitar uma nova art para o transportador " +
          useTransportadorService().getNomeTransportador(props.transportador) +
          "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (props.transportador?.idTransportador) {
            const {
              loading,
              requestNewArtEngenheiroTransportador
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            requestNewArtEngenheiroTransportador(
              props.transportador.idTransportador
            )
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const deleteEntity = (art: ArtEngenheiroTransportadorPayload) => {
      useConfirm().require({
        message:
          "Deseja excluir o art " +
          useArtEngenheiroTransportadorService().getNomeArtEngenheiroTransportador(
            art
          ) +
          "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (art && art.idArtEngenheiroTransportador) {
            const {
              loading,
              deleteArtEngenheiroTransportador
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            deleteArtEngenheiroTransportador(art)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const confirmSelection = (
      selected: Array<ArtEngenheiroTransportadorPayload>
    ) => {
      emit("confirm-selection", selected);
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const isBotaoBoletoPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("download_boleto", art.permissionInfo);
    };

    const isBotaoArtOriginalPresente = (
      art: ArtEngenheiroTransportadorPayload
    ) => {
      return !!amIAllowed("download_original", art.permissionInfo);
    };

    const isBotaoPlayPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("play", art.permissionInfo);
    };

    const isBotaoReplayPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("replay", art.permissionInfo);
    };

    const isBotaoAddMonitoramentoPresente = (
      art: ArtEngenheiroTransportadorPayload
    ) => {
      return !!amIAllowed("add_monitoring", art.permissionInfo);
    };

    const isBotaoArtPresente = (art: ArtEngenheiroTransportadorPayload) => {
      return !!amIAllowed("download", art.permissionInfo);
    };

    const play = (art: ArtEngenheiroTransportadorPayload) => {
      useConfirm().require({
        message: `Deseja solicitar a art para o Engenheiro '${getNomeEngenheiro(
          art.engenheiro
        )}'?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (art && art.idArtEngenheiroTransportador) {
            const {
              playArtEngenheiroTransportador,
              loading
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            playArtEngenheiroTransportador(art)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const addMonitoring = (art: ArtEngenheiroTransportadorPayload) => {
      useConfirm().require({
        message: `Deseja adicionar a art '${getNome(art)}' no monitoramento?`,
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (art && art.idArtEngenheiroTransportador) {
            const {
              addMonitoringArtEngenheiroTransportador,
              loading
            } = useArtEngenheiroTransportadorService();
            state.loadings.push(loading);
            addMonitoringArtEngenheiroTransportador(art)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const downloadBoleto = (
      art: ArtEngenheiroTransportadorPayload,
      newTab?: boolean
    ) => {
      const {
        downloadBoletoArtEngenheiroTransportador,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      downloadBoletoArtEngenheiroTransportador(art, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadOriginal = (
      art: ArtEngenheiroTransportadorPayload,
      newTab?: boolean
    ) => {
      const {
        downloadOriginalArtEngenheiroTransportador,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      downloadOriginalArtEngenheiroTransportador(art, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadArtFile = (
      art: ArtEngenheiroTransportadorPayload,
      newTab?: boolean
    ) => {
      const {
        downloadArtEngenheiroTransportador,
        loading
      } = useArtEngenheiroTransportadorService();
      state.loadings.push(loading);
      downloadArtEngenheiroTransportador(art, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const returnToDefault = () => {
      state.idArtEngenheiroTransportador = null;
      state.idTransportadorEdicao = null;
      state.showEdit = false;
      state.showRequestNew = false;
      executeSearch();
    };

    const afterRegisterScreenClosed = (
      art?: ArtEngenheiroTransportadorPayload
    ) => {
      if (state.params.idArtEngenheiroTransportador && state.params.isSearch) {
        if (art && art.idArtEngenheiroTransportador) {
          confirmSelection([art]);
        } else {
          cancelSelection();
        }
      } else {
        if (
          art &&
          state.params.isSearch &&
          indexesOfElement(
            state.params.selected,
            art,
            (ob) => ob && ob.idArtEngenheiroTransportador
          ).length <= 0
        ) {
          useConfirm().require({
            message: "Deseja selecionar o registro salvo?",
            header: "Confirmação",
            icon: "pi pi-info-circle",
            accept: () => {
              toggleElement(
                state.params.selected,
                art,
                (ob) => ob && ob.idArtEngenheiroTransportador,
                state.params.isMultiple
              );
              returnToDefault();
            },
            reject: () => {
              returnToDefault();
            }
          });
        } else {
          returnToDefault();
        }
      }
    };
    CadastroArtEngenheiroTransportadorRequest;
    const afterRequestScreenClosed = () => {
      returnToDefault();
    };

    const deveAbrirTelaCadastro =
      props.transportador?.idTransportador &&
      (state.params.idArtEngenheiroTransportador || state.params.isNew);
    if (deveAbrirTelaCadastro) {
      state.idTransportadorEdicao =
        props.transportador?.idTransportador ?? null;
      if (state.params.idArtEngenheiroTransportador) {
        state.idArtEngenheiroTransportador =
          state.params.idArtEngenheiroTransportador;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      state.stateReady = true;
      if (!deveAbrirTelaCadastro) {
        submitSearch();
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      newArtPlay,
      requestNewArt,
      deleteEntity,
      downloadBoleto,
      downloadArtFile,
      downloadOriginal,
      play,
      addMonitoring,
      amIAllowed,
      amIBlocked,
      isBotaoPlayPresente,
      isBotaoReplayPresente,
      isBotaoAddMonitoramentoPresente,
      isBotaoBoletoPresente,
      isBotaoArtOriginalPresente,
      isBotaoArtPresente,
      confirmSelection,
      cancelSelection,
      afterRegisterScreenClosed,
      afterRequestScreenClosed,
      getNome,
      getSituacao,
      getNomeVeiculoTrator,
      getNomeEngenheiro,
      getNomeTransportador,
      format,
      formatDate,
      isPast,
      stateLoading,
      ...toRefs(state)
    };
  },
  components: {
    CadastroArtEngenheiroTransportador,
    CadastroArtEngenheiroTransportadorRequest
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    transportador: {
      type: Object as PropType<TransportadorPayload>
    },
    idEngenheiro: Number,
    cliente: Object as PropType<ClientePayload>,
    idArtEngenheiroTransportador: Number,
    selected: {
      type: Array as PropType<Array<ArtEngenheiroTransportadorPayload>>
    },
    label: String
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
