import { useApiWithAuth } from "@/modules/api";
import { useCache } from "@/modules/cache";
interface UFPayload {
  idUnidadeFederal?: number | null;
  sigla?: string | null;
  nome?: string | null;
  idRegiao?: number | null;
}

interface MunicipioPayload {
  idMunicipio?: number | null;
  idUnidadeFederal?: number | null;
  nome?: string | null;
}

interface DocumentoPayload {
  idDocumento?: number | null;
  keyBlDocumento?: string | null;
  keyBlDocumentoTemp?: string | null;
}

interface OrgaoPayload {
  idOrgao?: number | null;
  nome?: string | null;
  sigla?: string | null;
  urlPortal?: string | null;
  maxVeiculos?: number | null;
  integracaoOrgaoViaRobo?: boolean | null;
  integracaoOrgaoViaCapa?: boolean | null;
  integracaoOrgaoArtViaRobo?: boolean | null;
  permiteDownloadTrechosLicenca?: boolean | null;
  permiteDescricaoTrechos?: boolean | null;
  possuiBoletoManual?: boolean | null;
  possuiComprovantePagamentoBoleto?: boolean | null;
  tiposLicencaOrgaoPermitidos: Array<number>;
  uf: UFPayload;
}

interface CredencialPayload {
  login?: string | null;
  senha?: string | null;
  novaSenha?: string | null;
}

interface CorPayload {
  idCor?: number | null;
  descricao?: string | null;
}

export const useUfService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getUfsToCache = (): Promise<void> => {
    return get(`/uf`);
  };

  const getUfs = (): Array<UFPayload> => {
    return useCache().getUfs();
  };

  const getUf = (idUnidadeFederal?: number | null): UFPayload | null => {
    if (!idUnidadeFederal) {
      return null;
    }

    return getUfs().find((a) => a.idUnidadeFederal == idUnidadeFederal) ?? null;
  };

  const getUfSigla = (
    idUnidadeFederal?: number | null
  ): string | null | undefined => {
    if (!idUnidadeFederal) {
      return null;
    }
    return getUf(idUnidadeFederal)?.sigla;
  };

  return {
    data,
    loading,
    getUfsToCache,
    getUfs,
    getUf,
    getUfSigla
  };
};

export const useMunicipioService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getMunicipiosToCache = (): Promise<void> => {
    return get(`/uf/municipio`);
  };

  const getMunicipios = (idUnidadeFederal: number): Array<MunicipioPayload> => {
    return useCache().getMunicipios(idUnidadeFederal);
  };

  const getMunicipio = (
    idUnidadeFederal?: number | null,
    idMunicipio?: number | null
  ): MunicipioPayload | null => {
    if (!idUnidadeFederal || !idMunicipio) {
      return null;
    }

    return (
      getMunicipios(idUnidadeFederal).find(
        (a) => a.idMunicipio == idMunicipio
      ) ?? null
    );
  };

  const getMunicipioNome = (
    idUnidadeFederal?: number | null,
    idMunicipio?: number | null
  ): string | null | undefined => {
    if (!idMunicipio) {
      return null;
    }
    return getMunicipio(idUnidadeFederal, idMunicipio)?.nome;
  };

  return {
    data,
    loading,
    getMunicipiosToCache,
    getMunicipios,
    getMunicipio,
    getMunicipioNome
  };
};

export const useOrgaoAetService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getOrgaosAetToCache = (): Promise<void> => {
    return get(`/orgao/aet`);
  };

  const getOrgaosAet = (): Array<OrgaoPayload> => {
    return useCache().getOrgaosAet();
  };

  const getOrgaoAet = (idOrgao?: number | null): OrgaoPayload | null => {
    if (!idOrgao) {
      return null;
    }

    return getOrgaosAet().find((a) => a.idOrgao == idOrgao) ?? null;
  };

  const getOrgaoAetSafe = (idOrgao?: number | null): OrgaoPayload => {
    return (
      getOrgaoAet(idOrgao) ?? {
        tiposLicencaOrgaoPermitidos: [],
        uf: {}
      }
    );
  };

  const getOrgaoAetSigla = (
    idOrgao?: number | null
  ): string | null | undefined => {
    if (!idOrgao) {
      return null;
    }
    return getOrgaoAet(idOrgao)?.sigla;
  };

  const getOrgaoAetStr = (
    idOrgao?: number | null
  ): string | null | undefined => {
    if (!idOrgao) {
      return null;
    }
    const orgao = getOrgaoAet(idOrgao);
    if (orgao) {
      return orgao.sigla + " - " + orgao.nome;
    }
    return "";
  };

  return {
    data,
    loading,
    getOrgaosAetToCache,
    getOrgaosAet,
    getOrgaoAet,
    getOrgaoAetSafe,
    getOrgaoAetSigla,
    getOrgaoAetStr
  };
};

export const useOrgaoArtService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getOrgaosArtToCache = (): Promise<void> => {
    return get(`/orgao/art`);
  };

  const getOrgaosArt = (): Array<OrgaoPayload> => {
    return useCache().getOrgaosArt();
  };

  const getOrgaoArt = (idOrgao?: number | null): OrgaoPayload | null => {
    if (!idOrgao) {
      return null;
    }

    return getOrgaosArt().find((a) => a.idOrgao == idOrgao) ?? null;
  };

  const getOrgaoArtSafe = (idOrgao?: number | null): OrgaoPayload => {
    return (
      getOrgaoArt(idOrgao) ?? {
        tiposLicencaOrgaoPermitidos: [],
        uf: {}
      }
    );
  };

  const getOrgaoArtSigla = (
    idOrgao?: number | null
  ): string | null | undefined => {
    if (!idOrgao) {
      return null;
    }
    return getOrgaoArt(idOrgao)?.sigla;
  };

  const getOrgaoArtStr = (
    idOrgao?: number | null
  ): string | null | undefined => {
    if (!idOrgao) {
      return null;
    }
    const orgao = getOrgaoArt(idOrgao);
    if (orgao) {
      return orgao.sigla + " - " + orgao.nome;
    }
    return "";
  };

  return {
    data,
    loading,
    getOrgaosArtToCache,
    getOrgaosArt,
    getOrgaoArt,
    getOrgaoArtSafe,
    getOrgaoArtSigla,
    getOrgaoArtStr
  };
};

export {
  UFPayload,
  MunicipioPayload,
  DocumentoPayload,
  CredencialPayload,
  CorPayload,
  OrgaoPayload
};
