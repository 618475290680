
import { Options, Vue } from "vue-class-component";
import CrudPlanilhaTrechos from "../../components/trecho/CrudPlanilhaTrechos.vue";

@Options({
  props: {
    isNew: Boolean,
    idPlanilhaTrechos: Number,
    idPlanilhaTrechosCopy: Number
  },
  components: {
    CrudPlanilhaTrechos
  }
})
export default class PlanilhaTrechos extends Vue {
  isNew?: boolean;
  idPlanilhaTrechos?: number;
  idPlanilhaTrechosCopy?: number;
}
