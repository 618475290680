
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    hasButton: { type: [String, Number, Object, Boolean], required: true }
  },
  components: {}
});
