<template>
  <div>
    <container
      :isModal="params.isSearch"
      @close-modal="cancelSelection()"
      :loading="!stateReady"
    >
      <template v-slot:header>
        <h3>Consulta de Licencas</h3>
      </template>
      <template v-slot:body>
        <div>
          <form @submit.prevent="submitSearch">
            <entity-selector-cliente
              :cliente="cliente"
              :canEdit="!cliente"
              :idClienteFiltered="filter.idCliente"
              @confirm-selection="confirmSelectionCliente"
              :parentLoading="stateLoading || showEdit"
            />
            <fieldset :disabled="stateLoading || showEdit">
              <div class="p-formgrid p-grid">
                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-4">
                  <entity-selector-veiculo-trator
                    :cliente="cliente"
                    :idVeiculoTratorFiltered="filter.idVeiculoTrator"
                    @confirm-selection="confirmSelectionVeiculoTrator"
                    :required="false"
                    :focus="true"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-6 p-lg-4">
                  <input-with-label label="Número AET" v-slot="l">
                    <InputText
                      class="p-inputtext-sm"
                      v-model="filter.numeroPedido"
                      :id="l.id"
                      :required="l.required"
                    />
                  </input-with-label>
                </div>

                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                  <entity-selector-orgao-aet
                    :idOrgao="filter.idOrgao"
                    @after-set-orgao="afterSetOrgao"
                    :readOnly="stateLoading || showEdit"
                    :applyGroupByAtendimento="true"
                  />
                </div>

                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                  <entity-selector-transportador
                    :cliente="cliente"
                    :idTransportadorFiltered="filter.idTransportador"
                    @confirm-selection="confirmSelectionTransportador"
                    :required="false"
                  />
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                  <input-with-label label="Situações" v-slot="l">
                    <MultiSelect
                      :id="l.id"
                      :required="l.required"
                      v-model="filter.flSituacoes"
                      :options="situacoesDisponiveis"
                      class="p-inputtext-sm"
                      optionValue="flag"
                      optionLabel="descricao"
                      :disabled="stateLoading || showEdit"
                    />
                  </input-with-label>
                </div>
                <div class="p-field p-fluid p-col-12 p-md-12 p-lg-4">
                  <period-picker
                    v-model:start="filter.dtInicial"
                    v-model:end="filter.dtFinal"
                    v-model:type="tipoPeriodo"
                  />
                </div>
                <div class="p-col-12">
                  <div class="p-grid p-mt-1">
                    <div class="p-field p-fluid p-col-12 p-md-6 p-lg-4">
                      <entity-selector-multi-carreta
                        :cliente="cliente"
                        :idCarretasFiltered="filter.idCarretas"
                        @confirm-selection="confirmSelectionCarretas"
                        :required="false"
                        :labelEmpty="'Selecione as Carretas'"
                      />
                    </div>
                    <div class="p-field p-fluid p-col-12 p-md-6 p-lg-4">
                      <entity-selector-gabarito
                        :idGabarito="filter.idGabarito"
                        @after-set-gabarito="afterSetGabarito"
                        :readOnly="stateLoading || showEdit"
                      />
                    </div>
                  </div>
                </div>
                <div class="p-col-12">
                  <button-group
                    :menuItems="[
                      {
                        label: 'Imprimir como Pdf',
                        icon: 'fa-solid fa-file-pdf',
                        command: () => {
                          downloadPdf();
                        }
                      },
                      {
                        label: 'Imprimir como Xls',
                        icon: 'fa-solid fa-file-excel',
                        command: () => {
                          downloadXls();
                        }
                      }
                    ]"
                    menuButtonClass="p-button-rounded p-button"
                  >
                    <Button
                      type="submit"
                      class="p-button-rounded"
                      label="Buscar"
                    />
                    <Button
                      label="Limpar"
                      class="p-button-rounded p-button-text"
                      @click="clearFilter()"
                    />

                    <!--<Button
                    class="p-button-rounded p-button-text"
                    @click="downloadPdf()"
                    label="Imprimir Consulta como Pdf"
                  />
                  <Button
                    class="p-button-rounded p-button-text"
                    @click="downloadXls()"
                    label="Imprimir Consulta como Xls"
                  />-->
                  </button-group>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div>
          <crud-licenca-orgao
            :externalResult="results"
            :showSelector="true"
            :isMultiple="true"
            :parentLoading="stateLoading"
            @confirm-selection="confirmSelectionLicencaOrgao"
            @execute-search="afterPageChange"
            :blockedSelection="blockedSelectionLicenca"
          >
            >
            <template v-slot:rowGroupHeader="slotProps">
              <div
                class="p-grid dynamic-grid-header p-m-0 p-pr-0 p-pl-2 p-pt-2 p-pb-2"
              >
                <div class="p-col">
                  <div class="p-grid p-m-0">
                    <div class="p-col-12 p-lg-5 p-p-0">
                      <div class="p-grid p-m-0">
                        <div class="p-col-fixed licenca-header">
                          Veículo Trator:
                        </div>
                        <div class="p-col licenca-value">
                          <b
                            v-text="
                              getNomeVeiculoTrator(
                                slotProps.data.licenca?.veiculoTratorPrincipal
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="p-col-12 p-lg-7 p-p-0">
                      <div class="p-grid p-m-0">
                        <div class="p-col-fixed">Carretas:</div>
                        <div class="p-col licenca-value">
                          <b
                            v-text="
                              getNomeCarretasPrincipais(slotProps.data.licenca)
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="p-col-12 p-p-0">
                      <div class="p-grid p-m-0">
                        <div class="p-col-fixed licenca-header">
                          Características:
                        </div>
                        <div class="p-col licenca-value">
                          <b
                            v-text="
                              getCaracteristicasLicenca(slotProps.data.licenca)
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="p-col-12 p-p-0">
                      <div class="p-grid p-m-0 p-p-0">
                        <div class="p-col-fixed licenca-header">
                          Transportador:
                        </div>
                        <div class="p-col licenca-value">
                          <b
                            v-text="
                              getNomeTransportador(
                                slotProps.data.licenca?.transportador
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-col-12 p-p-0"
                      v-if="amIAllowed('view', 'root-cliente')"
                    >
                      <div class="p-grid p-m-0 p-p-0">
                        <div class="p-col-fixed licenca-header">Cliente:</div>
                        <div class="p-col licenca-value">
                          <b
                            v-text="
                              getNomeCliente(slotProps.data.licenca?.cliente)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="p-col-fixed p-as-center p-d-flex p-jc-end"
                  style="width: 14rem"
                >
                  <button-group
                    :menuItems="
                      amIAllowed(
                        'view_id',
                        slotProps.data.licenca.permissionInfo
                      )
                        ? [
                            {
                              label: 'Copiar Id GovPass',
                              icon: 'fa-solid fa-copy',
                              command: () =>
                                copyIdToClipboard(slotProps.data.licenca)
                            }
                          ]
                        : []
                    "
                    menuButtonClass="p-button-rounded p-button-info"
                  >
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-rounded p-button-success"
                      v-title="
                        [
                          'Editar',
                          amIBlocked(
                            'edit',
                            slotProps.data.licenca.permissionInfo
                          )?.info
                        ]
                          .filter((e) => e)
                          .join(' - ')
                      "
                      :disabled="
                        amIBlocked(
                          'edit',
                          slotProps.data.licenca.permissionInfo
                        )
                      "
                      v-if="
                        amIAllowed(
                          'edit',
                          slotProps.data.licenca.permissionInfo
                        )
                      "
                      @click="openEntity(slotProps.data.licenca.idLicenca)"
                      @click.middle.prevent.stop="
                        openEntity(slotProps.data.licenca.idLicenca, true)
                      "
                    />

                    <Button
                      icon="fas fa-book-open"
                      class="p-button-rounded"
                      v-title="'Visualizar Licença como PDF'"
                      v-if="
                        amIAllowed(
                          'view',
                          slotProps.data.licenca.permissionInfo
                        )
                      "
                      @click="
                        downloadVisualizacao(slotProps.data.licenca.idLicenca)
                      "
                      @click.middle.prevent.stop="
                        downloadVisualizacao(
                          slotProps.data.licenca.idLicenca,
                          true
                        )
                      "
                    />

                    <Button
                      icon="fas fa-folder-minus"
                      class="p-button-rounded p-button-danger"
                      v-if="
                        !params.isSearch &&
                          amIAllowed(
                            'shelve',
                            slotProps.data.licenca.permissionInfo
                          )
                      "
                      @click="shelveEntity(slotProps.data.licenca)"
                      :disabled="
                        amIBlocked(
                          'shelve',
                          slotProps.data.licenca.permissionInfo
                        )
                      "
                      v-title="
                        [
                          'Arquivar',
                          amIBlocked(
                            'shelve',
                            slotProps.data.licenca.permissionInfo
                          )?.info
                        ]
                          .filter((e) => e)
                          .join(' - ')
                      "
                    />

                    <Button
                      icon="fas fa-folder-plus"
                      class="p-button-rounded p-button-secondary"
                      v-if="
                        !params.isSearch &&
                          amIAllowed(
                            'unshelve',
                            slotProps.data.licenca.permissionInfo
                          )
                      "
                      @click="unshelveEntity(slotProps.data.licenca)"
                      :disabled="
                        amIBlocked(
                          'unshelve',
                          slotProps.data.licenca.permissionInfo
                        )
                      "
                      v-title="
                        [
                          'Desarquivar',
                          amIBlocked(
                            'unshelve',
                            slotProps.data.licenca.permissionInfo
                          )?.info
                        ]
                          .filter((e) => e)
                          .join(' - ')
                      "
                    />
                  </button-group>
                </div>
              </div>
            </template>
            <template v-slot:actions>
              <button-group>
                <Button
                  label="Novo"
                  :class="
                    'p-button-rounded' +
                      (params.isSearch ? ' p-button-text' : '')
                  "
                  v-if="amIAllowed('create', 'licenca')"
                  @click="newEntity()"
                />

                <template v-if="!params.isSearch && results?.totalElements > 0">
                  <Button
                    class="p-button-rounded p-button-text"
                    @click="downloadZipBoletos()"
                    label="Baixar Boletos
                  Selecionadas"
                    v-if="amIAllowed('download_boleto', 'licenca')"
                  />
                  <Button
                    class="p-button-rounded p-button-text"
                    @click="downloadZipAets()"
                    label="Baixar Licencas
                  Selecionadas"
                    v-if="amIAllowed('download', 'licenca')"
                  />
                </template>
              </button-group>
            </template>
          </crud-licenca-orgao>
        </div>
        <div class="register" v-if="showEdit">
          <cadastro-licenca
            :idLicenca="idLicenca"
            :idLicencaCopy="idLicencaCopy"
            @after-register-screen-closed="afterRegisterScreenClosed"
            @after-save-new-entity="afterSaveNewEntity"
            @generate-copy="generateCopy"
            :key="keyRegister"
            :parentLoading="stateLoading"
            :cliente="cliente"
          />
        </div>
      </template>
    </container>
  </div>
</template>

<script lang="ts">
import CadastroLicenca from "./CadastroLicenca.vue";
import { PaginationResult, defaultResults } from "../../pagination";
import { useAuth, PermissionPayload } from "../../modules/auth";
import { DATE_SELECTORS, copyToClipboard } from "../../modules/utils";
import CrudLicencaOrgao from "./orgao/CrudLicencaOrgao.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  PropType,
  Ref,
  watch
} from "vue";
import { RouteLocationNormalizedLoaded, useRouter } from "vue-router";
import {
  ClientePayload,
  useClienteService,
  useClienteLogadoService
} from "../../modules/business/cliente/clienteBusiness";
import {
  LicencaFilterPayload,
  LicencaFilterReturnPayload,
  useLicencaService
} from "../../modules/business/licenca/licencaBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../modules/business/transportador/transportadorBusiness";
import {
  VeiculoTratorPayload,
  useVeiculoTratorService
} from "../../modules/business/veiculo/veiculoTratorBusiness";
import { useCarretaService } from "../../modules/business/veiculo/carretaBusiness";
import {
  indexesOfElement,
  toggleElement,
  formatNumber
} from "../../modules/utils";
import { CarretaPayload } from "../../modules/business/veiculo/carretaBusiness";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import EntitySelectorVeiculoTrator from "../veiculo/trator/EntitySelectorVeiculoTrator.vue";
import EntitySelectorTransportador from "../transportador/EntitySelectorTransportador.vue";
import EntitySelectorMultiCarreta from "../../components/veiculo/carreta/EntitySelectorMultiCarreta.vue";
import EntitySelectorOrgaoAet from "../common/EntitySelectorOrgaoAet.vue";
import EntitySelectorGabarito from "./gabarito/EntitySelectorGabarito.vue";
import { useGabaritoService } from "../../modules/business/licenca/gabaritoBusiness";
import { useOrgaoAetService } from "../../modules/business/common/commonBusiness";
import {
  LicencaOrgaoPayload,
  useLicencaOrgaoService
} from "../../modules/business/licenca/licencaOrgaoBusiness";
import {
  StatusPayload,
  useStatusService
} from "../../modules/business/status/statusBusiness";
import { useConfirm } from "../../modules/message";

interface LicencaComponentParams {
  isSearch: boolean;
  selected: Array<LicencaFilterReturnPayload>;
  isNew?: boolean;
  isMultiple?: boolean;
  idLicenca?: number;
  idLicencaCopy?: number;
}

interface LicencaState {
  idLicenca: number | null;
  idLicencaCopy: number | null;
  showEdit: boolean;
  results: PaginationResult<LicencaOrgaoPayload>;
  filter: LicencaFilterPayload;
  page: number;
  stateReady: boolean;
  licencaOrgaoSelected: Array<number>;
  params: LicencaComponentParams;
  loadings: Array<Ref<boolean>>;
  tipoPeriodo: string;
  situacoesDisponiveis: Array<StatusPayload>;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive<LicencaState>({
      idLicenca: null,
      idLicencaCopy: null,
      showEdit: false,
      stateReady: false,
      filter: useLicencaService().safeFilterLicenca(),
      page: 0,
      results: defaultResults(),
      licencaOrgaoSelected: [],
      params: {
        isSearch: props.isSearch,
        isNew: props.isNew,
        idLicenca: props.idLicenca,
        idLicencaCopy: props.idLicencaCopy,
        selected: props.selected ?? [],
        isMultiple: props.isMultiple
      },
      loadings: [],
      tipoPeriodo: DATE_SELECTORS.LAST_MONTH,
      situacoesDisponiveis: useStatusService().getStatusesOrgaoGovPass(),
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2)
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const amIBlocked = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIBlocked(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0;
    });

    const keyRegister = computed(() => {
      return state.showEdit
        ? state.idLicenca
          ? state.idLicenca
          : "new-" +
            Math.random()
              .toString(36)
              .substring(2)
        : null;
    });

    const getNome = (e?: LicencaFilterReturnPayload) => {
      return useLicencaService().getNomeLicencaVisualizacao(e);
    };

    const getNomeCliente = (e?: ClientePayload) => {
      return (amIAllowed("view", "root-cliente")
        ? useClienteService()
        : useClienteLogadoService()
      ).getNomeCliente(e);
    };

    const getNomeTransportador = (e?: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const getNomeVeiculoTrator = (e?: VeiculoTratorPayload) => {
      return useVeiculoTratorService().getNomeVeiculoTrator(e);
    };

    const getNomeCarretasPrincipais = (e?: LicencaFilterReturnPayload) => {
      if (e?.carretasPrincipais?.length) {
        return e.carretasPrincipais
          .map((c) => useCarretaService().getNomeCarreta(c))
          .join(", ");
      }
      return "";
    };

    const getCaracteristicasLicenca = (e?: LicencaFilterReturnPayload) => {
      if (e) {
        return (
          useGabaritoService().getNomeGabarito(e.idGabarito) +
          " - " +
          formatNumber(e.comprimentoTotal, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2
          }) +
          "m"
        );
      }
      return "";
    };

    const getOrgaoAetSigla = (idOrgao?: number | null) => {
      return useOrgaoAetService().getOrgaoAetSigla(idOrgao);
    };

    const executeSearch = () => {
      const { data, loading, searchLicenca } = useLicencaService();
      state.loadings.push(loading);
      return searchLicenca(state.filter, state.page)
        .then(() => {
          if (data.value && data.value.content) {
            const resultTratado = [] as Array<LicencaOrgaoPayload>;
            let index = 0;
            data.value.content.forEach((l: LicencaFilterReturnPayload) => {
              if (!l.licencasOrgaos || l.licencasOrgaos.length <= 0) {
                resultTratado.push({
                  licenca: l,
                  groupIndex: index
                });
              } else {
                l.licencasOrgaos.forEach((lo: LicencaOrgaoPayload) => {
                  lo.licenca = l;
                  lo.groupIndex = index;
                  resultTratado.push(lo);
                });
              }
              index++;
            });
            data.value.content = resultTratado;
            state.results = data.value;
          } else {
            state.results = defaultResults();
          }
          state.page = state.results.number;
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const submitSearch = () => {
      state.page = 0;
      state.results = defaultResults();
      return executeSearch();
    };

    const downloadZipBoletos = (newTab?: boolean) => {
      const { loading, downloadZipBoletos } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadZipBoletos(state.licencaOrgaoSelected, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadZipAets = (newTab?: boolean) => {
      const { loading, downloadZipAets } = useLicencaOrgaoService();
      state.loadings.push(loading);
      downloadZipAets(state.licencaOrgaoSelected, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const downloadPdf = (newTab?: boolean) => {
      if (state.filter) {
        const { loading, printSearch } = useLicencaService();
        state.loadings.push(loading);
        printSearch(state.filter, false, newTab)
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      }
    };

    const downloadXls = (newTab?: boolean) => {
      if (state.filter) {
        const { loading, printSearch } = useLicencaService();
        state.loadings.push(loading);
        printSearch(state.filter, true, newTab)
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      }
    };

    const downloadVisualizacao = (idLicenca?: number, newTab?: boolean) => {
      if (!idLicenca) {
        return;
      }
      const { downloadVisualizacao, loading } = useLicencaService();
      state.loadings.push(loading);
      downloadVisualizacao(idLicenca, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
        });
    };

    const copyIdToClipboard = (licenca?: LicencaFilterReturnPayload) => {
      copyToClipboard(licenca?.idLicenca?.toString());
    };

    const getParamsRouteEdit = (idLicenca: number) => {
      return {
        name: "licenca-edit",
        params: { idLicenca: idLicenca + "" }
      };
    };

    const processViewRoute = (
      route: Partial<RouteLocationNormalizedLoaded>
    ) => {
      if (route.name == "licenca-new") {
        state.idLicenca = null;
        state.idLicencaCopy = null;
        state.showEdit = true;
      } else if (route.name == "licenca-edit") {
        if (route.params && route.params.idLicenca) {
          state.idLicenca = Array.isArray(route.params.idLicenca)
            ? +route.params.idLicenca[0]
            : +route.params.idLicenca;
        } else {
          state.idLicenca = null;
        }
        state.idLicencaCopy = null;
        state.showEdit = true;
      } else if (route.name == "licenca-copy") {
        if (route.params && route.params.idLicencaCopy) {
          state.idLicencaCopy = Array.isArray(route.params.idLicencaCopy)
            ? +route.params.idLicencaCopy[0]
            : +route.params.idLicencaCopy;
        } else {
          state.idLicencaCopy = null;
        }
        state.idLicenca = null;
        state.showEdit = true;
      } else {
        state.showEdit = false;
        state.idLicencaCopy = null;
        state.idLicenca = null;
        executeSearch();
      }
    };

    const confirmSelection = (selected: Array<LicencaFilterReturnPayload>) => {
      emit("confirm-selection", selected);
    };

    const confirmSelectionLicencaOrgao = (
      selected: Array<LicencaOrgaoPayload>,
      added: Array<LicencaOrgaoPayload>,
      removed: Array<LicencaOrgaoPayload>
    ) => {
      if (added && added.length > 0) {
        added.forEach((lic) => {
          if (
            lic.idLicencaOrgao &&
            state.licencaOrgaoSelected.indexOf(lic.idLicencaOrgao) < 0
          ) {
            state.licencaOrgaoSelected.push(lic.idLicencaOrgao);
          }
        });
      }
      if (removed && removed.length > 0) {
        removed.forEach((lic) => {
          if (
            lic.idLicencaOrgao &&
            state.licencaOrgaoSelected.indexOf(lic.idLicencaOrgao) >= 0
          ) {
            state.licencaOrgaoSelected.splice(
              state.licencaOrgaoSelected.indexOf(lic.idLicencaOrgao),
              1
            );
          }
        });
      }
      return selected;
    };

    const cancelSelection = () => {
      emit("cancel-selection");
    };

    const shelveEntity = (licenca: LicencaFilterReturnPayload) => {
      useConfirm().require({
        message: "Deseja arquivar a Licença " + getNome(licenca) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (licenca && licenca.idLicenca) {
            const { shelveLicenca, loading } = useLicencaService();
            state.loadings.push(loading);
            shelveLicenca(licenca.idLicenca)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const unshelveEntity = (licenca: LicencaFilterReturnPayload) => {
      useConfirm().require({
        message: "Deseja desarquivar a Licença " + getNome(licenca) + "?",
        header: "Atenção",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (licenca && licenca.idLicenca) {
            const { unshelveLicenca, loading } = useLicencaService();
            state.loadings.push(loading);
            unshelveLicenca(licenca.idLicenca)
              .then(() => {
                executeSearch();
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              })
              .catch(() => {
                state.loadings.splice(state.loadings.indexOf(loading), 1);
              });
          }
        }
      });
    };

    const openEntity = (idLicenca: number, newTab?: boolean) => {
      const routeParams = getParamsRouteEdit(idLicenca);
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const newEntity = () => {
      const routeParams = {
        name: "licenca-new"
      };
      if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const generateCopy = (idLicencaCopy: number, newTab?: boolean) => {
      const routeParams = {
        name: "licenca-copy",
        params: { idLicencaCopy: idLicencaCopy + "" }
      };
      if (newTab) {
        window.open(router.resolve(routeParams).fullPath);
      } else if (state.params.isSearch) {
        processViewRoute(routeParams);
      } else {
        router.push(routeParams);
      }
    };

    const afterRegisterScreenClosed = (lic?: LicencaFilterReturnPayload) => {
      const routeParams = {
        name: "licenca"
      };
      if (state.params.isSearch) {
        if (state.params.idLicenca) {
          if (lic && lic.idLicenca) {
            confirmSelection([lic]);
          } else {
            cancelSelection();
          }
        } else {
          if (
            lic &&
            indexesOfElement(
              state.params.selected,
              lic,
              (ob) => ob && ob.idLicenca
            ).length <= 0
          ) {
            useConfirm().require({
              message: "Deseja selecionar o registro salvo?",
              header: "Confirmação",
              icon: "pi pi-info-circle",
              accept: () => {
                toggleElement(
                  state.params.selected,
                  lic,
                  (ob) => ob && ob.idLicenca,
                  state.params.isMultiple
                );
                processViewRoute(routeParams);
              },
              reject: () => {
                processViewRoute(routeParams);
              }
            });
          } else {
            processViewRoute(routeParams);
          }
        }
      } else {
        router.push(routeParams);
      }
    };

    const afterSaveNewEntity = (idLicenca: number) => {
      openEntity(idLicenca);
    };

    const afterPageChange = (page?: number) => {
      if (typeof page !== "undefined") {
        state.page = page;
      }
      executeSearch();
    };

    const newLicencaOrgao = () => {
      console.log("Nao implementado..");
    };

    const clearFilter = () => {
      //TODO KAIO REVER TODOS OS FILTROS DE CLIENTES, QUE NAO PODEM SER ALTERADOS
      state.filter = useLicencaService().safeFilterLicenca();
      state.tipoPeriodo = DATE_SELECTORS.LAST_MONTH;
      state.results = defaultResults();
    };

    const confirmSelectionCliente = (c?: ClientePayload) => {
      state.filter.idCliente = c && c.idCliente ? c.idCliente : undefined;
    };

    const confirmSelectionVeiculoTrator = (v?: VeiculoTratorPayload) => {
      const original = state.filter.idVeiculoTrator;
      state.filter.idVeiculoTrator =
        v && v.idVeiculoTrator ? v.idVeiculoTrator : undefined;
      if (original != state.filter.idVeiculoTrator) {
        state.tipoPeriodo = DATE_SELECTORS.NO_DATE;
      }
    };

    const confirmSelectionCarretas = (c?: Array<CarretaPayload>) => {
      state.filter.idCarretas.splice(0, state.filter.idCarretas.length);

      if (c && c.length) {
        c.forEach((car) => {
          if (car && car.idCarreta) {
            state.filter.idCarretas.push(car.idCarreta);
          }
        });
      }
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      state.filter.idTransportador =
        t && t.idTransportador ? t.idTransportador : undefined;
    };

    const afterSetOrgao = (idOrgao?: number | null) => {
      state.filter.idOrgao = idOrgao ? idOrgao : undefined;
    };

    const afterSetGabarito = (idGabarito?: number | null) => {
      state.filter.idGabarito = idGabarito ? idGabarito : undefined;
    };

    const blockedSelectionLicenca = (licencaOrgao: LicencaOrgaoPayload) => {
      return !licencaOrgao?.idLicencaOrgao;
    };

    const deveAbrirTelaCadastro = state.params.idLicenca || state.params.isNew;
    if (deveAbrirTelaCadastro) {
      if (state.params.idLicenca) {
        state.idLicenca = state.params.idLicenca;
      } else if (state.params.idLicencaCopy) {
        state.idLicencaCopy = state.params.idLicencaCopy;
      }
      state.showEdit = true;
    }

    onMounted(async () => {
      if (props.cliente && props.cliente.idCliente) {
        state.filter.idCliente = props.cliente.idCliente;
      }
      state.stateReady = true;
      if (!state.params.isSearch) {
        watch([router.currentRoute], () => {
          const currRoute = router.currentRoute.value;
          processViewRoute(currRoute);
        });
      }
    });

    return {
      submitSearch,
      executeSearch,
      openEntity,
      newEntity,
      generateCopy,
      shelveEntity,
      unshelveEntity,
      blockedSelectionLicenca,
      confirmSelection,
      confirmSelectionLicencaOrgao,
      cancelSelection,
      afterRegisterScreenClosed,
      afterSaveNewEntity,
      afterPageChange,
      clearFilter,
      keyRegister,
      amIAllowed,
      amIBlocked,
      formatNumber,
      getNome,
      getNomeCliente,
      getNomeTransportador,
      getCaracteristicasLicenca,
      getNomeVeiculoTrator,
      getNomeCarretasPrincipais,
      getOrgaoAetSigla,
      getParamsRouteEdit,
      newLicencaOrgao,
      stateLoading,
      confirmSelectionCliente,
      confirmSelectionVeiculoTrator,
      confirmSelectionTransportador,
      confirmSelectionCarretas,
      afterSetOrgao,
      afterSetGabarito,
      downloadZipAets,
      downloadZipBoletos,
      downloadPdf,
      downloadXls,
      downloadVisualizacao,
      copyIdToClipboard,
      ...toRefs(state)
    };
  },
  components: {
    CadastroLicenca,
    CrudLicencaOrgao,
    EntitySelectorCliente,
    EntitySelectorVeiculoTrator,
    EntitySelectorTransportador,
    EntitySelectorMultiCarreta,
    EntitySelectorOrgaoAet,
    EntitySelectorGabarito
  },
  props: {
    isSearch: Boolean,
    isNew: Boolean,
    isMultiple: Boolean,
    idLicenca: Number,
    idLicencaCopy: Number,
    cliente: Object as PropType<ClientePayload>,
    selected: {
      type: Array as PropType<Array<LicencaFilterReturnPayload>>
    }
  }
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.licenca-value {
  @media (max-width: 992px) {
    text-align: right;
  }
}

.licenca-header {
  @media (min-width: 992px) {
    width: 8rem;
  }
}
</style>
