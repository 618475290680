
import { defineComponent, reactive, toRefs, onMounted } from "vue";

interface InputWithLabelState {
  stateReady: boolean;
  stateId: string;
}

export default defineComponent({
  setup(props) {
    const state = reactive<InputWithLabelState>({
      stateReady: false,
      stateId:
        props.id ||
        "state-" +
          Math.random()
            .toString(36)
            .substring(2)
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      ...toRefs(state)
    };
  },
  props: {
    iconClass: String,
    divClass: String,
    label: String,
    subLabel: String,
    required: [String, Number, Object, Boolean],
    id: String,
    floatLabel: {
      type: Boolean,
      default: true
    },
    forceSublabelUnder: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    addRequiredHiddenField: {
      type: Boolean,
      default: false
    },
    hasValue: {
      type: Boolean,
      default: false
    }
  },
  components: {}
});
