import { useApiWithAuth } from "@/modules/api";
import { useCache } from "@/modules/cache";

interface StatusPayload {
  flag?: string | null;
  descricao?: string | null;
}

export const useStatusService = () => {
  const { data, loading, get } = useApiWithAuth();

  const getStatusesOrgaoGovPassToCache = (): Promise<void> => {
    return get(`/status/orgao/govpass`);
  };

  const getStatusesOrgaoGovPass = (): Array<StatusPayload> => {
    return useCache().getStatusesOrgaoGovPass();
  };

  const getStatusOrgaoGovPass = (
    flag?: string | null
  ): StatusPayload | null => {
    if (!flag) {
      return null;
    }

    return getStatusesOrgaoGovPass().find((a) => a.flag == flag) ?? null;
  };

  const getStatusesOrgaoToCache = (): Promise<void> => {
    return get(`/status/orgao`);
  };

  const getStatusesOrgao = (): Array<StatusPayload> => {
    return useCache().getStatusesOrgao();
  };

  const getStatusOrgao = (flag?: string | null): StatusPayload | null => {
    if (!flag) {
      return null;
    }

    return getStatusesOrgao().find((a) => a.flag == flag) ?? null;
  };

  const getStatusesRoboToCache = (): Promise<void> => {
    return get(`/status/robo`);
  };

  const getStatusesRobo = (): Array<StatusPayload> => {
    return useCache().getStatusesRobo();
  };

  const getStatusRobo = (flag?: string | null): StatusPayload | null => {
    if (!flag) {
      return null;
    }

    return getStatusesRobo().find((a) => a.flag == flag) ?? null;
  };

  return {
    data,
    loading,
    getStatusesOrgaoGovPassToCache,
    getStatusesOrgaoGovPass,
    getStatusOrgaoGovPass,
    getStatusesOrgaoToCache,
    getStatusesOrgao,
    getStatusOrgao,
    getStatusesRoboToCache,
    getStatusesRobo,
    getStatusRobo
  };
};

export { StatusPayload };
