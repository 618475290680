
import { defineComponent } from "vue";
export default defineComponent({
  name: "loading",
  props: {
    fullscreen: {
      type: Boolean,
      default: true
    },
    applyCustomLoading: Boolean
  }
});
