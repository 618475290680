<template>
  <Toast :position="group" :group="group">
    <template #message="slotProps">
      <div class="box p-grid nested-grid">
        <div class="p-col-fixed p-as-center" style="width: 4rem">
          <!-- TODO KAIO ARRUMAR ICONES -->
          <i class="pi pi-exclamation-triangle" style="font-size: 3rem" />
        </div>
        <div class="p-col">
          <div class="p-grid">
            <div class="p-col-12">
              <b v-text="slotProps.message.summary" />
            </div>
            <div class="p-col-12">
              <span v-html="slotProps.message.detail" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    group: { type: String, default: "top-right" }
  },
  components: {}
});
</script>
