
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref,
  PropType
} from "vue";
import { useMunicipioService } from "../../modules/business/common/commonBusiness";
import { useStorage } from "../../modules/storage";
import { useAuth, PermissionPayload } from "../../modules/auth";
import {
  useTransportadorService,
  TransportadorPayload
} from "../../modules/business/transportador/transportadorBusiness";
import { clearObject, copyObject } from "../../modules/utils";
import { useToast } from "../../modules/message";
import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";
import CrudArtEngenheiroTransportador from "./art/CrudArtEngenheiroTransportador.vue";
import EntitySelectorCliente from "../cliente/EntitySelectorCliente.vue";
import EntitySelectorMunicipio from "../common/EntitySelectorMunicipio.vue";

// eslint-disable-next-line
declare var require: any; //PQP MEMO

interface TransportadorCadastroState {
  entity: TransportadorPayload;
  stateReady: boolean;
  hasUser: boolean;
  savedEntity: boolean;
  loadings: Array<Ref<boolean>>;
  loadingEntity: boolean;
  lastLoadedEntity: TransportadorPayload | null;
  stateId: string;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<TransportadorCadastroState>({
      entity: useTransportadorService().safeTransportador(),
      hasUser: false,
      savedEntity: false,
      stateReady: false,
      loadings: [],
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      lastLoadedEntity: null,
      loadingEntity: false
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });

    const cpfCnpjSemMascara = computed(() => {
      return state.entity.cpfCnpj
        ? state.entity.cpfCnpj?.replace(/\D/g, "")
        : "";
    });

    const municipiosFiltrados = computed(() => {
      if (state.entity.municipio.idUnidadeFederal) {
        return useMunicipioService().getMunicipios(
          state.entity.municipio.idUnidadeFederal
        );
      } else {
        return [];
      }
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const loadEntity = (isRefresh: boolean) => {
      const cpfCpnj = state.entity.cpfCnpj?.replace(/\D/g, "");
      if (cpfCpnj && (cpfCpnj.length == 11 || cpfCpnj.length == 14)) {
        const { loadUsingCpfCnpj, loading, data } = useTransportadorService();
        state.loadings.push(loading);
        state.loadingEntity = true;
        loadUsingCpfCnpj(
          cpfCpnj,
          isRefresh,
          state.entity.cliente || props.cliente
        )
          .then(() => {
            const transp = data.value;
            state.lastLoadedEntity = transp;

            if (transp && transp.nome) {
              if (transp.idTransportador) {
                if (!state.entity.idTransportador) {
                  emit("request-entity-edit", transp.idTransportador);
                }
              } else {
                delete transp["idTransportador"];
                delete transp["cpfCnpj"];
                clearObject(transp);
                if (
                  transp.municipio &&
                  transp.municipio.idUnidadeFederal &&
                  transp.municipio.idUnidadeFederal !=
                    state.entity.municipio.idUnidadeFederal
                ) {
                  state.entity.municipio.idUnidadeFederal =
                    transp.municipio.idUnidadeFederal;
                }
                copyObject(transp, state.entity, ["municipio"]);
              }
            }
            state.loadingEntity = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadingEntity = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        useToast().warning("CPF/CNPJ inválido");
      }
    };

    const onChangeCpfCnpj = () => {
      const cpfCpnj = state.entity.cpfCnpj?.replace(/\D/g, "");
      if (
        !state.entity.idTransportador &&
        (cpfCpnj?.length == 11 || cpfCpnj?.length == 14)
      ) {
        loadEntity(false);
      }
    };

    const confirmSelectionTransportadorGrupo = (t?: TransportadorPayload) => {
      if (t) {
        state.entity.transportadorGrupo = t;
      } else {
        state.entity.transportadorGrupo = useTransportadorService().safeTransportador().transportadorGrupo;
      }
      const currentIdTransportador =
        state.entity.transportadorGrupo?.idTransportador;
      if (currentIdTransportador) {
        const {
          isTransportadorValidoParaGrupoCadastro,
          loading
        } = useTransportadorService();
        state.loadings.push(loading);
        isTransportadorValidoParaGrupoCadastro(
          currentIdTransportador,
          state.entity.idTransportador,
          state.entity.cliente || props.cliente
        )
          .then(() => {
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.entity.transportadorGrupo = useTransportadorService().safeTransportador().transportadorGrupo;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      }
    };

    const setEntity = (entity: TransportadorPayload) => {
      state.entity = useTransportadorService().safeTransportador(entity);

      state.hasUser = !!state.entity.usuario.login;

      const contratoElement = document.querySelector(
        "#" + state.stateId + "_contrato"
      ) as HTMLInputElement;
      if (contratoElement) {
        contratoElement.value = "";
      }

      const procuracaoElement = document.querySelector(
        "#" + state.stateId + "_procuracao"
      ) as HTMLInputElement;
      if (procuracaoElement) {
        procuracaoElement.value = "";
      }
    };

    const downloadContrato = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idTransportador) {
        onEnd();
        return;
      }
      const { downloadContrato, loading } = useTransportadorService();
      state.loadings.push(loading);
      downloadContrato(state.entity.idTransportador, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const downloadProcuracao = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idTransportador) {
        onEnd();
        return;
      }
      const { downloadProcuracao, loading } = useTransportadorService();
      state.loadings.push(loading);
      downloadProcuracao(state.entity.idTransportador, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const deleteTempFiles = () => {
      if (state.entity.contratoSocial.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(
          state.entity.contratoSocial.keyBlDocumentoTemp
        );
        state.entity.contratoSocial.keyBlDocumentoTemp = null;
      }
      if (state.entity.procuracao.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(state.entity.procuracao.keyBlDocumentoTemp);
        state.entity.procuracao.keyBlDocumentoTemp = null;
      }
    };

    const save = () => {
      const idTransportadorProps = props.idTransportador ?? 0;
      const isInsert = !idTransportadorProps;
      const {
        saveNewTransportador,
        updateTransportador,
        loading: loadingCadastro,
        data: dataCadastro
      } = useTransportadorService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewTransportador(state.entity)
        : updateTransportador(idTransportadorProps, state.entity)
      )
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          setEntity(dataCadastro.value);
          state.savedEntity = true;
          useToast().success("Transportador salvo com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idTransportador);
          }
        })
        .catch(() => {
          deleteTempFiles();
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const processFile = (fileElementId: string) => {
      return new Promise<string>((resolve) => {
        const file = document.querySelector(
          "#" + fileElementId
        ) as HTMLInputElement;
        if (!file || !file.files || file.files.length === 0) {
          resolve("");
        } else {
          const formData = new FormData();
          formData.append("file", file.files[0]);
          const storage = useStorage();
          state.loadings.push(storage.loading);
          storage
            .uploadTempFile(formData)
            .then((key: string) => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
              resolve(key);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            });
        }
      });
    };

    const submitSave = () => {
      const arrayPromises = new Array<Promise<string>>();
      arrayPromises.push(
        processFile(state.stateId + "_contrato").then(
          (key) => (state.entity.contratoSocial.keyBlDocumentoTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_procuracao").then(
          (key) => (state.entity.procuracao.keyBlDocumentoTemp = key)
        )
      );
      Promise.all(arrayPromises).then(
        () => {
          save();
        },
        () => {
          deleteTempFiles();
        }
      );
    };

    onMounted(async () => {
      const {
        getTransportador,
        newTransportador,
        data,
        loading
      } = useTransportadorService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      (props.idTransportador
        ? getTransportador(props.idTransportador)
        : newTransportador(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      downloadContrato,
      downloadProcuracao,
      municipiosFiltrados,
      cpfCnpjSemMascara,
      amIAllowed,
      onChangeCpfCnpj,
      loadEntity,
      confirmSelectionCliente,
      confirmSelectionTransportadorGrupo,
      stateLoading,
      stateBlocked,
      afterSetMunicipio,
      ...toRefs(state)
    };
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.EntitySelectorTransportador = require("./EntitySelectorTransportador.vue").default;
    }
  },
  components: {
    CrudArtEngenheiroTransportador,
    EntitySelectorCliente,
    EntitySelectorMunicipio
  },
  props: {
    idTransportador: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
