
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  Ref,
  PropType,
  watch
} from "vue";
import { useAuth, PermissionPayload } from "../../../modules/auth";
import { useToast, useConfirm } from "../../../modules/message";
import { useCache } from "../../../modules/cache";
import { useStorage } from "../../../modules/storage";
import {
  formatDateTime,
  clearObject,
  copyObject
} from "../../../modules/utils";
import {
  VeiculoTratorPayload,
  ConsultaVeiculoTratorPlacaPayload,
  TipoVeiculoTratorPayload,
  useVeiculoTratorService,
  ModeloVeiculoTratorPayload,
  useModeloVeiculoTratorService
} from "../../../modules/business/veiculo/veiculoTratorBusiness";
import { useTipoCarroceriaService } from "../../../modules/business/veiculo/carroceriaBusiness";
import EntitySelectorCliente from "../../cliente/EntitySelectorCliente.vue";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";
import EntitySelectorTransportador from "../../transportador/EntitySelectorTransportador.vue";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../../modules/business/transportador/transportadorBusiness";
import EntitySelectorModeloVeiculoTrator from "../../veiculo/trator/modelo/EntitySelectorModeloVeiculoTrator.vue";
import EntitySelectorMunicipio from "../../common/EntitySelectorMunicipio.vue";
import CrudFilaVeiculo from "../../veiculo/CrudFilaVeiculo.vue";

interface VeiculoTratorCadastroState {
  entity: VeiculoTratorPayload;
  stateReady: boolean;
  savedEntity: boolean;
  stateId: string;
  loadings: Array<Ref<boolean>>;
  loadingPlates: boolean;
  lastLoadedPlate: ConsultaVeiculoTratorPlacaPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<VeiculoTratorCadastroState>({
      entity: useVeiculoTratorService().safeVeiculoTrator(),
      savedEntity: false,
      stateReady: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      loadings: [],
      lastLoadedPlate: null,
      loadingPlates: false
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const stateLoading = computed(() => {
      return state.loadings.length !== 0 || props.parentLoading;
    });

    const stateBlocked = computed(() => {
      return !!(
        stateLoading.value || !amIAllowed("edit", state.entity.permissionInfo)
      );
    });
    const stateFullBlocked = computed(() => {
      return !!(
        stateBlocked.value ||
        (amIAllowed("view", "root-cliente") && !state.entity.cliente?.idCliente)
      );
    });

    const afterSetMunicipio = (
      idUnidadeFederal: number | null,
      idMunicipio: number | null
    ) => {
      if (idUnidadeFederal !== state.entity.municipio.idUnidadeFederal) {
        state.entity.municipio.idUnidadeFederal = idUnidadeFederal;
        state.entity.municipio.idMunicipio = null;
      } else if (idMunicipio !== state.entity.municipio.idMunicipio) {
        state.entity.municipio.idMunicipio = idMunicipio;
      }
    };

    const tiposVeiculoTrator = useVeiculoTratorService().getTiposVeiculoTrator();

    const tracoesDisponiveis = useVeiculoTratorService().getTracoesVeiculoTrator(
      true
    );

    const direcoesDisponiveis = useCache().getDirecoesVeiculoTrator();

    const cores = useCache().getCores();

    const tipoVeiculoTratorSelecionado = computed(() => {
      if (state.entity.flTipoVeiculoTrator) {
        const tipo: TipoVeiculoTratorPayload = tiposVeiculoTrator.filter(
          (t) => t.flTipoVeiculoTrator == state.entity.flTipoVeiculoTrator
        )[0];
        return tipo;
      } else {
        return null;
      }
    });

    const possuiCarroceria = computed(() => {
      return tipoVeiculoTratorSelecionado.value?.unidadeComCarga;
    });

    const tiposCarroceriasFiltrados = computed(() => {
      if (
        tipoVeiculoTratorSelecionado.value &&
        tipoVeiculoTratorSelecionado.value?.idsCarroceriasAceitas
      ) {
        return useTipoCarroceriaService()
          .getTiposCarroceria()
          .filter(
            (t) =>
              t.idTipoCarroceria &&
              (
                tipoVeiculoTratorSelecionado.value?.idsCarroceriasAceitas ?? []
              ).indexOf(t.idTipoCarroceria) > -1
          );
      } else {
        return [];
      }
    });

    watch([tipoVeiculoTratorSelecionado], () => {
      if (!tipoVeiculoTratorSelecionado.value) {
        state.entity.idTipoCarroceria = null;
      } else if (
        state.entity.idTipoCarroceria &&
        (
          tipoVeiculoTratorSelecionado.value?.idsCarroceriasAceitas ?? []
        ).indexOf(state.entity.idTipoCarroceria) <= -1
      ) {
        state.entity.idTipoCarroceria = null;
      } else if (
        tipoVeiculoTratorSelecionado.value?.idsCarroceriasAceitas?.length == 1
      ) {
        state.entity.idTipoCarroceria =
          tipoVeiculoTratorSelecionado.value.idsCarroceriasAceitas[0];
      }
    });

    const setEntity = (entity: VeiculoTratorPayload) => {
      state.entity = useVeiculoTratorService().safeVeiculoTrator(entity);
      const docElement = document.querySelector(
        "#" + state.stateId + "_crlv"
      ) as HTMLInputElement;
      if (docElement) {
        docElement.value = "";
      }

      const cnhMotoristaElement = document.querySelector(
        "#" + state.stateId + "_cnh_motorista"
      ) as HTMLInputElement;
      if (cnhMotoristaElement) {
        cnhMotoristaElement.value = "";
      }
    };

    const closeScreen = () => {
      emit(
        "after-register-screen-closed",
        state.savedEntity ? state.entity : undefined
      );
    };

    const confirmSelectionCliente = (c: ClientePayload) => {
      if (c) {
        state.entity.cliente = c;
      }
    };

    const confirmSelectionTransportador = (t?: TransportadorPayload) => {
      if (t) {
        state.entity.transportador = t;
      } else {
        state.entity.transportador = useVeiculoTratorService().safeVeiculoTrator().transportador;
      }
    };

    const getTransportadorNome = (e: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const confirmSelectionModeloVeiculo = (m: ModeloVeiculoTratorPayload) => {
      if (m) {
        state.entity.modeloVeiculo = m;
      } else {
        state.entity.modeloVeiculo = useVeiculoTratorService().safeVeiculoTrator().modeloVeiculo;
      }
    };

    const downloadCNHMotorista = (newTab: boolean, onEnd: Function) => {
      if (!state.entity.idVeiculoTrator) {
        onEnd();
        return;
      }
      const { downloadCNHMotorista, loading } = useVeiculoTratorService();
      state.loadings.push(loading);
      downloadCNHMotorista(state.entity.idVeiculoTrator, newTab)
        .then(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          onEnd();
        });
    };

    const downloadCRLV = (newTab: boolean, onEnd: Function) => {
      const { downloadCRLV, loading } = useVeiculoTratorService();
      if (state.entity.idVeiculoTrator) {
        state.loadings.push(loading);
        downloadCRLV(state.entity.idVeiculoTrator, newTab)
          .then(() => {
            onEnd();
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            onEnd();
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        onEnd();
      }
    };

    const updateCRLV = () => {
      useConfirm().require({
        message:
          "O documento será atualizado, Deseja alterar o ano Exercício para o ano atual?",
        header: "Confirmação",
        icon: "pi pi-info-circle",
        accept: () => {
          state.entity.anoExercicioCrlv = new Date().getFullYear();
        }
      });
    };

    const loadPlates = (isRefresh: boolean) => {
      if (state.entity.placa?.length == 7) {
        const {
          loadUsingPlateNumber,
          loading,
          data
        } = useVeiculoTratorService();
        state.loadings.push(loading);
        state.loadingPlates = true;
        loadUsingPlateNumber(
          state.entity.placa,
          isRefresh,
          state.entity.cliente
        )
          .then(() => {
            state.lastLoadedPlate = data.value;
            if (state.lastLoadedPlate) {
              const veiculoTrator = state.lastLoadedPlate.veiculoTrator;
              if (veiculoTrator.idVeiculoTrator) {
                if (!isRefresh) {
                  emit("request-entity-edit", veiculoTrator.idVeiculoTrator);
                }
              } else {
                delete veiculoTrator["idVeiculoTrator"];
                clearObject(veiculoTrator);
                if (
                  veiculoTrator.municipio &&
                  veiculoTrator.municipio.idUnidadeFederal &&
                  veiculoTrator.municipio.idUnidadeFederal !=
                    state.entity.municipio.idUnidadeFederal
                ) {
                  state.entity.municipio.idUnidadeFederal =
                    veiculoTrator.municipio.idUnidadeFederal;
                }
                copyObject(veiculoTrator, state.entity, ["municipio"]);
                if (!veiculoTrator.modeloVeiculo && !isRefresh) {
                  state.entity.modeloVeiculo = useModeloVeiculoTratorService().safeModeloVeiculoTrator();
                }
              }
            }
            state.loadingPlates = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          })
          .catch(() => {
            state.loadingPlates = false;
            state.loadings.splice(state.loadings.indexOf(loading), 1);
          });
      } else {
        useToast().warning("Placa inválida");
      }
    };

    const onChangePlaca = () => {
      if (!state.entity.idVeiculoTrator && state.entity.placa?.length == 7) {
        loadPlates(false);
      }
    };

    const deleteTempFiles = () => {
      if (state.entity.keyBlDocumentoCrlvTemp) {
        useStorage().deleteTempFile(state.entity.keyBlDocumentoCrlvTemp);
        state.entity.keyBlDocumentoCrlvTemp = null;
      }
      if (state.entity.cnhMotorista.keyBlDocumentoTemp) {
        useStorage().deleteTempFile(
          state.entity.cnhMotorista.keyBlDocumentoTemp
        );
        state.entity.cnhMotorista.keyBlDocumentoTemp = null;
      }
    };

    const save = () => {
      const idVeiculoTratorProps = props.idVeiculoTrator ?? 0;
      const isInsert = !idVeiculoTratorProps;
      const {
        saveNewVeiculoTrator,
        updateVeiculoTrator,
        loading: loadingCadastro,
        data: dataCadastro
      } = useVeiculoTratorService();
      state.loadings.push(loadingCadastro);
      (isInsert
        ? saveNewVeiculoTrator(state.entity)
        : updateVeiculoTrator(idVeiculoTratorProps, state.entity)
      )
        .then(() => {
          setEntity(dataCadastro.value);
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
          state.savedEntity = true;
          useToast().success("Veículo salvo com sucesso");
          if (isInsert) {
            emit("after-save-new-entity", dataCadastro.value.idVeiculoTrator);
          }
        })
        .catch(() => {
          deleteTempFiles();
          state.loadings.splice(state.loadings.indexOf(loadingCadastro), 1);
        });
    };

    const processFile = (fileElementId: string) => {
      return new Promise<string>((resolve) => {
        const file = document.querySelector(
          "#" + fileElementId
        ) as HTMLInputElement;
        if (!file || !file.files || file.files.length === 0) {
          resolve("");
        } else {
          const formData = new FormData();
          formData.append("file", file.files[0]);
          const storage = useStorage();
          state.loadings.push(storage.loading);
          storage
            .uploadTempFile(formData)
            .then((key: string) => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
              resolve(key);
            })
            .catch(() => {
              state.loadings.splice(state.loadings.indexOf(storage.loading), 1);
            });
        }
      });
    };

    const submitSave = () => {
      const arrayPromises = new Array<Promise<string>>();
      arrayPromises.push(
        processFile(state.stateId + "_crlv").then(
          (key) => (state.entity.keyBlDocumentoCrlvTemp = key)
        )
      );
      arrayPromises.push(
        processFile(state.stateId + "_cnh_motorista").then(
          (key) => (state.entity.cnhMotorista.keyBlDocumentoTemp = key)
        )
      );
      Promise.all(arrayPromises).then(
        () => {
          save();
        },
        () => {
          deleteTempFiles();
        }
      );
    };

    onMounted(async () => {
      const {
        getVeiculoTrator,
        newVeiculoTrator,
        data,
        loading
      } = useVeiculoTratorService();
      state.loadings.push(loading);
      if (props.cliente) {
        state.entity.cliente = props.cliente;
      }
      (props.idVeiculoTrator
        ? getVeiculoTrator(props.idVeiculoTrator)
        : newVeiculoTrator(props.cliente?.idCliente)
      )
        .then(() => {
          setEntity(data.value);
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          state.stateReady = true;
        })
        .catch(() => {
          state.loadings.splice(state.loadings.indexOf(loading), 1);
          closeScreen();
        });
    });

    return {
      submitSave,
      closeScreen,
      amIAllowed,
      confirmSelectionCliente,
      confirmSelectionTransportador,
      confirmSelectionModeloVeiculo,
      stateLoading,
      stateBlocked,
      stateFullBlocked,
      tiposVeiculoTrator,
      tipoVeiculoTratorSelecionado,
      cores,
      onChangePlaca,
      loadPlates,
      direcoesDisponiveis,
      tracoesDisponiveis,
      possuiCarroceria,
      tiposCarroceriasFiltrados,
      downloadCNHMotorista,
      downloadCRLV,
      updateCRLV,
      getTransportadorNome,
      formatDateTime,
      afterSetMunicipio,
      ...toRefs(state)
    };
  },
  components: {
    EntitySelectorCliente,
    EntitySelectorTransportador,
    EntitySelectorModeloVeiculoTrator,
    EntitySelectorMunicipio,
    CrudFilaVeiculo
  },
  props: {
    idVeiculoTrator: Number,
    cliente: Object as PropType<ClientePayload>,
    parentLoading: Boolean
  }
});
