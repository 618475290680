<template>
  <div
    class="p-d-flex p-jc-center p-ai-center"
    :class="{
      'loading-fullscreen': fullscreen,
      'loading-parent': !fullscreen,
      'main-loading': applyCustomLoading
    }"
  >
    <ProgressSpinner v-if="!applyCustomLoading" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "loading",
  props: {
    fullscreen: {
      type: Boolean,
      default: true
    },
    applyCustomLoading: Boolean
  }
});
</script>
<style scoped lang="scss">
.loading-parent {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  cursor: wait !important;
  opacity: 0.5;
  background-color: $bg-color;
  z-index: 999999;
  position: absolute;
}

.loading-fullscreen {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: wait !important;
  opacity: 0.5;
  background-color: $bg-color;
  z-index: 999999;
}
</style>
