<template>
  <container :isModal="false" :loading="loading">
    <template v-slot:body>
      <form @submit.prevent="submit">
        <fieldset
          :disabled="loading"
          class="p-d-flex p-jc-center"
          style="height: 100vh"
        >
          <div
            class="p-as-center p-p-5 shadow-box p-shadow-24"
            style="background: white; border-radius: 0.25rem"
          >
            <div style="width: 300px">
              <div class="p-d-flex m-3 p-jc-center">
                <img class="img-fluid main-logo" alt="GovPass" />
              </div>
              <div class="p-d-flex p-jc-center p-mt-3">
                <p>
                  Por favor preencha os dados abaixo para definir uma nova senha
                </p>
              </div>
              <div class="p-field p-fluid p-col-12">
                <input-with-label
                  iconClass="fas fa-lock p-mr-1"
                  label="Nova senha"
                  v-slot="l"
                  :required="true"
                >
                  <Password
                    v-model="credentials.novaSenha"
                    :id="l.id"
                    :required="l.required"
                    v-focus
                  />
                </input-with-label>
                <span
                  style="color: red"
                  class="p-pr-2"
                  v-if="
                    credentials.novaSenha &&
                      confirmaSenha &&
                      credentials.novaSenha != confirmaSenha
                  "
                >
                  <small><b>As senhas devem ser iguais</b></small>
                </span>
              </div>
              <div class="p-field p-fluid p-col-12">
                <input-with-label
                  iconClass="fas fa-lock p-mr-1"
                  label="Confirmar Senha"
                  v-slot="l"
                  :required="true"
                >
                  <Password
                    v-model="confirmaSenha"
                    :id="l.id"
                    :required="l.required"
                    :feedback="false"
                  />
                </input-with-label>
              </div>
              <div class="p-d-flex p-m-3 p-jc-center">
                <Button type="submit" class="p-button-rounded" label="Enviar" />

                <Button
                  label="Voltar"
                  class="p-button-rounded p-button-text"
                  @click="back()"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
  </container>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

import { useRouter } from "vue-router";
import { useApi } from "../../modules/api";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";
import { useToast } from "../../modules/message";

interface RecoverPayload {
  credentials: CredencialPayload;
  token: string;
  confirmaSenha: string;
  loading: boolean;
}

export default defineComponent({
  setup(props) {
    const router = useRouter();

    if (!props.token) {
      useToast().warning("Token inválido, redirecionando para login.");
      router.push({ name: "login" });
    }

    const state = reactive<RecoverPayload>({
      credentials: {},
      token: props.token ?? "",
      confirmaSenha: "",
      loading: false
    });

    const back = () => {
      router.push({ name: "login" });
    };

    const submit = () => {
      state.loading = true;
      useApi()
        .post("/login/password/token/" + state.token, state.credentials)
        .then(() => {
          useToast().success(
            "Senha atualizada com sucesso. Redirecionando para tela de login.."
          );
          back();
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };
    return {
      submit,
      back,
      ...toRefs(state)
    };
  },
  props: {
    token: String
  }
});
</script>
