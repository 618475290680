import { useApiWithAuth } from "@/modules/api";
import { useUfService } from "@/modules/business/common/commonBusiness";
import { PermissionPayload } from "@/modules/auth";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";
import {
  EngenheiroPayload,
  useEngenheiroService
} from "@/modules/business/engenheiro/engenheiroBusiness";
import {
  VeiculoTratorPayload,
  useVeiculoTratorService
} from "@/modules/business/veiculo/veiculoTratorBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "./transportadorBusiness";

interface FilaArtPayload {
  idFilaArt: number | null;
  timestampUltimaExecucao?: Date | null;
  usuario?: string | null;
  situacao?: string | null;
  statusGovpassOrgao?: string | null;
}

interface ArtEngenheiroTransportadorPayload {
  idArtEngenheiroTransportador?: number | null;
  art?: string | null;
  keyBlDocumento?: string | null;
  keyBlDocumentoTemp?: string | null;
  dataVencimento?: Date | null;
  idUnidadeFederal?: number | null;
  transportador: TransportadorPayload;
  engenheiro: EngenheiroPayload;
  multipla?: boolean | null;
  numeroRIV?: string | null;
  veiculoTratorRIV: VeiculoTratorPayload;
  filaArtDTO?: FilaArtPayload | null;
  permissionInfo?: PermissionPayload;
}

interface ArtEngenheiroTransportadorRequestPayload {
  idOrgao?: number | null;
  transportador: TransportadorPayload;
  engenheiro: EngenheiroPayload;
  cliente?: ClientePayload | null;
}

const useArtEngenheiroTransportadorRequestService = () => {
  const { data, loading, get, post } = useApiWithAuth();
  const searchArtEngenheiroTransportadorRequest = (): Promise<void> => {
    return get(`/transportador/art/request`);
  };

  const newArtEngenheiroTransportadorRequest = (): Promise<void> => {
    return get(`/transportador/art/request/new`);
  };

  const safeArtEngenheiroTransportadorRequest = (
    entity?: ArtEngenheiroTransportadorRequestPayload | null
  ): ArtEngenheiroTransportadorRequestPayload => {
    const ret = entity ?? {
      transportador: useTransportadorService().safeTransportador(),
      engenheiro: useEngenheiroService().safeEngenheiro()
    };

    ret.engenheiro = ret.engenheiro ?? useEngenheiroService().safeEngenheiro();
    ret.transportador =
      ret.transportador ?? useTransportadorService().safeTransportador();
    return ret;
  };

  const saveNewArtEngenheiroTransportadorRequest = (
    artRequest: ArtEngenheiroTransportadorRequestPayload
  ): Promise<void> => {
    return post(`/transportador/art/request`, artRequest);
  };

  return {
    data,
    loading,
    searchArtEngenheiroTransportadorRequest,
    newArtEngenheiroTransportadorRequest,
    safeArtEngenheiroTransportadorRequest,
    saveNewArtEngenheiroTransportadorRequest
  };
};

const useArtEngenheiroTransportadorService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    patch,
    downloadFileNewTab,
    downloadFile,
    del
  } = useApiWithAuth();

  const searchArtEngenheiroTransportador = (
    idTransportador: number,
    idEngenheiro?: number
  ): Promise<void> => {
    return get(
      `/transportador/${idTransportador}/art`,
      idEngenheiro ? { idEngenheiro: idEngenheiro } : {}
    );
  };

  const deleteArtEngenheiroTransportador = (
    art: ArtEngenheiroTransportadorPayload
  ): Promise<void> => {
    return del(
      `/transportador/${art.transportador.idTransportador}/art/${art.idArtEngenheiroTransportador}`
    );
  };

  const downloadArtEngenheiroTransportador = (
    art: ArtEngenheiroTransportadorPayload,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/transportador/${art.transportador.idTransportador}/art/${art.idArtEngenheiroTransportador}/download/art`
    );
  };

  const downloadBoletoArtEngenheiroTransportador = (
    art: ArtEngenheiroTransportadorPayload,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/transportador/${art.transportador.idTransportador}/art/${art.idArtEngenheiroTransportador}/download/boleto`
    );
  };

  const downloadOriginalArtEngenheiroTransportador = (
    art: ArtEngenheiroTransportadorPayload,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/transportador/${art.transportador.idTransportador}/art/${art.idArtEngenheiroTransportador}/download/art-original`
    );
  };

  const getArtEngenheiroTransportador = (
    idTransportador: number,
    idArtEngenheiroTransportador: number
  ): Promise<void> => {
    return get(
      `/transportador/${idTransportador}/art/${idArtEngenheiroTransportador}`
    );
  };

  const newArtEngenheiroTransportador = (
    idTransportador: number,
    idEngenheiro?: number
  ): Promise<void> => {
    return get(
      `/transportador/${idTransportador}/art/new`,
      idEngenheiro ? { idEngenheiro: idEngenheiro } : {}
    );
  };

  const saveNewArtEngenheiroTransportador = (
    idTransportador: number,
    art: ArtEngenheiroTransportadorPayload
  ): Promise<void> => {
    return post(`/transportador/${idTransportador}/art`, art);
  };

  const updateArtEngenheiroTransportador = (
    idTransportador: number,
    idArtEngenheiroTransportador: number,
    art: ArtEngenheiroTransportadorPayload
  ): Promise<void> => {
    return put(
      `/transportador/${idTransportador}/art/${idArtEngenheiroTransportador}`,
      art
    );
  };

  const requestNewArtEngenheiroTransportador = (
    idTransportador: number
  ): Promise<void> => {
    return post(`/transportador/${idTransportador}/art/solicitar`);
  };

  const playArtEngenheiroTransportador = (
    art: ArtEngenheiroTransportadorPayload
  ): Promise<void> => {
    return patch(
      `/transportador/${art.transportador.idTransportador}/art/${art.idArtEngenheiroTransportador}/solicitar`
    );
  };

  const addMonitoringArtEngenheiroTransportador = (
    art: ArtEngenheiroTransportadorPayload
  ): Promise<void> => {
    return patch(
      `/transportador/${art.transportador.idTransportador}/art/${art.idArtEngenheiroTransportador}/adicionar-consulta`
    );
  };

  const getNomeArtEngenheiroTransportador = (
    entity?: ArtEngenheiroTransportadorPayload
  ) => {
    if (!entity) {
      return "";
    }
    return [entity.art, useUfService().getUfSigla(entity.idUnidadeFederal)]
      .filter((e) => e)
      .join("/");
  };

  const getSituacaoArtEngenheiroTransportador = (
    entity?: ArtEngenheiroTransportadorPayload
  ) => {
    if (!entity || !entity.filaArtDTO) {
      return "";
    }
    return entity.filaArtDTO.situacao;
  };

  const safeArtEngenheiroTransportador = (
    entity?: ArtEngenheiroTransportadorPayload | null
  ): ArtEngenheiroTransportadorPayload => {
    const ret = entity ?? {
      transportador: useTransportadorService().safeTransportador(),
      engenheiro: useEngenheiroService().safeEngenheiro(),
      veiculoTratorRIV: useVeiculoTratorService().safeVeiculoTrator()
    };

    ret.engenheiro = ret.engenheiro ?? useEngenheiroService().safeEngenheiro();
    ret.transportador =
      ret.transportador ?? useTransportadorService().safeTransportador();
    ret.veiculoTratorRIV =
      ret.veiculoTratorRIV ?? useVeiculoTratorService().safeVeiculoTrator();
    ret.keyBlDocumentoTemp = null;
    return ret;
  };

  return {
    data,
    loading,
    downloadArtEngenheiroTransportador,
    downloadBoletoArtEngenheiroTransportador,
    downloadOriginalArtEngenheiroTransportador,
    getArtEngenheiroTransportador,
    newArtEngenheiroTransportador,
    saveNewArtEngenheiroTransportador,
    updateArtEngenheiroTransportador,
    searchArtEngenheiroTransportador,
    deleteArtEngenheiroTransportador,
    safeArtEngenheiroTransportador,
    getSituacaoArtEngenheiroTransportador,
    getNomeArtEngenheiroTransportador,
    playArtEngenheiroTransportador,
    requestNewArtEngenheiroTransportador,
    addMonitoringArtEngenheiroTransportador
  };
};

export {
  useArtEngenheiroTransportadorRequestService,
  useArtEngenheiroTransportadorService,
  ArtEngenheiroTransportadorPayload,
  ArtEngenheiroTransportadorRequestPayload
};
