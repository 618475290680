
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  computed,
  watch
} from "vue";
import CrudTransportador from "./CrudTransportador.vue";
import {
  TransportadorPayload,
  useTransportadorService
} from "../../modules/business/transportador/transportadorBusiness";

import { ClientePayload } from "../../modules/business/cliente/clienteBusiness";

interface TransportadorSelectorState {
  stateReady: boolean;
  crudOpen: boolean;
  idTransportadorToView?: number | null;
  entitiesToSelect: Array<TransportadorPayload>;
  transportador?: TransportadorPayload | null;
  cpfCnpjInternal: string | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<TransportadorSelectorState>({
      stateReady: false,
      crudOpen: false,
      idTransportadorToView: null,
      cpfCnpjInternal: null,
      entitiesToSelect: []
    });

    const es = ref();

    const preFilter = computed(() => {
      const pf = useTransportadorService().safeFilterTransportador();
      if (state.cpfCnpjInternal) {
        pf.cpfCnpj = state.cpfCnpjInternal;
      }
      return pf;
    });

    watch([props], () => {
      if (!props.transportador || !props.transportador.idTransportador) {
        if (
          props.idTransportadorFiltered != state.transportador?.idTransportador
        ) {
          state.transportador = null;
        }
      }
    });

    const entitiesSelected = computed(() => {
      return props.transportador && props.transportador.idTransportador
        ? [props.transportador]
        : state.transportador && state.transportador.idTransportador
        ? [state.transportador]
        : [];
    });

    const clearEntity = () => {
      state.cpfCnpjInternal = null;
      state.transportador = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idTransportadorToView = null;
      es.value.focus();
    };

    const confirmSelection = (selected: Array<TransportadorPayload>) => {
      state.transportador =
        selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.transportador);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.cpfCnpjInternal = null;
      state.crudOpen = false;
      state.idTransportadorToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.cpfCnpjInternal = null;
      state.crudOpen = false;
      state.idTransportadorToView = null;
      emit("cancel-selection");
    };

    const getNome = (e: TransportadorPayload) => {
      return useTransportadorService().getNomeTransportador(e);
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idTransportadorToView = null;
      state.crudOpen = true;
    };

    const openEntity = (e: TransportadorPayload) => {
      if (e.idTransportador) {
        state.idTransportadorToView = e.idTransportador;
        state.crudOpen = true;
      }
    };

    const onChangeCpfCnpjTransportadorFilter = () => {
      if (
        state.cpfCnpjInternal &&
        (state.cpfCnpjInternal.length == 14 ||
          state.cpfCnpjInternal.length == 18)
      ) {
        openSearch();
      } else {
        state.cpfCnpjInternal = null;
      }
    };

    onMounted(async () => {
      state.transportador = props.transportador;
      state.stateReady = true;
    });

    return {
      openEntity,
      openSearch,
      clearEntity,
      confirmSelection,
      cancelSelection,
      preFilter,
      es,
      entitiesSelected,
      getNome,
      onChangeCpfCnpjTransportadorFilter,
      ...toRefs(state)
    };
  },
  components: {
    CrudTransportador
  },
  props: {
    transportador: {
      type: Object as PropType<TransportadorPayload>
    },
    idTransportadorFiltered: Number,
    cliente: Object as PropType<ClientePayload>,
    canEdit: {
      type: Boolean,
      default: true
    },
    focus: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Transportador"
    }
  }
});
