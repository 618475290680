
import { defineComponent, reactive, toRefs, onMounted, PropType } from "vue";

import { useAuth, PermissionPayload } from "../../../modules/auth";
import {
  useLicencaOrgaoService,
  LicencaOrgaoPayload
} from "../../../modules/business/licenca/licencaOrgaoBusiness";
import { formatDateTime } from "../../../modules/utils";

interface LicencaOrgaoErroImgState {
  stateId: string;
  stateReady: boolean;
  containerOpen: boolean;
  imgErrolUrl: string | null;
  logExecucao: string | null;
  imgErroThumbnailUrl: string | null;
  licencaOrgao: LicencaOrgaoPayload;
}

export default defineComponent({
  setup(props) {
    const state = reactive<LicencaOrgaoErroImgState>({
      imgErroThumbnailUrl: null,
      imgErrolUrl: null,
      logExecucao: null,
      stateReady: false,
      containerOpen: false,
      stateId:
        "state-" +
        Math.random()
          .toString(36)
          .substring(2),
      licencaOrgao: props.licencaOrgao
    });

    const amIAllowed = (
      authority: string,
      permissionInfo?: PermissionPayload | string | null
    ) => {
      return useAuth().amIAllowed(authority, permissionInfo);
    };

    const clickView = () => {
      const idLicenca = state.licencaOrgao.idLicenca;
      const idLicencaOrgao = state.licencaOrgao.idLicencaOrgao;
      if (idLicenca && idLicencaOrgao) {
        state.imgErrolUrl = null;
        state.logExecucao = null;

        if (
          amIAllowed("view_error_image", state.licencaOrgao?.permissionInfo)
        ) {
          const { getUrlErro, data } = useLicencaOrgaoService();
          getUrlErro(idLicenca, idLicencaOrgao).then(() => {
            state.imgErrolUrl = data?.value ?? null;
          });
        }

        if (
          amIAllowed("view_execution_log", state.licencaOrgao?.permissionInfo)
        ) {
          const { getLogExecucao, data: dataLog } = useLicencaOrgaoService();
          getLogExecucao(idLicenca, idLicencaOrgao).then(() => {
            state.logExecucao = dataLog.value;
          });
        }
        state.containerOpen = true;
      }
    };

    const closeView = () => {
      state.imgErrolUrl = null;
      state.logExecucao = null;
      state.containerOpen = false;
    };

    const loadImgErro = () => {
      //state.imgErroThumbnailUrl = null;
      //if (props.idLicenca && props.idLicencaOrgao) {
      //  const { getUrlErroThumbnail, data } = useLicencaOrgaoService();
      //  getUrlErroThumbnail(props.idLicenca, props.idLicencaOrgao).then(() => {
      //    state.imgErroThumbnailUrl = data?.value ?? null;
      //  });
      //}
    };

    onMounted(async () => {
      state.stateReady = true;
      loadImgErro();
    });

    return {
      amIAllowed,
      clickView,
      closeView,
      formatDateTime,
      ...toRefs(state)
    };
  },
  props: {
    licencaOrgao: {
      type: Object as PropType<LicencaOrgaoPayload>,
      required: true
    }
  }
});
