
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  onMounted,
  PropType,
  computed,
  watch
} from "vue";
import CrudVeiculoTrator from "./CrudVeiculoTrator.vue";
import {
  VeiculoTratorPayload,
  useVeiculoTratorService
} from "../../../modules/business/veiculo/veiculoTratorBusiness";
import { format } from "../../../modules/utils";
import { ClientePayload } from "../../../modules/business/cliente/clienteBusiness";

interface EntitySelectorVeiculoTratorState {
  stateReady: boolean;
  crudOpen: boolean;
  idVeiculoTratorToView?: number | null;
  placaNfInternal: string | null;
  entitiesToSelect: Array<VeiculoTratorPayload>;
  veiculoTrator?: VeiculoTratorPayload | null;
}

export default defineComponent({
  setup(props, { emit }) {
    const state = reactive<EntitySelectorVeiculoTratorState>({
      stateReady: false,
      crudOpen: false,
      idVeiculoTratorToView: null,
      placaNfInternal: null,
      entitiesToSelect: []
    });

    const es = ref();

    const preFilter = computed(() => {
      const pf = useVeiculoTratorService().safeFilterVeiculoTrator();
      if (state.placaNfInternal) {
        pf.placaNf = state.placaNfInternal;
      }
      return pf;
    });

    watch([props], () => {
      if (!props.veiculoTrator || !props.veiculoTrator.idVeiculoTrator) {
        if (
          props.idVeiculoTratorFiltered != state.veiculoTrator?.idVeiculoTrator
        ) {
          state.veiculoTrator = null;
        }
      }
    });

    const entitiesSelected = computed(() => {
      return props.veiculoTrator && props.veiculoTrator.idVeiculoTrator
        ? [props.veiculoTrator]
        : state.veiculoTrator && state.veiculoTrator.idVeiculoTrator
        ? [state.veiculoTrator]
        : [];
    });

    const clearEntity = () => {
      state.placaNfInternal = null;
      state.veiculoTrator = null;
      emit("confirm-selection", null);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.crudOpen = false;
      state.idVeiculoTratorToView = null;
      es.value.focus();
    };

    const confirmSelection = (selected: Array<VeiculoTratorPayload>) => {
      state.veiculoTrator =
        selected && selected.length > 0 ? selected[0] : null;
      emit("confirm-selection", state.veiculoTrator);
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.placaNfInternal = null;
      state.crudOpen = false;
      state.idVeiculoTratorToView = null;
      es.value.next();
    };

    const cancelSelection = () => {
      state.entitiesToSelect.splice(0, state.entitiesToSelect.length);
      state.placaNfInternal = null;
      state.crudOpen = false;
      state.idVeiculoTratorToView = null;
      emit("cancel-selection");
    };

    const openSearch = () => {
      entitiesSelected.value.forEach((e) => state.entitiesToSelect.push(e));
      state.idVeiculoTratorToView = null;
      state.crudOpen = true;
    };

    const onChangePlacaVeiculoTratorFilter = () => {
      if (state.placaNfInternal && state.placaNfInternal.length > 0) {
        openSearch();
      } else {
        state.placaNfInternal = null;
      }
    };

    const openEntity = (e: VeiculoTratorPayload) => {
      if (e.idVeiculoTrator) {
        state.idVeiculoTratorToView = e.idVeiculoTrator;
        state.crudOpen = true;
      }
    };

    const getNome = (e?: VeiculoTratorPayload) => {
      return useVeiculoTratorService().getNomeVeiculoTrator(e);
    };

    onMounted(async () => {
      state.veiculoTrator = props.veiculoTrator;
      state.stateReady = true;
      if (props.openOnCreate && !props.veiculoTrator?.idVeiculoTrator) {
        openSearch();
      }
    });

    return {
      openEntity,
      openSearch,
      clearEntity,
      confirmSelection,
      cancelSelection,
      preFilter,
      es,
      entitiesSelected,
      onChangePlacaVeiculoTratorFilter,
      format,
      getNome,
      ...toRefs(state)
    };
  },
  components: {
    CrudVeiculoTrator
  },
  props: {
    veiculoTrator: {
      type: Object as PropType<VeiculoTratorPayload>
    },
    idVeiculoTratorFiltered: Number,
    cliente: Object as PropType<ClientePayload>,
    canEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    openOnCreate: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    blockedSelection: {
      type: Function,
      required: false
    },
    label: {
      type: String,
      default: "Veículo Trator"
    },
    subLabel: {
      type: String,
      default: ""
    },
    focus: {
      type: Boolean,
      default: false
    }
  }
});
