
import { defineComponent, reactive, toRefs } from "vue";

import { useRouter } from "vue-router";
import { useApi } from "../../modules/api";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";
import { useToast } from "../../modules/message";

interface RequestRecoverPayload {
  credentials: CredencialPayload;
}

export default defineComponent({
  setup() {
    const { loading, post } = useApi();
    const router = useRouter();
    const state = reactive<RequestRecoverPayload>({
      credentials: {}
    });

    const back = () => {
      router.push({ name: "login" });
    };

    const submit = () => {
      post("/login/request-recovery-password", state.credentials).then(() => {
        //trazer logica de usuario para aqui
        useToast().success(
          "As instruções para recuperação de senha será enviado para conta de e-mail configurada no usuário."
        );
        back();
      });
    };

    return {
      loading,
      submit,
      back,
      ...toRefs(state)
    };
  }
});
