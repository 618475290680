<template>
  <container :isModal="false" :loading="loading">
    <template v-slot:body>
      <form @submit.prevent="submit">
        <fieldset
          :disabled="loading"
          class="p-d-flex p-jc-center"
          style="height: 100vh"
        >
          <div
            class="p-as-center p-p-5 shadow-box p-shadow-24"
            style="background: white; border-radius: 0.25rem"
          >
            <div style="width: 300px">
              <div class="p-d-flex m-3 p-jc-center">
                <img class="img-fluid main-logo" alt="GovPass" />
              </div>
              <div class="p-d-flex p-jc-center p-mt-3">
                <p>
                  Por favor preencha seu usuário e será encaminhado um e-mail
                  para definir sua nova senha
                </p>
              </div>
              <div class="p-d-flex p-jc-center p-mt-3">
                <input-with-label
                  iconClass="fas fa-envelope p-mr-1"
                  label="Usuário"
                  v-slot="l"
                  :required="true"
                >
                  <InputText
                    v-model="credentials.login"
                    :id="l.id"
                    :required="l.required"
                    v-focus
                  />
                </input-with-label>
              </div>

              <div class="p-d-flex p-m-3 p-jc-center">
                <Button type="submit" class="p-button-rounded" label="Enviar" />

                <Button
                  label="Voltar"
                  class="p-button-rounded p-button-text"
                  @click="back()"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
  </container>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

import { useRouter } from "vue-router";
import { useApi } from "../../modules/api";
import { CredencialPayload } from "../../modules/business/common/commonBusiness";
import { useToast } from "../../modules/message";

interface RequestRecoverPayload {
  credentials: CredencialPayload;
}

export default defineComponent({
  setup() {
    const { loading, post } = useApi();
    const router = useRouter();
    const state = reactive<RequestRecoverPayload>({
      credentials: {}
    });

    const back = () => {
      router.push({ name: "login" });
    };

    const submit = () => {
      post("/login/request-recovery-password", state.credentials).then(() => {
        //trazer logica de usuario para aqui
        useToast().success(
          "As instruções para recuperação de senha será enviado para conta de e-mail configurada no usuário."
        );
        back();
      });
    };

    return {
      loading,
      submit,
      back,
      ...toRefs(state)
    };
  }
});
</script>
