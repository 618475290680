
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineComponent, PropType, reactive, toRefs, onMounted } from "vue";

interface EntitySelectorState {
  stateReady: boolean;
}

export default defineComponent({
  setup() {
    const state = reactive<EntitySelectorState>({
      stateReady: false
    });

    onMounted(async () => {
      state.stateReady = true;
    });

    return {
      ...toRefs(state)
    };
  },
  props: {
    entities: {
      type: Array as PropType<Array<any>>
    },
    keyParam: String,
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {}
});
