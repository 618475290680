import { ToasterOptions } from "@meforma/vue-toaster";

import { clearObject, copyObject, cloneObject } from "@/modules/utils";
import {
  initGlobalConfirm,
  useGlobalConfirm,
  initGlobalToast,
  useGlobalToaster
} from "@/modules/globalAppProperties";

export interface ToastOptions {
  severity?: "success" | "info" | "warn" | "error";
  summary?: string;
  detail?: string;
  life?: number;
  group?:
    | "top-center"
    | "bottom-center"
    | "top-right"
    | "bottom-right"
    | "top-left"
    | "bottom-left";
  closable?: boolean;
}

export const useToast = () => {
  const defaultOpts = {
    severity: "info",
    group: "top-right",
    life: 10000
  } as ToastOptions;

  const toToasterOptions = (options: ToastOptions): ToasterOptions => {
    const ret = {
      position: "bottom-right",
      duration: 10000
    } as ToasterOptions;

    switch (options.group) {
      case "top-center":
        ret.position = "top";
        break;
      case "bottom-center":
        ret.position = "bottom";
        break;
      default:
        ret.position = options.group;
        break;
    }

    ret.position = "bottom-right"; //chumbado para testes
    ret.duration = options.life;
    ret.dismissible = !!options.life;

    return ret;
  };

  const getOptions = (
    options: ToastOptions,
    additionalOptions?: ToastOptions
  ) => {
    return copyObject(
      copyObject(clearObject(additionalOptions), options),
      cloneObject(defaultOpts)
    ) as ToastOptions;
  };

  const runMessage = (messageRun: () => void) => {
    try {
      messageRun();
    } catch (err) {
      setTimeout(() => {
        try {
          messageRun();
        } catch (err) {
          setTimeout(messageRun, 1000);
        }
      }, 300);
    }
  };

  const clear = () => {
    runMessage(() => {
      useGlobalToaster().clear
        ? useGlobalToaster().clear()
        : useGlobalToaster().removeAllGroups();
    });
  };

  const success = (message: string, options?: ToastOptions) => {
    runMessage(() => {
      const optionsInternal = getOptions(
        {
          severity: "success",
          summary: "Sucesso!",
          detail: message,
          life: 3000
        },
        options
      );
      useGlobalToaster().success
        ? useGlobalToaster().success(
            optionsInternal.summary
              ? "<b>" + optionsInternal.summary + "</b>"
              : "" + message,
            toToasterOptions(optionsInternal)
          )
        : useGlobalToaster().add(optionsInternal);
    });
  };

  const error = (message: string, options?: ToastOptions) => {
    runMessage(() => {
      const optionsInternal = getOptions(
        { severity: "error", summary: "Ops!", detail: message },
        options
      );
      useGlobalToaster().error
        ? useGlobalToaster().error(message, toToasterOptions(optionsInternal))
        : useGlobalToaster().add(optionsInternal);
    });
  };

  const info = (message: string, options?: ToastOptions) => {
    runMessage(() => {
      const optionsInternal = getOptions(
        { severity: "info", detail: message, life: 3000 },
        options
      );
      useGlobalToaster().info
        ? useGlobalToaster().info(message, toToasterOptions(optionsInternal))
        : useGlobalToaster().add(optionsInternal);
    });
  };

  const warning = (message: string, options?: ToastOptions) => {
    runMessage(() => {
      const optionsInternal = getOptions(
        { severity: "warn", summary: "Atenção", detail: message },
        options
      );
      useGlobalToaster().warning
        ? useGlobalToaster().warning(message, toToasterOptions(optionsInternal))
        : useGlobalToaster().add(optionsInternal);
    });
  };

  return {
    success,
    error,
    info,
    warning,
    clear
  };
};

export function useConfirm() {
  initGlobalConfirm();

  return useGlobalConfirm();
}

export function initGlobalMessageService() {
  initGlobalConfirm();
  initGlobalToast();
}
