import { useApiWithAuth } from "@/modules/api";
import { ClientePayload } from "@/modules/business/cliente/clienteBusiness";
import { useCache } from "@/modules/cache";
import { PermissionPayload } from "@/modules/auth";
import {
  MunicipioPayload,
  DocumentoPayload,
  CorPayload
} from "@/modules/business/common/commonBusiness";
import {
  TransportadorPayload,
  useTransportadorService
} from "@/modules/business/transportador/transportadorBusiness";
import {
  FilaVeiculoPayload,
  useFilaVeiculoService
} from "@/modules/business/veiculo/filaVeiculoBusiness";

interface VeiculoTratorFilterPayload {
  idCliente?: number;
  placaNf: string;
  idTransportador?: number | null;
  arquivado: boolean;
}

interface TracaoPayload {
  id: string;
  eixosTotal: number;
  eixosTracao: number;
  variante: boolean;
  disposicaoEixos: Array<number>;
}

interface DirecaoVeiculoTratorPayload {
  flag: string;
  descricao: number;
}

interface TipoVeiculoTratorPayload {
  flTipoVeiculoTrator?: string | null;
  descricao?: string | null;
  idsCarroceriasAceitas?: Array<number> | null;
  unidadeComCarga?: boolean | null;
  pesoMaximo?: number | null;
}

interface VeiculoTratorPayload {
  idVeiculoTrator?: number | null;
  placa?: string | null;
  notaFiscal?: string | null;
  notaFiscalChaveAcesso?: string | null;
  flExclusao?: boolean | null;
  renavam?: string | null;
  tracao?: string | null;
  flDirecao?: string | null;
  numeroEixos?: number | null;
  peso?: number | null;
  ano?: number | null;
  chassi?: string | null;
  idCor?: number | null;
  anoExercicioCrlv?: number | null;
  nomeProprietarioCrlv?: string | null;
  keyBlDocumentoCrlv?: string | null;
  keyBlDocumentoCrlvTemp?: string | null;
  flTipoVeiculoTrator?: string | null;
  idTipoCarroceria?: number | null;
  modeloVeiculo: ModeloVeiculoTratorPayload;
  cor: CorPayload;
  municipio: MunicipioPayload;
  transportador: TransportadorPayload;
  cnhMotorista: DocumentoPayload;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
}

interface ConsultaVeiculoTratorPlacaPayload {
  veiculoTrator: VeiculoTratorPayload;
  caminhao: boolean;
}

interface ModeloVeiculoTratorFilterPayload {
  idCliente?: number;
  marca: string;
  nome: string;
}

interface ModeloVeiculoTratorCambioPayload {
  idModeloCambio?: number | null;
  reducao?: number | null;
  numeroMarcha?: number | null;
}

interface ModeloVeiculoTratorPayload {
  idModeloVeiculoTrator?: number | null;
  nome?: string | null;
  apelido?: string | null;
  marca?: string | null;
  massaEixoTracao?: number | null;
  cmt?: number | null;
  potencia?: number | null;
  potenciaMaxima?: number | null;
  torqueMaximo?: number | null;
  reducaoMaximaCaixaMudanca?: number | null;
  reducaoEixo?: number | null;
  marchas: Array<ModeloVeiculoTratorCambioPayload>;
  cliente?: ClientePayload | null;
  permissionInfo?: PermissionPayload;
}

const useModeloVeiculoTratorService = () => {
  const { data, loading, get, post, put, del } = useApiWithAuth();

  const searchModeloVeiculoTrator = (
    filter: ModeloVeiculoTratorFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/veiculo/trator/modelo", {
      ...filter,
      ...{
        page: page
      }
    });
  };

  const getModeloVeiculoTrator = (
    idModeloVeiculoTrator: number
  ): Promise<void> => {
    return get(`/veiculo/trator/modelo/${idModeloVeiculoTrator}`);
  };

  const newModeloVeiculoTrator = (idCliente?: number | null): Promise<void> => {
    return get(
      "/veiculo/trator/modelo/new",
      idCliente ? { idCliente: idCliente } : {}
    );
  };

  const copyModeloVeiculoTrator = (
    idModeloVeiculoTrator: number
  ): Promise<void> => {
    return get("/veiculo/trator/modelo/new", {
      idModeloVeiculoOrigem: idModeloVeiculoTrator
    });
  };

  const deleteModeloVeiculoTrator = (
    idModeloVeiculoTrator: number
  ): Promise<void> => {
    return del("/veiculo/trator/modelo/" + idModeloVeiculoTrator);
  };

  const saveNewModeloVeiculoTrator = (
    modeloVeiculoTrator: ModeloVeiculoTratorPayload
  ): Promise<void> => {
    return post("/veiculo/trator/modelo", modeloVeiculoTrator);
  };

  const updateModeloVeiculoTrator = (
    idModeloVeiculoTrator: number,
    modeloVeiculoTrator: ModeloVeiculoTratorPayload
  ): Promise<void> => {
    return put(
      `/veiculo/trator/modelo/${idModeloVeiculoTrator}`,
      modeloVeiculoTrator
    );
  };

  const safeModeloVeiculoTrator = (
    entity?: ModeloVeiculoTratorPayload | null
  ): ModeloVeiculoTratorPayload => {
    const ret = entity ?? {
      marchas: []
    };
    ret.marchas = ret.marchas ?? [];
    return ret;
  };

  const safeFilterModeloVeiculoTrator = (): ModeloVeiculoTratorFilterPayload => {
    return {
      nome: "",
      marca: ""
    };
  };

  const getNomeModeloVeiculoTrator = (entity?: ModeloVeiculoTratorPayload) => {
    if (!entity) {
      return "";
    }
    return entity.apelido
      ? entity.apelido + " (Nome Oficial: " + entity.nome + ")"
      : entity.nome;
  };

  return {
    data,
    loading,
    searchModeloVeiculoTrator,
    deleteModeloVeiculoTrator,
    saveNewModeloVeiculoTrator,
    updateModeloVeiculoTrator,
    newModeloVeiculoTrator,
    copyModeloVeiculoTrator,
    getModeloVeiculoTrator,
    safeModeloVeiculoTrator,
    safeFilterModeloVeiculoTrator,
    getNomeModeloVeiculoTrator
  };
};

const useVeiculoTratorService = () => {
  const {
    data,
    loading,
    get,
    post,
    put,
    patch,
    downloadFileNewTab,
    downloadFile
  } = useApiWithAuth();

  const searchVeiculoTrator = (
    filter: VeiculoTratorFilterPayload,
    page: number
  ): Promise<void> => {
    return get("/veiculo/trator", {
      ...filter,
      ...{
        page: page
      }
    });
  };

  const getVeiculoTrator = (idVeiculoTrator: number): Promise<void> => {
    return get(`/veiculo/trator/${idVeiculoTrator}`);
  };

  const newVeiculoTrator = (idCliente?: number | null): Promise<void> => {
    return get(
      "/veiculo/trator/new",
      idCliente ? { idCliente: idCliente } : {}
    );
  };

  const shelveVeiculoTrator = (idVeiculoTrator: number): Promise<void> => {
    return patch("/veiculo/trator/" + idVeiculoTrator + "/arquivar");
  };

  const unshelveVeiculoTrator = (idVeiculoTrator: number): Promise<void> => {
    return patch("/veiculo/trator/" + idVeiculoTrator + "/desarquivar");
  };

  const saveNewVeiculoTrator = (
    veiculoTrator: VeiculoTratorPayload
  ): Promise<void> => {
    return post("/veiculo/trator", veiculoTrator);
  };

  const updateVeiculoTrator = (
    idVeiculoTrator: number,
    veiculoTrator: VeiculoTratorPayload
  ): Promise<void> => {
    return put(`/veiculo/trator/${idVeiculoTrator}`, veiculoTrator);
  };

  const getTracoesVeiculoTratorToCache = (): Promise<void> => {
    return get(`/veiculo/trator/tracao`);
  };

  const getTracoesVeiculoTrator = (
    apenasPrincipal?: boolean
  ): Array<TracaoPayload> => {
    return useCache()
      .getTracoesVeiculoTrator()
      .filter((e) => !apenasPrincipal || !e.variante);
  };

  const getTracaoVeiculoTrator = (
    param?: string | null | Array<number>,
    id?: string | null
  ): TracaoPayload | null => {
    if (!param || (Array.isArray(param) && (!param.length || !id))) {
      return null;
    }
    const isArray = Array.isArray(param);
    return (
      getTracoesVeiculoTrator(!isArray).find((a) => {
        if (isArray) {
          const tracaoOriginal = getTracaoVeiculoTrator(id);
          if (a.eixosTracao == tracaoOriginal?.eixosTracao) {
            let isEq = param.length == a.disposicaoEixos.length;
            if (isEq) {
              for (let i = 0; i < param.length; i++) {
                if (param[i] != a.disposicaoEixos[i]) {
                  isEq = false;
                  break;
                }
              }
            }
            return isEq;
          }
          return false;
        } else {
          return a.id == param && !a.variante;
        }
      }) ?? null
    );
  };

  const getTiposVeiculoTratorToCache = (): Promise<void> => {
    return get(`/veiculo/trator/tipo`);
  };

  const getTiposVeiculoTrator = (): Array<TipoVeiculoTratorPayload> => {
    return useCache().getTiposVeiculoTrator();
  };

  const getTipoVeiculoTrator = (
    flTipoVeiculoTrator?: string | null
  ): TipoVeiculoTratorPayload | null => {
    if (!flTipoVeiculoTrator) {
      return null;
    }

    return (
      getTiposVeiculoTrator().find(
        (a) => a.flTipoVeiculoTrator == flTipoVeiculoTrator
      ) ?? null
    );
  };

  const getDirecoesDisponiveis = (): Promise<void> => {
    return get(`/veiculo/trator/direcao`);
  };

  const safeVeiculoTrator = (
    entity?: VeiculoTratorPayload | null
  ): VeiculoTratorPayload => {
    const ret =
      entity ??
      ({
        transportador: useTransportadorService().safeTransportador(),
        cor: {},
        municipio: {},
        tipoVeiculoTrator: {},
        cnhMotorista: {},
        modeloVeiculo: useModeloVeiculoTratorService().safeModeloVeiculoTrator()
      } as VeiculoTratorPayload);

    ret.transportador =
      ret.transportador ?? useTransportadorService().safeTransportador();
    ret.cor = ret.cor ?? {};
    ret.municipio = ret.municipio ?? {};
    ret.cnhMotorista = ret.cnhMotorista ?? {};
    ret.cnhMotorista.keyBlDocumentoTemp = null;
    ret.modeloVeiculo =
      ret.modeloVeiculo ??
      useModeloVeiculoTratorService().safeModeloVeiculoTrator();
    return ret;
  };

  const safeFilterVeiculoTrator = (): VeiculoTratorFilterPayload => {
    return {
      placaNf: "",
      arquivado: false
    };
  };

  const downloadCRLV = (
    idVeiculoTrator: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/veiculo/trator/${idVeiculoTrator}/download/crlv`
    );
  };

  const downloadCNHMotorista = (
    idVeiculoTrator: number,
    newTab?: boolean
  ): Promise<void> => {
    return (newTab ? downloadFileNewTab : downloadFile)(
      `/veiculo/trator/${idVeiculoTrator}/download/cnh-motorista`
    );
  };

  const loadUsingPlateNumber = (
    placa: string,
    isRefresh: boolean,
    cliente?: ClientePayload | null
  ): Promise<void> => {
    const params: Record<string, boolean | number> = { refresh: isRefresh };
    if (cliente && cliente.idCliente) {
      params.idCliente = cliente.idCliente;
    }
    return get("/veiculo/trator/busca/" + placa, params);
  };

  const getNomeVeiculoTrator = (entity?: VeiculoTratorPayload) => {
    if (!entity) {
      return "";
    }
    return entity.placa ? entity.placa : entity.notaFiscal;
  };

  return {
    data,
    loading,
    searchVeiculoTrator,
    saveNewVeiculoTrator,
    updateVeiculoTrator,
    newVeiculoTrator,
    getVeiculoTrator,
    safeVeiculoTrator,
    safeFilterVeiculoTrator,
    shelveVeiculoTrator,
    unshelveVeiculoTrator,
    downloadCRLV,
    downloadCNHMotorista,
    getNomeVeiculoTrator,
    getDirecoesDisponiveis,
    getTiposVeiculoTratorToCache,
    getTiposVeiculoTrator,
    getTipoVeiculoTrator,
    getTracoesVeiculoTratorToCache,
    getTracoesVeiculoTrator,
    getTracaoVeiculoTrator,
    loadUsingPlateNumber
  };
};

const useFilaVeiculoTratorService = () => {
  const { data, loading, get, patch } = useApiWithAuth();

  const searchFilaVeiculoTrator = (idVeiculo: number): Promise<void> => {
    return get(`/veiculo/trator/${idVeiculo}/fila`);
  };

  const playFilaVeiculoTrator = (
    idVeiculo: number,
    idFilaVeiculo: number | Array<number>
  ): Promise<void> => {
    if (Array.isArray(idFilaVeiculo)) {
      return patch(
        `/veiculo/trator/${idVeiculo}/fila/solicitar`,
        idFilaVeiculo
      );
    }
    return patch(
      `/veiculo/trator/${idVeiculo}/fila/${idFilaVeiculo}/solicitar`
    );
  };

  const markAsOkFilaVeiculoTrator = (
    idVeiculo: number,
    idFilaVeiculo: number | Array<number>
  ): Promise<void> => {
    if (Array.isArray(idFilaVeiculo)) {
      return patch(
        `/veiculo/trator/${idVeiculo}/fila/finalizar`,
        idFilaVeiculo
      );
    }
    return patch(
      `/veiculo/trator/${idVeiculo}/fila/${idFilaVeiculo}/finalizar`
    );
  };

  const getNomeFilaVeiculoTrator = (entity?: FilaVeiculoPayload) => {
    return useFilaVeiculoService().getNomeFilaVeiculo(entity);
  };

  return {
    data,
    loading,
    searchFilaVeiculoTrator,
    playFilaVeiculoTrator,
    markAsOkFilaVeiculoTrator,
    getNomeFilaVeiculoTrator
  };
};

export {
  useVeiculoTratorService,
  useModeloVeiculoTratorService,
  useFilaVeiculoTratorService,
  VeiculoTratorFilterPayload,
  VeiculoTratorPayload,
  TracaoPayload,
  DirecaoVeiculoTratorPayload,
  TipoVeiculoTratorPayload,
  ConsultaVeiculoTratorPlacaPayload,
  ModeloVeiculoTratorFilterPayload,
  ModeloVeiculoTratorPayload
};
